import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import ChevronDownIcon from '../../../../icons/mono/ChevronDownIcon';
import ChevronUpIcon from '../../../../icons/mono/ChevronUpIcon';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import FaqCategoryBadgeList from '../faqCategoryBadgeList/FaqCategoryBadgeList';
import {useSelector} from 'react-redux';
import {FAQ_TOP_CATEGORY_ID} from 'shared/src/constants/faq';

const useStyles = makeStyles(theme => ({
    layout: {
        border: '2px solid #D9D9D9',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px',
        }
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        height: '24px',
        width: '24px',
        minHeight: '24px',
        minWidth: '24px',
        borderRadius: '24px',
        backgroundColor: '#E6E6E6',
        cursor: 'pointer',
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: '6px',
        left: '6px',
        width: '12px',
        height: '12px',
        minWidth: '12px',
        minHeight: '12px',
    },
    content: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        '&.paragraph3': {
            color: '#5C5C66',
            '& > p': {
                margin: '0px',
                padding: '0px',
            }
        }
    },
    categoriesTitle: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        },
        '&.paragraph3': {
            fontWeight: 'bold',
        }
    },
    badgeList: {
        marginTop: '4px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '8px',
        },
    }
}));

export default function FaqCategoryListItem(props) {
    const { faq, isOpen } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const faqCategories = useSelector(state => state.faq.faqCategories);

    const { t } = useTranslation();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    function handleClick() {
        setOpen(!open);
    }

    if (!faqCategories) {
        return null;
    }


    function getBadgeCategories(faqCategories, faq) {
        return faqCategories.filter(faqCategory => faq.faqCategoryIds.includes(faqCategory.id) && faqCategory.id !== FAQ_TOP_CATEGORY_ID);
    }

    return (
        <div className={classes.layout}>
            <div className={classes.titleRow}>
                <h5>
                    {faq.title}
                </h5>
                <div className={classes.button} onClick={handleClick}>
                    {open ?
                        <ChevronUpIcon className={classes.icon} fill={'#5C5C66'}/>
                        :
                        <ChevronDownIcon className={classes.icon} fill={'#5C5C66'}/>
                    }
                </div>
            </div>
            {open &&
            <>
                <div className={clsx('paragraph3', classes.content)}
                     dangerouslySetInnerHTML={createMarkup(faq.summary)}/>
                <div className={clsx('paragraph3', classes.categoriesTitle)}>
                    {t('Text.Categories')}
                </div>
                <FaqCategoryBadgeList className={classes.badgeList}
                                      faqCategories={getBadgeCategories(faqCategories, faq)}/>
            </>
            }
        </div>
    );
}
