import React from 'react';
import EducationContentBar from '../components/contents/education/EducationContentBar';
import ContentScreen from '../components/pages/ContentScreen';

export default function Education() {
    return (
        <ContentScreen>
            <EducationContentBar/>
        </ContentScreen>
    );
}
