import React, {useEffect, useRef} from 'react';
import Routes from "./Routes";
import 'url-search-params-polyfill';
import {useDispatch, useStore} from 'react-redux';
import {useTranslation} from 'react-i18next';
import GlobalCss from '../styles/GlobalCSS';
import {errorActions, menuActions, settingActions, simpleAction} from 'client_shared/src/state/actions';
import {rootStoreActions} from 'client_shared/src/state/reducers';
import _ from 'lodash';
import {badgeActions} from 'client_shared/src/state/actions/badgeActions';
import {useLocation} from 'react-router';
import {pageActions} from 'client_shared/src/state/actions/pageActions';
import {contentService as pageService} from 'client_shared/src/services/pageService';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';

function App() {
    const { i18n: { language } } = useTranslation();
    const previousLanguageRef = useRef(language);
    const store = useStore();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        async function initState() {
            Promise.all(await App.initState({ dispatch, storeState: store.getState(), path: location.pathname }));
        }

        const languageChanged = (previousLanguageRef.current !== language);
        if (languageChanged) {
            dispatch(simpleAction(rootStoreActions.RESET_STORE, {}));
        }
        previousLanguageRef.current = language;

        initState().catch(error => console.log(error));
    }, [language, dispatch, store, location]);

    return (
        <GlobalCss>
            <Routes/>
        </GlobalCss>
    );
}

App.initState = async (environment) => {
    const { dispatch, storeState } = environment;

    let promises = [];

    if (_.isEmpty(storeState.setting.settings)) {
        promises.push(dispatch(settingActions.loadSettings()));
    }

    if (_.isEmpty(storeState.menu.menus)) {
        promises.push(dispatch(menuActions.loadMenus()));
    }

    if (_.isEmpty(storeState.menu.badges)) {
        promises.push(dispatch(badgeActions.loadBadges()));
    }

    if (_.isEmpty(storeState.checkout.order)) {
        promises.push(dispatch(checkoutActions.loadOrder()));
    }

    let pages = storeState.page.pages;
    if (_.isEmpty(storeState.page.pages)) {
        try {
            pages = await pageService.getPages();
            promises.push(dispatch(pageActions.setPages(pages)));
        } catch (error) {
            promises.push(dispatch(errorActions.setError(error)));
        }
    }

    promises = [...promises, ...(await Routes.initState({ ...environment, pages }))];

    return promises;
};

export default App;