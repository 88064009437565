import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {getPathWithLanguageForLanguageChange} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {LANGUAGES} from 'shared/src/constants/translation';
import {useTranslation} from 'react-i18next';
import SvgChevronDownSmaller from '../../../icons/mono/ChevronDownSmallerIcon';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useHistory} from 'react-router';

const useStyles = makeStyles(theme => ({
    selected: {
        '&.paragraph3': {
            color: '#2E2E33',
        },
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    hidden: {
        display: 'none',
    },
    menuPopover: {
        border: 'none',
        padding: '0',
        zIndex: 1200,
        '& > *': {
            borderRadius: '0px',
            boxShadow: 'none',
        }
    },
    menu: {
        zIndex: 1200,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },
    mainMenuButton: {
        border: 'none',
        textAlign: 'center',
        verticalAlign: 'middle',
        cursor: 'pointer',
        backgroundColor: 'white',
        fontFamily: theme.typography.fontFamily,
    },
    menuButton: {
        border: 'none',
        padding: '10px',
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: 'white',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
    },
    menuItem: {
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: 'white',
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "inherit",
        color: "inherit",
        '&:hover': {
            cursor: 'pointer',
            fontWeight: 'bold',
        }
    },
    icon: {
        width: '15px',
        color: '#2E2E33'
    }
}));

export default function LanguageMenu() {
    const classes = useStyles();
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const history = useHistory();
    const { i18n } = useTranslation();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.location.reload();
        });
        return () => {
            unlisten();
        };
    }, [history]);

    function handleClick(event) {
        if (anchorEl) {
            setAnchorEl(null);
            return;
        }
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    const currentLanguage = LANGUAGES.find(language => language.code === i18n.language);

    const handleMenuItemClick = (language) => {
        history.push(getPathWithLanguageForLanguageChange(ROUTES_ANP.HOME, language.code));
    };


    if (!currentLanguage) {
        return null;
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleClose}>
                <div>
                    <button
                        ref={anchorRef}
                        className={clsx('paragraph3', classes.selected, classes.mainMenuButton)}
                        onClick={handleClick}
                        aria-controls={open ? 'language-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="menu"
                    >
                        {currentLanguage.shortName}
                        <SvgChevronDownSmaller className={classes.icon}/>
                    </button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        disablePortal
                        role={undefined}
                        className={clsx(classes.menuPopover)}
                    >
                        <MenuList id={'language-menu'} className={clsx(classes.menu)}>
                            {LANGUAGES.map(language => (
                                <MenuItem
                                    className={classes.menuItem}
                                    key={language.id}
                                    onClick={(event) => handleMenuItemClick(language)}
                                >
                                    {language.shortName}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Popper>
                </div>
            </ClickAwayListener>
        </>
    );
}
