export const pageModule = {
    getPageByType,
    getParentPages,
    getBreadCrumbsFromPageList
};

function getPageByType(pages, pageType) {
    return pages.find(page => page.type === pageType);
}

function getParentPages(pages, page, includePage = true) {
    const pageMap = pages.reduce((accumulator, pageItem) => {
        return {...accumulator, [pageItem.id]: pageItem};
    }, {});

    let currentPage = page;
    let parentPages = includePage ? [currentPage] : [];
    while (currentPage && currentPage.parentPageId !== null ) {
        currentPage = pageMap[currentPage.parentPageId];
        parentPages = [currentPage, ...parentPages];
    }

    return parentPages;
}

function getBreadCrumbsFromPageList(pages) {
    return pages.map(page => ({title: page.name, url: page.url}))
}
