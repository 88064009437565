import React from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import MicroSiteHeader from '../components/contents/microSite/MicroSiteHeader';
import {useSelector} from 'react-redux';
import {contentActions} from 'client_shared/src/state/actions';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import PromotionWide from '../components/contents/promotions/PromotionWide';
import ArticleList from '../components/contents/microSite/lists/articleList/ArticleList';
import PromotionWideBig from '../components/contents/promotions/PromotionWideBig';

const useStyles = makeStyles(theme => ({
    contentLayout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '64px',
    },
    videoBar: {
        '&.padding': {
            paddingTop: '32px',
            paddingBottom: '32px',
            [theme.breakpoints.up('lg')]: {
                paddingTop: '64px',
                paddingBottom: '70px'
            }
        },
        background: 'linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%)',
    },
    promotionBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: '32px 0px 0px 0px',
        [theme.breakpoints.up('lg')]: {
            margin: '48px 0px 0px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '64px 0px 0px 0px',
        }
    },
    articlesBar: {},
    promotionWideBigBar: {
        margin: '32px 0px',
        [theme.breakpoints.up('lg')]: {
            margin: '48px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '64px 0px',
        }
    }
}));


function MicroSite() {
    const classes = useStyles();
    const page = useSelector(state => state.page.page);
    const content = useSelector(state => state.content.content);
    const promotion = useSelector(state => state.content.promotion);
    const promotionBig = useSelector(state => state.content.promotionBig);
    const microSiteArticles = useSelector(state => state.content.articles);
    /*const microSiteVideos = useSelector(state => state.content.videos);*/

    return (
        <ContentScreen showTitleBar={false}>
            {page && content &&
            <>
                <MicroSiteHeader microSite={page} content={content}/>
                {/*
                <div className={clsx('padding', classes.videoBar)}>
                    <VideoList contents={microSiteVideos}/>
                </div>
*/}
                <div className={clsx('padding', classes.articlesBar)}>
                    <ArticleList page={page} articles={microSiteArticles}/>
                </div>
                <div className={clsx('padding', classes.promotionBar)}>
                    <PromotionWide promotion={promotion}/>
                </div>
                <div className={clsx('padding', classes.promotionWideBigBar)}>
                    <PromotionWideBig promotion={promotionBig}/>
                </div>
            </>
            }
        </ContentScreen>
    );
}

MicroSite.initState = (environment) => {
    const { dispatch, page } = environment;

    const promises = [];

    if (page.contentId) {
        promises.push(dispatch(contentActions.loadContent(page.contentId)));
    }

    promises.push(dispatch(contentActions.loadArticles(page.id)));

    promises.push(dispatch(contentActions.loadVideos(page.id)));

    return promises;
};

export default MicroSite;