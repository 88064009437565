import * as React from "react";

function SvgChevronRight(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M37.9 32L14.6 9.1c-2.1-2.1-2.1-5.5 0-7.5s5.6-2.1 7.7 0l27.1 26.7c2.1 2.1 2.1 5.5 0 7.5L22.3 62.4c-2.1 2.1-5.6 2.1-7.7 0-2.1-2.1-2.1-5.5 0-7.5L37.9 32z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgChevronRight;
