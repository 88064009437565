import * as React from "react";

function Svg05Oltozkodes(props) {
    return (
        <svg width={16} height={14} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M3.994 12.344v.485c0 .468.414.848.923.848h6.154c.51 0 .923-.38.923-.848v-.485h-8z"
                    fill="#5C5C66"
                />
                <path
                    d="M14.3 3.498C14.081 1.7 12.61.344 10.872.344h-.368a.321.321 0 00-.314.328c0 .463-.94.982-2.196.982-1.258 0-2.197-.519-2.197-.982 0-.181-.141-.328-.314-.328h-.368c-1.738 0-3.21 1.357-3.425 3.152L.833 9.954a1.343 1.343 0 001.114 1.503 1.251 1.251 0 001.444-1.083l.696-5.681h.302l-.475 6.23h8.16l-.455-6.23.303-.016.748 5.74a1.187 1.187 0 001.411 1.01 1.358 1.358 0 001.078-1.513l-.86-6.416z"
                    fill="#17171A"
                />
            </g>
        </svg>
    );
}

export default Svg05Oltozkodes;
