import React from 'react';
import {makeStyles} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useSelector} from 'react-redux';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import clsx from 'clsx';
import ParkInfoList from '../../../contents/park/lists/parkInfoList/ParkInfoList';
import ParkIconList from '../../../contents/park/lists/parkIconList/ParkIconList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: '0px',
        }
    },
    layoutColumn: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '& > *:last-child': {
            marginBottom: '24px',
            [theme.breakpoints.up('lg')]: {
                marginBottom: '0px',
            }
        }
    },
    title: {
        margin: '32px 0px 24px 0px',
    },
    tabletPadding: {
        paddingLeft: '32px',
        paddingRight: '32px',
    },
    layoutSideMenu: {
        '&.padding': {
            paddingTop: '24px',
            paddingBottom: '32px',
            [theme.breakpoints.up('sm')]: {
                paddingTop: '24px',
                paddingBottom: '32px',
            },
            [theme.breakpoints.up('lg')]: {
                paddingTop: '0px',
                paddingBottom: '0px',
            }
        },
        borderTop: '1px solid rgba(23,23,26, 0.1)',
        [theme.breakpoints.up('lg')]: {
            borderTop: '0px',
        }
    },
}));

export default function MainMenuPopoverPark() {
    const classes = useStyles();

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);
    const isMobile = !isTablet && !isDesktop;

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_MAIN, user);
    const parkMenu = menu.find(menuItem => menuItem.index === MENU.MAIN_MENU_ANP.PARK);

    return (
        <div className={clsx(isDesktop && 'padding', classes.layout)}>
            <div
                className={clsx((isMobile && 'padding') || (isTablet && !isDesktop && classes.tabletPadding), classes.layoutColumn)}>
                {!isDesktop &&
                <h4 className={classes.title}>
                    {parkMenu.title}
                </h4>
                }
                <ParkIconList isMenu={true}/>
            </div>
            <div
                className={clsx((isMobile && 'padding') || (isTablet && !isDesktop && classes.tabletPadding), classes.layoutSideMenu)}>
                <ParkInfoList isMenu={true}/>
            </div>
        </div>
    );
}
