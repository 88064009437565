import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import BadgeList from '../badge/lists/badgeList/BadgeList';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        position: 'relative',
        width: '100%',
        height: '182px',
        marginTop: '0px',
        [theme.breakpoints.up('sm')]: {
            height: '230px',
            marginTop: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '400px',
            marginTop: '24px',
        },
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url("/content/images/${props.news.bannerImage || props.news.headerImage}")`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }),
    titleLayoutWrapper: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    titleLayout: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '32px 15px',
        '@media (min-width:400px)': {
            padding: '32px 20px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '32px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '48px 100px',
        },
        '& > * + *': {
            marginTop: '21px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '16px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '24px',
            }
        },
        '& h1': {
            color: '#FFFFFF',
        }
    },
    badgeRow: {
        display: 'flex',
        alignItems: 'center',
    },
    publishedText: {
        marginLeft: '14px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '16px',
        },
        '&.paragraph3': {
            color: '#FFFFFF',
            fontWeight: 'bold',
        }
    },
    dateText: {
        '&.paragraph4': {
            color: '#FFFFFF',
        },
        marginLeft: '8px',
    }
}));
export default function NewsArticleHeader(props) {
    const { news } = props;
    const classes = useStyles(props);

    const { t } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={clsx('padding-article', classes.titleLayoutWrapper)}>
                <div className={classes.titleLayout}>
                    <h1>
                        {news.title}
                    </h1>
                    <div className={classes.badgeRow}>
                        <BadgeList badgeIds={news.badgeIds}/>
                        <div className={clsx('paragraph3', classes.publishedText)}>
                            {t('Text.Published')}
                        </div>
                        <div className={clsx('paragraph4', classes.dateText)}>
                            {moment(news.date).format('LL')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
