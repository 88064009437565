import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import Home from '../screens/Home';
import NotFound from '../screens/NotFound';
import Tours from '../screens/Tours';
import CaveTours from '../screens/CaveTours';
import Payment from '../screens/Payment';
import Tour from '../screens/Tour';
import Locations from '../screens/Locations';
import PaymentLanding from '../screens/PaymentLanding';
import Maps from '../screens/Maps';
import Park from '../screens/Park';
import {getLanguageFromLocation, getPathParserWithLanguage} from 'shared/src/helpers/pathHelper';
import {useTranslation} from 'react-i18next';
import Education from '../screens/Education';
import {contentActions, menuActions} from 'client_shared/src/state/actions';
import {matchPath} from 'react-router';
import {handleClientError} from 'shared/src/helpers/errorHelper';
import {useDispatch, useSelector} from 'react-redux';
import News from '../screens/News';
import Content from '../screens/Content';
import Faq from '../screens/Faq';
import Search from '../screens/Search';
import Accommodations from '../screens/Accommodations';
import Accommodation from '../screens/Accommodation';
import TourGuides from '../screens/TourGuides';
import TourGuide from '../screens/TourGuide';
import AdvancedCaveTours from '../screens/AdvancedCaveTours';
import WalkingTours from '../screens/WalkingTours';
import CyclingTours from '../screens/CyclingTours';
import HorseTours from '../screens/HorseTours';
import OtherProgrammes from '../screens/OtherProgrammes';
import TourPackages from '../screens/TourPackages';
import {PAGE} from 'shared/src/constants/pages';
import {pageActions} from 'client_shared/src/state/actions/pageActions';
import MicroSite from '../screens/MicroSite';
import NewsArticle from '../screens/NewsArticle';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';
import ContentArticle from '../screens/ContentArticle';

const mapPageTypeToComponent = {
    [PAGE.TYPE.HOME]: Home,
    [PAGE.TYPE.MAPS]: Maps,
    [PAGE.TYPE.TOURS]: Tours,
    [PAGE.TYPE.CAVE_TOURS]: CaveTours,
    [PAGE.TYPE.ADVANCED_CAVE_TOURS]: AdvancedCaveTours,
    [PAGE.TYPE.WALKING_TOURS]: WalkingTours,
    [PAGE.TYPE.CYCLING_TOURS]: CyclingTours,
    [PAGE.TYPE.HORSE_TOURS]: HorseTours,
    [PAGE.TYPE.OTHER_PROGRAMMES]: OtherProgrammes,
    [PAGE.TYPE.TOUR_PACKAGES]: TourPackages,
    [PAGE.TYPE.TOUR]: Tour,
    [PAGE.TYPE.LOCATIONS]: Locations,
    [PAGE.TYPE.PARK]: Park,
    [PAGE.TYPE.EDUCATION]: Education,
    [PAGE.TYPE.NEWS]: News,
    [PAGE.TYPE.FAQ]: Faq,
    [PAGE.TYPE.SEARCH]: Search,
    [PAGE.TYPE.ACCOMMODATIONS]: Accommodations,
    [PAGE.TYPE.ACCOMMODATION]: Accommodation,
    [PAGE.TYPE.TOUR_GUIDES]: TourGuides,
    [PAGE.TYPE.TOUR_GUIDE]: TourGuide,
    [PAGE.TYPE.PAYMENT]: Payment,
    [PAGE.TYPE.PAYMENT_ACKNOWLEDGEMENT]: PaymentLanding,
    [PAGE.TYPE.PAYMENT_TIMEOUT]: PaymentLanding,
    [PAGE.TYPE.CONTENT]: Content,
    [PAGE.TYPE.MICRO_SITE]: MicroSite,
    [PAGE.TYPE.SEARCH]: Search,
    [PAGE.TYPE.NEWS_ARTICLE]: NewsArticle,
    [PAGE.TYPE.CONTENT_ARTICLE]: ContentArticle,
};

function getPathDefs(pages) {
    if (!pages) {
        return [];
    }

    let pathDefs = pages.map(page => ({
        path: getPathParserWithLanguage(`${page.url}/:id?`),
        exact: true,
        component: mapPageTypeToComponent[page.type],
        page: page
    }));

    return pathDefs;
}

function Routes() {
    const location = useLocation();
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const pages = useSelector(state => state.page.pages);
    const [pathDefs, setPathDefs] = useState(getPathDefs(pages));

    useEffect(() => {
        if (pages.length === 0) {
            return;
        }

        const pathDefs = getPathDefs(pages);
        setPathDefs(pathDefs);
    }, [pages]);

    useEffect(() => {
        if (!i18n.language) {
            return;
        }

        const language = getLanguageFromLocation(location);
        if (language !== i18n.language) {
            i18n.changeLanguage(language).catch(handleClientError);
        }
    }, [location, i18n]);

    useEffect(() => {
        dispatch(menuActions.hideMobileMenuItem());
        dispatch(menuActions.hideMobileMenu());
        dispatch(menuActions.hideMainMenuItem());
        dispatch(checkoutActions.interruptCheckout());
        dispatch(checkoutActions.setPaymentInfo(null));
        if (URLSearchParams && location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.get('checkout')) {
                dispatch(checkoutActions.launchCheckout());
            }
        }

        window.scrollTo(0, 0);
    }, [location, dispatch]);

    return (
        <Switch>
            {pathDefs.map((pathDef, index) =>
                <Route key={index} path={pathDef.path} exact={pathDef.exact} component={pathDef.component}/>
            )}
            <Route component={NotFound}/>
        </Switch>
    );
}

Routes.initState = async (environment) => {
    const { dispatch, path, pages } = environment;

    let promises = [];

    const pathDefs = getPathDefs(pages);
    const pathDef = pathDefs.find(pathDef => matchPath(path, pathDef));

    if (!pathDef) {
        return promises;
    }

    const { params } = matchPath(path, pathDef);

    const page = pathDef.page;

    dispatch(pageActions.setPage(page));

    if (params.parent) {
        const parentPathDef = pathDefs.find(pathDef => matchPath(`/${params.parent}`, pathDef));
        if (parentPathDef) {
            dispatch(pageActions.setParentPage(parentPathDef.page));
        }
    } else {
        dispatch(pageActions.setParentPage(null));
    }

    promises.push(dispatch(contentActions.loadPromotion(page.promotionId)));

    promises.push(dispatch(contentActions.loadPromotionBig(page.promotionBigId)));

    if (pathDef && pathDef.component.initState) {
        promises = [...promises, ...(await pathDef.component.initState({ ...environment, params, page }))];
    }

    return promises;
};

export default Routes;
