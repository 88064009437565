import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        borderRadius: '12px',
        borderWidth: '0px',
        backgroundColor: '#5C5C66',
        boxShadow: '0 3px 0 0 rgba(23,23,26,0.1)',
        paddingLeft: '25px',
        paddingRight: '25px',
        textTransform: 'uppercase',
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#8A8A99',
            boxShadow: '0 3px 10px 0 rgba(23,23,26,0.5)',
        },
        '&:active': {
            cursor: 'pointer',
            backgroundColor: '#45454D',
            boxShadow: 'none',
        },
        '&:focus': {
            /*outline: '0 !important',*/
        },
    },
    variantBig: {
        '&.paragraph3': {
            fontWeight: 'bold',
            color: theme.palette.common.white,
        },
        height: '44px',
        whiteSpace: 'nowrap',
    },
    variantNormal: {
        '&.paragraph4': {
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
        height: '34px',
        whiteSpace: 'nowrap',
    },
}));

export default function PrimaryBasicButton(props) {
    const { title, variant, className, type = 'button', onClick } = props;
    const classes = useStyles();

    return (
        <button
            className={clsx(classes.layout, variant === 'big' ? clsx('paragraph3', classes.variantBig) : clsx('paragraph4', classes.variantNormal), className)}
            type={type}
            onClick={onClick}
        >
            {title}
        </button>
    );
}
