import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / kempingezes"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#AABCF2" cx={24} cy={24} r={24}/>
                <path
                    d="M14.813 30.375h.521l7.153-12.467-2.25-3.938a1.314 1.314 0 112.277-1.312L24 15.257l1.486-2.599a1.314 1.314 0 012.278 1.312l-2.251 3.938 7.153 12.467h.522a1.312 1.312 0 110 2.625h-5.976l-2.362-4.725a.948.948 0 00-1.7 0L20.788 33h-5.976a1.312 1.312 0 110-2.625z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent