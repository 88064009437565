import React from 'react';
import {makeStyles} from '@material-ui/core';
import EducationIconMenuList from '../../../contents/education/lists/educationIconMenuList/EducationIconMenuList';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useSelector} from 'react-redux';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: '0px',
        }
    },
    title: {
        margin: '32px 0px 24px 0px',
    },
    tabletPadding: {
        paddingLeft: '32px',
        paddingRight: '32px',
    }
}));

export default function MainMenuPopoverEducation() {
    const classes = useStyles();

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_MAIN, user);
    const educationMenu = menu.find(menuItem => menuItem.index === MENU.MAIN_MENU_ANP.EDUCATION);

    return (
        <div className={clsx(isDesktop && 'padding', classes.layout)}>
            <div
                className={clsx((!isTablet && !isDesktop && 'padding') || (isTablet && !isDesktop && classes.tabletPadding), classes.layout)}>
                {!isDesktop &&
                <h4 className={classes.title}>
                    {educationMenu.title}
                </h4>
                }
                <EducationIconMenuList isMenu={true}/>
            </div>
        </div>
    );
}
