import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / allatbarat"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#E6C4AE" cx={24} cy={24} r={24}/>
                <path
                    d="M24 21c.26 0 .53 0 .78.04.11.02.22.05.33.09.7.24 1.29.78 1.75 1.32.88 1.02 1.61 1.89 2.48 2.91 1.31 1.31 2.92 2.76 2.61 4.8-.29 1.01-1.02 2.03-2.33 2.32-.73.14-3.06-.44-5.54-.44h-.18c-2.48 0-4.81.59-5.54.44-1.31-.29-2.04-1.3-2.33-2.32-.3-2.03 1.31-3.48 2.62-4.79.88-1.02 1.61-1.89 2.48-2.91.47-.54 1.05-1.08 1.75-1.32.11-.04.22-.07.33-.09.26-.05.53-.05.79-.05zm-7.5-3.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm15 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-10.5-4a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm6 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent