import * as React from "react";

function SvgEnvironmentalEducation(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M32 64c14.9 0 27-12.3 27-27.4-14.9 0-27 12.3-27 27.4zM12.8 28.2c0 4.2 3.4 7.6 7.5 7.6 1.6 0 3-.5 4.3-1.3l-.1.5c0 4.2 3.4 7.6 7.5 7.6s7.5-3.4 7.5-7.6l-.1-.6c1.2.9 2.7 1.3 4.3 1.3 4.1 0 7.5-3.4 7.5-7.6 0-3-1.8-5.6-4.3-6.9 2.5-1.2 4.3-3.8 4.3-6.9 0-4.2-3.4-7.6-7.5-7.6-1.6 0-3 .5-4.3 1.3l.1-.6c0-4-3.4-7.4-7.5-7.4s-7.5 3.4-7.5 7.6l.1.6c-1.2-.9-2.7-1.3-4.3-1.3-4.1 0-7.5 3.4-7.5 7.6 0 3 1.8 5.6 4.3 6.9-2.5 1.2-4.3 3.7-4.3 6.8zM32 13.7c4.1 0 7.5 3.4 7.5 7.6S36.1 29 32 29s-7.5-3.4-7.5-7.6 3.4-7.7 7.5-7.7zM5 36.6C5 51.7 17.1 64 32 64c0-15.1-12.1-27.4-27-27.4z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgEnvironmentalEducation;
