import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import MainMenu from '../menus/main/MainMenu';
import SearchBar from '../../search/SearchBar';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import MobileMenuButton from '../../buttons/MobileMenuButton';
import MobileMenuPopover from '../menus/mobile/MobileMenuPopover';
import CheckoutPopover from '../../checkout/CheckoutPopover';
import MainMenuPopover from '../menus/main/MainMenuPopover';
import TicketsButton from '../../buttons/TicketsButton';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    layoutMenuBarRight: {
        flex: 1,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: '16px',
        },
        [theme.breakpoints.up('sm')]: {
            '& > * + *': {
                marginLeft: '25px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            '& > *': {
                marginLeft: '16px',
            },
        }
    },
    mainMenu: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
        }
    },
    searchBar: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        }
    },
    logo: {
        height: '40px',
        [theme.breakpoints.up('sm')]: {
            height: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
    },
    logoLink: {
        height: '40px',
        [theme.breakpoints.up('sm')]: {
            height: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        },
    },
    ticketsButton: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
            flex: 0,
        }
    },
    mobileMenuButton: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    }
}));

export default function HeaderBarBottom(props) {
    const { className, mouseMoveHandler, headerBarRef } = props;
    const classes = useStyles();

    const { i18n } = useTranslation();

    const [isInSearchMode, setIsInSearchMode] = useState(false);


    return (
        <div className={clsx(classes.layout, className)}>
            <Link to={getPathWithLanguage(ROUTES_ANP.HOME, i18n.language)} className={classes.logoLink}>
                <img src='/assets/logo-black.png' alt='ANPI logo' className={classes.logo}/>
            </Link>
            <div className={classes.layoutMenuBarRight}>
                {!isInSearchMode && <MainMenu className={classes.mainMenu} mouseMoveHandler={mouseMoveHandler}/>}
                <SearchBar className={classes.searchBar} isInSearchMode={isInSearchMode}
                           setIsInSearchMode={setIsInSearchMode}/>
                <div className={classes.ticketsButton}>
                    <TicketsButton/>
                </div>
                <div className={classes.mobileMenuButton}>
                    <MobileMenuButton/>
                </div>
            </div>
            <CheckoutPopover headerBarRef={headerBarRef}/>
            <MobileMenuPopover headerBarRef={headerBarRef}/>
            <MainMenuPopover mouseMoveHandler={mouseMoveHandler}/>
        </div>
    );
}
