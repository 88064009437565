import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import TourGuideListItem from './TourGuideListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        '& > * + *': {
            marginTop: '12px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '14px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '24px'
            }
        }
    }
}));

export default function TourGuideList(props) {
    const { className, tourGuides } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.layout, className)}>
            {tourGuides.map(tourGuide =>
                <TourGuideListItem key={tourGuide.id} tourGuide={tourGuide}/>
            )}
        </div>
    );
}
