import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, TextField} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';
import {useTranslation} from 'react-i18next';
import SearchIcon from '../icons/mono/SearchIcon';
import {useHistory} from 'react-router';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import clsx from 'clsx';

function RenderInput(inputProps) {
    const { InputProps, classes, ref, isInSearchMode, ...other } = inputProps;
    const inputRef = useRef(null);

    useEffect(() => {
        if (isInSearchMode) {
            inputRef.current.focus();
        }
    }, [isInSearchMode]);

    return (
        <TextField
            InputProps={{
                inputRef: inputRef,
                disableUnderline: true,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem && suggestion && selectedItem.id === suggestion.id);

    let text = '';

    return (
        <MenuItem
            {...itemProps}
            key={index}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {text}
        </MenuItem>
    );
}

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        width: '100%',
    },
    searchIcon: (props) => ({
        display: props.focused ? 'none' : 'block',
        position: 'absolute',
        zIndex: 1002,
        top: '16px',
        right: '16px',
        width: '12px',
        height: '12px',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            top: '12px',
            left: '12px',
            width: '16px',
            height: '16px',
        }
    }),
    searchButton: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            position: 'absolute',
            zIndex: 1002,
            top: '6px',
            right: '6px',
        }
    },
    paper: {
        position: 'absolute',
        zIndex: 1001,
        marginTop: '10px',
        left: 0,
        right: 0,
    },
    inputRoot: {},
    inputInput: {
        borderRadius: '24px',
        border: '1px solid #D9D9D9',
        '&:focus': {
            border: '2px solid #F2B600',
        },
        padding: '14px 40px 14px 20px',
        [theme.breakpoints.up('lg')]: {
            padding: '10px 80px 10px 40px',
            marginLeft: '1px solid #17171A',
        },
    },
}));

export default function SearchField(props) {
    const { className, isInSearchMode, setIsInSearchMode } = props;
    const [value, setValue] = useState('');
    const [results, setResults] = useState([]);
    const [focused, setFocused] = useState(false);
    const classes = useStyles({ focused });

    const history = useHistory();

    const { t, i18n } = useTranslation();

    async function getSuggestions(phrase) {
        setValue(phrase);

        const results = [];
        setResults(results);
    }

    function handleLeaveFocus() {
        setFocused(false);
        setIsInSearchMode && setIsInSearchMode(false);
    }

    function handleOnFocus(inputValue, openMenu) {
        return async () => {
            setFocused(true);
            await getSuggestions(inputValue);
            openMenu();
        };
    }

    function onItemSelected(selection) {
    }

    function submitSearch() {
        if (value.trim() !== '') {
            handleLeaveFocus();
            history.push(getPathWithLanguage(`${ROUTES_ANP.SEARCH}?phrase=${encodeURIComponent(value.trim())}`, i18n.language));
        }
    }

    function handleStateChange(changes) {
        if (changes.type === Downshift.stateChangeTypes.changeInput) {
        }

        if (changes.type === Downshift.stateChangeTypes.keyDownEscape && setIsInSearchMode) {
            setIsInSearchMode(false);
        }
    }

    return (
        <div className={clsx(className, classes.search)}>
            <SearchIcon className={classes.searchIcon} fill={focused ? '#F2B600' : '#5C5C66'}/>
            {/*<SearchButton onClick={submitSearch(value)} className={classes.searchButton} />*/}
            <Downshift id="downshift-simple"
                       onChange={onItemSelected}
                       itemToString={item => {
                           if (!item) {
                               return '';
                           }
                           return '';
                       }}
                       onInputValueChange={getSuggestions}
                       onStateChange={handleStateChange}
                       inputValue={value}
            >
                {({
                      getInputProps,
                      getItemProps,
                      getMenuProps,
                      highlightedIndex,
                      isOpen,
                      selectedItem,
                      inputValue,
                      openMenu,
                  }) => (
                    <div>
                        <RenderInput
                            fullWidth={true}
                            classes={classes}
                            isInSearchMode={isInSearchMode}
                            InputProps={getInputProps({
                                placeholder: t('Text.SearchPlaceholder'),
                                onFocus: handleOnFocus(inputValue, openMenu),
                                onBlur: handleLeaveFocus,
                            })}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    submitSearch();
                                }
                            }
                            }
                        />
                        <div {...getMenuProps()}>
                            {isOpen ? (
                                <Paper className={classes.paper} square={true}>
                                    {results.map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem,
                                        }),
                                    )
                                    }
                                </Paper>
                            ) : null}
                        </div>
                    </div>
                )}
            </Downshift>
        </div>

    );
}
