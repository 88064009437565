import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';
import {themeBase} from '../../styles/styles';
import 'moment/locale/hu';
import CalendarPopup from './CalendarPopup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {tourService} from 'client_shared/src/services/tourService';
import {tourModule} from 'shared/src/modules/tour';

const calendarTheme = createTheme(_.merge({},
    themeBase,
    {
        typography: {
            body1: {
                fontWeight: 'bold',
                color: '#17171A',
                lineHeight: '21px',
            },
            body2: {
                color: '#2E2E33',
                lineHeight: '22px',
            }
        },
        overrides: {
            MuiPickersStaticWrapper: {
                staticWrapperRoot: {
                    minWidth: 'initial',
                    '@media (min-width:1280px)': {
                        minWidth: '350px',
                    }
                }
            },
            MuiSvgIcon: {
                root: {
                    height: '30px',
                    width: '30px',
                    color: '#17171A',
                }
            },
            MuiPickersCalendar: {
                week: {
                    justifyContent: 'space-around',
                    lineHeight: '60px',
                },
                transitionContainer: {
                    minHeight: '360px',
                },
            },
            MuiPickersBasePicker: {
                pickerView: {
                    maxWidth: 'initial',
                    minWidth: 'initial',
                    minHeight: '330px',
                    '@media (min-width:1280px)': {
                        minWidth: '310px',
                    }
                }
            },
            MuiPickersCalendarHeader: {
                iconButton: {
                    fontWeight: 'bold',
                    fontSize: '40px',
                    zIndex: 0,
                },
                dayLabel: {
                    ...themeBase.typography.paragraph2,
                    color: '#5C5C66',
                    fontWeight: 'bold',
                    width: '32px',
                },
                daysHeader: {
                    justifyContent: 'space-around',
                },
                switchHeader: {
                    justifyContent: 'center',
                    marginBottom: '25px',
                },
            },
            MuiIconButton: {
                root: {
                    width: '36px',
                    height: '36px',
                    padding: '0px',
                    '&:hover': {
                        backgroundColor: '#FFF3CD',
                    }
                }
            }
        }
    })
);

const useStyles = makeStyles(theme => ({
    dayWrapper: {
        position: 'relative',
    },
    dottedDay: {
        position: 'absolute',
        bottom: '4px',
        left: 'calc(50% - 7px)',
        height: '14px',
        width: '14px',
        border: '4px solid #FFFFFF',
        borderRadius: '14px',
        backgroundColor: '#96CC29',
    },
    selectedDay: {
        '& > button': {
            backgroundColor: '#E6CFB8',
            '&:hover': {
                backgroundColor: '#F2E0CE',
            }
        },
        '& > button p': {
            color: '#000000',
        }
    },
    currentDay: {
        fontWeight: 'bold',
        '& > button': {
            border: '2px solid #E6CFB8',
            '&:hover': {}
        },
        '& > button p': {
            color: '#000000',
            fontWeight: 'bold',
        }
    },
    currentSelectedDay: {
        fontWeight: 'bold',
        '& > button': {
            backgroundColor: '#E6CFB8',
            '&:hover': {
                backgroundColor: '#F2E0CE',
            }
        },
        '& > button p': {
            color: '#000000',
            fontWeight: 'bold',
        }
    },
}));

class LocalizedUtils extends MomentUtils {
    getWeekdays() {
        return this.moment.weekdaysMin(true);
    }
}

export default function Calendar() {
    const [date, changeDate] = useState(moment());
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const [tours, setTours] = useState([]);

    async function loadCalendarToursForDateRange() {
        //const newTours = await tourService.getTours({startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), showInCalendar: true});
        const tours = await tourService.getTours({showInCalendar: true});
        setTours(tours);
    }

    useEffect(() => {
        //loadCalendarToursForDateRange(moment().startOf('month'), moment().endOf('month')).catch(error => console.log(error));
        loadCalendarToursForDateRange().catch(error => console.log(error));
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (date) => {
        changeDate(date);
/*
        if (date.isBefore(startDate) || date.isAfter(endDate)) {
            const newStartDate = date.startOf('month');
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            loadCalendarToursForDateRange(newStartDate, newEndDate).catch(error => console.log(error));
        }
*/
    };

    const handleMonthChange = (date) => {
        debugger;
    };

    const handleClick = (date) => (event) => {
        if (tourModule.dateHasCalendarEvents(tours, date)) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    return (
        <>
            <ThemeProvider theme={calendarTheme}>
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale="hu" moment={moment}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <DatePicker
                                autoOk={false}
                                fullWidth
                                variant="static"
                                openTo="date"
                                disableToolbar={true}
                                value={date}
                                onChange={handleChange}
                                onMonthChange={handleMonthChange}
                                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                    const hasEvents = isInCurrentMonth && tourModule.dateHasCalendarEvents(tours, day);
                                    const selectedDay = day.isSame(selectedDate);
                                    const currentDay = moment(day).isSame(moment(), 'day');
                                    return (
                                        <div
                                            className={clsx(classes.dayWrapper, selectedDay && currentDay && classes.currentSelectedDay, !currentDay && selectedDay && classes.selectedDay, currentDay && !selectedDay && classes.currentDay)}
                                            onClick={handleClick(day)}
                                        >
                                            {dayComponent}
                                            <div className={clsx(hasEvents ? classes.dottedDay : null)}/>
                                        </div>
                                    );
                                }}
                            />
                            <CalendarPopup anchorEl={anchorEl} open={open} date={date} tours={tours}/>
                        </div>
                    </ClickAwayListener>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </>
    );
}
