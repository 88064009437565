import StylesGeneral from './StylesGeneral';
import StylesGeneralNarrow from './StylesGeneralNarrow';
import StylesTables from './StylesTables';
import StylesTablesNarrow from './StylesTablesNarrow';
import StylesImagesAndVideos from './StylesImagesAndVideos';
import StylesImagesAndVideosNarrow from './StylesImagesAndVideosNarrow';
import StylesListsAndIndents from './StylesListsAndIndents';
import StylesListsAndIndentsNarrow from './StylesListsAndIndentsNarrow';
import StylesCalendar from './StylesCalendar';

export default {
    '.contentAnp': {
        '& .ra-rich-text-input .ql-editor': {
            width: '1000px',
            fontFamily: '"Nunito Sans", "sans-serif"',
            fontSize: '16px',
            color: '#17171A',
            lineHeight: '28px',
            letterSpacing: '0.5px',
            '@media (min-width:1280px)': {
                fontSize: '18px',
            },
            '& li:not(.ql-direction-rtl)::before': {

            }
        },
        '& .ql-container': Object.assign({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            fontSize: '16px',
            color: '#17171A',
            lineHeight: '28px',
            letterSpacing: '0.5px',
            '@media (min-width:1280px)': {
                fontSize: '18px',
            },
        }, StylesGeneral, StylesImagesAndVideos, StylesListsAndIndents, StylesTables, StylesCalendar)
    },
    '.contentAnp.contentNarrow': {
        '& .ql-container': Object.assign({
        }, StylesGeneralNarrow, StylesImagesAndVideosNarrow, StylesListsAndIndentsNarrow, StylesTablesNarrow)
    }
};
