import React from 'react';
import {makeStyles} from '@material-ui/core';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import CallMadeIcon from '@material-ui/icons/CallMade';

const useStyles = makeStyles(theme => ({
    layout: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    list: {
        height: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: 0,
        padding: 0,
    },
    listItem: {
        height: '100%',
        display: "block",
        flex: "0 1 auto",
        listStyleType: "none",
        '&:not(:first-child)': {
            paddingLeft: '24px',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: "16px",
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "16px",
            },
        },
    },
    link: {
        height: '100%',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    activeLink: {
        position: 'relative',
        '& > .paragraph3': {
            fontWeight: 'bold',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '0px',
            left: 'calc(50% - 8px)',
            display: 'block',
            height: '4px',
            width: '16px',
            borderRadius: '0 0 2px 2px',
            backgroundColor: '#D9D9D9',
        }
    },
    linkContent: {
        display: 'flex',
        alignItems: 'center',
        '&.paragraph3': {
            color: '#2E2E33',
        },
    },
    arrow: {
        marginLeft: '2px',
    }
}));

export default function DepartmentsMenu() {
    const classes = useStyles();

    const { t } = useTranslation();

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const departmentsMenu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_DEPARTMENTS, user);

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const menu = departmentsMenu.filter(menuItem => {
        if (isDesktop || menuItem.index !== MENU.DEPARTMENTS_MENU_ANP.AGGTELEK) {
            return menuItem;
        }

        return null;
    });

    return (
        <div className={classes.layout}>
            <ul className={classes.list}>
                {menu.map((menuItem, index) =>
                    <li key={menuItem.id} className={classes.listItem}>
                        <a href={menuItem.url}
                           className={clsx(classes.link, menuItem.index === MENU.DEPARTMENTS_MENU_ANP.AGGTELEK && classes.activeLink)}>
                            <div className={clsx('paragraph3', classes.linkContent)}>
                                {t(menuItem.title)}
                                {!isDesktop && <CallMadeIcon style={{ fontSize: '12px' }} className={classes.arrow}/>}
                            </div>
                        </a>
                    </li>
                )}
            </ul>
        </div>

    );
}
