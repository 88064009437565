import React from 'react';
import {makeStyles} from '@material-ui/core';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import {useSelector} from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    list: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 0,
        padding: 0,
    },
    listItem: {
        height: '100%',
        flex: '0 1 auto',
        listStyleType: 'none',
        '&:not(:first-child)': {
            paddingLeft: '32px',
        },
    },
    link: {
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    selectedLink: {
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '10px',
            left: 'calc(50% - 10px)',
            display: 'block',
            height: '4px',
            width: '20px',
            borderRadius: '2px',
            backgroundColor: '#F2A200',
        }
    },
    linkText: {
        '&.paragraph2': {
            fontWeight: 'bold',
            color: '#17171A',
        },
    },
    selectedLinkText: {
        '&.paragraph2': {
            fontWeight: '800',
            color: '#000000',
        },
    },
    menuItem: {
        border: 'none',
        padding: '0px',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: 'none',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
    }
}));

export default function MainMenu(props) {
    const { className, mouseMoveHandler } = props;
    const classes = useStyles();

    const selectedMainMenuItem = useSelector(state => state.menu.selectedMainMenuItem);

    /*
        const dispatch = useDispatch();

        function handleClick(menuItem, selectedMainMenuItem) {
            return () => {
                mouseMoveHandler && mouseMoveHandler.handleMouseEnter();
                dispatch(menuActions.showMainMenuItem(menuItem));
            }
        }

    */
    function handleMouseEnter(menuItem) {
        return () => {
            mouseMoveHandler && mouseMoveHandler.handleMouseEnter(menuItem);
        }
    }

    function handleMouseLeave() {
        return () => {
            mouseMoveHandler && mouseMoveHandler.handleMouseLeave();
        }
    }

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_MAIN, user);

    return (
        <>
            <div className={clsx('mainMenu', classes.layout, className)}>
                <ul className={classes.list}>
                    {menu.map(menuItem =>
                        <li key={menuItem.id} className={classes.listItem}>
                            <div
                                onMouseEnter={handleMouseEnter(menuItem, selectedMainMenuItem)}
                                onMouseLeave={handleMouseLeave()}
                                className={clsx(classes.link, selectedMainMenuItem === menuItem && classes.selectedLink)}>
                                <button onClick={menuItem === selectedMainMenuItem ? handleMouseLeave() : handleMouseEnter(menuItem, selectedMainMenuItem)}
                                    className={clsx(classes.menuItem, 'paragraph2', classes.linkText, selectedMainMenuItem === menuItem && classes.selectedLinkText)}>
                                {menuItem.title}
                                </button>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}
