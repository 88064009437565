import React from 'react';
import {makeStyles} from '@material-ui/core';
import FooterBarNewsletter from './FooterBarNewsletter';
import FooterBarMenu from './FooterBarMenu';
import FooterBarBottom from './FooterBarBottom';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
    }
}));

export default function FooterBar() {
    const classes = useStyles();

    return (
        <footer className={classes.layout}>
            <FooterBarNewsletter/>
            <FooterBarMenu/>
            <FooterBarBottom/>
        </footer>
    );
}
