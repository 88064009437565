import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import ParkIconListItem from './ParkIconListItem';
import NatureValuesIcon from '../../../../icons/multicolor/ParkrolTermeszetiErtekekIcon';
import WorldHeritageIcon from '../../../../icons/multicolor/ParkrolVilagoroksegIcon';
import {ROUTES_ANP} from 'shared';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layoutContentOnPage: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-30px',
        marginTop: '-30px',
        '& > *': {
            marginLeft: '30px',
            marginTop: '30px',
        },

        '& > *:not(:first-child)': {
            marginLeft: '20px',
        },
    },
    layoutContentInMenu: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '-12px',
        '& > *': {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-20px',
            '& > *': {
                marginLeft: '20px',
            },
            flexDirection: 'row',
        }
    },
}));

export default function ParkIconList(props) {
    const { isMenu } = props;
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <div className={clsx(isMenu ? classes.layoutContentInMenu : classes.layoutContentOnPage)}>
            <ParkIconListItem Icon={NatureValuesIcon} title={t('Text.NatureValues')}
                              summary={t('Text.NatureValuesText')} linkTitle={t('Text.DiscoverSights')}
                              url={getPathWithLanguage(ROUTES_ANP.NATURE_VALUE, i18n.language)} isMenu={isMenu}/>
            <ParkIconListItem Icon={WorldHeritageIcon} title={t('Text.WorldHeritage')}
                              summary={t('Text.WorldHeritageText')} linkTitle={t('Text.DiscoverWorldHeritage')}
                              url={getPathWithLanguage(ROUTES_ANP.WORLD_HERITAGE, i18n.language)} isMenu={isMenu}/>
        </div>

    );
}
