import React from 'react';
import {makeStyles} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import TicketOfferIcon from '../../../../icons/mono/TicketOfferIcon';
import {tourModule} from 'shared/src/modules/tour';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        }
    },
    leftLayout: {
        flex: 1,
        position: 'relative',
        marginTop: '8px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        }
    },
    rightLayout: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '379px',
            marginLeft: '21px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '467px',
            marginLeft: '33px',
        }
    },
    tourPackageImageLayout: {
        height: '88px',
        [theme.breakpoints.up('sm')]: {
            height: '100%',
        },
        display: 'flex',
        flexDirection: 'row',
        '& > *': {
            flex: 1,
        },
        '& > * + *': {
            marginLeft: '8px',
        }
    },
    tourPackageImage1: props => ({
        height: '100%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.tourPackage.tours[0].headerImage}')`,
    }),
    tourPackageImage2: props => ({
        height: '100%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.tourPackage.tours[1].headerImage}')`,
    }),
    iconWrapper: {
        position: 'absolute',
        top: 'calc(50% - 24px)',
        left: 'calc(50% - 24px)',
        height: '48px',
        width: '48px',
        borderRadius: '48px',
        backgroundColor: '#FFFFFF',
        padding: '4px',
    },
    icon: {
        width: '40px',
        height: '40px',
        color: '#F2CA00',
        fontWeight: 'bold',
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    subTitle: {
        '&.paragraph4': {
            color: '#5C5C66',
        },
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    subTitleOnMobile: {
        '&.paragraph4': {
            color: '#5C5C66',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    titleOnMobile: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    discountRow: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: '8px',
        }
    },
    discountIcon: {
        width: '20px',
        height: '20px',
    },
    discountText: {
        '&.paragraph2': {
            color: '#17171A',
        }
    },
    buyingRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '46px',
        },
    },
    link: {
        textDecoration: 'none',
    },
}));

export default function TourPackageListItem(props) {
    const { tourPackage } = props;
    const classes = useStyles(props);

    const { i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <h3 className={classes.titleOnMobile}>
                {tourPackage.title}
            </h3>
            {tourPackage.subTitle &&
                <div className={clsx('paragraph4', classes.subTitleOnMobile)}>
                    {tourPackage.subTitle}
                </div>
            }
            <div className={classes.leftLayout}>
                <div className={classes.tourPackageImageLayout}>
                    <div className={classes.tourPackageImage1}/>
                    <div className={classes.tourPackageImage2}/>
                </div>
                <div className={classes.iconWrapper}>
                    <AddIcon className={classes.icon}/>
                </div>
            </div>
            <div className={classes.rightLayout}>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(tourPackage.id), i18n.language)}
                      className={classes.link}>
                    <h3 className={classes.title}>
                        {tourPackage.title}
                    </h3>
                    {tourPackage.subTitle &&
                        <div className={clsx('paragraph4', classes.subTitle)}>
                            {tourPackage.subTitle}
                        </div>
                    }
                </Link>
                {tourPackage && tourModule.tourHasTickets(tourPackage) &&
                    <div className={classes.discountRow}>
                        <TicketOfferIcon className={classes.discountIcon} fill={'#96CC29'}/>
                        <div className={clsx('paragraph2', classes.discountText)}>
                            2 000 Ft kedvezménnyel
                        </div>
                    </div>
                }
                {/*
                <div className={classes.buyingRow}>
                    <PrimaryBuyingButton variant={'big'} title={t('Text.BuyPackage')} tour={tourPackage}/>
                    <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(tourPackage.id), i18n.language)}
                          className={clsx('linkSecondary', classes.link)}>{t('Text.ShowOffer')}</Link>
                </div>
*/}
            </div>
        </div>
    );
}
