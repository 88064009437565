import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import {useHistory} from 'react-router';
import {contentModule} from 'shared/src/modules/content';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        width: '100%',
        height: '580px',
        marginTop: '0px',
        marginBottom: '0px',
        [theme.breakpoints.up('sm')]: {
            height: '561px',
            marginTop: '0px',
            marginBottom: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '600px',
            marginTop: '64px',
            marginBottom: '0px',
        },
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url("/content/images/${props.promotion.headerImage}")`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    }),
    layoutCover: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(46,46,51,0.5)',
    },
    descriptionsPosition: {
        width: '100%',
        position: 'absolute',
        bottom: '0px',
    },
    lowerBlock: {
        width: '100%',
        backgroundColor: theme.palette.common.black,
        [theme.breakpoints.up('lg')]: {
            backgroundColor: 'initial',
        },
    },
    lowerBlockLayout: {
        paddingTop: '24px',
        paddingBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '32px',
            paddingBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '0px',
            paddingBottom: '48px',
        },
    },
    title: {
        color: theme.palette.common.white,
    },
    titlePadding: {
        padding: '12px 0px 24px 0px',
        width: '280px',
        [theme.breakpoints.up('sm')]: {
            padding: '16px 0px 25px 0px',
            width: '460px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px 0px 32px 0px',
            width: '790px',
        },
    },
    summaryBlock: {
        display: 'flex',
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '32px',
        },
    },
    leftBar: {
        width: '4px',
        borderRadius: '2px',
        backgroundColor: '#F2CA00',
    },
    summary: {
        '&.paragraph1': {
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
        paddingLeft: '32px',
        '& > p': {
            margin: '0px',
            '&:not(:last-child)': {
                margin: '0px 0px 16px 0px',

            }
        }
    },
    summaryAlignment: {
        width: '284px',
        [theme.breakpoints.up('sm')]: {
            width: '672px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '790px',
        },
    },
    link: {
        textDecoration: 'none',
    }
}));

export default function PromotionBig(props) {
    const { className, promotion } = props;
    const classes = useStyles(props);
    const history = useHistory();

    const { i18n } = useTranslation();

    function handleClick() {
        history.push(contentModule.getContentUrl(promotion, i18n.language));
    }

    if (!promotion || !promotion.title || !promotion.summary || !promotion) {
        return null;
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.layoutCover}>
                <div className={clsx(classes.descriptionsPosition)}>
                    <div className={'padding-left'}>
                        <Link to={contentModule.getContentUrl(promotion, i18n.language)} className={classes.link}>
                            <h1 className={clsx(classes.title, classes.titlePadding)}>
                                {promotion.title}
                            </h1>
                        </Link>
                    </div>
                    <div className={classes.lowerBlock}>
                        <div className={clsx('padding-left', classes.lowerBlockLayout)}>
                            <div className={classes.summaryBlock}>
                                <div className={classes.leftBar}/>
                                <div className={clsx('paragraph1', classes.summary, classes.summaryAlignment)}
                                     dangerouslySetInnerHTML={createMarkup(promotion.summary)}/>
                            </div>
                            {promotion.callToActionText && promotion.url &&
                            <PrimaryBasicButton variant='big' title={promotion.callToActionText} onClick={handleClick}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
