import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / horgaszat"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#80CAFF" cx={24} cy={24} r={24}/>
                <path
                    d="M16.992 23.311L13.5 18v12l3.492-5.311C18.961 27.11 26.273 34.578 34.5 24c-8.227-10.578-15.54-3.111-17.508-.689zm12.516.978c-.82 0-1.477-.622-1.477-1.4 0-.778.657-1.4 1.477-1.4.82 0 1.476.622 1.476 1.4 0 .778-.656 1.4-1.476 1.4z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
