import {FAQ_TOP_CATEGORY_ID} from '../../constants/faq';

class FaqModule {
    isTopFaqCategory(faqCategory) {
        return !faqCategory.parentFaqCategoryId;
    }

    isLeafFaqCategory(faqCategory) {
        return faqCategory.parentFaqCategoryId !== FAQ_TOP_CATEGORY_ID;
    }

    getTopFaqCategory(faqCategories) {
        return faqCategories.find(faqCategory => !faqCategory.parentFaqCategoryId);
    }

    getFaqCategoryById(faqCategories, id) {
        return faqCategories.find(faqCategory => faqCategory.id === id);
    }

    getFaqChildCategories(faqCategories, parentFaqCategory) {
        return faqCategories.filter(faqCategory => faqCategory.parentFaqCategoryId === parentFaqCategory.id);
    }
}

export const faqModule = new FaqModule();