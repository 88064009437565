import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import ANPILogoWithText from '../../logos/ANPILogoWithText';
import InterregLogo from '../../logos/InterregLogo';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#17171A',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
        '&.padding': {
            paddingTop: '32px',
            paddingBottom: '32px',
            [theme.breakpoints.up('lg')]: {
                paddingTop: '37px',
                paddingBottom: '37px',
            }
        },
    },
    layoutLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        }
    },
    layoutRight: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    logo: {
        height: '35px',
        [theme.breakpoints.up('sm')]: {
            height: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '50px',
        }
    },
    interregLogo: {
        height: '20px',
        [theme.breakpoints.up('sm')]: {
            height: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '28px',
        }
    },
    divider: {
        height: '40px',
        width: '2px',
        borderRadius: '1px',
        backgroundColor: '#45454D',
        margin: '0px 20px 0px 20px',
        [theme.breakpoints.up('sm')]: {
            margin: '0px 12px 0px 12px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0px 32px 0px 32px',
        }
    },
    circleWithArrow: {
        height: '34px',
        width: '34px',
        borderRadius: '17px',
        backgroundColor: '#F2CA00',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrow: {
        width: '18px',
        height: '18px',
        color: theme.palette.common.black,
    },
    copyrightBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '24px 0px 24px 0px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
            margin: '0px 24px 0px 10px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0px 24px 0px 10px',
        }
    },
    copyrightText: {
        '&.paragraph4': {
            color: '#F2CA00',
        },
    },
    dividerText: {
        '&.paragraph4': {
            color: '#45454D',
            fontWeight: 'bold',
        },
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    anpiText: {
        '&.paragraph4': {
            color: theme.palette.common.white,
        },
        marginTop: '5px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '00px',
        },
    },
    interregTextBlock: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginTop: 'unset',
            marginLeft: '10px',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {}
    },
    interregText: {
        '&.paragraph4': {
            color: 'white',
            fontSize: '8px',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'unset',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '10px',
            },
        },
        '& a': {
            color: 'white',
            textDecoration: 'none',
        },
    },
}));

export default function FooterBarBottom() {
    const classes = useStyles();

    const { t } = useTranslation();

    function handleUpClick() {
        window.scrollTo(0, 0);
        document.getElementById('page-header').focus();
    }

    return (
        <div className={clsx('padding', classes.layout)}>
            <div className={classes.layoutLeft}>
                <ANPILogoWithText className={classes.logo}/>
                <div className={classes.divider}/>
                <InterregLogo className={classes.interregLogo}/>
            </div>
            <div className={classes.interregTextBlock}>
                <div className={clsx('paragraph4', classes.interregText)}
                     dangerouslySetInnerHTML={createMarkup(t('Text.InterregTitle'))}/>
                <div className={clsx('paragraph4', classes.interregText)}
                     dangerouslySetInnerHTML={createMarkup(t('Text.InterregDisclaimer'))}/>
            </div>
            <div className={classes.layoutRight}>
                <div className={classes.copyrightBlock}>
                    <div className={clsx('paragraph4', classes.copyrightText)}>{t('Text.Copyright')}</div>
                    <div className={clsx('paragraph4', classes.dividerText)}>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                    <div className={clsx('paragraph4', classes.anpiText)}>{t('Text.ANPI')}</div>
                </div>
                <Link to={'#'}>
                    <div className={classes.circleWithArrow}>
                        <ArrowUpwardIcon className={classes.arrow} onClick={handleUpClick}/>
                    </div>
                </Link>
            </div>
        </div>
    );
}
