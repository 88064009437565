import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import InfoPopup from '../../controls/InfoPopup';

const useStyles = makeStyles(theme => ({
    layout: {},
    title: {
        marginBottom: '24px',
    },
    row: {
        display: 'flex',
    },
    dots: {
        flex: 1,
        borderBottom: '1px dashed #5C5C66',
        margin: '0px 24px 5px 24px',
    },
    table: {
        borderCollapse: 'collapse',
        '& tr td:first-child': {
            width: '100%',
        },
    },
    link: {
        marginLeft: '24px',
        '&.linkSecondary': {
            fontSize: '12px !important',
            textTransform: 'lowercase',
        },
        cursor: 'pointer',
    }
}));

export default function PriceTable(props) {
    const { tour } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const classes = useStyles();

    const { t } = useTranslation();

    const products = tour ? tour.products : [];

    function handleDetailsClick(product) {
        return (event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
            setSelectedProduct(product);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
        setSelectedProduct(null);
    };

    return (
        <div className={classes.layout}>
            <h3 className={classes.title}>{t('Text.TicketPricesAndTypes')}</h3>
            <table className={classes.table}>
                <tbody>
                {products.map(product => (
                    <tr key={product.id}>
                        <td>
                            <div className={classes.row}>
                                <div className={'paragraph4'}>
                                    <b>{product.name}</b>
                                </div>
                                <div className={classes.dots}/>
                                <div className={'paragraph4'}>
                                    {product.price} Ft / {t('Text.Person')}
                                </div>
                            </div>
                        </td>
                        <td>
                            {product.summary && product.summary.trim() !== '' &&
                            <div className={clsx('linkSecondary', classes.link)} onClick={handleDetailsClick(product)}>
                                {t('Text.Details')}
                            </div>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <InfoPopup open={open} title={selectedProduct && selectedProduct.name}
                       description={selectedProduct && selectedProduct.summary} anchorEl={anchorEl}
                       handleClose={handleClose}/>
        </div>
    );
}
