import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / apartment-call"}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M14.436 21.849c2.56 5.031 6.684 9.138 11.715 11.715l3.911-3.91c.48-.48 1.191-.64 1.814-.427a20.28 20.28 0 006.346 1.013c.978 0 1.778.8 1.778 1.778v6.204C40 39.2 39.2 40 38.222 40 21.53 40 8 26.471 8 9.778 8 8.8 8.8 8 9.778 8H16c.978 0 1.778.8 1.778 1.778 0 2.222.355 4.355 1.013 6.346a1.784 1.784 0 01-.444 1.814l-3.911 3.91z"
                    fill="#F2CA00"
                />
                <path
                    d="M14.436 21.849c2.56 5.031 6.684 9.138 11.715 11.715l3.911-3.91c.48-.48 1.191-.64 1.814-.427a20.28 20.28 0 006.346 1.013c.978 0 1.778.8 1.778 1.778v6.204C40 39.2 39.2 40 38.222 40 21.53 40 8 26.471 8 9.778 8 8.8 8.8 8 9.778 8H16c.978 0 1.778.8 1.778 1.778 0 2.222.355 4.355 1.013 6.346a1.784 1.784 0 01-.444 1.814l-3.911 3.91zm2.927-4.895a.386.386 0 00.107-.393 21.587 21.587 0 01-1.084-6.783A.392.392 0 0016 9.39H9.778a.392.392 0 00-.387.387c0 4.415.992 8.599 2.766 12.34.267.562 8.715 11.347 13.719 13.722a28.724 28.724 0 0012.346 2.769c.21 0 .387-.177.387-.387v-6.204a.392.392 0 00-.387-.387c-2.325 0-4.624-.37-6.798-1.088a.377.377 0 00-.378.094l-5.534 5.538c-4.03-2.587-6.846-4.722-8.446-6.407-1.368-1.44-3.004-3.99-4.909-7.65-.111-.118 5.092-5.05 5.206-5.164z"
                    fill="#17171A"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent