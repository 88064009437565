import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';
import {useTranslation} from 'react-i18next';
import {tourModule} from 'shared/src/modules/tour';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    layout: {
        borderRadius: '12px',
        borderWidth: '0px',
        backgroundColor: '#F2CA00',
        boxShadow: '0 3px 0 0 rgba(242,202,0,0.4)',
        position: 'relative',
        padding: '10px 20px 10px 20px',
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#F2D649',
            boxShadow: '0 3px 0 0 rgba(242,182,0,0.7)',
        },
        '&:active': {
            cursor: 'pointer',
            backgroundColor: '#F2B600',
            boxShadow: 'none',
        },
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
    },
    variantBig: {
        '&.paragraph3': {
            color: '#17171A',
            fontWeight: 'bold',
        },
        height: '44px',
        whiteSpace: 'nowrap',
    },
    variantNormal: {
        '&.paragraph4': {
            color: '#17171A',
            fontWeight: 'bold',
        },
        fontWeight: 'bold',
        height: '34px',
        whiteSpace: 'nowrap',
    },
    disabled: {
        '&.paragraph3': {
            color: '#6d6d71',
        },
        '&.paragraph4': {
            color: '#6d6d71',
        }
    }
}));

export default function PrimaryBuyingButton(props) {
    const { type, title, variant, className, onClick, tour, date, time, tourDateId, tourTimeId, disabled } = props;
    const classes = useStyles();
    const order = useSelector(state => state.checkout.order);
    const orderSaving = useSelector(state => state.checkout.orderSaving);
    const [launchCheckout, setLaunchCheckout] = useState(false);
    /*
        const [tourIsAddedToOrder, setTourIsAddedToOrder] = useState(false);
    */
    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (launchCheckout && !orderSaving) {
            dispatch(checkoutActions.addToOrder(order, tour, date, time, tourDateId, tourTimeId));
            setLaunchCheckout(false);
        }
    }, [dispatch, tour, launchCheckout, orderSaving, order, date, time, tourDateId, tourTimeId]);

    /*    useEffect(() => {
            if (!tour || !order) {
                return;
            }
            const tourIsAddedToOrder = !!checkoutModule.getOrderTours(order).find(orderTour => orderTour.id === tour.id);
            setTourIsAddedToOrder(tourIsAddedToOrder);
        }, [tour, order]);*/

    if (tour && !tourModule.tourHasTickets(tour)) {
        return null;
    }

    function handleClick(event) {
        event.preventDefault();
        dispatch(checkoutActions.launchCheckout());
        setLaunchCheckout(true);
    }

    const handleButtonClick = type === 'submit' ? undefined : (onClick || handleClick);

    const variantLayout = variant === 'big' ?
        ['paragraph3', classes.variantBig] : ['paragraph4', classes.variantNormal];

    return (
        <Button
            className={clsx(...variantLayout, classes.layout, className, disabled && classes.disabled)}
            onClick={handleButtonClick}
            disabled={disabled}
            type={type || 'button'}>
            {/*{title || (tourIsAddedToOrder ? t('Button.Tickets') : t('Button.BuyTickets'))}*/}
            {title || t('Button.BuyTickets')}
        </Button>
    );
}
