import * as React from "react";

function SvgTourguides(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M54.6 6.4H41.1C39.7 2.7 36.2 0 32 0s-7.7 2.7-9.1 6.4H9.4C5.9 6.4 3 9.3 3 12.8v44.8C3 61.1 5.9 64 9.4 64h45.1c3.5 0 6.4-2.9 6.4-6.4V12.8c.1-3.5-2.8-6.4-6.3-6.4zM32 6.4c1.8 0 3.2 1.4 3.2 3.2s-1.5 3.2-3.2 3.2c-1.8 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2zm0 12.8c5.3 0 9.7 4.3 9.7 9.6s-4.3 9.6-9.7 9.6-9.7-4.3-9.7-9.6 4.4-9.6 9.7-9.6zm19.3 38.4H12.7v-4.5c0-6.4 12.9-9.9 19.3-9.9s19.3 3.5 19.3 9.9v4.5z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgTourguides;
