import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import HeaderBarTop from './HeaderBarTop';
import HeaderBarBottom from './HeaderBarBottom';
import {styleConstants} from '../../../styles/styles';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import {menuActions} from 'client_shared/src/state/actions';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        height: styleConstants.headerBar.mobileHeight,
        [theme.breakpoints.up('sm')]: {
            height: styleConstants.headerBar.tabletHeight,
        },
        [theme.breakpoints.up('lg')]: {
            height: styleConstants.headerBar.desktopHeight,
        },
    },
    headerBarTop: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    headerBarBottom: {
        flex: 1,
    },
    divider: {
        display: 'flex',
        height: '2px',
        minHeight: '2px',
        width: '100%',
        backgroundColor: '#E6E6E6',
    },
    hidden: {
        display: 'none'
    }
}));

class MouseMoveHandler {
    hideTimer = null;
    showTimer = null;
    dispatch = null;
    menuItem = null;

    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    handleMouseEnter(menuItem) {
        this.cancelHideTimer();
        if (menuItem) {
            this.menuItem = menuItem;
            this.cancelShowTimer();
            this.startShowTimer();
        }
    }

    handleMouseLeave() {
        this.startHideTimer();
    }

    startHideTimer() {
        if (this.hideTimer || this.showTimer) {
            this.cancelHideTimer();
            this.cancelShowTimer();
        }

        this.hideTimer = setTimeout(() => {
            this.dispatch(menuActions.hideMainMenuItem());
        }, 300);
    }

    startShowTimer() {
        if (this.hideTimer || this.showTimer) {
            this.cancelHideTimer();
            this.cancelShowTimer();
        }

        this.showTimer = setTimeout(() => {
            this.dispatch(menuActions.showMainMenuItem(this.menuItem));
        }, 300);
    }

    cancelHideTimer() {
        if (this.hideTimer) {
            clearTimeout(this.hideTimer);
            this.hideTimer = null;
        }
    }

    cancelShowTimer() {
        if (this.showTimer) {
            clearTimeout(this.showTimer);
            this.showTimer = null;
        }
    }
}


export default function HeaderBar(props) {
    const classes = useStyles(props);
    const [mouseMoveHandler, setMouseMoveHandler] = useState(null);
    const dispatch = useDispatch();
    const headerBarRef = useRef(null);

    useEffect(() => {
        if (!dispatch) {
            return;
        }

        setMouseMoveHandler(new MouseMoveHandler(dispatch));
    }, [dispatch]);

    return (
        <header id='page-header' className={clsx('mui-fixed', classes.layout)}>
            <HeaderBarTop className={clsx('padding', classes.headerBarTop)}/>
            <HeaderBarBottom className={clsx('padding', classes.headerBarBottom)}
                             mouseMoveHandler={mouseMoveHandler} headerBarRef={headerBarRef}/>
            <div className={classes.divider} ref={headerBarRef}/>
        </header>
    );
}
