import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: '0px 0px 14px 0px',
        [theme.breakpoints.up('sm')]: {
            border: '2px solid #D9D9D9',
            borderRadius: '12px',
            flexDirection: 'row',
            padding: '24px 24px 16px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            padding: '32px 32px 24px 32px',
        }
    },
    pictureBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #E6E6E6',
        padding: '0px 0px 14px 0px',
        [theme.breakpoints.up('sm')]: {
            borderBottom: '0px',
            borderRight: '1px solid #E6E6E6',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px 24px 0px 0px'
        },
        [theme.breakpoints.up('lg')]: {
            border: '0px',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px'
        }
    },
    pictureNameBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: '24px',
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
            marginLeft: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center',
            marginLeft: '0px',
        }
    },
    tourTypeBar: {
        flex: 1,
    },
    image: {
        height: '80px',
        width: '80px',
        borderRadius: '100px',
        [theme.breakpoints.up('sm')]: {
            height: '140px',
            width: '140px',
            borderRadius: '100px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '213px',
            width: '213px',
            borderRadius: '139px',
        }
    },
    name: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '24px',
        }
    },
    title: {
        '&.paragraph3': {
            color: '#5C5C66',
        },
        marginTop: '4px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '6px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '4px',
        }
    },
}));

export default function TourGuideProfile(props) {
    const { tourGuide } = props;
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <div className={classes.pictureBar}>
                <img alt={tourGuide.name} src={`/content/images/${tourGuide.headerImage}`} className={classes.image}/>
                <div className={classes.pictureNameBar}>
                    <h3 className={classes.name}>
                        {tourGuide.name}
                    </h3>
                    <div className={clsx('paragraph3', classes.title)}>
                        {tourGuide.title}
                    </div>
                </div>
            </div>
            <div className={classes.tourTypeBar}>
            </div>
        </div>

    );
}
