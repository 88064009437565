import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {ROUTES_ANP} from 'shared';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {checkoutModule} from 'shared/src/modules/checkout';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        height: props.colored ? 'auto' : '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: '250px'
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '380px'
        },
    }),
    layoutColored: {
        backgroundColor: '#FFF3CD',
        borderRadius: '12px',
    },
    topBoxColored: {
        padding: '26px 24px 0px 24px',
    },
    summaryEmbedded: {
        borderRadius: '0 0 12px 12px',
        backgroundColor: '#F2CA00',
        padding: '20px 24px 20px 24px',
        margin: '0px !important',
    },
    bottomBoxColored: {},
    noTicketBox: {
        padding: '12px 16px 12px 16px',
        borderRadius: '4px',
        backgroundColor: theme.palette.common.white,
    },
    ticketSummaryTitle: {
    },
    ticketSummaryDate: {
        '&.paragraph4': {
        },
        marginBottom: '6px',
    },
    table: {
        borderCollapse: 'collapse',
        marginBottom: '24px',
    },
    tableRow: {
        '&:not(:last-child)': {
            '& > td': {
                paddingBottom: '10px',
            }
        },
    },
    tableCellAmount: {
        '&.paragraph4': {
            color: '#17171A',
            fontWeight: 'bold',
        },
        textAlign: 'left',
        verticalAlign: 'center',
    },
    tableCellName: {
        paddingLeft: '10px',
        textAlign: 'left',
        verticalAlign: 'center',
    },
    tableCellName2: {
        textAlign: 'left',
        verticalAlign: 'center',
    },
    tableCellPrice: {
        textAlign: 'right',
        verticalAlign: 'center',
    },
    summary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0px 0px 16px 0px',
    },
    summaryText: {
        '&.paragraph2': {
            color: '#17171A',
        }
    },
    summaryTextEmbedded: {
        '&.paragraph2': {
            fontWeight: 'bold',
        }
    },
    summaryPrice: {
        color: '#17171A',
        fontWeight: 'bold',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '11px',
    },
    modifyLink: {
        '&.linkSecondary': {
            fontSize: '12px',
        }
    }
}));

export default function CheckoutSummaryBox(props) {
    const { colored, order } = props;
    const classes = useStyles(props);

    const { t, i18n } = useTranslation();

    if (!order) {
        return null;
    }

    const tours = checkoutModule.getOrderTours(order);

    const orderItemMap = checkoutModule.getOrderItemMap(order, tours);

    const sum = checkoutModule.getOrderSum(order);

    return (
        <div className={clsx(classes.layout, colored && classes.layoutColored)}>
            <div>
                <div className={clsx(colored && classes.topBoxColored)}>
                    {colored &&
                    <div className={classes.header}>
                        <h3 className={classes.title}>{t('Text.Summary')}</h3>
                        <Link className={clsx('linkSecondary', classes.modifyLink)}
                              to={`${getPathWithLanguage(ROUTES_ANP.HOME, i18n.language)}?checkout=true`}>
                            {t('Text.Modify')}
                        </Link>
                    </div>
                    }
                    {sum === 0 &&
                    <div className={classes.noTicketBox}>
                        <div className={'paragraph4'}>{t('Text.NoTicket')}</div>
                    </div>
                    }
                    {sum > 0 && tours.filter(tour => orderItemMap[tour.id].length > 0).map(tour =>
                        <div key={tour.id}>
                            <h5 className={classes.ticketSummaryTitle}>{tour.name_hu}</h5>
                            <div className={clsx('paragraph4', classes.ticketSummaryDate)}>{moment(orderItemMap[tour.id][0].date).format('YYYY. MM. DD.')} {moment(orderItemMap[tour.id][0].time, 'HH:mm').format('HH:mm')}</div>
                            <table className={classes.table}>
                                <tbody>
                                {orderItemMap[tour.id].map((orderItem, index) =>
                                    <tr key={index} className={classes.tableRow}>
                                        <td className={clsx('paragraph4', classes.tableCellAmount)}>
                                            {orderItem.amount} x
                                        </td>
                                        <td className={clsx('paragraph3', classes.tableCellName)}>
                                            {orderItem.product.name}
                                        </td>
                                        <td className={clsx('paragraph3', classes.tableCellPrice)}>
                                            {checkoutModule.formatMoney(orderItem.price)}
                                        </td>
                                    </tr>
                                )}
                                {/*
                            <tr>
                                <td colSpan="2" className={clsx('paragraph3', classes.tableCellName2)}>
                                    Családi kedvezmény
                                </td>
                                <td className={clsx('paragraph3', classes.tableCellPrice)}>
                                    - 1 400 Ft
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className={clsx('paragraph3', classes.tableCellName2)}>
                                    {t('Text.SubTotalPrice')}
                                </td>
                                <td className={clsx('paragraph3', classes.tableCellPrice)}>
                                    6 000 Ft
                                </td>
                            </tr>
    */}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                {/*<CheckoutDiscountOffer variant={colored ? 'embedded' : 'self-contained'}/>*/}
            </div>
            <div className={clsx(classes.summary, colored && classes.summaryEmbedded)}>
                <div className={clsx('paragraph2', classes.summaryText, colored && classes.summaryTextEmbedded)}>
                    {t('Text.TotalPrice')}
                </div>
                <h4 className={clsx(classes.summaryPrice)}>
                    {checkoutModule.formatMoney(sum)} Ft
                </h4>
            </div>
        </div>

    );
}
