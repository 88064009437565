import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TOURS} from 'shared';
import ContentScreen from '../components/pages/ContentScreen';
import TourPackageList from '../components/contents/tour/lists/tourPackageList/TourPackageList';
import {tourActions} from 'client_shared/src/state/actions';
import clsx from 'clsx';
import ToursIconList from '../components/contents/tour/lists/toursIconList/ToursIconList';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    contentLayout: {
        display: 'flex',
        flexDirection: 'column',

        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px',
        },
    },
}));

function TourPackages() {
    const classes = useStyles();
    const [tourPackages, setTourPackages] = useState([]);
    const { t } = useTranslation();

    const tours = useSelector(state => state.tour.tours);

    useEffect(() => {
        if (!tours || tours.length === 0) {
            return;
        }

        const tourPackages = tours.filter(tour => tour.tourTypeId === TOURS.TYPES.TOUR_PACKAGE);
        tourPackages.forEach(tourPackage => {
            const tour1 = tours.find(tour => tour.id === tourPackage.tourIds[0]);
            const tour2 = tours.find(tour => tour.id === tourPackage.tourIds[1]);
            tourPackage.tours = [tour1, tour2];
        });

        setTourPackages(tourPackages);
    }, [tours]);


    return (
        <ContentScreen>
            <div className={clsx('padding', classes.contentLayout)}>
                <TourPackageList tourPackages={tourPackages}/>
                <ToursIconList title={t('Text.ToursBySubject')} isBanner={true}/>
            </div>
        </ContentScreen>
    );
}

TourPackages.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.tour.tours)) {
        promises.push(dispatch(tourActions.loadTours()));
    }

    return promises;
};

export default TourPackages;