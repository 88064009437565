import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {TOURS} from 'shared';
import WalkingTourIcon from '../components/icons/multicolor/LatnivalokGyalogturakAFelszinenIcon';
import ContentScreen from '../components/pages/ContentScreen';
import TourList from '../components/contents/tour/lists/tourList/TourList';
import _ from 'lodash';
import {contentActions, tourActions} from 'client_shared/src/state/actions';
import {tourModule} from 'shared/src/modules/tour';
import DangerLargeList from '../components/contents/danger/lists/dangerLargeList/DangerLargeList';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    contentLayout: {
        display: 'flex',
        flexDirection: 'column',
        '&.padding': {
            paddingBottom: '48px',
        }
    },
    tourList: {
        width: '100%',
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
        },
    },
    tour: {
        paddingBottom: '32px',
        '&:not(:first-child)': {
            paddingTop: '32px',
        },
        '&:not(:last-child)': {
            borderBottom: '2px solid #E6E6E6',
        },
        [theme.breakpoints.up('sm')]: {
            '&:not(:first-child)': {
                paddingTop: '48px',
            },
            paddingBottom: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            '&:not(:first-child)': {
                paddingTop: '0px',
            },
            paddingBottom: '64px',
            '&:not(:last-child)': {
                borderBottom: 'none',
            },
        },
    },
    spacer: {
        marginBottom: '64px',
    }
}));

function WalkingTours() {
    const [tourLists, setTourLists] = useState([]);
    const classes = useStyles();
    const tours = useSelector(state => state.tour.tours);

    useEffect(() => {
        if (!tours || tours.length === 0) {
            return;
        }

        const walkingTours = tours.filter(tour => tour.tourTypeId === TOURS.TYPES.WALKING_TOUR);
        const tourLists = tourModule.getTourLists(walkingTours);
        setTourLists(tourLists);
    }, [tours]);

    const allDangers = useSelector(state => state.content.dangers);
    const page = useSelector(state => state.page.page);
    const dangers = allDangers.filter(danger => page.dangerIds && page.dangerIds.includes(danger.id));

    return (
        <ContentScreen Icon={WalkingTourIcon} divider={true}>
            <div className={clsx(classes.tourList)}>
                {tourLists.map((tourList, index) =>
                    <div className={classes.tour} key={index}>
                        <TourList
                            showTitleLink={false}
                            tours={tourList}
                            title={tourList.length && tourList[0].tourList.title}
                            description={tourList.length && tourList[0].tourList.description}
                            className={'padding'}
                        />
                    </div>
                )}
            </div>
            <DangerLargeList dangers={dangers} className={'padding'}/>
            <div className={classes.spacer}/>
        </ContentScreen>
    );
}

WalkingTours.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.tour.tours)) {
        promises.push(dispatch(tourActions.loadTours()));
    }

    if (_.isEmpty(storeState.content.dangers)) {
        promises.push(dispatch(contentActions.loadDangers()));
    }

    return promises;
};

export default WalkingTours;