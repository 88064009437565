import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import DepartmentsMenu from '../menus/departments/DepartmentsMenu';
import LanguageMenu from '../menus/language/LanguageMenu';
import {styleConstants} from '../../../styles/styles';

const useStyles = makeStyles(theme => ({
    layout: {
        height: styleConstants.headerBar.desktopTopHeight,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    layoutMenuBar: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    layoutMenuBarRight: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > div': {
            marginLeft: '16px',
        },
    },
    divider: {
        height: 1,
        borderRadius: 1,
        backgroundColor: '#F2F2F2'
    },
    anpiTitle: {
        '&.paragraph3': {
            color: '#5C5C66',
        }
    },
    separator: {
        height: '23px',
        width: '2px',
        borderRadius: '1px',
        backgroundColor: '#E6E6E6',
    },
}));

export default function HeaderBarTop(props) {
    const { className } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.layoutMenuBar}>
                <div className={clsx('paragraph3', classes.anpiTitle)}>
                    {t('Text.ANPI')}
                </div>
                <div className={classes.layoutMenuBarRight}>
                    <DepartmentsMenu/>
                    <div className={classes.separator}/>
                    <LanguageMenu/>
                </div>
            </div>
            <div className={classes.divider}/>
        </div>
    );
}
