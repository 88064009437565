import {sendServerRequest} from './baseService';

export const accommodationService = {
    getAccommodation,
    getAccommodations,
    getAccommodationCategories,
    getAccommodationServices
};

async function getAccommodation(accommodationId) {
    return sendServerRequest('GET', `/api/accommodations/${accommodationId}`);
}

async function getAccommodations() {
    return sendServerRequest('GET', `/api/accommodations`);
}

async function getAccommodationCategories() {
    return sendServerRequest('GET', `/api/accommodationCategories`);
}

async function getAccommodationServices() {
    return sendServerRequest('GET', `/api/accommodationServices`);
}
