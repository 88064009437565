import {ROUTES_ANP} from '../../constants/routes';
import {CONTENT} from '../../constants/content';
import {getPathWithLanguage} from '../../helpers/pathHelper';

export const contentModule = {
    getContentUrl,
};

function getContentUrl(content, language) {
    if (content.url) {
        return getPathWithLanguage(content.url, language);
    }

    switch (content.type) {
        case CONTENT.TYPE.NEWS:
            return getPathWithLanguage(ROUTES_ANP.ARTICLE(content.id), language);
        default:
            throw new Error(`Unknown content type ${content.type}`);
    }
}
