import * as React from "react";

function SvgTicketOffer(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M57.6 32c0-3.4 2.9-6.2 6.4-6.2V13.2C64 9.8 61.1 7 57.6 7H6.4C2.9 7 0 9.8 0 13.2v12.5c3.5 0 6.4 2.8 6.4 6.2S3.6 38.2 0 38.2v12.5C0 54.2 2.9 57 6.4 57h51.2c3.5 0 6.4-2.8 6.4-6.3V38.2c-3.5 0-6.4-2.8-6.4-6.2zM43.5 47L32 39.8 20.5 47 24 34.1l-10.5-8.4 13.5-.8 5-12.3L36.9 25l13.6.8L40 34.2 43.5 47z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgTicketOffer;
