import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import CheckoutSummaryBox from '../../checkout/CheckoutSummaryBox';
import {Formik} from 'formik';
import TextFieldComponent from '../../controls/TextFieldComponent';
import CheckboxComponent from '../../controls/CheckboxComponent';
import PrimaryBuyingButton from '../../buttons/PrimaryBuyingButton';
import {useDispatch, useSelector} from 'react-redux';
import {checkoutModule} from 'shared/src/modules/checkout';
import SimplePayForm from './SimplePayForm';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';
import {COUNTRY_CODES, ROUTES_ANP} from 'shared';
import _ from 'lodash';
import {cityService} from 'client_shared/src/services/cityService';
import moment from 'moment';
import countries from 'i18n-iso-countries';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ScrollToError from 'client_shared/src/components/errors/ScrollToError';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import HelpBar from './HelpBar';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useHistory} from 'react-router';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '&.padding': {
            paddingTop: '64px',
            paddingBottom: '64px',
        },
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    leftColumn: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px',
        [theme.breakpoints.up('sm')]: {
            width: '480px',
            marginTop: '0px'
        },
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '34px',
        },
    },
    leftColumnBlock: {
        borderLeft: '4px solid #96CC29',
        borderRadius: '2px',
        paddingLeft: '43px',
        '&:not(:last-child)': {
            marginBottom: '48px'
        }
    },
    title: {
        marginBottom: '16px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '42px',
        },
    },
    blockTitle: {
        marginBottom: '24px',
    },
    paymentBox: {
        borderRadius: '8px',
        border: '2px solid #B0E645',
        padding: '14px 17px 20px 0px',
        display: 'flex',
    },
    paymentBoxLeftColumn: {
        marginTop: '4px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '56px'
    },
    paymentBoxRightColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        '& > * + *': {
            marginTop: '14px'
        }
    },
    paymentBoxRightColumnTitleBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    paymentBoxTitleRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logo: {
        height: '30px'
    },
    simplePayDescription: {
        '&.paragraph3': {
            color: '#5C5C66',
        }
    },
    radioButton: {
        height: '20px',
        width: '20px',
        border: '2px solid #96CC29',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        position: 'relative'
    },
    dot: {
        position: 'absolute',
        top: '2px',
        left: '2px',
        height: '12px',
        width: '12px',
        borderRadius: '10px',
        backgroundColor: '#B0E645',
    },
    billingCityRow: {
        display: 'flex',
        '& > * + *': {
            marginLeft: '9px',
        }
    },
    countryField: {
        marginBottom: '20px'
    },
    label: {
        '&.paragraph3': {
            fontWeight: 'bold',
            marginBottom: '8px',
        }
    },
}));


export default function PaymentInfo() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const order = useSelector(state => state.checkout.order);
    const paymentInfo = useSelector(state => state.checkout.paymentInfo);
    const orderSaving = useSelector(state => state.checkout.orderSaving);
    const error = useSelector(state => state.error.error);
    const dispatch = useDispatch();
    const history = useHistory();

    const isNotMobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {
        if (paymentInfo && !orderSaving) {
            document.getElementById('SimplePayForm').submit();
        }
    }, [paymentInfo, orderSaving]);

    useEffect(() => {
        if (history && error) {
            history.push(getPathWithLanguage(ROUTES_ANP.HOME, i18n.language));
        }
    }, [history, error, i18n.language]);

    if (!order) {
        return null;
    }

    async function onSubmit(values, { setSubmitting }) {
        dispatch(checkoutActions.saveOrder(values, true));
        setSubmitting(false);
    }

    async function validate(values) {
        let errors = {};
        const requiredFields = [
            'guideName',
            'guideEmail',
            'guidePhone',
            'rulesApproved',
        ];
        requiredFields.forEach(field => {
            if (!values[field] && values[field] !== 0) {
                errors[field] = t('Form.Mandatory');
            }
        });

        if (values['invoiceRequested']) {
            [
                'billingName',
                'billingVATNumber',
                'billingCountry',
                'billingPostCode',
                'billingCity',
                'billingAddress',
            ]
                .forEach(field => {
                    if (!values[field]) {
                        errors[field] = t('Form.Mandatory');
                    }
                });

            /*
                        if (values.billingCountry === COUNTRY.HUNGARY &&
                            !taxModule.validateCompanyVATNumber(values.billingVATNumber) &&
                            !taxModule.validatePersonalVATNumber(values.billingVATNumber)) {
                            errors.billingVATNumber = t('Form.InvalidVATNumber');
                        }
            */

            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.billingEmail)) {
                errors.billingEmail = t('Form.InvalidEmail');
            }
        }

        if (
            values.guideEmail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.guideEmail)
        ) {
            errors.guideEmail = t('Form.InvalidEmail');
        }

        if (
            values.guidePhone &&
            !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/gi.test(values.guidePhone)
        ) {
            errors.guidePhone = t('Form.InvalidPhone');
        }

        if (!values.rulesApproved) {
            errors.rulesApproved = t('Form.RuleApprovalMandatory');
        }

        return errors;
    }

    function onInvoiceRequestedChange(values, setFieldValue) {
        return (event) => {
            const invoiceRequested = event.target.checked;
            setFieldValue('invoiceRequested', invoiceRequested);
            if (invoiceRequested && values.billingName === '') {
                setFieldValue('billingName', values.guideName);
                setFieldValue('billingEmail', values.guideEmail);
            }
        }
    }

    function postCodeChanged(cityField, handleChange, setFieldValue) {
        return async (event) => {
            handleChange(event);

            if (event.target.value && event.target.value.length === 4) {
                const { city } = await cityService.getCity(event.target.value);

                if (city) {
                    setFieldValue(cityField, city.city);
                }
            }
        }
    }

    moment.locale(i18n.language);

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/hu.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/sk.json"));

    const countryMap = countries.getNames(i18n.language);
    const countryMapNamesToCodes = _.invert(countryMap);
    const countryNames = COUNTRY_CODES.map(countryCode => countryMap[countryCode]).sort(Intl.Collator().compare);

    const sum = checkoutModule.getOrderSum(order);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={order}
            validate={validate}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  errors,
                  values,
                  setFieldValue,
                  isSubmitting,
                  isValidating
              }) => (
                <div className={clsx('padding', classes.layout)}>
                    <form onSubmit={handleSubmit} className={classes.leftColumn}>
                        <ScrollToError errors={errors} isSubmitting={isSubmitting} isValidating={isValidating}/>
                        {isNotMobile &&
                            <h1 className={classes.title}>{t('Text.Payment')}</h1>
                        }
                        <div className={classes.leftColumnBlock}>
                            <h3 className={classes.blockTitle}>{t('Text.PersonalData')}</h3>
                            <div>
                                <TextFieldComponent
                                    inputId={'PaymentInfo-guideName'}
                                    placeholder={t('Text.FullNamePlaceholder')}
                                    propertyName={'guideName'}
                                    label={t('Text.FullName')}
                                />
                                <TextFieldComponent
                                    inputId={'PaymentInfo-guideEmail'}
                                    placeholder={t('Text.EmailPlaceholder')}
                                    propertyName={'guideEmail'}
                                    label={t('Text.EmailAddress')}
                                />
                                <TextFieldComponent
                                    inputId={'PaymentInfo-guidePhone'}
                                    placeholder={t('Text.PhoneNumberPlaceholder')}
                                    propertyName={'guidePhone'}
                                    label={t('Text.PhoneNumber')}
                                />

                                <CheckboxComponent
                                    inputId={'PaymentInfo-invoiceRequested'}
                                    label={t('Text.InvoiceRequest')}
                                    propertyName={'invoiceRequested'}
                                    onChange={onInvoiceRequestedChange(values, setFieldValue)}
                                />
                                {values.invoiceRequested &&
                                    <>
                                        <TextFieldComponent
                                            inputId={'PaymentInfo-billingEmail'}
                                            placeholder={t('Text.BillingEmailPlaceholder')}
                                            propertyName={'billingEmail'}
                                            label={t('Text.BillingEmail')}
                                        />

                                        <TextFieldComponent
                                            inputId={'PaymentInfo-billingName'}
                                            placeholder={t('Text.BillingNamePlaceholder')}
                                            propertyName={'billingName'}
                                            label={t('Text.BillingName')}
                                        />

                                        <div className={clsx('paragraph3', classes.label)}>
                                            {t('Text.BillingCountry')}
                                        </div>

                                        <FormControl variant="outlined" className={classes.countryField}>
                                            <Select
                                                value={values.billingCountry}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{
                                                    name: 'billingCountry',
                                                    id: 'billingCountry',
                                                }}
                                            >
                                                {countryNames.map((countryName, index) => (
                                                    <MenuItem key={index} value={countryMapNamesToCodes[countryName]}>
                                                        {countryName}
                                                    </MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl>

                                        <TextFieldComponent
                                            inputId={'PaymentInfo-billingPostCode'}
                                            placeholder={t('Text.PostCodePlaceholder')}
                                            propertyName={'billingPostCode'}
                                            label={t('Text.PostCode')}
                                            onChange={postCodeChanged('billingCity', handleChange, setFieldValue)}
                                        />

                                        <TextFieldComponent
                                            inputId={'PaymentInfo-billingCity'}
                                            placeholder={t('Text.CityPlaceholder')}
                                            propertyName={'billingCity'}
                                            label={t('Text.City')}
                                        />
                                        <TextFieldComponent
                                            inputId={'PaymentInfo-billingAddress'}
                                            placeholder={t('Text.AddressPlaceholder')}
                                            propertyName={'billingAddress'}
                                            label={t('Text.Address')}
                                        />
                                        <TextFieldComponent
                                            inputId={'PaymentInfo-billingVatNumber'}
                                            placeholder={t('Text.BillingVATNumberPlaceholder')}
                                            propertyName={'billingVATNumber'}
                                            label={t('Text.BillingVATNumber')}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                        <div className={classes.leftColumnBlock}>
                            <h3 className={classes.blockTitle}>{t('Text.PaymentMethods')}</h3>
                            <div className={classes.paymentBox}>
                                <div className={classes.paymentBoxLeftColumn}>
                                    <div className={classes.radioButton}>
                                        <div className={classes.dot}/>
                                    </div>
                                </div>
                                <div className={classes.paymentBoxRightColumn}>
                                    <div className={classes.paymentBoxRightColumnTitleBar}>
                                        <h5>{t('Checkout.PaymentCard')}</h5>
                                        <a className={classes.logo}
                                           href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                                           target="_blank" rel="noopener noreferrer">
                                            <img height="30px" src="/images/simplepay_horizontal_02.png"
                                                 title=" SimplePay - Online bankkártyás fizetés"
                                                 alt=" SimplePay vásárlói tájékoztató"/>
                                        </a>
                                    </div>
                                    <div className={clsx('paragraph3', classes.simplePayDescription)}
                                         dangerouslySetInnerHTML={createMarkup(t('Checkout.SimplePayDescriptionAnp'))}/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.leftColumnBlock}>
                            <h3 className={classes.blockTitle}>{t('Text.ExtraInformation')}</h3>
                            <div>
                                <TextFieldComponent
                                    inputId={'PaymentInfo-comment'}
                                    placeholder={t('Text.CommentPlaceholder')}
                                    propertyName={'comment'}
                                    label={t('Text.Comment')}
                                    multiline={true}
                                />
                                {/*<CheckboxComponent label={t('Text.NewsletterSubsciptionLabel')}/>*/}
                                <CheckboxComponent
                                    inputId={'PaymentInfo-rulesApproved'}
                                    label={t('Text.RuleApproveLabel')}
                                    propertyName={'rulesApproved'}
                                />
                                {/*<CheckboxComponent label={t('Text.TermsAndConditionsApproveLabel')}/>*/}
                            </div>
                        </div>
                        <PrimaryBuyingButton title={`${t('Button.Pay')} (${checkoutModule.formatMoney(sum)} Ft)`}
                                             variant={'big'} type={'submit'}/>
                        {!isNotMobile &&
                            <HelpBar/>
                        }
                    </form>
                    <div className={classes.rightColumn}>
                        {!isNotMobile &&
                            <h1 className={classes.title}>{t('Text.Payment')}</h1>
                        }
                        <CheckoutSummaryBox colored={true} order={order}/>
                        {isNotMobile &&
                            <HelpBar/>
                        }
                    </div>
                    <SimplePayForm paymentInfo={paymentInfo}/>
                </div>
            )}
        </Formik>
    );
}







