import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {getPathWithLanguageForLanguageChange} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

const useStyles = makeStyles(theme => ({
    layout: {
        borderRadius: '8px',
        border: '2px solid #D9D9D9',
        backgroundColor: theme.palette.background.default,
        padding: '10px 25px',
        textTransform: 'uppercase',
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.background.default,
        },
        '&:focus': {
            /*outline: '0 !important',*/
        },

    },
    active: {
        border: '2px solid #B0E645',
    },
}));

export default function LanguageButton(props) {
    const { language } = props;
    const classes = useStyles();

    const { i18n } = useTranslation();

    const history = useHistory();

    function handleClick() {
        if (i18n.language !== language.code) {
            history.push(getPathWithLanguageForLanguageChange(ROUTES_ANP.HOME, language.code));
        }
    }

    const active = i18n.language === language.code;

    return (
        <button
            className={clsx('paragraph3', active && classes.active, classes.layout)}
            onClick={handleClick}
        >
            {language.shortName}
        </button>
    );
}
