import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layoutOnPage: props => ({
        width: '441px',
        borderRadius: '12px',
        padding: '32px',
        backgroundColor: '#FFFFFF',
        border: '2px solid #D9D9D9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > *:not(:last-child)': {
            marginBottom: '24px',
        },
        '& > *:last-child': {
            marginTop: 'auto',
        }
    }),
    layoutInMenu: props => ({
        width: '441px',
        borderRadius: '12px',
        padding: '32px',
        backgroundColor: '#FFFFFF',
        border: '2px solid #D9D9D9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > *:not(:last-child)': {
            marginBottom: '24px',
        },
        '& > *:last-child': {
            marginTop: 'auto',
        }
    }),
    headerOnPage: {
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:first-child)': {
            marginLeft: '16px'
        }
    },
    headerInMenu: {
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:first-child)': {
            marginLeft: '16px'
        }
    },
    iconOnPage: {
        width: '80px',
        height: '80px',
    },
    iconInMenu: {
        width: '80px',
        height: '80px',
    },
    summaryOnPage: {
        '&.paragraph2': {
            color: '#5C5C66',
        }
    },
    summaryInMenu: {
        '&.paragraph3': {
            color: '#5C5C66',
        }
    }
}));

export default function ParkIconListItem(props) {
    const { title, summary, linkTitle, url, isMenu, Icon } = props;
    const classes = useStyles(props);

    return (
        <div className={clsx(isMenu ? classes.layoutInMenu : classes.layoutOnPage)}>
            <div className={clsx(isMenu ? classes.headerInMenu : classes.headerOnPage)}>
                <Icon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)}/>
                <Link to={url} className={'link'}>
                    {isMenu ? <h3>{title}</h3> : <h3>{title}</h3>}
                </Link>
            </div>
            <div
                className={isMenu ? clsx('paragraph3', classes.summaryInMenu) : clsx('paragraph2', classes.summaryOnPage)}>{summary}</div>
            <Link to={url} className={'linkBig'}>{linkTitle}</Link>
        </div>
    );
}
