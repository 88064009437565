export default {
    '& p': {
        margin: '0px',
        padding: '0px 15px',
        '@media (min-width:400px)': {
            padding: '0px 20px',
        },
        '@media (min-width:760px)': {
            padding: '0px 60px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px',
        },
    },
    '& p + p': {
        '@media (min-width:1280px)': {
            paddingTop: '10px',
        },
    },
    '& p + ul': {
        '@media (min-width:1280px)': {
            paddingTop: '10px',
        },
    },
    '& ul + p': {
        '@media (min-width:1280px)': {
            paddingTop: '10px',
        },
    },
    '& p + ol': {
        '@media (min-width:1280px)': {
            paddingTop: '10px',
        },
    },
    '& ol + p': {
        '@media (min-width:1280px)': {
            paddingTop: '10px',
        },
    },
    '& img': {
        maxWidth: '100%',
        width: '100%',
        display: 'block',
        '@media (min-width:760px)': {
            borderRadius: '12px',
        },
    },
    '& .fileLink': {
        padding: '0px 15px',
        '@media (min-width:400px)': {
            padding: '0px 20px',
        },
        '@media (min-width:760px)': {
            padding: '0px 60px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px',
        },
    },
    '& a': {
        color: '#0F6099',
        display: 'inline-block',
        position: 'relative',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '18px',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        '@media (min-width:1280px)': {
            fontSize: '14px',
            lineHeight: '20px',
        },
        '&:before': {
            height: '2px',
            backgroundColor: '#1A9FFF',
            borderRadius: '1px',
            content: '""',
            position: 'absolute',
            bottom: '-1px',
            width: '100%',
        },
        '&:hover': {
            '&:before': {
                backgroundColor: '#F2A200',
            }
        },
    },
    '& h3': {
        fontSize: '22px',
        color: '#17171A',
        lineHeight: '33px',
        letterSpacing: '0.5px',
        fontWeight: '800',
        marginBottom: '12px',
        '&:not(:first-child)': {
            marginTop: '12px',
        },
        padding: '0px 15px',
        '@media (min-width:400px)': {
            padding: '0px 20px',
            margin: '0px 0px 24px 0px',
        },
        '@media (min-width:760px)': {
            fontSize: '24px',
            padding: '0px 60px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px',
        },
    },
    '& h4': {
        fontSize: '18px',
        color: '#17171A',
        lineHeight: '28px',
        letterSpacing: '0.5px',
        fontWeight: '800',
        marginBottom: '12px',
        '&:not(:first-child)': {
            marginTop: '12px',
        },
        padding: '0px 15px',
        '@media (min-width:400px)': {
            padding: '0px 20px',
            margin: '0px 0px 24px 0px',
        },
        '@media (min-width:760px)': {
            fontSize: '20px',
            padding: '0px 60px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px',
        },
    },
    '& h5': {
        fontSize: '14px',
        color: '#17171A',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        fontWeight: '800',
        marginBottom: '12px',
        '&:not(:first-child)': {
            marginTop: '12px',
        },
        padding: '0px 15px',
        '@media (min-width:400px)': {
            padding: '0px 20px',
            margin: '0px 0px 24px 0px',
        },
        '@media (min-width:760px)': {
            fontSize: '16px',
            padding: '0px 60px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px',
        },
    },
    '& .ql-align-center': {
        textAlign: 'center',
        width: '100%',
    },
    '& .ql-align-justify': {
        textAlign: 'justify',
        width: '100%',
    },
    '& .ql-align-right': {
        textAlign: 'right',
        width: '100%',
    },
    '& .ql-bg-black': {
        backgroundColor: '#000',
    },
    '& .ql-bg-red': {
        backgroundColor: '#e60000',
    },
    '& .ql-bg-orange': {
        backgroundColor: '#f90',
    },
    '& .ql-bg-yellow': {
        backgroundColor: '#ff0',
    },
    '& .ql-bg-green': {
        backgroundColor: '#008a00',
    },
    '& .ql-bg-blue': {
        backgroundColor: '#06c',
    },
    '& .ql-bg-purple': {
        backgroundColor: '#93f',
    },
    '& .ql-color-white': {
        color: '#fff',
    },
    '& .ql-color-red': {
        color: '#e60000',
    },
    '& .ql-color-orange': {
        color: '#f90',
    },
    '& .ql-color-yellow': {
        color: '#ff0',
    },
    '& .ql-color-green': {
        color: '#008a00',
    },
    '& .ql-color-blue': {
        color: '#06c',
    },
    '& .ql-color-purple': {
        color: '#93f',
    },
    '& p, & ol, & ul, & pre, & blockquote, & h1, & h2, & h3, & h4, & h5, & h6': {
        margin: '0',
        padding: '0',
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
        width: '100%',
    },
}
