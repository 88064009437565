export default {
    '& table': {
        borderCollapse: 'collapse',
        margin: '12px 15px',
        '@media (min-width:400px)': {
            margin: '12px 20px',
        },
        '@media (min-width:760px)': {
            margin: '12px 60px',
        },
        '@media (min-width:1280px)': {
            margin: '12px 100px',
        },
    },
    '& table p': {
        padding: '0px 10px',
        margin: 0,
    },
    '& table td': {
        border: '1px solid black',
        padding: '5px',
        height: '25px',
    },
    '& .ql-table': {
        width: 'auto !important',
        marginRight: 0,
    },
    '& .ql-contain': {
        marginRight: 0,
    },
    '& .ql-picker.ql-table': {
        fontWeight: 'normal',
    },
    '& .ql-picker.ql-table svg': {
        display: 'none',
    },
    '& .ql-picker.ql-table .ql-picker-label': {
        padding: '2px 3px',
    },
};
