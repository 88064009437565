import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {tourModule} from 'shared/src/modules/tour';

const useStyles = makeStyles(theme => ({
    layout: {
        height: '289px',
        border: '1px solid #D9D9D9',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    infoBlock: (props) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '214px',
        width: '100%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px 12px 0 0',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.tour.headerImage}')`,
        position: 'relative',
    }),
    tourDateCard: {
        position: 'absolute',
        top: '12px',
        right: '12px',
        borderRadius: '4px',
        backgroundColor: 'rgb(23,23,26, 0.4)',
        padding: '4px 10px 4px 10px',
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        lineHeight: '16px',
    },
    titleBar: {
        paddingBottom: '12px',
    },
    title: {
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    subTitle: {
        '&.paragraph4': {
            color: theme.palette.common.white
        },
        textAlign: 'center',
    },
    infoLine: {
        '&.paragraph3': {
            color: theme.palette.common.white,
        },
        display: 'flex',
        paddingBottom: '24px',
    },
    separator: {
        height: '24px',
        width: '2px',
        borderRadius: '1px',
        backgroundColor: '#F2A200',
        margin: '0px 12px 0px 12px',
    },
    bottomBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0px 24px 16px 24px',
    },
    link: {
        textDecoration: 'none',
    }
}));

export default function TourListItem(props) {
    const { tour } = props;
    const classes = useStyles(props);

    const { t, i18n } = useTranslation();

    const tourDateInterval = tourModule.getTourDateInterval(tour);

    return (
        <div className={classes.layout}>
            <div className={classes.infoBlock}>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(tour.id), i18n.language)}
                      className={clsx(classes.link, classes.titleBar)} tabIndex={-1}>
                    <h3 className={classes.title}>
                        {tour.name}
                    </h3>
                    {tour.subTitle &&
                        <div className={clsx('paragraph4', classes.subTitle)}>
                            {tour.subTitle}
                        </div>
                    }
                </Link>
                <div className={clsx('paragraph3', classes.infoLine)}>
                    {tour.distance}
                    {tour.distance && (tour.time || tour.difficulty) && <div className={classes.separator}/>}
                    {tour.time}
                    {tour.time && tour.difficulty && <div className={classes.separator}/>}
                    {tour.difficulty}
                </div>
                {tourDateInterval &&
                    <div className={classes.tourDateCard}>
                        {tourModule.formatTourDateInterval(tourDateInterval)}
                    </div>
                }
            </div>
            <div className={classes.bottomBox}>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(tour.id), i18n.language)}
                      className={'linkSecondary'}>{t('Text.TourDetails')}</Link>
                {/*
                <PrimaryBuyingButton tour={tour}/>
*/}
            </div>
        </div>
    );
}
