import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';
import BadgeList from '../badge/lists/badgeList/BadgeList';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        height: '300px',
        [theme.breakpoints.up('sm')]: {
            height: '480px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '100%',
            minHeight: '600px',
        },
        backgroundImage: props.headlineContent ? `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url("/content/images/${props.headlineContent.headlineImage}")` : null,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    }),
    descriptionsPosition: {
        position: 'absolute',
        bottom: '24px',
        [theme.breakpoints.up('sm')]: {
            bottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            bottom: '46px',
        },
    },
    titleBlock: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '50px',
        },
    },
    title: {
        color: theme.palette.common.white,
    },
    titleAdjustment: {
        width: '482px',
        '@media (max-width:500px)': {
            width: 'calc(100% - 15px)',
            fontSize: '26px',
        }
    },
    titlePadding: {
        padding: '12px 0px 24px 0px',
        [theme.breakpoints.up('sm')]: {
            padding: '16px 0px 25px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px 0px 32px 0px',
        },
    },
    summaryBlock: {
        display: 'flex',
    },
    leftBar: {
        width: '4px',
        borderRadius: '2px',
        backgroundColor: '#F2CA00',
    },
    summary: {
        '&.paragraph1': {
            color: theme.palette.common.white,
        },
        width: '446px',
        paddingLeft: '32px',
        '& > p': {
            margin: '0px',
            '&:not(:last-child)': {
                margin: '0px 0px 16px 0px',

            }
        }
    },
    summaryVisibility: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    link: {
        textDecoration: 'none',
    }
}));

export default function TopNews(props) {
    const { className, headlineContent } = props;
    const { i18n } = useTranslation();
    const classes = useStyles({ headlineContent });
    const history = useHistory();

    function handleCallToActionClick() {
        history.push(getPathWithLanguage(ROUTES_ANP.NEWS_ARTICLE(headlineContent.id), i18n.language));
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={clsx('padding-left', classes.descriptionsPosition)}>
                <div className={classes.titleBlock}>
                    <BadgeList badgeIds={headlineContent && headlineContent.badgeIds}/>
                    <Link
                        to={getPathWithLanguage(ROUTES_ANP.NEWS_ARTICLE(headlineContent && headlineContent.id), i18n.language)}
                        className={classes.link}>
                        <h1 className={clsx(classes.title, classes.titleAdjustment, classes.titlePadding)}>
                            {headlineContent && headlineContent.title}
                        </h1>
                    </Link>
                    <div className={classes.summaryBlock}>
                        <div className={classes.leftBar}/>
                        <div className={clsx('paragraph1', classes.summary, classes.summaryVisibility)}
                             dangerouslySetInnerHTML={createMarkup(headlineContent ? headlineContent.summary : '')}/>
                    </div>
                </div>
                <PrimaryBasicButton variant='big' title={headlineContent ? headlineContent.callToActionText : ''}
                                    onClick={handleCallToActionClick}/>
            </div>
        </div>
    );
}
