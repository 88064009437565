import React from 'react';
import {makeStyles} from '@material-ui/core';
import TourPackageListItem from './TourPackageListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            '&:not(:last-child)': {
                paddingBottom: '32px',
                [theme.breakpoints.up('sm')]: {
                    paddingBottom: '32px',
                },
                [theme.breakpoints.up('lg')]: {
                    paddingBottom: '47px',
                }
            },
            '&:not(:first-child)': {
                paddingTop: '24px',
                [theme.breakpoints.up('sm')]: {
                    paddingTop: '32px',
                },
                [theme.breakpoints.up('lg')]: {
                    paddingTop: '47px',
                },
                borderTop: '1px solid #D9D9D9',
            },
            '&:last-child': {
                paddingBottom: '32px',
                borderBottom: '1px solid #D9D9D9',
                [theme.breakpoints.up('sm')]: {
                    paddingBottom: '0px',
                    borderBottom: '0px',
                },
            }
        },
    }
}));

export default function TourPackageList(props) {
    const { tourPackages } = props;
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            {tourPackages.map(tourPackage =>
                <TourPackageListItem key={tourPackage.id} tourPackage={tourPackage}/>
            )}
        </div>
    );
}
