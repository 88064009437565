import React from 'react';
import {makeStyles} from '@material-ui/core';
import SearchResultNewsListItem from './SearchResultNewsListItem';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: '14px',
        }
    }
}));

export default function SearchResultNewsList(props) {
    const { className, news } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.layout, className)}>
            {news.map(newsItem =>
                <SearchResultNewsListItem key={newsItem.id} news={newsItem}/>
            )}
        </div>

    );
}
