class ImageModule {
    getImagesWrapperNode(node) {
        if (!node) {
            return null;
        }

        if (node.tagName &&
            node.getAttribute &&
            node.tagName.toUpperCase() === 'DIV' &&
            node.getAttribute('class') &&
            node.getAttribute('class').indexOf('imagesWrapper') > -1) {
            return node;
        }

        if (node.tagName &&
            node.getAttribute &&
            node.tagName.toUpperCase() === 'DIV' &&
            node.getAttribute('class') &&
            node.getAttribute('class').indexOf('anpContent') > -1) {
            return null;
        }

        if (node.parentNode) {
            return this.getImagesWrapperNode(node.parentNode);
        }

        return null;
    }

    getImageWrapperNode(node) {
        if (!node) {
            return null;
        }

        if (node.tagName &&
            node.getAttribute &&
            node.tagName.toUpperCase() === 'DIV' &&
            node.getAttribute('class') &&
            node.getAttribute('class').indexOf('imageWrapper') > -1) {
            return node;
        }

        if (node.tagName &&
            node.getAttribute &&
            node.tagName.toUpperCase() === 'DIV' &&
            node.getAttribute('class') &&
            node.getAttribute('class').indexOf('videoWrapper') > -1) {
            return node;
        }

        if (node.tagName &&
            node.getAttribute &&
            node.tagName.toUpperCase() === 'DIV' &&
            node.getAttribute('class') &&
            node.getAttribute('class').indexOf('imagesWrapper') > -1) {
            return null;
        }

        if (node.parentNode) {
            return this.getImageWrapperNode(node.parentNode);
        }

        return null;
    }

    getImagesAndOptionsFromWrapperNode(domNode) {
        if (!domNode) {
            return null;
        }

        const children = [...domNode.children];
        const images = children.map((childNode, index) => {
            return {...this.getImageFromWrapperNode(childNode), id: index};
        });

        const options = {
            wide: domNode.getAttribute('class').indexOf('imagesWrapperWide') > -1,
            slides: domNode.getAttribute('class').indexOf('imagesWrapperSlides') > -1
        };

        return {
            images,
            options,
        };
    }

    getImageFromWrapperNode(domNode) {
        if (!domNode) {
            return null;
        }

        return {
            title: domNode.children.length > 0 && domNode.children[0].innerText || '',
            iframe: domNode.children.length > 0 && domNode.children[0].tagName && domNode.children[0].tagName.toUpperCase() === 'IFRAME' ? domNode.innerHTML : '',
            src: domNode.children.length > 1 && domNode.children[1].getAttribute('src') || '',
            alt: domNode.children.length > 1 && domNode.children[1].getAttribute('alt') || '',
            maxHeight: domNode.children.length > 1 && domNode.children[1].style['maxHeight'] || '',
            maxWidth: domNode.children.length > 1 && domNode.children[1].style['maxWidth'] || '',
            enlarge: domNode.getAttribute('class').indexOf('imageWrapperEnlarge') > -1,
            noGradient: domNode.getAttribute('class').indexOf('imageWrapperNoGradient') > -1,
            noRounding: domNode.getAttribute('class').indexOf('imageWrapperNoRounding') > -1,
        };
    }
}

export const imageModule = new ImageModule();
