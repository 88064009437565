import React from 'react';
import {makeStyles} from '@material-ui/core';
import {menuActions} from 'client_shared/src/state/actions';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menu: {
        display: "flex",
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    menuItem: {
        display: "block",
        flex: "0 1 auto",
        listStyleType: "none",
    },
    menuItemLink: {
        position: 'relative',
        textDecoration: 'none',
    },
    menuItemLinkContent: {
        display: 'flex',
        alignItems: 'center',
    },
    menuItemLinkText: {
        '&.paragraph2': {
            fontWeight: 'bold',
            color: '#17171A',
        },
    },
    menuItemLinkIcon: {
        width: '25px',
        height: '25px',
        color: '#17171A',
    },
}));

export default function MobileMenuBack() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    function handleClick() {
        return (event) => {
            event.preventDefault();
            dispatch(menuActions.hideMobileMenuItem());
        };
    }

    return (
        <ul className={classes.menu}>
            <li className={classes.menuItem}>
                <a
                    href={'/'}
                    onClick={handleClick()}
                    className={classes.menuItemLink}>
                    <div className={classes.menuItemLinkContent}>
                        <ChevronLeftIcon className={classes.menuItemLinkIcon}/>
                        <div className={clsx('paragraph2', classes.menuItemLinkText)}>
                            {t('Text.MobileMenuBackTitle')}
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    );
}
