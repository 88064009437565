import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: (props) => ({
        width: '100%',
        border: props.selected ? '0px' : '1px solid #5C5C66',
        borderRadius: '8px',
        padding: '0px 25px',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontFamily: theme.typography.fontFamily,
        backgroundColor: props.selected ? '#5C5C66' : '#FFFFFF',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:focus': {
            outline: '0 !important',
        },
        height: '35px',
        [theme.breakpoints.up('lg')]: {
            height: '44px',
        },
    }),
    title: (props) => ({
        '&.paragraph3': {
            color: props.selected ? '#FFFFFF' : '#17171A',
        }
    }),
}));

export default function SearchFilterListItem(props) {
    const { searchFilterType, selected, title, onClick } = props;
    const classes = useStyles({ selected });

    function handleClick() {
        onClick(searchFilterType);
    }

    return (
        <button className={classes.layout} onClick={handleClick}>
            <div className={clsx('paragraph3', classes.title)}>
                {title}
            </div>
        </button>
    );
}
