import * as React from "react";

function SvgKornyezetiNevelesTanosvenyek(props) {
    return (
        <svg
            id="kornyezeti_neveles-tanosvenyek_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>{".kornyezeti_neveles-tanosvenyek_svg__st6{fill:#f3a200}"}</style>
            <g id="kornyezeti_neveles-tanosvenyek_svg__Layer_4">
                <g id="kornyezeti_neveles-tanosvenyek_svg__kornyezeti_neveles-tanosvenyek">
                    <circle cx={32} cy={32} r={27.5} fill="#e6e6e6"/>
                    <path
                        d="M32 59.5c15.2 0 27.5-12.3 27.5-27.5 0-8.1-3.6-15.9-9.9-21.1C34 13.3 15.4 25.3 4.5 33.2 5.2 47.9 17.3 59.5 32 59.5z"
                        fill="#b3b3b3"
                    />
                    <path
                        d="M59.3 28.6c-16.6-3.5-18.2-9.1-15-11 2.9-1.4 6-2.4 9.2-2.7-.8-1.1-1.8-2.1-2.8-3-3.9.4-7.9 1.2-10.2 2.5-5.5 3.1-7.2 6.1-6.4 8.9 1.6 5.3 19.2 8 17.9 15.2-1 5.2-4.6 10.9-19.2 13-.1-1.1-.1-2.2 0-3.3.1-.6-.3-1.1-.9-1.1s-1.1.3-1.1.9c-.2 1.3-.2 2.6 0 3.8-.7.1-1.4.2-2.2.2-.4-2.6-.2-5.2.7-7.7.2-.5-.1-1.1-.6-1.3-.5-.2-1.1.1-1.3.6-.9 2.7-1.2 5.7-.8 8.5-4.4.3-8.9.4-13.3.2 11.1 10.3 28.5 9.6 38.8-1.6 5.6-5.9 8.2-14.1 7.2-22.1z"
                        fill="#5c5c67"
                    />
                    <path
                        d="M37.1 30.2l-5.5-5c-.1-.1-.2-.2-.4-.2h-7.5v-5.9h12.2c.3 0 .5-.2.5-.5 0-.1 0-.2-.1-.3l-4-4.5 4-4.5c.2-.2.2-.5 0-.7-.1-.1-.2-.1-.3-.1H23.7V6.7c.1-.9-.6-1.8-1.6-1.8s-1.8.6-1.8 1.6v2H9.7c-.1 0-.3.1-.4.2l-5.5 5c-.2.2-.2.5 0 .7L9.4 19c.1.1.2.2.4.2h10.6V25H8.6c-.3 0-.5.2-.5.5 0 .1 0 .2.1.3l4 4.5-4 4.5c-.2.2-.2.5 0 .7.1.1.2.1.3.1h11.7v18.5c0 .9 3.4.9 3.4 0V35.7h7.5c.1 0 .3-.1.4-.2l5.5-4.7c.2-.1.3-.4.1-.6z"
                        fill="#17171a"
                    />
                    <path
                        d="M35.9 17.4H9.7c-.1 0-.3-.1-.4-.2l-5.5-4.7c-.2-.2-.2-.5 0-.7l5.5-5c.1-.1.2-.2.4-.2h26.1c.3 0 .5.2.5.5 0 .1 0 .2-.1.3l-4 4.5 4 4.5c.2.2.1.5-.1.7 0 .3-.1.3-.2.3z"
                        fill="#f2ca00"
                    />
                    <path
                        d="M8.6 34h22.6c.1 0 .3-.1.4-.2l5.5-4.7c.2-.2.2-.5 0-.7l-5.5-5c-.1-.1-.2-.2-.4-.2H8.6c-.3 0-.5.2-.5.5 0 .1 0 .2.1.3l4 4.5-4 4.5c-.2.2-.1.5.1.7.2.3.2.3.3.3z"
                        fill="#f2b600"
                    />
                    <path
                        className="kornyezeti_neveles-tanosvenyek_svg__st6"
                        d="M27.5 13.1H13.1c-.6 0-1-.4-1-1s.4-1 1-1h14.5c.6 0 1 .4 1 1s-.5 1-1.1 1zM31.8 29.6h-14c-.6 0-1-.4-1-1s.4-1 1-1h14c.6 0 1 .4 1 1s-.5 1-1 1z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgKornyezetiNevelesTanosvenyek;
