import React from 'react';
import {makeStyles} from '@material-ui/core';
import {LANGUAGES} from 'shared/src/constants/translation';
import LanguageButton from '../../../buttons/LanguageButton';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *:not(:first-child)': {
            marginLeft: '24px',
        }
    }
}));

export default function LanguageButtonMenu() {
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            {LANGUAGES.map(language => (
                <LanguageButton key={language.id} language={language}/>
            ))}
        </div>
    );
}
