import React from 'react';
import {makeStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import {useFormikContext} from 'formik';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#D9D9D9',
                borderRadius: '8px',
            },
            '&:hover fieldset': {
                borderColor: '#5C5C66',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#5C5C66',
                borderWidth: '1px',
            },
            '&.Mui-error:hover fieldset': {
                borderColor: '#f44336',
            }
        },
    },
    label: {
        '&.paragraph3': {
            fontWeight: 'bold',
            marginBottom: '8px',
        }
    },
    textField: {
        '&:not(:last-child)': {
            marginBottom: '24px',
        }
    }
}));

export default function TextFieldComponent(props) {
    const { inputId, label, ariaLabel, className, propertyName, placeholder, multiline, onChange} = props;

    const { values, handleChange, handleBlur, touched, errors } = useFormikContext();

    const classes = useStyles();

    return (
        <>
            {label &&
            <label htmlFor={inputId} className={clsx('paragraph3', classes.label)}>
                {label}
            </label>
            }
            <TextField
                id={inputId}
                className={clsx(classes.textField, className)}
                name={propertyName}
                multiline={multiline}
                placeholder={placeholder}
                value={values[propertyName]}
                onChange={onChange || handleChange}
                onBlur={handleBlur}
                variant='outlined'
                fullWidth
                error={!!(touched[propertyName] && errors[propertyName])}
                helperText={touched[propertyName] && errors[propertyName]}
                classes={{
                    root: classes.root,
                }}
                inputProps={{
                        "aria-label": ariaLabel ? ariaLabel : null,
                        "aria-required": "true",
                        "aria-invalid": !!(touched[propertyName] && errors[propertyName]) ? 'true' : null,
                        "aria-describedby": !!(touched[propertyName] && errors[propertyName]) ? `${inputId}-helper-text` : null
                }}
            />
        </>
    );
}
