import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / parkolas"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#6CBED9" cx={24} cy={24} r={24}/>
                <path
                    d="M25 15c3.31 0 6 2.69 6 6s-2.69 6-6 6h-3v6h-4V15zm.2 4H22v4h3.2c1.1 0 2-.9 2-2s-.9-2-2-2z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
