import React from 'react';
import InterregIcon from '../icons/logos/InterregIcon';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        lineHeight: '0px',
    }
}));

export default function InterregLogo(props) {
    const classes = useStyles();

    return (
        <a href="http://www.skhu.eu/" className={classes.link}>
            <InterregIcon {...props}/>
        </a>
    );
}
