import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useSelector} from 'react-redux';
import {getActualMenu, getActualSubMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            marginTop: '-32px',
            marginLeft: '-30px',
            '& > *': {
                marginTop: '32px',
                marginLeft: '30px',
            },
        },
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:not(:first-child)': {
            borderTop: '1px solid #45454D',
            borderRadius: '1px',
        },
        [theme.breakpoints.up('lg')]: {
            '&:not(:first-child)': {
                border: '0px',
            }
        }
    },
    list: {
        display: "flex",
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            alignItems: "flex-start",
            width: 'auto',
        }
    },
    listItem: {
        '& > .paragraph4': {
            lineHeight: '29px',
            textDecoration: 'none',
            color: '#D9D9D9',
        },
        flex: "0 1 auto",
        listStyleType: "none",
        width: '100%',
        justifyContent: "space-between",
        [theme.breakpoints.up('lg')]: {
            display: "flex",
            alignItems: "flex-start",
            width: 'auto',
        }
    },
    listItemHeader: {
        '& > h5': {
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
        display: 'flex',
        justifyContent: "space-between",
        paddingBottom: '16px',
        paddingTop: '16px',
        [theme.breakpoints.up('lg')]: {
            paddingTop: '0px',
            paddingBottom: '24px',
        }
    },
    downIcon: {
        color: '#45454D',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    }
}));

export default function FooterMenu() {
    const classes = useStyles();
    const [expandedStates, setExpandedStates] = useState({});
    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);
    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_FOOTER, user);

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const { t, i18n } = useTranslation();

    function handleClick(menuItemId) {
        return () => {
            setExpandedStates(Object.assign({}, expandedStates, {
                    [menuItemId]: !expandedStates[menuItemId],
                }
            ));
        }
    }

    return (
        <nav className={classes.layout}>
            {menu.map(menuItem =>
                <div key={menuItem.id} className={classes.menu}>
                    <ul className={classes.list}>
                        <div className={clsx(classes.listItemHeader)} onClick={handleClick(menuItem.id)}>
                            <h5>
                                {t(menuItem.title)}
                            </h5>
                            {!expandedStates[menuItem.id] &&
                            <ExpandMoreIcon className={classes.downIcon}/>
                            }
                            {expandedStates[menuItem.id] &&
                            <ExpandLessIcon className={classes.downIcon}/>
                            }
                        </div>
                        {(expandedStates[menuItem.id] || isDesktop) &&
                        <>
                            {getActualSubMenu(menus, menuItem.id, user).map(subMenuItem =>
                                <li key={subMenuItem.id} className={classes.listItem}>
                                    <Link to={getPathWithLanguage(subMenuItem.url, i18n.language)}
                                          className={clsx('paragraph4', classes.link)}>
                                        {t(subMenuItem.title)}
                                    </Link>
                                </li>
                            )}
                        </>
                        }
                    </ul>
                </div>
            )}
        </nav>
    );
}
