import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    layoutOpen: {
        borderRadius: '19px',
        borderWidth: '0px',
        backgroundColor: '#F2CA00',
        boxShadow: '0 2px 0 0 rgba(242,202,0,0.4)',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#F2D649',
            boxShadow: '0 2px 0 0 rgba(242,182,0,0.7)',
        },
        '&:active': {
            backgroundColor: '#F2B600',
            boxShadow: 'none',
        },
        '&:focus': {
            /*outline: '0 !important',*/
        },
        height: '28px',
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.up('sm')]: {
            height: '38px',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
    textOpen: {
        whiteSpace: 'nowrap',
        '&.paragraph3': {
            fontWeight: 'bold',
        },
        [theme.breakpoints.up('sm')]: {
            '&.paragraph1': {
                fontWeight: 'bold',
            }
        },
        [theme.breakpoints.up('lg')]: {
            '&.paragraph2': {
                fontWeight: 'bold',
            }
        },
    },
    redDot: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        backgroundColor: '#E66045',
        borderRadius: '6px',
        height: '8px',
        width: '8px',
        [theme.breakpoints.up('sm')]: {
            height: '12px',
            width: '12px',
        }
    },
    layoutClose: {
        borderRadius: '19px',
        borderWidth: '0px',
        backgroundColor: '#5C5C66',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:focus': {
            /*outline: '0 !important',*/
        },
        height: '28px',
        paddingLeft: '12px',
        paddingRight: '12px',
        [theme.breakpoints.up('sm')]: {
            height: '38px',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
    textClose: {
        ...theme.typography.paragraph3,
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        [theme.breakpoints.up('md')]: {
            ...theme.typography.paragraph1,
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.common.white,
        },
        [theme.breakpoints.up('lg')]: {
            ...theme.typography.paragraph2,
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.common.white,
        },
    },
    popover: {
        width: '500px',
        height: '500px',
    }
}));

export default function TicketsButton(props) {
    const { className } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const checkoutIsOpen = useSelector(state => state.checkout.open);

    function onClick() {
        checkoutIsOpen ? dispatch(checkoutActions.interruptCheckout()) : dispatch(checkoutActions.launchCheckout());
    }

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const textStyle = isDesktop ? 'paragraph2' : (isTablet ? 'paragraph1' : 'paragraph3');

    return (
        <>
            {!checkoutIsOpen ?
                <button className={clsx(className, textStyle, classes.layoutOpen, classes.textOpen)} onClick={onClick}>
                    {t('Button.Tickets')}
                    <div className={classes.redDot}/>
                </button>
                :
                <button className={clsx(className, classes.layoutClose, classes.textClose)} onClick={onClick}>
                    {t('Button.Close')}
                </button>
            }
        </>
    );
}
