import * as React from "react";

function SvgMenu(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M58.7 48.2c2.9 0 5.3 2.4 5.3 5.4 0 3-2.4 5.4-5.3 5.4H5.3C2.4 59 0 56.6 0 53.6c0-3 2.4-5.4 5.3-5.4h53.4zm0-21.6c2.9 0 5.3 2.4 5.3 5.4s-2.4 5.4-5.3 5.4H5.3C2.4 37.4 0 35 0 32s2.4-5.4 5.3-5.4h53.4zm0-21.6c2.9 0 5.3 2.4 5.3 5.4s-2.4 5.4-5.3 5.4H5.3c-2.9 0-5.3-2.4-5.3-5.4S2.4 5 5.3 5h53.4z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#17171a"
            />
        </svg>
    );
}

export default SvgMenu;
