import {sendServerRequest} from './baseService';

export const emailAddressService = {
    getEmailAddress,
};

async function getEmailAddress(token) {
    const body = {
        token,
    };

    return sendServerRequest('POST', `/api/emailAddress`, body);
}
