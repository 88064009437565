import React from 'react';
import {makeStyles} from '@material-ui/styles';
import ContentStyleSheetAnp from 'shared/src/styles/ContentStyleSheetAnp';

const useStyles = makeStyles(theme => ({
    '@global': Object.assign({}, ContentStyleSheetAnp, {
        h1: {
            ...theme.typography.h1,
            margin: '0px',
        },
        h2: {
            ...theme.typography.h2,
            margin: '0px',
        },
        h3: {
            ...theme.typography.h3,
            margin: '0px',
        },
        h4: {
            ...theme.typography.h4,
            margin: '0px',
        },
        h5: {
            ...theme.typography.h5,
            margin: '0px',
        },
        '.h3': {
            ...theme.typography.h3,
            margin: '0px',
        },
        '.h4': {
            ...theme.typography.h4,
            margin: '0px',
        },
        '.paragraph1': {
            ...theme.typography.paragraph1,
        },
        '.paragraph2': {
            ...theme.typography.paragraph2,
        },
        '.paragraph3': {
            ...theme.typography.paragraph3,
        },
        '.paragraph4': {
            ...theme.typography.paragraph4,
        },
        '.linkBig': {
            ...theme.typography.linkBig,
        },
        '.linkBigInverse': {
            ...theme.typography.linkBigInverse,
        },
        '.linkNormal': {
            ...theme.typography.linkNormal,
        },
        '.linkNormalInverse': {
            ...theme.typography.linkNormalInverse,
        },
        '.linkSecondary': {
            ...theme.typography.linkSecondary,
        },
        '.link': {
            textDecoration: 'none',
        },
        '.padding': {
            width: '100%',
            padding: '0px 15px',
            '@media (min-width:400px)': {
                padding: '0px 20px',
            },
            /*
                    '@media (min-width:460px)': {
                        padding: '0px calc((100vw - 420px) / 2)',
                    },
            */
            '@media (min-width:760px)': {
                padding: '0px calc((100vw - 700px) / 2)',
            },
            '@media (min-width:1020px)': {
                padding: '0px calc((100vw - 940px) / 2)',
            },
            '@media (min-width:1280px)': {
                padding: '0px 40px',
            },
            '@media (min-width:1430px)': {
                padding: '0px calc((100vw - 1350px) / 2)',
            },
        },
        '.padding-left': {
            paddingLeft: '15px',
            '@media (min-width:400px)': {
                paddingLeft: '20px',
            },
            /*
                    '@media (min-width:460px)': {
                        paddingLeft: 'calc((100vw - 420px) / 2)',
                    },
            */
            '@media (min-width:760px)': {
                paddingLeft: 'calc((100vw - 700px) / 2)',
            },
            '@media (min-width:1020px)': {
                paddingLeft: 'calc((100vw - 940px) / 2)',
            },
            '@media (min-width:1280px)': {
                paddingLeft: '40px',
            },
            '@media (min-width:1430px)': {
                paddingLeft: 'calc((100vw - 1350px) / 2)',
            },
        },
        '.padding-right': {
            paddingRight: '15px',
            '@media (min-width:400px)': {
                paddingRight: '20px',
            },
            /*
                    '@media (min-width:460px)': {
                        paddingLeft: 'calc((100vw - 420px) / 2)',
                    },
            */
            '@media (min-width:760px)': {
                paddingRight: 'calc((100vw - 700px) / 2)',
            },
            '@media (min-width:1020px)': {
                paddingRight: 'calc((100vw - 940px) / 2)',
            },
            '@media (min-width:1280px)': {
                paddingRight: '40px',
            },
            '@media (min-width:1430px)': {
                paddingRight: 'calc((100vw - 1350px) / 2)',
            },
        },
        '.padding-article': {
            width: '100%',
            padding: '0px 0px',
            '@media (min-width:400px)': {
                padding: '0px 0px',
            },
            '@media (min-width:760px)': {
                padding: '0px calc((100vw - 700px) / 2)',
            },
            '@media (min-width:1020px)': {
                padding: '0px calc((100vw - 940px) / 2)',
            },
            '@media (min-width:1280px)': {
                padding: '0px calc((100vw - 995px) / 2)',
            },
            '@media (min-width:1430px)': {
                padding: '0px calc((100vw - 995px) / 2)',
            },
        },
    }),
}));

export default function GlobalCss(props) {
    useStyles();

    return (
        <>
            {props.children}
        </>
    );
}