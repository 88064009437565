import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
    helpBar: {
        marginTop: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    helpBarItem: {
        '&:not(:first-child)': {
            marginLeft: '6px'
        },
    },
    helpBarImage: {
        width: '20px',
        height: '20px',
        color: '#17171A',
        opacity: '0.2',
    },
    helpBarText1: {
        '&.paragraph3': {
            color: '#5C5C66',
        }
    },
    helpBarText2: {
        '&.paragraph3': {
            color: '#5C5C66',
            fontWeight: 'bold',
        }
    },
}));

export default function HelpBar() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.helpBar}>
            <HelpIcon className={clsx(classes.helpBarItem, classes.helpBarImage)}/>
            <div className={clsx('paragraph3', classes.helpBarText1, classes.helpBarItem)}>
                {t('Text.Help')}
            </div>
            <div className={clsx('paragraph3', classes.helpBarText2, classes.helpBarItem)}>
                {t('Text.HelpPhoneNumber')}
            </div>
        </div>
    );
}








