import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import AccommodationListItem from './AccommodationListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        '& > * + *': {
            marginTop: '12px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '12px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '14px'
            }
        }
    }
}));

export default function AccommodationList(props) {
    const { className, accommodations } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.layout, className)}>
            {accommodations.map(accommodation =>
                <AccommodationListItem key={accommodation.id} accommodation={accommodation}/>
            )}
        </div>
    );
}
