import React from 'react';
import {makeStyles} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import SearchField from './SearchField';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
    },
    expandedLayout: {
        flex: 1,
    },
    searchButton: {
        boxSizing: 'border-box',
        height: '38px',
        width: '38px',
        border: '1px solid #D9D9D9',
        borderRadius: '19px',
        '&:hover': {
            cursor: 'pointer',
        },
        padding: '0px',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: 'none',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
    },
    searchIcon: {
        margin: '4px 0 0 2px',
        color: '#5C5C66',
    }
}));

export default function SearchBar(props) {
    const { className, isInSearchMode, setIsInSearchMode } = props;
    const classes = useStyles();

    function searchIconClicked() {
        setIsInSearchMode(true);
    }

    return (
        <div className={clsx(classes.layout, isInSearchMode && classes.expandedLayout, className)}>
            {!isInSearchMode &&
            <button className={classes.searchButton} onClick={searchIconClicked}>
                <SearchIcon className={classes.searchIcon} />
            </button>
            }
            {isInSearchMode &&
            <SearchField setIsInSearchMode={setIsInSearchMode} isInSearchMode={isInSearchMode}/>
            }
        </div>
    );
}
