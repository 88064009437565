import React from 'react';
import ANPILogoIcon from '../icons/logos/AnpiLogoWTextInversIcon';
import {makeStyles} from '@material-ui/core';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        lineHeight: '0px',
    }
}));

export default function ANPILogoWithText(props) {
    const classes = useStyles();

    const { i18n } = useTranslation();

    return (
        <a href={getPathWithLanguage(ROUTES_ANP.HOME, i18n.language)} className={classes.link}>
            <ANPILogoIcon {...props}/>
        </a>
    );
}
