import * as React from "react";

function SvgInfoFilled(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M32 0C14.3 0 0 14.3 0 32s14.3 32 32 32 32-14.3 32-32S49.7 0 32 0zm3.2 48h-6.4V28.8h6.4V48zm0-25.6h-6.4V16h6.4v6.4z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgInfoFilled;
