import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import initI18n from 'client_shared/src/i18n/i18n';
import {createStoreWithInitialState} from 'client_shared/src/state/store';
import theme from './styles/styles';
import {SITE} from 'shared/src/constants/site';
import Framework from 'client_shared/src/components/framework/Framework';
import {getPreloadedState} from 'client_shared/src/modules/ssrHelper';

localStorage.setItem('siteId', `${SITE.ANP}`);

const i18n = initI18n(SITE.ANP);

const preloadedState = getPreloadedState();

const store = createStoreWithInitialState(preloadedState);

ReactDOM.hydrate(
    <Framework ssr={false} store={store} i18n={i18n} theme={theme}>
        <App/>
    </Framework>,
    document.getElementById('root')
);
