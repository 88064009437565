import {sendServerRequest} from './baseService';

export const tourGuideService = {
    getTourGuides,
    getTourGuide,
};

async function getTourGuides() {
    return sendServerRequest('GET', `/api/tourGuides`);
}

async function getTourGuide(tourGuideId) {
    return sendServerRequest('GET', `/api/tourGuides/${tourGuideId}`);
}
