import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import {accommodationModule} from 'shared/src/modules/accommodation';
import PetsIcon from '../../icons/accommodations/SzallasAllatbaratIcon';
import CaveIcon from '../../icons/accommodations/SzallasBarlangBejaratIcon';
import CashIcon from '../../icons/accommodations/SzallasCsakKeszpenzIcon';
import ForintIcon from '../../icons/accommodations/SzallasCsakForintIcon';
import FishingIcon from '../../icons/accommodations/SzallasHorgaszatIcon';
import CampingIcon from '../../icons/accommodations/SzallasKempingezesIcon';
import CyclingIcon from '../../icons/accommodations/SzallasKerekparKolcsonzesIcon';
import ParkingIcon from '../../icons/accommodations/SzallasParkolasIcon';
import FireplaceIcon from '../../icons/accommodations/SzallasTuzrakohelyIcon';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        borderRadius: '12px',
        backgroundColor: '#F2F2F2',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        '& > * + *': {
            marginTop: '12px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '16px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '12px',
            },
        }
    },
    serviceRow: {
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: '10px',
            [theme.breakpoints.up('lg')]: {
                marginLeft: '16px'
            }
        }
    },
    icon: {
        height: '32px',
        width: '32px',
        minHeight: '32px',
        minWidth: '32px',
    },
    infoText: {
        '&.paragraph4': {
            fontSize: '12px',
            color: '#17171A',
        },
    },
}));

export default function AccommodationServiceBar(props) {
    const { className, accommodation } = props;
    const classes = useStyles();
    const allAccommodationServices = useSelector(state => state.accommodation.accommodationServices);

    const accommodationServices = accommodationModule.getAccommodationServices(allAccommodationServices, accommodation);

    const icons = [
        PetsIcon,
        CampingIcon,
        FireplaceIcon,
        ParkingIcon,
        CashIcon,
        ForintIcon,
        CyclingIcon,
        CaveIcon,
        FishingIcon,
    ];

    return (
        <div className={clsx(className, classes.layout)}>
            {accommodationServices.map((accommodationService, index) =>
                <div key={index} className={classes.serviceRow}>
                    {React.createElement(icons[accommodationService.iconId - 1], {
                        className: classes.icon,
                    })
                    }
                    <div className={clsx('paragraph4', classes.infoText)}>
                        {accommodationService.title}
                    </div>
                </div>
            )}
        </div>
    );
}
