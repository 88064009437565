import React, {useEffect, useState} from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import {useSelector} from 'react-redux';
import {ROUTES_ANP} from 'shared';
import NewsArticleHeader from '../components/contents/news/NewsArticleHeader';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {contentActions} from 'client_shared/src/state/actions/contentActions';
import ArticleContent from '../components/contents/article/ArticleContent';

const useStyles = makeStyles(theme => ({
    bodyLayout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px',
        },
    },
    newsSummary: {
        marginTop: '32px',
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
            marginBottom: '24px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
            marginBottom: '24px',
        },
        fontWeight: 'bold'
    },
}));


function NewsArticle() {
    const classes = useStyles();
    const [breadCrumbTrailTail, setBreadCrumbTrailTail] = useState([]);
    const news = useSelector(state => state.content.content);

    useEffect(() => {
        if (!news) {
            return;
        }

        const breadCrumbTrailTail = [{
            title: news.title,
            url: ROUTES_ANP.NEWS_ARTICLE(news.id)
        }];

        setBreadCrumbTrailTail(breadCrumbTrailTail);
    }, [news]);


    return (
        <ContentScreen showTitleBar={false} breadCrumbsTrailTail={breadCrumbTrailTail}
                       includePageInBreadCrumbTail={false}>
            {news &&
            <>
                <NewsArticleHeader news={news}/>
                <div className={clsx('padding-article', classes.bodyLayout)}>
                    <ArticleContent content={news.summary} className={classes.newsSummary}/>
                    <ArticleContent content={news.body}/>
                </div>
            </>
            }
        </ContentScreen>
    );
}

NewsArticle.initState = (environment) => {
    const { dispatch, params } = environment;

    const promises = [];

    const contentId = params.id;

    if (contentId) {
        promises.push(dispatch(contentActions.loadContent(contentId)));
    }

    return promises;
};

export default NewsArticle;