export default {
    '& table': {
        '@media (min-width:760px)': {
            margin: '12px 0px',
        },
        '@media (min-width:1280px)': {
            margin: '12px 0px',
        },
    },
};
