import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import TourParametersBar from './TourParametersBar';
import ArticleContent from '../article/ArticleContent';
import DangerList from '../danger/lists/dangerList/DangerList';
import {useSelector} from 'react-redux';
import PriceTable from './PriceTable';
import TourTimeTable from './TourTimeTable';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        }
    }),
    leftColumn: {
        [theme.breakpoints.up('lg')]: {
            flex: 1,
        },
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '40px',
        paddingRight: '40px',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
            flexDirection: 'row',
            '& > *': {
                flex: 1,
            }
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            width: '560px',
            paddingLeft: '40px',
            paddingRight: '40px',
            '& > *': {
                flex: 0,
            },
        },
        backgroundColor: '#F2F2F2',
        '& > *': {
            marginBottom: '48px',
        },
        '& > *:first-child': {
            marginTop: '24px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '32px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '48px',
            }
        },
        '& > *:not(:first-child)': {
            marginTop: '0px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '32px',
                marginLeft: '32px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '0px',
                marginLeft: '0px'
            }
        }
    },
    map: {
        marginTop: '24px',
    },
    title: {
        marginTop: '48px',
        marginBottom: '24px',
    },
    text: {
        ...theme.typography.paragraph1,
        '& p': {
            ...theme.typography.paragraph1,
        }
    },
    dailyToursBlock: {
        marginTop: '52px',
    },
    dailyToursTitle: {
        marginBottom: '24px',
    },
    bodyLayout: {
        margin: '48px 0px',
    },
    dangerList: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        }
    },
    tourParametersBarNoDangers: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        }
    },
    tourParametersBarWithDangers: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        }
    }
}));

export default function TourContentBar(props) {
    const { tour } = props;
    const showTicketBar = tour.hasTickets || tour.hasOfficeTickets;
    const classes = useStyles({ ...props, showTicketBar });

    const allDangers = useSelector(state => state.content.dangers);
    const dangers = allDangers.filter(danger => tour.dangerIds && tour.dangerIds.includes(danger.id));

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <div className={clsx(classes.layout)}>
            <div className={clsx(showTicketBar && isDesktop ? 'padding-left' : 'padding', classes.leftColumn)}>
                <DangerList dangers={dangers} className={classes.dangerList}/>
                <TourParametersBar tour={tour}
                                   className={dangers && dangers.length > 0 ? classes.tourParametersBarWithDangers : classes.tourParametersBarNoDangers}/>
                <div className={clsx(!showTicketBar ? null : null, classes.bodyLayout)}>
                    <ArticleContent content={tour.body} narrow={true}/>
                </div>
            </div>
            {showTicketBar &&
            <div className={clsx(!isDesktop && 'padding', classes.rightColumn)}>
                <TourTimeTable tour={tour}/>
                <PriceTable tour={tour}/>
            </div>
            }
        </div>
    );
}
