import React from 'react';
import {makeStyles} from '@material-ui/core';
import NewsListItem from './NewsListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flex: '0 0 100%',
            marginTop: '12px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '-20px',
            marginTop: '-20px',
            '& > *': {
                flex: '0 0 calc(50% - 20px)',
                marginLeft: '20px',
                marginTop: '20px',
            }
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '-30px',
            marginLeft: '-30px',
            '& > *': {
                flex: '0 0 calc(33.333% - 30px)',
                marginLeft: '30px',
                marginTop: '30px',
            }
        },
    },
}));

export default function NewsList(props) {
    const { news } = props;
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            {news && news.map(newsItem => (
                <NewsListItem key={newsItem.id} news={newsItem}/>
            ))}
        </div>
    );
}
