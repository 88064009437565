import React, {useEffect, useState} from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import {useDispatch, useSelector} from 'react-redux';
import {contentActions, tourActions, userActions} from 'client_shared/src/state/actions';
import ArticleContent from '../components/contents/article/ArticleContent';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';
import TourList from '../components/contents/tour/lists/tourList/TourList';
import ArticleList from '../components/contents/microSite/lists/articleList/ArticleList';
import PrimaryBasicButton from '../components/buttons/PrimaryBasicButton';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {

    },
    bodyLayout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px',
        },
    },
    articlesBar: {},
    spacer: {
        marginBottom: '64px',
    },
    tourList: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
        },
    }
}));

function Content() {
    const [tourList, setTourList] = useState([]);
    const content = useSelector(state => state.content.content);
    const tours = useSelector(state => state.tour.tours);
    const page = useSelector(state => state.page.page);
    const articles = useSelector(state => state.content.articles);
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!tours || tours.length === 0 || !page.tourListId) {
            return;
        }

        const tourList = tours.filter(tour => tour.tourListId === page.tourListId);
        setTourList(tourList);
    }, [tours, page]);

    function onDetailsClick() {
        dispatch(userActions.setCookiesPopupOpen(true));
    }

    return (
        <ContentScreen>
            {content &&
            <>
                {content.body && content.body !== '' &&
                <div className={clsx('padding-article', classes.bodyLayout)}>
                    <ArticleContent content={content.body}/>
                </div>
                }
                <div className={clsx('padding', classes.articlesBar)}>
                    <ArticleList page={page} title={content.description} articles={articles}/>
                </div>
                <TourList
                    showTitleLink={false}
                    tours={tourList}
                    title={tourList.length && tourList[0].tourList.title}
                    description={tourList.length && tourList[0].tourList.description}
                    className={clsx('padding', classes.tourList)}
                />
                {page.url === '/cookie-szabalyzat' &&
                <PrimaryBasicButton variant={'normal'} onClick={onDetailsClick} title={t('Text.AdjustCookies')} />
                }
                <div className={classes.spacer}/>
            </>
            }
        </ContentScreen>
    );
}

Content.initState = (environment) => {
    const { dispatch, page, storeState } = environment;

    const promises = [];

    promises.push(dispatch(contentActions.loadContent(page.contentId)));

    if (_.isEmpty(storeState.tour.tours)) {
        promises.push(dispatch(tourActions.loadTours()));
    }

    promises.push(dispatch(contentActions.loadArticles(page.id)));

    return promises;
};

export default Content;