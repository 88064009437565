import React from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import _ from 'lodash';
import {accommodationActions} from 'client_shared/src/state/actions/accommodationActions';
import clsx from 'clsx';
import Promotion from '../components/contents/promotions/Promotion';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import AccommodationList from '../components/contents/accommodations/lists/accommodationList/AccommodationList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px',
            flexDirection: 'row',
        },
    },
    leftLayout: {
        width: 'unset',
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
        },
        [theme.breakpoints.up('lg')]: {
            width: '277px',
        },
    },
    rightLayout: {
        flex: 1,
        marginLeft: '0px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '30px',
        },
    },
    promotion: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        }
    }
}));

function Accommodations() {
    const classes = useStyles();
    const promotion = useSelector(state => state.content.promotion);
    const accommodations = useSelector(state => state.accommodation.accommodations);

    const accommodationsOrdered = _.orderBy(accommodations, ['index'], ['asc']);

    return (
        <ContentScreen>
            <div className={clsx('padding', classes.layout)}>
                <div className={classes.leftLayout}>
                    <Promotion promotion={promotion} className={classes.promotion}/>
                </div>
                <div className={classes.rightLayout}>
                    <AccommodationList accommodations={accommodationsOrdered}/>
                </div>
            </div>

        </ContentScreen>
    );
}

Accommodations.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.accommodation.accommodations)) {
        promises.push(dispatch(accommodationActions.loadAccommodations()));
    }

    if (_.isEmpty(storeState.accommodation.accommodationCategories)) {
        promises.push(dispatch(accommodationActions.loadAccommodationCategories()));
    }

    if (_.isEmpty(storeState.accommodation.accommodationServices)) {
        promises.push(dispatch(accommodationActions.loadAccommodationServices()));
    }

    return promises;
};

export default Accommodations;
