import React from 'react';
import {makeStyles} from '@material-ui/core';
import DangerIcon from '../../icons/mono/DangerIcon';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        width: '100%',
    },
    iconBar: {
        backgroundColor: '#D93616',
        padding: '20px 12px',
        [theme.breakpoints.up('sm')]: {
            padding: '8px 10px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '12px 12px',
        },
        lineHeight: '1px',
        borderRadius: '6px 0 0 6px',
    },
    dangerIcon: {
        width: '20px',
        height: '18px',
        [theme.breakpoints.up('sm')]: {
            width: '20px',
            height: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '24px',
            height: '21px',
        }
    },
    messageBar: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '0 6px 6px 0',
        backgroundColor: '#E66045',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '8px 16px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '12px 16px',
        },
    },
    message: {
        '&.paragraph2': {
            lineHeight: '22.4px',
            color: '#FFFFFF',
        },
        '& p': {
            margin: 0,
            padding: 0,
        }
    },
    showMeLink: {
        cursor: 'pointer',
        marginLeft: '27px',
        textDecoration: 'underline',
        [theme.breakpoints.up('lg')]: {
            textDecoration: 'none',
        },
        '&.paragraph3': {
            color: '#FFFFFF',
            fontWeight: 'bold',
        },
    }
}));

export default function DangerSummary(props) {
    const { dangers, className } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    function handleShowMeClick() {
        const dangerListDiv = document.querySelector(`[id="dangerList"]`);
        if (dangerListDiv) {
            setTimeout(() => {
                dangerListDiv.scrollIntoViewIfNeeded({block: 'center', behavior: "smooth" });
            }, 100);
            /*
                        var topOfElement = categoryDiv.offsetTop - 120;
                        window.scroll({ top: topOfElement, behavior: "smooth" });
            */

            /*
                        categoryDiv.scrollIntoView(true);

                        const scrolledY = window.scrollY;

                        const offset = isDesktop ? styleConstants.headerBar.desktopHeight : 0;

                        if (scrolledY) {
                            debugger;
                            window.scroll(0, scrolledY - offset);
                        }
            */

            //window.scrollTo(0, categoryDiv.offsetTop - offset);
        }
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.iconBar}>
                <DangerIcon className={classes.dangerIcon} fill={'#F2CA00'}/>
            </div>
            <div className={classes.messageBar}>
                <div className={clsx('paragraph2', classes.message)}
                     dangerouslySetInnerHTML={createMarkup(`<strong>${dangers.length}</strong> ${t('Text.DangerCount')}`)}/>
                <div className={clsx('paragraph3', classes.showMeLink)}
                     onClick={handleShowMeClick}>{t('Text.ShowMe').toLowerCase()}</div>
            </div>
        </div>
    );
}
