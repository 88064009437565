import * as React from "react";

function SvgChevronLeft(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M26.1 32l23.3 22.9c2.1 2.1 2.1 5.5 0 7.5-2.1 2.1-5.6 2.1-7.7 0L14.6 35.8c-2.1-2.1-2.1-5.5 0-7.5L41.7 1.6c2.1-2.1 5.6-2.1 7.7 0 2.1 2.1 2.1 5.5 0 7.5L26.1 32z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgChevronLeft;
