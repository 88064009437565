import React from 'react';
import {makeStyles} from '@material-ui/core';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {contentModule} from 'shared/src/modules/content';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        backgroundColor: '#F2E0CE',
        borderRadius: '12px',
        padding: '24px 32px 32px 32px',
        '& > *': {
            '&:nth-child(1)': {
                marginBottom: '16px',
            },
            '&:nth-child(2)': {
                marginBottom: '24px',
            }
        }
    },
    descriptionBlock: {
        borderLeft: '4px solid #F2A200',
        borderRadius: '2px',
        paddingLeft: '16px',
    },
    description: {
        '&.paragraph3': {
            color: '#5C5C66',
        }
    }
}));

export default function Promotion(props) {
    const { className, promotion } = props;
    const classes = useStyles();
    const history = useHistory();

    const { i18n } = useTranslation();

    function handleClick() {
        history.push(contentModule.getContentUrl(promotion, i18n.language));
    }

    if (!promotion || !promotion.title || !promotion.summary || !promotion) {
        return null;
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <h3>{promotion.title}</h3>
            <div className={classes.descriptionBlock}>
                <div className={clsx('paragraph3', classes.description)}
                     dangerouslySetInnerHTML={createMarkup(promotion.summary)}/>
            </div>
            <PrimaryBasicButton variant={'big'} title={promotion.callToActionText} onClick={handleClick}/>
        </div>
    );
}
