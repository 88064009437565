import React from 'react';
import {makeStyles} from '@material-ui/core';
import DangerListItem from './DangerListItem';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: '12px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '14px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '12px',
            }
        }
    }
}));

export default function DangerList(props) {
    const { dangers, className } = props;
    const classes = useStyles();

    if (!dangers || dangers.length === 0) {
        return null;
    }

    return (
        <div className={clsx(classes.layout, className)}>
            {dangers.map(danger =>
            <DangerListItem key={danger.id} danger={danger}/>
            )}
        </div>
    );
}
