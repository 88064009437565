import * as React from "react"

function CookieBannerIcon(props) {
    return (
        <svg viewBox="0 0 48 51" {...props}>
            <path
                d="M18.5 3.9c2-.5 3.9.7 4.4 2.6.7 3.1 3.5 5.3 6.6 5.4.4 0 .8.2 1.1.5.3.3.4.7.4 1.1v.7c0 4.7 4.7 8.4 9.6 6.6.7-.3 1.5 0 1.9.7.9 1.8 2.6 3.1 4.6 3.6.3.1.6.3.8.5.2.3.3.6.3.9.1 6.4-2.3 12.5-6.8 17.1C36.6 48.4 30.5 51 24 51 10.8 51 0 40.3 0 27.1 0 16 7.6 6.4 18.5 3.9zM41.2 12c1.2 0 2.2 1 2.2 2.3s-1 2.3-2.2 2.3-2.2-1-2.2-2.3 1-2.3 2.2-2.3zM31.1 3.4c.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.2-.7.4-1.1.4s-.8-.2-1.1-.4c-.3-.3-.4-.7-.4-1.1s.2-.8.4-1.1c.6-.5 1.6-.5 2.2 0z"
                opacity={0.1}
                fillRule="evenodd"
                clipRule="evenodd"
            />
            <path
                d="M18.7.8c1.9-.3 3.7.8 4.2 2.7.7 3.1 3.5 5.3 6.6 5.4.4 0 .8.2 1.1.5.3.3.4.7.4 1.1v.7c0 4.7 4.7 8.4 9.6 6.6.7-.3 1.5 0 1.9.7.9 1.8 2.6 3.1 4.6 3.6.3.1.6.3.8.5.2.3.3.6.3.9.1 6.4-2.3 12.5-6.8 17.1C36.6 45.4 30.5 48 24 48 10.8 48 0 37.3 0 24.1 0 13 7.6 3.4 18.5.9l.2-.1zM41.2 9c1.2 0 2.2 1 2.2 2.3s-1 2.3-2.2 2.3-2.2-1-2.2-2.3S40 9 41.2 9zM31.1.4c.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.2-.7.4-1.1.4s-.8-.2-1.1-.4c-.3-.3-.4-.7-.4-1.1s.2-.8.4-1.1c.6-.5 1.6-.5 2.2 0z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
            />
            <path
                d="M31.1 36.4c.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.2-.7.4-1.1.4-.4 0-.8-.2-1.1-.4-.3-.3-.4-.7-.4-1.1 0-.4.2-.8.4-1.1.6-.5 1.6-.5 2.2 0zM20.2 33c1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.3-2.2 2.3-1.2 0-2.2-1-2.2-2.3 0-1.3 1-2.3 2.2-2.3zm6.4-5.6c.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.3-.7.4-1.1.4s-.8-.2-1.1-.4c-.3-.3-.4-.7-.4-1.1s.2-.8.4-1.1c.6-.5 1.6-.5 2.2 0zm10.1-1.9c1.2 0 2.2 1 2.2 2.3S38 30 36.7 30s-2.2-1-2.2-2.3 1-2.2 2.2-2.2zm-26.6.4c.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.3-.7.4-1.1.4-.4 0-.8-.2-1.1-.4-.3-.3-.4-.7-.4-1.1 0-.4.2-.8.4-1.1.6-.5 1.6-.5 2.2 0zm6-4.5c.3.3.4.7.4 1.1s-.2.8-.4 1.1c-.3.2-.7.4-1.1.4-.4 0-.8-.2-1.1-.4-.3-.3-.4-.7-.4-1.1s.2-.8.4-1.1c.6-.5 1.6-.5 2.2 0zm10.5-1.5c.3.3.4.7.4 1.1 0 .4-.2.8-.4 1.1-.3.3-.7.4-1.1.4s-.8-.2-1.1-.4c-.2-.3-.4-.7-.4-1.1 0-.4.2-.8.4-1.1.6-.5 1.6-.5 2.2 0zM18.7 12c1.2 0 2.2 1 2.2 2.3s-1 2.3-2.2 2.3c-1.2 0-2.2-1-2.2-2.3s1-2.3 2.2-2.3z"
                fill="#807366"
            />
        </svg>
    )
}

export default CookieBannerIcon
