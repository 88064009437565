import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import FooterMenu from '../menus/footer/FooterMenu';
import {useTranslation} from 'react-i18next';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {Link} from 'react-router-dom';
import EmailPopup from './EmailPopup';
import SecondaryButton from '../../buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
    layout: {
        backgroundColor: '#2E2E33',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& > *:first-child': {
                height: '350px',
            }
        },
        '&.padding': {
            paddingTop: '32px',
            paddingBottom: '48px',
            [theme.breakpoints.up('lg')]: {
                paddingTop: '64px',
                paddingBottom: '64px',
            }
        }
    },
    sharedColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    sharedMenuColumn: {
        [theme.breakpoints.up('lg')]: {
            '&:not(:first-child)': {
                marginTop: '32px',
            },
        },
    },
    contacts: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px',
        width: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            flexDirection: 'row',
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '278px',
            flexDirection: 'column',
            marginTop: '0px',
        }
    },
    contactBlock: {
        maxWidth: '278px',
    },
    contactBlockTitle: {
        '&.paragraph2': {
            fontWeight: 'bold',
            color: '#F2CA00',
        },
        marginBottom: '10px',
    },
    contactTable: {
        borderCollapse: 'collapse',
    },
    contactTableRow: {
        '&:not(:last-child)': {
            '& > td': {
                paddingBottom: '10px',
            }
        },
    },
    contactTableCell1: {
        '&.paragraph4': {
            color: theme.palette.common.white,
            fontWeight: 'bold',
            textAlign: 'left',
            verticalAlign: 'top',
        },
        minWidth: '80px',
    },
    contactTableCell2: {
        '&.paragraph4': {
            color: '#F2F2F2',
            textAlign: 'left',
            verticalAlign: 'top',
        },
        paddingLeft: '16px',
    },
    directionsLink: {
        marginTop: '16px',
    },
    contactBlockWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '&:not(:first-child)': {
            marginTop: '32px',
        },
        [theme.breakpoints.up('sm')]: {
            flex: 1,
            '&:not(:first-child)': {
                marginTop: '0px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            flex: 'unset',
            '&:not(:first-child)': {
                marginTop: '24px',
            },
        },
    },
    email: {
        '&.paragraph4': {
            color: '#F2F2F2',
            textDecoration: 'none',
        },
    },
    privacyPolicyLink: {
        marginTop: '24px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        },
        height: '100%',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    handicapPolicyLink: {
        marginTop: '5px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '5px',
        },
        height: '100%',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    cookiePolicyLink: {
        marginTop: '5px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '5px',
        },
        height: '100%',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    linkContent: {
        '&.paragraph3': {
            color: '#D9D9D9',
        },
    },
    rightBar: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    emailButton: {
        height: '20px',
        '&.paragraph4': {
            lineHeight: '10px',
        },
    }
}));

export default function FooterBarMenu() {
    const classes = useStyles();
    const [emailPopupOpen, setEmailPopupOpen] = useState(false);

    const { t, i18n } = useTranslation();

    function handleEmailPopClick() {
        setEmailPopupOpen(true);
    }

    function handleEmailPopupClose() {
        setEmailPopupOpen(false);
    }


    return (
        <div className={clsx('padding', classes.layout)}>
            <FooterMenu/>
            <div className={classes.rightBar}>
                <div className={classes.contacts}>
                    <div className={classes.contactBlockWrapper}>
                        <div className={classes.contactBlock}>
                            <div className={clsx('paragraph2', classes.contactBlockTitle)}>
                                {t('Text.ANPHeadquarter')}
                            </div>
                            <table className={classes.contactTable}>
                                <tbody>
                                <tr className={classes.contactTableRow}>
                                    <td className={clsx('paragraph4', classes.contactTableCell1)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.HeadquarterAddress'))}/>
                                    <td className={clsx('paragraph4', classes.contactTableCell2)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.HeadquarterAddressValue'))}/>
                                </tr>
                                <tr className={classes.contactTableRow}>
                                    <td className={clsx('paragraph4', classes.contactTableCell1)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.HeadquarterPhone'))}/>
                                    <td className={clsx('paragraph4', classes.contactTableCell2)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.HeadquarterPhoneValue'))}/>
                                </tr>
                                <tr className={classes.contactTableRow}>
                                    <td className={clsx('paragraph4', classes.contactTableCell1)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.HeadquarterEmail'))}/>
                                    <td className={clsx('paragraph4', classes.contactTableCell2)}>
                                        <SecondaryButton title={'Email cím'} className={classes.emailButton} onClick={handleEmailPopClick}/>
{/*
                                        <a href={`mailto:${t('Text.HeadquarterEmailValue')}`}
                                           className={clsx('paragraph4', classes.email)}>{t('Text.HeadquarterEmailValue')}</a>
*/}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={classes.contactBlockWrapper}>
                        <div className={classes.contactBlock}>
                            <div className={clsx('paragraph2', classes.contactBlockTitle)}>
                                {t('Text.ANPPark')}
                            </div>
                            <table className={classes.contactTable}>
                                <tbody>
                                <tr className={classes.contactTableRow}>
                                    <td className={clsx('paragraph4', classes.contactTableCell1)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.ParkPhone'))}/>
                                    <td className={clsx('paragraph4', classes.contactTableCell2)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.ParkPhoneValue'))}/>
                                </tr>
                                <tr className={classes.contactTableRow}>
                                    <td className={clsx('paragraph4', classes.contactTableCell1)}
                                        dangerouslySetInnerHTML={createMarkup(t('Text.ParkEmail'))}/>
                                    <td className={clsx('paragraph4', classes.contactTableCell2)}>
                                        <a href={`mailto:${t('Text.ParkEmailValue')}`}
                                           className={clsx('paragraph4', classes.email)}>{t('Text.ParkEmailValue')}</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <a href={t('Text.GoogleMapsUrl')} className={clsx('linkNormalInverse', classes.directionsLink)}>
                            {t('Text.Directions')}
                        </a>
                    </div>
                </div>
                <Link to={getPathWithLanguage(ROUTES_ANP.PRIVACY_POLICY, i18n.language)} className={classes.privacyPolicyLink}>
                    <div className={clsx('paragraph3', classes.linkContent)}>
                        {t('Text.PrivacyPolicy')}
                    </div>
                </Link>
                <Link to={getPathWithLanguage(ROUTES_ANP.HANDICAP_POLICY, i18n.language)} className={classes.handicapPolicyLink}>
                    <div className={clsx('paragraph3', classes.linkContent)}>
                        {t('Text.HandicapPolicy')}
                    </div>
                </Link>
                <Link to={getPathWithLanguage(ROUTES_ANP.COOKIE_POLICY, i18n.language)} className={classes.cookiePolicyLink}>
                    <div className={clsx('paragraph3', classes.linkContent)}>
                        {t('Text.CookiePolicy')}
                    </div>
                </Link>
            </div>
            <EmailPopup open={emailPopupOpen} onClose={handleEmailPopupClose}/>
        </div>
    );
}
