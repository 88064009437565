import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import ThanksIcon from '../../icons/multicolor/ThanksIcon';
import clsx from 'clsx';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import {useTranslation} from 'react-i18next';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {useLocation} from 'react-router';
import ErrorIcon from '@material-ui/icons/Error';
import {useDispatch, useSelector} from 'react-redux';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '96px 0px',
    },
    icon: {
        height: '128px',
        width: '128px',
        marginBottom: '32px',
    },
    errorIcon: {
        color: 'rgba(255,0,0,1)',
        width: '128px',
        height: '128px',
        marginBottom: '32px',
    },
    title: {
        marginBottom: '48px',
    },
    greyBox: {
        width: '585px',
        padding: '24px',
        backgroundColor: '#F2F2F2',
        borderRadius: '12px',
        marginBottom: '32px',
    },
    greyBoxText1: {
        marginBottom: '12px',
    },
    greyBoxText2: {
        marginBottom: '12px',
        '&.paragraph2': {
            color: '#5C5C66',
        }
    },
    greenBox: {
        width: '585px',
        display: 'flex',
    },
    greenBoxLeft: {
        padding: '23px',
        borderRadius: '6px 0 0 6px',
        backgroundColor: '#96CC29',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    greenBoxRight: {
        flex: 1,
        padding: '12px 16px',
        borderRadius: '0 6px 6px 0',
        backgroundColor: '#DDFF99',
    },
    treeIcon: {
        height: '26px',
        width: '26px',
        color: theme.palette.common.white,
    },
    greenText: {
        '& > p': {
            margin: '0px',
        }
    },
}));

export default function PaymentLandingContent() {
    const classes = useStyles();

    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const order = useSelector(state => state.checkout.order);
    const [initialised, setInitialised] = useState(false);
    const [orderRef, setOrderRef] = useState(null);
    const [paymentRef, setPaymentRef] = useState(null);
    const [date, setDate] = useState(null);
    const [resultCode, setResultCode] = useState(null);
    const [email, setEmail] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if (URLSearchParams) {
            let searchParams = new URLSearchParams(location.search);
            const orderRef = searchParams.get('order_ref');
            const paymentRef = searchParams.get('payrefno');
            const date = searchParams.get('date');
            const resultCode = searchParams.get('RC');
            const email = searchParams.get('orderEmail');

            const success = (resultCode === '000' || resultCode === '001' || resultCode === '');

            setOrderRef(orderRef);
            setPaymentRef(paymentRef);
            setDate(date);
            setResultCode(resultCode);
            setEmail(email);
            setSuccess(success);

            setInitialised(true);
        }
    }, [location]);

    useEffect(() => {
        if (success && order) {
            dispatch(checkoutActions.resetOrder());
        }
    }, [dispatch, success, order]);

    if (!initialised) {
        return null;
    }

    return (
        <div className={classes.layout}>
            {success &&
            <>
                <ThanksIcon className={classes.icon}/>
                <h2 className={classes.title}>{t('Text.ThanksForShopping')}</h2>
            </>
            }
            {!success &&
            <>
                <ErrorIcon className={classes.errorIcon}/>
                <h2 className={classes.title}>{t('Text.FailedTransaction')}</h2>
            </>
            }
            <div className={classes.boxes}>
                {success &&
                <>
                    <div className={classes.greyBox}>
                        <div className={clsx('paragraph2', classes.greyBoxText1)}>
                            {t('Text.PaymentFeedback1')} <strong>{email}</strong>
                        </div>
                        <div className={clsx('paragraph3', classes.greyBoxText2)}>
                            {t('Text.SimplePayRef')}: <strong>{paymentRef}</strong><br/>
                            {t('Text.OrderRef')}: <strong>{orderRef}</strong><br/>
                            {t('Text.OrderDate')}: <strong>{date}</strong><br/>
                        </div>
                        <div className={clsx('paragraph3', classes.greyBoxText2)}>
                            {t('Text.PaymentFeedback2')}
                        </div>
                    </div>
                    <div className={classes.greenBox}>
                        <div className={classes.greenBoxLeft}>
                            <NaturePeopleIcon className={classes.treeIcon}/>
                        </div>
                        <div className={classes.greenBoxRight}>
                            <div className={clsx('paragraph3', classes.greenText)}
                                 dangerouslySetInnerHTML={createMarkup(t('Text.PaymentGreen'))}/>
                        </div>
                    </div>
                </>
                }
                {!success &&
                <div className={classes.greyBox}>
                    {!resultCode &&
                    (
                        <div className={clsx('paragraph2', classes.greyBoxText1)}>
                            {t('Text.CancelledTransaction')}<br/>
                            {t('Text.CancelledTransactionDescription')}<br/>
                            <br/>
                            {t('Text.OrderRef')}: <strong>{orderRef}</strong><br/>
                            {date && (<>{t('Text.OrderDate')}: <strong>{date}</strong><br/></>)}
                        </div>
                    )}
                    {resultCode &&
                    (
                        <div className={clsx('paragraph2', classes.greyBoxText1)}>
                            {t('Text.FailedTransactionDescription')}<br/>
                            <br/>
                            {t('Text.SimplePayRef')}: <strong>{paymentRef}</strong><br/>
                            {t('Text.OrderRef')}: <strong>{orderRef}</strong><br/>
                            {t('Text.OrderDate')}: <strong>{date}</strong><br/>
                        </div>
                    )}
                </div>
                }
            </div>
        </div>
    );
}

