import React, {useEffect, useState} from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import {tourGuideActions} from 'client_shared/src/state/actions/tourGuideActions';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import TourGuideProfile from '../components/contents/tourGuide/TourGuideProfile';
import {useSelector} from 'react-redux';
import TourGuideSummary from '../components/contents/tourGuide/TourGuideSummary';
import TourGuideTourList from '../components/contents/tourGuide/lists/tourGuideTourList/TourGuideTourList';
import {tourModule} from 'shared/src/modules/tour';
import {ROUTES_ANP} from 'shared';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            marginTop: '32px',
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
            marginBottom: '64px',
            flexDirection: 'row',
        },
    },
    leftLayout: {},
    rightLayout: {
        width: '100%',
        marginTop: '32px',
        marginLeft: '0px',
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('sm')]: {
            marginTop: '12px',
            marginLeft: '0px',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
            marginLeft: '30px',
            flexDirection: 'column',
        },
    },
    promotion: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        }
    },
    tourGuideSummary: {
        marginTop: '32px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
        },
    },
    tourGuideTourList: {
        marginTop: '0px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        },
    }
}));

function TourGuide() {
    const classes = useStyles();
    const [breadCrumbTrailTail, setBreadCrumbTrailTail] = useState([]);
    const tourGuide = useSelector(state => state.tourGuide.tourGuide);

    useEffect(() => {
        if (!tourGuide) {
            return;
        }

        const breadCrumbTrailTail = [
            {
                title: tourGuide.name,
                url: ROUTES_ANP.TOUR_GUIDE(tourGuide.id)
            }];

        setBreadCrumbTrailTail(breadCrumbTrailTail);
    }, [tourGuide]);

    return (
        <ContentScreen showTitleBar={false} breadCrumbsTrailTail={breadCrumbTrailTail}
                       includePageInBreadCrumbTail={false}>
            {tourGuide &&
            <div className={clsx('padding', classes.layout)}>
                <TourGuideProfile tourGuide={tourGuide}/>
                <div className={classes.rightLayout}>
                    <TourGuideSummary tourGuide={tourGuide} className={classes.tourGuideSummary}/>
                    {tourModule.tourGuideHasTours(tourGuide) &&
                    <TourGuideTourList tours={tourGuide.tours} className={classes.tourGuideTourList}/>}
                </div>
            </div>
            }
        </ContentScreen>
    );
}

TourGuide.initState = (environment) => {
    const { dispatch, params } = environment;

    const promises = [];

    promises.push(dispatch(tourGuideActions.loadTourGuide(params.id)));

    return promises;
};

export default TourGuide;
