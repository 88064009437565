import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import SearchFilterList from './lists/searchFilterList/SearchFilterList';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    searchFilterBar: {
        display: 'flex',
        '& > *:first-child': {
            flex: 1,
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '4px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '8px',
        },
    },
    searchPhraseTitle: {
        '&.paragraph3': {
            color: '#5C5C66',
        }
    },
    divider: {
        height: '4px',
        width: '100%',
        borderRadius: '2px',
        backgroundColor: '#E6E6E6',
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        },
    },
    searchFilterList: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        }
    }
}));

export default function SearchHeaderBar(props) {
    const { className, searchFilter, searchPhrase, onSearchFilterChange } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={clsx('paragraph3', classes.searchPhraseTitle)}>
                {t('Text.SearchPhrase')}
            </div>
            <div className={classes.searchFilterBar}>
                <h1>
                    {`„${searchPhrase}“`}
                </h1>
                <SearchFilterList searchFilter={searchFilter} onSearchFilterChange={onSearchFilterChange}
                                  className={classes.searchFilterList}/>
            </div>
            <div className={classes.divider}/>
        </div>
    );
}
