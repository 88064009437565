import * as React from "react";

function SvgParkrolTermeszetiErtekek(props) {
    return (
        <svg
            id="parkrol-termeszeti_ertekek_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".parkrol-termeszeti_ertekek_svg__st2{fill:#5c5c67}.parkrol-termeszeti_ertekek_svg__st3{fill:#fff}.parkrol-termeszeti_ertekek_svg__st4{fill:#ccc}.parkrol-termeszeti_ertekek_svg__st5{fill:#f2b600}.parkrol-termeszeti_ertekek_svg__st8{fill:#17171a}"
                }
            </style>
            <g id="parkrol-termeszeti_ertekek_svg__Layer_4">
                <g id="parkrol-termeszeti_ertekek_svg__parkrol-termeszeti_ertekek">
                    <circle
                        id="parkrol-termeszeti_ertekek_svg__oval"
                        cx={32}
                        cy={32}
                        r={27.5}
                        fill="#b3b3b3"
                    />
                    <path
                        id="parkrol-termeszeti_ertekek_svg__sky"
                        d="M30.2 4.6C15 5.6 3.5 18.7 4.6 33.8c0 1.2.2 2.3.4 3.4 12.1-5 33.3-12.3 53.5-12.4C55.1 12.1 43.2 3.7 30.2 4.6z"
                        fill="#e6e6e6"
                    />
                    <g id="parkrol-termeszeti_ertekek_svg__flowers">
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st2"
                            d="M47.9 46.6h-.3c-.5-.2-.8-.7-.7-1.2 4.3-14.1 12.8-20 13.1-20.2.4-.3 1.1-.3 1.4.2.3.4.3 1.1-.2 1.4l-.1.1S53 32.6 48.8 46c-.1.4-.5.6-.9.6zM50.8 47.8c-.1 0-.3 0-.4-.1-.5-.2-.7-.8-.5-1.3 3.8-8.1 9.7-9.8 9.9-9.8.5-.1 1.1.2 1.2.7.1.5-.2 1.1-.7 1.2 0 0-5.2 1.6-8.7 8.8 0 .2-.4.5-.8.5zM30.1 37.7c-.5 0-1-.4-1-.9-.3-5.6-1.5-11.2-3.6-16.5-2-4.8-6.1-11.4-10.8-14.7-.4-.4-.4-1 0-1.4.3-.3.7-.4 1.1-.2 5.2 3.4 9.3 10.2 11.6 15.5 2.2 5.4 3.5 11.2 3.7 17.1.1.6-.3 1-1 1.1.1 0 .1 0 0 0zM35.4 29.9c-.5 0-1-.4-1-.9-.3-4.6-1.5-9-3.4-13.2-.3-.5-.1-1.1.3-1.4.5-.3 1.1-.1 1.4.3 0 0 0 .1.1.1 2 4.4 3.3 9.1 3.6 13.9.1.6-.3 1.1-1 1.2.1-.1.1-.1 0 0zM27.2 40.9c-.5 0-.9-.3-1-.7-2-6.8-6-12.9-11.6-17.3-.4-.3-.5-1-.2-1.4.3-.4 1-.5 1.4-.2C21.7 26 26 32.4 28.1 39.7c.1.5-.2 1.1-.7 1.2h-.2zM20 45.5c-.4 0-.8-.2-.9-.6-3.3-8-10.4-14.5-16-17.5-.5-.3-.7-.9-.4-1.4.3-.4.8-.6 1.3-.3 5.9 3.1 13.5 10 17 18.5.2.5 0 1.1-.5 1.3H20zM15.1 46.2c-.4 0-.7-.2-.9-.6-1.5-2.8-3.4-5.4-5.7-7.6-.5-.4-.5-1-.1-1.4.4-.4 1-.5 1.4-.1 2.5 2.4 4.6 5.2 6.2 8.3.2.5 0 1.1-.5 1.3-.1 0-.3.1-.4.1zM21.1 40.5c-.5 0-.9-.4-1-.9-.3-3-2.9-6.8-2.9-6.9-.3-.5-.2-1.1.3-1.4.5-.3 1.1-.2 1.4.3.1.2 2.8 4.3 3.2 7.7.1.5-.3 1-.9 1.1l-.1.1z"
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st3"
                            cx={15.3}
                            cy={22}
                            r={3.3}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st3"
                            cx={31.9}
                            cy={15.3}
                            r={2.9}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st3"
                            cx={18.1}
                            cy={32.1}
                            r={2}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st3"
                            cx={9.1}
                            cy={37.2}
                            r={3.4}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st4"
                            cx={15.3}
                            cy={4.8}
                            r={4.8}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st4"
                            cx={3.5}
                            cy={26.5}
                            r={3.4}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st4"
                            cx={60.6}
                            cy={26}
                            r={3.4}
                        />
                        <circle
                            className="parkrol-termeszeti_ertekek_svg__st4"
                            cx={60.1}
                            cy={37.4}
                            r={2.1}
                        />
                    </g>
                    <g id="parkrol-termeszeti_ertekek_svg__bird">
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st2"
                            d="M33.2 48c.2 0 .4-.1.5-.3V48l-.3.5-.5 1-1 2.1-1.5 3.3 5.8 2.3c.2.1.3.3.2.5-.2.3-.4.4-.6.3l-7.2-1.7c-.5-.1-.8-.6-.7-1.1V55l2-4.2 1-2.1.5-1.1.3-.5.2-.3.1-.2.1-.2c.2-.2.4-.3.7-.4.6-.1 1.1.3 1.1.9.3.5-.1 1-.7 1.1zm10.3-4.8c-.1-.5-.6-.9-1.1-.9-.3.1-.5.2-.7.4 0 0-.1.1-.1.2l-.2.2-.4.4-.6.9-1.2 1.7-2.2 3.5v.1c-.1.2-.1.3-.1.5 0 .5.5.9 1 .9l10.8-.6c.2 0 .4-.2.4-.4s-.2-.4-.4-.4l-9.1-.5 1.3-2 1.1-1.7.6-.8.3-.4c0-.1.1-.1.1-.2 0 0 .1-.1 0 0s-.3.2-.5.2c.6 0 1-.5.9-1.1.1.1.1 0 .1 0z"
                        />
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st5"
                            d="M52.7 18.7L48.9 17s.7-1.4 0-2.1-7.7-2.2-8.8-1.4S12.4 48 7.9 53.8c-.1.1-.1.3 0 .4.1.1.2.1.3 0l14.2-6.5s14.6 5 24.5-7.2c5.9-7.3 1.7-19.4 1.7-19.4 1.5-.4 2.9-1.3 4.1-2.4z"
                        />
                        <linearGradient
                            id="parkrol-termeszeti_ertekek_svg__SVGID_1_"
                            gradientUnits="userSpaceOnUse"
                            x1={14.771}
                            y1={5.088}
                            x2={49.531}
                            y2={46.118}
                            gradientTransform="matrix(1 0 0 -1 0 66)"
                        >
                            <stop offset={0} stopColor="#fff3ca"/>
                            <stop offset={0.29} stopColor="#f2ca00"/>
                            <stop offset={0.71} stopColor="#f2b600"/>
                            <stop offset={1} stopColor="#f29a00"/>
                        </linearGradient>
                        <path
                            d="M52.7 18.7L48.9 17s.7-1.4 0-2.1-7.7-2.2-8.8-1.4S12.4 48 7.9 53.8c-.1.1-.1.3 0 .4.1.1.2.1.3 0l14.2-6.5s14.6 5 24.5-7.2c5.9-7.3 1.7-19.4 1.7-19.4 1.5-.4 2.9-1.3 4.1-2.4z"
                            fill="url(#parkrol-termeszeti_ertekek_svg__SVGID_1_)"
                        />
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st5"
                            d="M11.1 44.2c-.2.2-.2.5 0 .7.1.1.3.2.5.1 4.9-1.3 17.9-5.3 22-10.4 2.5-3.2 3.1-7.4 1.6-11.1-.4-.9-1.4-1.4-2.4-1.1-.2.1-.4.2-.6.4L11.1 44.2z"
                        />
                        <path
                            d="M34.2 36.7c3.3-4.7 2.6-9.8 1.6-12.9-.2-1-1.2-1.6-2.2-1.3-.4.1-.7.3-.9.6l-3.8 4.3C23.1 34.6 16.4 43.1 12 48.6c5.2-1.6 18-6.1 22.2-11.9z"
                            fill="#f3a200"
                        />
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st8"
                            d="M46.4 21.1h2.1c.7 2.1 1.1 4.3 1.4 6.5-2.7-1.2-3.5-6.5-3.5-6.5zm2.5-6.2c-.6-.7-5.9-1.8-8-1.6 2.7 2.8 8 3.7 8 3.7s.7-1.3 0-2.1z"
                        />
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st2"
                            d="M16 49.5c.4-.9.9-1.8 1.6-2.5.7-.8 1.5-1.4 2.4-1.9-.4.9-.9 1.8-1.6 2.5-.7.8-1.5 1.5-2.4 1.9zM31.1 43.8c.4-1.1.9-2.2 1.6-3.2.7-1 1.5-1.9 2.4-2.6-.4 1.1-.9 2.2-1.6 3.2-.6 1-1.4 1.9-2.4 2.6zM32.9 47c.2-1.4 1.1-2.7 2.3-3.4-.2 1.5-1 2.7-2.3 3.4zM36.3 41.3c.1-.6.3-1.2.7-1.7.4-.5.9-.9 1.5-1.1-.1.6-.3 1.2-.8 1.7-.3.5-.8.8-1.4 1.1zM38.8 43.8c0-.6.2-1.1.5-1.6s.8-.8 1.3-1c0 .6-.2 1.1-.5 1.6-.3.4-.7.8-1.3 1z"
                        />
                        <linearGradient
                            id="parkrol-termeszeti_ertekek_svg__SVGID_2_"
                            gradientUnits="userSpaceOnUse"
                            x1={25.975}
                            y1={23.065}
                            x2={21.745}
                            y2={35.745}
                            gradientTransform="matrix(1 0 0 -1 0 66)"
                        >
                            <stop offset={0} stopColor="#fff3ca"/>
                            <stop offset={0.48} stopColor="#f2ca00"/>
                            <stop offset={1} stopColor="#f2b600"/>
                        </linearGradient>
                        <path
                            d="M11.1 44.2c-.2.2-.2.5 0 .7.1.1.3.2.5.1 4.9-1.3 17.9-5.3 22-10.4 2.5-3.2 3.1-7.4 1.6-11.1-.4-.9-1.4-1.4-2.4-1.1-.2.1-.4.2-.6.4L11.1 44.2z"
                            fill="url(#parkrol-termeszeti_ertekek_svg__SVGID_2_)"
                        />
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st8"
                            d="M33.2 29.6s.8 2.9 0 4.4c-.3.5-.9 1.5-1.7 2.7-1.4 1.1-2.9 2-4.5 2.8l.7-1.1 5.5-8.8zm-8.6 11c.6-1 1.1-1.9 1.4-2.3.7-1.2 0-3.5 0-3.5l-4.4 7 3-1.2z"
                        />
                        <path
                            className="parkrol-termeszeti_ertekek_svg__st2"
                            d="M27.4 33.7c.2-.9.7-1.7 1.2-2.4s1.2-1.3 2-1.8c-.2.9-.6 1.7-1.2 2.4-.5.8-1.2 1.4-2 1.8z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgParkrolTermeszetiErtekek;
