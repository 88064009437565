import React from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import _ from 'lodash';
import {tourGuideActions} from 'client_shared/src/state/actions/tourGuideActions';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Promotion from '../components/contents/promotions/Promotion';
import {useSelector} from 'react-redux';
import TourGuideList from '../components/contents/tourGuide/lists/tourGuideList/TourGuideList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px',
            flexDirection: 'row',
        },
    },
    leftLayout: {
        width: 'unset',
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
        },
        [theme.breakpoints.up('lg')]: {
            width: '277px',
        },
    },
    rightLayout: {
        flex: 1,
        marginLeft: '0px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '30px',
        },
    },
    promotion: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    }
}));

function TourGuides() {
    const classes = useStyles();
    const promotion = useSelector(state => state.content.promotion);
    const tourGuides = useSelector(state => state.tourGuide.tourGuides);

    return (
        <ContentScreen>
            <div className={clsx('padding', classes.layout)}>
                <div className={classes.leftLayout}>
                    <Promotion promotion={promotion} className={classes.promotion}/>
                </div>
                <div className={classes.rightLayout}>
                    <TourGuideList tourGuides={tourGuides}/>
                </div>
            </div>
        </ContentScreen>
    );
}

TourGuides.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.tourGuide.tourGuides)) {
        promises.push(dispatch(tourGuideActions.loadTourGuides()));
    }

    return promises;
};

export default TourGuides;
