import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {tourActions} from 'client_shared/src/state/actions/tourActions';
import TourContentBar from '../components/contents/tour/TourContentBar';
import ContentScreen from '../components/pages/ContentScreen';
import {ROUTES_ANP} from 'shared';
import {contentActions} from 'client_shared/src/state/actions/contentActions';
import _ from 'lodash';

function Tour() {
    const [breadCrumbTrailTail, setBreadCrumbTrailTail] = useState([]);
    const tour = useSelector(state => state.tour.tour);
    const pages = useSelector(state => state.page.pages);

    useEffect(() => {
        if (!tour || pages.length === 0) {
            return;
        }

        const parentPage = pages.find(page => page.id === tour.tourType.pageId);
        const breadCrumbTrailTail = [{ title: parentPage.name, url: parentPage.url }, {
            title: tour.name,
            url: ROUTES_ANP.TOUR(tour.id)
        }];

        setBreadCrumbTrailTail(breadCrumbTrailTail);
    }, [tour, pages]);

    return (
        <ContentScreen title={tour && tour.name} subTitle={tour && tour.subTitle} backgroundImage={tour && tour.headerImage}
                       breadCrumbsTrailTail={breadCrumbTrailTail} includePageInBreadCrumbTail={false}>
            {tour && <TourContentBar tour={tour}/>}
        </ContentScreen>
    );
}

Tour.initState = (environment) => {
    const { dispatch, params, storeState} = environment;

    const promises = [];

    promises.push(dispatch(tourActions.loadTour(params.id)));

    if (_.isEmpty(storeState.content.dangers)) {
        promises.push(dispatch(contentActions.loadDangers()));
    }

    return promises;
};

export default Tour;
