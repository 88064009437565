import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {userActions} from 'client_shared/src/state/actions';
import {useDispatch, useSelector} from 'react-redux';
import {CONTENT} from 'shared';
import {contentService} from 'client_shared/src/services/contentService';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {useTranslation} from 'react-i18next';
import PrimaryBasicInverseButton from '../buttons/PrimaryBasicInverseButton';
import CookieBannerIcon from '../icons/multicolor/CookieBannerIcon';
import PrimaryBasicButton from '../buttons/PrimaryBasicButton';

const useStyles = makeStyles(theme => ({
    layout: {
        zIndex: 100,
        position: 'fixed',
        bottom: '0px',
        minHeight: '90px',
        width: '100%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 15px 0 rgba(92,92,102,0.15), 0 -5px 30px 0 #D9D9D9',
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            padding: '20px',
        }
    },
    layoutWrapper: {
        borderRadius: '8px',
        backgroundColor: '#F2A200',
        boxShadow: '0 3px 0 0 #F2CA00',
        padding: '20px 30px 20px 30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > *:not(:first-child)': {
            marginTop: '25px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            '& > *:not(:first-child)': {
                marginTop: '0px',
            },
        }
    },
    rowLayout: {
        display: 'flex',
    },
    text: {
        '&.paragraph3': {
            fontWeight: 'bold',
        },
        '& > p': {
            margin: '0px',
            padding: '0px'
        }
    },
    descriptionRow: {
        display: 'flex',
        alignItems: 'flex-start',
        '& > *:not(:first-child)': {
            marginLeft: '30px',
        }
    },
    icon: {
        width: '32px',
        height: '32px',
        minWidth: '32px',
        minHeight: '32px',
        [theme.breakpoints.up('sm')]: {
            width: '48px',
            height: '48px',
        }
    },
    buttonBar: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '15px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '20px',
        },
        '& > * + *': {
            marginLeft: '15px',
            [theme.breakpoints.up('sm')]: {
                marginLeft: '20px',
            }
        }
    },
    button: {
        whiteSpace: 'nowrap',
    }

}));

export default function CookieCard() {
    const classes = useStyles();
    const [warningText, setWarningText] = useState(null);
    const cookiesAccepted = useSelector(state => state.user.cookiesAccepted);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        async function loadWarningText() {
            const warningTextContent = await contentService.getContentByName(CONTENT.COOKIE_WARNING);
            setWarningText(warningTextContent.summary);
        }

        if (!cookiesAccepted && !warningText) {
            loadWarningText().catch(error => console.log(error));
        }
    }, [cookiesAccepted, warningText]);

    function onApproveClick() {
        dispatch(userActions.setCookiesAccepted({
            importantCookiesApproved: true,
            convenienceCookiesApproved: true,
            statisticalCookiesApproved: true
        }));
    }

    function onDetailsClick() {
        dispatch(userActions.setCookiesPopupOpen(true));
    }

    if (cookiesAccepted && cookiesAccepted.importantCookiesApproved) {
        return null;
    }

    return (
        <>
            <div className={clsx(classes.layout)}>
                <div className={clsx(classes.layoutWrapper)}>
                    <div className={classes.descriptionRow}>
                        <CookieBannerIcon className={classes.icon}/>
                        <div className={clsx('paragraph3', classes.text)}
                             dangerouslySetInnerHTML={createMarkup(warningText)}/>
                    </div>
                    <div className={classes.buttonBar}>
                        <PrimaryBasicButton variant={'normal'} onClick={onDetailsClick} title={t('Text.AdjustCookies')} className={classes.button}/>
                        <PrimaryBasicInverseButton variant={'normal'} onClick={onApproveClick}
                                                   title={t('Text.AcceptCookies')} className={classes.button}/>
                    </div>
                </div>
            </div>
        </>
    );
}
