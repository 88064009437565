import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import EducationIconListItem from './EducationIconListItem';
import EducationTrailsIcon from '../../../../icons/multicolor/KornyezetiNevelesTanosvenyekIcon';
import ClassTripsIcon from '../../../../icons/multicolor/KornyezetiNevelesOsztalykirandulasokIcon';
import ForestCampsIcon from '../../../../icons/multicolor/KornyezetiNevelesErdeiTaborokIcon';
import {ROUTES_ANP} from 'shared';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layoutContentOnPage: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-30px',
        marginTop: '-30px',
        '& > *': {
            marginLeft: '30px',
            marginTop: '30px',
        },

        '& > *:not(:first-child)': {
            marginLeft: '20px',
        },
    },
    layoutContentInMenu: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '-12px',
        '& > *': {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-20px',
            '& > *': {
                marginLeft: '20px',
            },
            flexDirection: 'row',
        }
    },
}));

export default function EducationIconList(props) {
    const { isMenu } = props;
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <div className={clsx(isMenu ? classes.layoutContentInMenu : classes.layoutContentOnPage)}>
            <EducationIconListItem Icon={EducationTrailsIcon} title={t('Text.EducationTrails')}
                                   summary={t('Text.EducationTrailsDescription')}
                                   url={getPathWithLanguage(ROUTES_ANP.NATURE_VALUE, i18n.language)} isMenu={isMenu}/>
            <EducationIconListItem Icon={ClassTripsIcon} title={t('Text.ClassTrips')} summary={t('Text.ClassTripsDescription')}
                                   url={getPathWithLanguage(ROUTES_ANP.WORLD_HERITAGE, i18n.language)} isMenu={isMenu}/>
            <EducationIconListItem Icon={ForestCampsIcon} title={t('Text.ForestCamps')} summary={t('Text.ForestCampsDescriptions')}
                                   url={getPathWithLanguage(ROUTES_ANP.WORLD_HERITAGE, i18n.language)} isMenu={isMenu}/>
        </div>

    );
}
