import React from 'react';
import {makeStyles} from '@material-ui/core';
import LocationBox from './LocationBox';
import clsx from 'clsx';
import {ROUTES_ANP} from 'shared';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layoutOnPage: {
        marginBottom: '64px'
    },
    layoutInMenu: {
        width: '100%',
    },
    layoutContentOnPage: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-30px',
        marginTop: '-64px',
        '& > *': {
            flex: '1 0 auto',
            marginLeft: '30px',
            marginTop: '64px',
            '&:last-child': {
                flex: '0 0 790px',
                height: '300px',
                borderRadius: '4px',
                margin: '10px auto',
                marginTop: '64px',
                marginBottom: '0px',
            }
        }
    },
    layoutContentInMenu: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '-12px',
        '& > *:not(:last-child):not(:first-child)': {
            marginTop: '12px',
        },
        '& > *:last-child': {
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            '& > *:not(:last-child):not(:first-child)': {
                marginTop: '0px',
                marginLeft: '30px',
            },
            '& > *:last-child': {
                marginTop: '0px',
                marginLeft: 'auto',
            }
        }
    },
}));

export default function LocationsContentBar(props) {
    const { isMenu } = props;
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <div className={clsx(isMenu ? clsx(classes.layoutInMenu) : clsx('padding', classes.layoutOnPage))}>
            <div className={clsx(isMenu ? classes.layoutContentInMenu : classes.layoutContentOnPage)}>
                <LocationBox image={'/content/images/koncz_peter-formak.jpg'} title={t('Text.BaradlaCave')}
                             summary={t('Text.BaradlaCaveSummary')}
                             url={getPathWithLanguage(ROUTES_ANP.BARADLA, i18n.language)} isMenu={isMenu}/>
                <LocationBox image={'/content/images/_NAN6256-SZQZH1DBs.jpg'} title={t('Text.Hucul')}
                             summary={t('Text.HuculSummary')} url={getPathWithLanguage(ROUTES_ANP.HUCUL, i18n.language)}
                             isMenu={isMenu}/>
                <LocationBox image={'/content/images/image6.jpg'} title={t('Text.Bodrogzug')}
                             summary={t('Text.BodrogzugSummary')}
                             url={getPathWithLanguage(ROUTES_ANP.BODROGZUG, i18n.language)}
                             externalLinkTitle={t('Text.BodrogzugExternalLinkTitle')} isMenu={isMenu}/>
            </div>
        </div>
    );
}


