import React from 'react';
import {makeStyles} from '@material-ui/core';
import {CONTENT} from 'shared/src/constants/content';
import DangerIcon from '../../../../icons/mono/DangerIcon';
import InfoFilledIcon from '../../../../icons/mono/InfoFilledIcon';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        width: '100%',
    },
    iconBar: props => ({
        backgroundColor: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#199FFF' : '#D93616',
        padding: '20px 12px',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0px 10px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 12px',
        },
        lineHeight: '1px',
        borderRadius: '6px 0 0 6px',
    }),
    infoIcon: {
        width: '16px',
        height: '16px',
        [theme.breakpoints.up('sm')]: {
            width: '20px',
            height: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '24px',
            height: '24px',
        }
    },
    dangerIcon: {
        width: '20px',
        height: '18px',
        [theme.breakpoints.up('sm')]: {
            width: '20px',
            height: '18px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '24px',
            height: '21px',
        }
    },
    messageBar: props => ({
        flex: 1,
        borderRadius: '0 6px 6px 0',
        backgroundColor: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#66BFFF' : '#E66045',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '8px 16px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '12px 16px',
        },
        '&.paragraph3': {
            lineHeight: '22.4px',
            color: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#17171A' : '#FFFFFF',
        },
        '& p': {
            margin: 0,
            padding: 0,
        },
        '& a': {
            color: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#17171A' : '#FFFFFF',
        }
    })
}));

export default function DangerListItem(props) {
    const { danger } = props;
    const classes = useStyles({danger});

    return (
        <div className={classes.layout}>
            <div className={classes.iconBar}>
                {danger.subType === CONTENT.DANGER_TYPE.INFO ? <InfoFilledIcon className={classes.infoIcon} fill={'#FFFFFF'}/> : <DangerIcon className={classes.dangerIcon} fill={'#F2CA00'}/>}
            </div>
            <div className={clsx('paragraph3', classes.messageBar)} dangerouslySetInnerHTML={createMarkup(danger.summary)} />
        </div>
    );
}
