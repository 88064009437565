import React, {useEffect, useState} from 'react';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FAQ_TOP_CATEGORY_ID} from 'shared/src/constants/faq';
import FaqCategoryListItem from './FaqCategoryListItem';
import {faqModule} from 'shared/src/modules/faq/faqModule';

const useStyles = makeStyles(theme => ({
    layout: {},
    category: {
        '&:first-child': {
            marginTop: '32px',
            [theme.breakpoints.up('lg')]: {
                marginTop: '0px',
            },
        },
        '&:not(:first-child)': {
            marginTop: '32px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '48px',
            },
        }
    },
    title: {
        marginBottom: '16px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '24px',
        },
    },
    list: {
        '& > * + *': {
            marginTop: '12px',
            [theme.breakpoints.up('lg')]: {
                marginTop: '14px',
            }
        }
    }
}));

export default function FaqCategoryList() {
    const classes = useStyles();
    const faqCategories = useSelector(state => state.faq.faqCategories);
    const faqCategory = useSelector(state => state.faq.faqCategory);
    const faqChildCategory = useSelector(state => state.faq.faqChildCategory);
    const faqs = useSelector(state => state.faq.faqs);
    const [childFaqCategories, setChildFaqCategories] = useState([]);

    const { t } = useTranslation();

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    useEffect(() => {
        if (!faqCategory || faqCategories.length === 0) {
            return;
        }

        const childFaqCategories = faqModule.getFaqChildCategories(faqCategories, faqCategory);

        setChildFaqCategories(childFaqCategories);
    }, [faqCategories, faqCategory]);

    useEffect(() => {
        if (!faqCategory || childFaqCategories.length === 0 || faqCategory === FAQ_TOP_CATEGORY_ID || !faqChildCategory) {
            return;
        }

        const categoryDiv = document.querySelector(`[id="category-${faqChildCategory.id}"]`);
        if (categoryDiv) {
            setTimeout(() => {
                categoryDiv.scrollIntoViewIfNeeded({ block: 'center', behavior: "smooth" });
            }, 100);
            /*
                        var topOfElement = categoryDiv.offsetTop - 120;
                        window.scroll({ top: topOfElement, behavior: "smooth" });
            */

            /*
                        categoryDiv.scrollIntoView(true);

                        const scrolledY = window.scrollY;

                        const offset = isDesktop ? styleConstants.headerBar.desktopHeight : 0;

                        if (scrolledY) {
                            debugger;
                            window.scroll(0, scrolledY - offset);
                        }
            */

            //window.scrollTo(0, categoryDiv.offsetTop - offset);
        }

    }, [faqCategory, faqChildCategory, childFaqCategories, isTablet, isDesktop]);

    return (
        <div className={classes.layout}>
            {faqCategory && faqCategory.id === FAQ_TOP_CATEGORY_ID &&
            <>
                <h2 className={classes.title}>
                    {t('Text.MostFrequentQuestions')}
                </h2>
                <div className={classes.list}>
                    {faqs.map(faq =>
                        <FaqCategoryListItem key={faq.id} faq={faq} isOpen={true}/>
                    )}
                </div>
            </>
            }
            {faqCategory && faqChildCategory && faqCategory.id !== FAQ_TOP_CATEGORY_ID && childFaqCategories && childFaqCategories.map(childFaqCategory =>
                <div key={childFaqCategory.id} className={classes.category}>
                    <h2 className={classes.title} id={`category-${childFaqCategory.id}`}>
                        {childFaqCategory.title}
                    </h2>
                    <div className={classes.list}>
                        {faqs.map(faq =>
                            <FaqCategoryListItem key={faq.id} faq={faq}
                                                 isOpen={childFaqCategory.id === faqChildCategory.id}/>
                        )}
                    </div>
                </div>)
            }

        </div>
    );
}
