import React from 'react';
import {makeStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import {useTranslation} from 'react-i18next';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import TicketTypeBox from './TicketTypeBox';
import {useFormikContext} from 'formik';
import clsx from 'clsx';
import {checkoutModule} from 'shared/src/modules/checkout';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    layout: {
        borderRadius: '12px',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 3px 14px 0 #D9D9D9',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
    },
    headerBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '34px',
    },
    tourBoxTitle: {},
    dateBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    dateBoxTitle: {
        marginBottom: '16px',
    },
    dateSelectorBar: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '32px',
        '& .MuiInputBase-input': {
            minWidth: '100px'
        }
    },

    dateSelector: {
        margin: '0px',
        width: '100%',
    },
    ticketsBox: {},
    ticketsBoxTitleBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px'
    },
    ticketTypesBar: {
        display: 'flex',
        alignItems: 'stretch',
        marginBottom: '32px',
    },
    ticketTypeBox: {
        '&:not(:first-child)': {
            marginLeft: '10px',
        },
    },
    couponBar: {
        display: 'flex',
        alignItems: 'center',
    },
    couponBarTitle: {
        '&.paragraph3': {
            color: '#17171A',
        }
    },
    couponCodeTextField: {
        flex: 1,
    },
    couponBarItem: {
        '&:not(:first-child)': {
            marginLeft: '12px',
        }
    },
    footerBar: {
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    deleteTickets: {
        '&.paragraph3': {
            fontWeight: 'bold',
        },
        cursor: 'pointer',
    },
    slots: {
        marginBottom: '10px'
    }
}));

export default function CheckoutTourBox(props) {
    const { className, tour, open, editedTourIdChanged } = props;
    const classes = useStyles();

    const { setValues, /*handleBlur, */values/*, setFieldValue*/ } = useFormikContext();

    const { t } = useTranslation();

    if (_.isEmpty(values)) {
        return null;
    }

    const orderItemIndices = checkoutModule.getOrderItemIndicesForTour(values, tour);
    if (!orderItemIndices || orderItemIndices.length === 0) {
        return null;
    }

    let remainingTicketNumber = values.tourLimit - values.tourAmount;
    if (remainingTicketNumber < 0) {
        remainingTicketNumber = 0;
    }

    function handleDeleteTickets() {
        editedTourIdChanged(tour.id, true);
        checkoutModule.removeFromOrder(values, tour);
        setValues(values);
    }

    // async function onDateChange(date) {
    //     const normalizedDate = moment(date).startOf('day');
    //     const nextAvailableTime = checkoutModule.getNextAvailableTime(tour, moment().isBefore(normalizedDate) ? normalizedDate : moment()).time;
    //     Bluebird.each(orderItemIndices, async (orderItemIndex) => {
    //         await setFieldValue(`orderItems[${orderItemIndex}].date`, formatDate(normalizedDate));
    //         await setFieldValue(`orderItems[${orderItemIndex}].time`, nextAvailableTime);
    //     })
    // }

    function handleExpandButtonClicked() {
        editedTourIdChanged(open ? null : tour.id);
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale="hu" moment={moment}>
            <div className={clsx(classes.layout, className)}>
                <div className={classes.headerBar}>
                    <h4 className={classes.tourBoxTitle}>{tour.name_hu}</h4>
                    {!open &&
                        <IconButton onClick={handleExpandButtonClicked}>
                            {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                        </IconButton>
                    }
                </div>
                {open &&
                    <>
                        <div className={classes.dateBox}>
                            <h5 className={classes.dateBoxTitle}>{t('Text.TourVisitDate')}</h5>
                            <div className={classes.dateSelectorBar}>
                                {moment(values.orderItems[0].date).format('YYYY. MM. DD.')} {moment(values.orderItems[0].time, 'HH:mm:ss').format('HH:mm')}
                                {/*                            <DatePicker
                                name="date"
                                autoOk={true}
                                margin="normal"
                                variant="inline"
                                inputVariant="outlined"
                                value={values.orderItems[orderItemIndices[0]].date}
                                onChange={onDateChange}
                                onBlur={handleBlur}
                                invalidDateMessage={t('Checkout.DateInvalid')}
                                disablePast
                                minDateMessage={t('Checkout.DateMinMessage')}
                                className={classes.dateSelector}
                            />
                            <CheckoutTimeList tour={tour}/>*/}
                            </div>
                            <div className={clsx('paragraph4', classes.slots)}>
                                {t('Text.RemainingTicketNumber')}: {remainingTicketNumber}
                            </div>
                        </div>
                        <div className={classes.ticketsBox}>
                            {/*
                        <div className={classes.ticketsBoxTitleBar}>
                            <h5>{t('Text.StandardTickets')}</h5>
                            <Link className={clsx('linkSecondary')}>Csoportos jegyek (20 főtől)</Link>
                        </div>
*/}
                            <div className={classes.ticketTypesBar}>
                                {orderItemIndices.map(orderItemIndex =>
                                    <TicketTypeBox key={orderItemIndex} className={classes.ticketTypeBox}
                                                   orderItemIndex={orderItemIndex}/>
                                )}
                            </div>
                        </div>
                        {/*
                <div className={classes.couponBar}>
                    <div className={clsx('paragraph3', classes.couponBarItem, classes.couponBarTitle)}>
                        {t('Text.CouponCode')}
                    </div>
                    <TextField
                        className={classes.couponBarItem}
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={!!(touched.guideName && errors.guideName)}
                        helperText={touched.guideName && errors.guideName}
                    />
                    <SecondaryButton title={t('Button.Use')} variant={'big'} className={classes.couponBarItem}/>
                </div>
*/}
                        <div className={classes.footerBar}>
                            <div className={clsx('paragraph3', classes.deleteTickets)} onClick={handleDeleteTickets}>
                                {t('Text.DeleteTickets')}
                            </div>
                        </div>
                    </>
                }
            </div>
        </MuiPickersUtilsProvider>
    );
}
