import React from 'react';
import {makeStyles, Popover} from '@material-ui/core';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {menuActions} from 'client_shared/src/state/actions';
import {styleConstants} from '../../../../styles/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import SearchField from '../../../search/SearchField';
import MobileMenu from './MobileMenu';
import MobileMenuPopoverTours from './MobileMenuPopoverTours';
import MobileMenuPopoverLocations from './MobileMenuPopoverLocations';
import MobileMenuPopoverPark from './MobileMenuPopoverPark';
import MobileMenuPopoverEducation from './MobileMenuPopoverEducation';
import {MENU} from 'shared';
import MobileMenuBack from './MobileMenuBack';
import DepartmentsMenu from '../departments/DepartmentsMenu';
import LanguageButtonMenu from '../language/LanguageButtonMenu';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        width: '100%',
        height: '100%',
        maxHeight: `calc(100% - ${styleConstants.headerBar.mobileHeight}px)`,
        maxWidth: 'unset',
        [theme.breakpoints.up('sm')]: {
            width: '450px',
            height: 'unset',
            maxHeight: `calc(100% - ${styleConstants.headerBar.tabletHeight}px)`,
        },
    },
    layout: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F2F2F2',
        boxShadow: '-20px 60px 90px 0 #17171A',
    },
    paddingTablet: {
        padding: '0px 32px'
    },
    searchBar: {
        backgroundColor: '#F2F2F2',
        '&.padding': {
            paddingTop: '24px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '24px',
            paddingBottom: '24px',
        }
    },
    backBar: {
        borderTop: '2px solid #E6E6E6',
        '&.padding': {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
        }
    },
    languages: {
        marginTop: 'auto',
        '& > *': {
            paddingTop: '32px',
            paddingBottom: '32px',
            borderBottom: '1px solid #E6E6E6',
        }
    },
    bottomBar: {
        display: 'flex',
        justifyContent: 'space-between',
        '&.padding': {
            paddingTop: '32px',
            paddingBottom: '32px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '32px',
            paddingBottom: '32px',
        },
    },
    subMenu: {
        backgroundColor: '#E6CFB8',
    }
}));

export default function MobileMenuPopover(props) {
    const { headerBarRef } = props;
    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isMobile = !isTablet && !isDesktop;

    const isMobileMenuOpen = useSelector(state => state.menu.isMobileMenuOpen);
    const selectedMobileMenuItem = useSelector(state => state.menu.selectedMobileMenuItem);
    const classes = useStyles({ isMobile, isTablet, isDesktop });

    const dispatch = useDispatch();

    if (!isMobileMenuOpen) {
        return null;
    }

    if (isDesktop) {
        dispatch(menuActions.hideMobileMenu());
        return null;
    }

    function handleClickAway() {
        dispatch(menuActions.hideMobileMenuItem());
        dispatch(menuActions.hideMobileMenu());
    }

    if (!headerBarRef || !headerBarRef.current) {
        return null;
    }

    return (
        <Popover
            open={isMobileMenuOpen}
            onClose={handleClickAway}
            anchorEl={headerBarRef.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            marginThreshold={0}
            classes={{
                paper: classes.popoverPaper,
            }}
        >
            <div className={classes.layout}>
                {!selectedMobileMenuItem &&
                <>
                    <div className={clsx(isMobile ? 'padding' : classes.paddingTablet, classes.searchBar)}>
                        <SearchField/>
                    </div>
                    <div className={clsx(isMobile ? 'padding' : classes.paddingTablet)}>
                        <MobileMenu/>
                    </div>
                    <div className={clsx(isMobile ? 'padding' : classes.paddingTablet, classes.languages)}>
                        <LanguageButtonMenu/>
                    </div>
                    <div className={clsx(isMobile ? 'padding' : classes.paddingTablet, classes.bottomBar)}>
                        <DepartmentsMenu/>
                    </div>
                </>
                }
                {selectedMobileMenuItem &&
                <>
                    <div className={clsx(isMobile ? 'padding' : classes.paddingTablet, classes.backBar)}>
                        <MobileMenuBack/>
                    </div>
                    <div className={classes.subMenu}>
                        {selectedMobileMenuItem.index === MENU.MAIN_MENU_ANP.TOUR &&
                        <MobileMenuPopoverTours/>
                        }
                        {selectedMobileMenuItem.index === MENU.MAIN_MENU_ANP.LOCATION &&
                        <MobileMenuPopoverLocations/>
                        }
                        {selectedMobileMenuItem.index === MENU.MAIN_MENU_ANP.PARK &&
                        <MobileMenuPopoverPark/>
                        }
                        {selectedMobileMenuItem.index === MENU.MAIN_MENU_ANP.EDUCATION &&
                        <MobileMenuPopoverEducation isMenu={true}/>
                        }
                    </div>
                </>
                }
            </div>
        </Popover>
    );
}
