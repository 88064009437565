import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import Promotion from '../promotions/Promotion';
import ParkIconList from './lists/parkIconList/ParkIconList';
import ParkInfoList from './lists/parkInfoList/ParkInfoList';

const useStyles = makeStyles(theme => ({
    layoutOnPage: {
        marginBottom: '64px',
        display: 'flex',
    },
    layoutInMenu: {
        width: '100%',
        display: 'flex',
    },
    layoutLeft: {
        flex: 1,
    },
    layoutRight: {
        width: '380px',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            '&:not(last-child)': {
                marginBottom: '32px',
            }
        }
    }

}));

export default function ParkContentBar(props) {
    const { isMenu, promotion } = props;
    const classes = useStyles();

    return (
        <div className={isMenu ? clsx(classes.layoutInMenu) : clsx('padding', classes.layoutOnPage)}>
            <div className={classes.layoutLeft}>
                <ParkIconList isMenu={false}/>
            </div>
            <div className={classes.layoutRight}>
                <ParkInfoList isMenu={false}/>
                <Promotion promotion={promotion}/>
            </div>
        </div>
    );
}
