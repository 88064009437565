import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / kerekpar_kolcsonzes"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#E6C973" cx={24} cy={24} r={24}/>
                <path
                    d="M30.344 21.748c-.153 0-.285 0-.438.021l-1.422-4.79a.656.656 0 00-.634-.479h-1.99a.68.68 0 00-.679.675c0 .37.306.675.678.675h1.488l.525 1.764H21.156l.372-1.002c.088-.196.044-.435-.066-.61a.685.685 0 00-.546-.283h-2.253a.68.68 0 00-.679.675c0 .37.307.675.678.675h1.27l-.373.98-.853 1.873c-.328-.087-.7-.13-1.05-.13a4.08 4.08 0 00-4.156 4.093C13.5 28.17 15.36 30 17.634 30c2.166 0 3.96-1.677 4.135-3.789h1.51a.655.655 0 00.502-.24l4.44-5.16.395 1.307c-1.422.653-2.428 2.09-2.428 3.745.021 2.286 1.88 4.137 4.156 4.137 2.275 0 4.156-1.85 4.156-4.115s-1.86-4.137-4.156-4.137zm-12.71 6.924a2.799 2.799 0 01-2.8-2.787c0-1.546 1.27-2.787 2.8-2.787.175 0 .328.021.482.043l-.985 2.134c-.087.196-.087.457.044.631a.66.66 0 00.569.305h2.669a2.791 2.791 0 01-2.779 2.46zm2.625-3.789h-1.465l.547-1.198c.415.284.743.719.918 1.198zm2.713 0h-1.313a4.13 4.13 0 00-1.728-2.439l.678-1.502h5.753l-3.39 3.941zm7.372 3.789a2.799 2.799 0 01-2.8-2.787c0-1.067.59-1.982 1.465-2.46l.81 2.765c.087.283.35.479.634.479.066 0 .131 0 .197-.022a.692.692 0 00.46-.828l-.81-2.721h.044c1.553 0 2.8 1.262 2.8 2.787a2.799 2.799 0 01-2.8 2.787z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
