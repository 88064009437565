import * as React from "react";

function SvgKornyezetiNevelesErdeiTaborok(props) {
    return (
        <svg
            id="kornyezeti_neveles-erdei_taborok_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".kornyezeti_neveles-erdei_taborok_svg__st2{fill:#fff}.kornyezeti_neveles-erdei_taborok_svg__st3{fill:#17171a}.kornyezeti_neveles-erdei_taborok_svg__st4{fill:#5c5c67}"
                }
            </style>
            <g id="kornyezeti_neveles-erdei_taborok_svg__Layer_4">
                <g id="kornyezeti_neveles-erdei_taborok_svg__kornyezeti_neveles-erdei_taborok">
                    <g id="kornyezeti_neveles-erdei_taborok_svg__background">
                        <circle cx={32} cy={32} r={27.5} fill="#e6e6e6"/>
                        <path
                            d="M57.5 41.8L5.1 38.4c3.5 14.8 18.4 23.9 33.2 20.3 8.8-2.1 16-8.4 19.2-16.9z"
                            fill="#b3b3b3"
                        />
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st2"
                            d="M38.8 13.5c0 3.3-5 2.8-6.3 2.8-.9 0-3.5.1-4.3.1-1.3-.1-2.3-1.2-2.2-2.6.1-1.2 1-2.2 2.2-2.2h.2c.3-2.2 2.3-3.8 4.6-3.6 1.9.2 3.3 1.7 3.6 3.6h.4c1 0 1.8.9 1.8 1.9zm10.1-3.2c-2 .9-3.4 2.8-3.7 5h-.3c-2.1 0-3.8 1.7-3.8 3.8 0 2.1 1.7 3.8 3.8 3.8 1.2 0 5.3-.2 6.8-.2 2 .1 4.1 0 6.1-.4-1.8-4.7-4.9-8.8-8.9-12z"
                        />
                    </g>
                    <g id="kornyezeti_neveles-erdei_taborok_svg__tree">
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st3"
                            d="M8 45.7l-.2-11.3c0-.3-.3-.6-.6-.6h-.4c-.3 0-.6.3-.6.6v7.5c.5 1.3 1.1 2.6 1.8 3.8z"
                        />
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st4"
                            d="M14 36.4l-3.1-6.1H12c.3 0 .6-.3.4-.5l-2.7-5.4h.9c.3 0 .5-.2.5-.5 0-.1 0-.2-.1-.2L7.4 17c-.1-.2-.4-.3-.6-.2-.1 0-.2.1-.2.2L3 23.6c-.1.2 0 .5.2.6.1 0 .1.1.2.1h.9l-2.7 5.4c-.1.2.1.5.4.5h1.1L0 36.3c-.1.2 0 .5.2.6.1 0 .1.1.2.1h13.1c.3 0 .5-.2.5-.4.1 0 0-.1 0-.2z"
                        />
                    </g>
                    <g id="kornyezeti_neveles-erdei_taborok_svg__house">
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st3"
                            d="M47.7 44.3H19.3c-.4 0-.7-.3-.7-.7 0-.1 0-.2.1-.3l9.6-22.9h29.5L47.7 44.3z"
                        />
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st2"
                            d="M20.1 44.3L35.4 47 29 22.4z"
                        />
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st4"
                            d="M30.9 46.2l-5.8-1 .3-9.6 5.4.4z"
                        />
                        <linearGradient
                            id="kornyezeti_neveles-erdei_taborok_svg__SVGID_1_"
                            gradientUnits="userSpaceOnUse"
                            x1={42.23}
                            y1={48.908}
                            x2={51.71}
                            y2={12.568}
                            gradientTransform="matrix(1 0 0 -1 0 66)"
                        >
                            <stop offset={0.19} stopColor="#f2ca00"/>
                            <stop offset={0.88} stopColor="#f2b600"/>
                        </linearGradient>
                        <path
                            d="M62.9 45.1l-26.2 2.8c-.6.1-1.1-.3-1.3-.8l-7.2-26.6h29.5l6.3 23c.2.6-.2 1.3-.9 1.5 0 0-.1.1-.2.1z"
                            fill="url(#kornyezeti_neveles-erdei_taborok_svg__SVGID_1_)"
                        />
                        <path
                            className="kornyezeti_neveles-erdei_taborok_svg__st4"
                            d="M48.7 46.6l-1.4.2-6.8-26.3h1.4l6.8 26.1zM37.5 20.4H36l6.8 26.8 1.4-.2-6.7-26.6zm.9 27.3l1.3-.1L33 20.4h-1.4l6.8 27.3zm17-27.3H54l6.8 24.9 1.4-.2-6.8-24.7zm-9 0H45l6.8 25.8 1.4-.1-6.8-25.7zm4.5 0h-1.4l6.8 25.3 1.4-.2-6.8-25.1z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgKornyezetiNevelesErdeiTaborok;
