import * as React from "react";

function SvgLatnivalokKerekparosTurautvonalak(props) {
    return (
        <svg
            id="latnivalok-kerekparos_turautvonalak_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".latnivalok-kerekparos_turautvonalak_svg__st3{fill:#17171a}.latnivalok-kerekparos_turautvonalak_svg__st4{fill:#2e2e33}.latnivalok-kerekparos_turautvonalak_svg__st5{fill:#5c5c67}"
                }
            </style>
            <g id="latnivalok-kerekparos_turautvonalak_svg__Layer_4">
                <g id="latnivalok-kerekparos_turautvonalak_svg__latnivalok-kerekparos_turautvonalak">
                    <circle
                        id="latnivalok-kerekparos_turautvonalak_svg__oval"
                        cx={32}
                        cy={32}
                        r={27.5}
                        fill="#e6e6e6"
                    />
                    <path
                        id="latnivalok-kerekparos_turautvonalak_svg__ground"
                        d="M10.2 48.7c9.2 12.1 26.5 14.3 38.6 5.1 3.1-2.3 5.6-5.3 7.4-8.7-17.3-.1-34.9 2-46 3.6z"
                        fill="#b3b3b3"
                    />
                    <path
                        id="latnivalok-kerekparos_turautvonalak_svg__cloud"
                        d="M54.8 25c1.3 0 2.5-.4 3.5-1.2-2.2-6.9-7-12.7-13.4-16.1-2.5.9-4.4 2.9-5.3 5.4-3-1.1-6.3.4-7.4 3.4-.1.4-.3.8-.3 1.2-.8-.2-1.6-.1-2.3.3-1.5.8-1.7 2.6-1.1 4.2 1.9 5.2 24.9 2.8 26.3 2.8z"
                        fill="#fff"
                    />
                    <g id="latnivalok-kerekparos_turautvonalak_svg__bringa">
                        <g id="latnivalok-kerekparos_turautvonalak_svg__kormany">
                            <path
                                className="latnivalok-kerekparos_turautvonalak_svg__st3"
                                d="M41.1 18.7l3.3-.7c.1 0 .2-.1.3-.2.5-.8 1.6-1.1 2.5-.6.6.4 1.1.9 1.6 1.5.2.2.2.4 0 .6l-1.6 1.9c-.1.2-.4.2-.6.1l-1.5-.7c-.2-.1-.3-.2-.3-.4 0-.3-.2-.5-.5-.5h-.1l-2.7.7c-.2 0-.5-.1-.5-.4l-.2-.8c-.1-.2.1-.5.3-.5z"
                            />
                            <path
                                className="latnivalok-kerekparos_turautvonalak_svg__st4"
                                d="M42.9 17l2.9 2.8 1.5-1.1-2.8-3.3z"
                            />
                            <circle
                                className="latnivalok-kerekparos_turautvonalak_svg__st5"
                                cx={43.7}
                                cy={16.2}
                                r={1.1}
                            />
                        </g>
                        <path
                            id="latnivalok-kerekparos_turautvonalak_svg__nyereg"
                            className="latnivalok-kerekparos_turautvonalak_svg__st5"
                            d="M17.7 20.8c-1.3-.2-2.5-.9-3.2-2v-.3c0-.1.1-.2.2-.2 2.6-.9 8.5 0 8.8 0 .2 0 .3.2.4.4 0 .3-.2.6-.4.8-.4.3-.9.4-1.3.3-.9-.1-3.4.7-4.3 1.1-.1-.1-.1-.1-.2-.1z"
                        />
                        <circle
                            className="latnivalok-kerekparos_turautvonalak_svg__st4"
                            cx={10.2}
                            cy={40.5}
                            r={3.3}
                        />
                        <path
                            className="latnivalok-kerekparos_turautvonalak_svg__st3"
                            d="M23.4 40.5c0-7.3-5.9-13.2-13.2-13.2-1.9 0-3.7.4-5.5 1.2-.1 1.2-.2 2.4-.2 3.5v.3c4.5-3.2 10.8-2.1 13.9 2.4s2.1 10.8-2.4 13.9c-1.3.9-2.9 1.5-4.5 1.7.9 1 1.8 1.9 2.8 2.7 5.4-1.7 9.1-6.8 9.1-12.5z"
                        />
                        <g id="latnivalok-kerekparos_turautvonalak_svg__wheel">
                            <path
                                className="latnivalok-kerekparos_turautvonalak_svg__st3"
                                d="M50.8 53.8c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2S64 33.2 64 40.5s-5.9 13.2-13.2 13.3zm0-23.3c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
                            />
                            <circle
                                className="latnivalok-kerekparos_turautvonalak_svg__st4"
                                cx={50.8}
                                cy={40.5}
                                r={3.3}
                            />
                        </g>
                        <linearGradient
                            id="latnivalok-kerekparos_turautvonalak_svg__vaz_1_"
                            gradientUnits="userSpaceOnUse"
                            x1={9.033}
                            y1={34.843}
                            x2={51.438}
                            y2={34.843}
                            gradientTransform="matrix(1 0 0 -1 0 66)"
                        >
                            <stop offset={0} stopColor="#f3a200"/>
                            <stop offset={0.52} stopColor="#f2b600"/>
                            <stop offset={1} stopColor="#f2ca00"/>
                        </linearGradient>
                        <path
                            id="latnivalok-kerekparos_turautvonalak_svg__vaz"
                            d="M51.4 40.3l-5.8-15.7v-.1c-.2-.5-.7-.8-1.2-.8l-1.9-4.8c-.3-.7-1.1-1-1.7-.7-.7.3-1 1.1-.7 1.7l.6 1.5-16.2 6.5-2.5-6.8c-.3-.7-1-1-1.7-.8-.7.3-1 1-.8 1.7l2.7 7.3-12.6 10c-.5.4-.6 1.2-.2 1.7.2.3.6.5 1 .5.1 0 .3 0 .4-.1l16.3 2.8H27c.5.1 1.1-.3 1.2-.8.1 0 .3 0 .4-.1.3-.1.5-.3.6-.5L42 24.9l.5 1.3c.2.5.7.9 1.2.9l6.4 13.7c.1.3.5.5.8.4.4-.2.6-.6.5-.9zm-22.9-1l-3.2-8.9 13.3-5.3-10.1 14.2zm-1.9 2.5l-14.2-1.6L23 31.8l3.6 10z"
                            fill="url(#latnivalok-kerekparos_turautvonalak_svg__vaz_1_)"
                        />
                        <g id="latnivalok-kerekparos_turautvonalak_svg__pedal">
                            <circle
                                className="latnivalok-kerekparos_turautvonalak_svg__st5"
                                cx={28.7}
                                cy={43}
                                r={2.8}
                            />
                            <path
                                className="latnivalok-kerekparos_turautvonalak_svg__st4"
                                d="M29.5 42l5.4 5c.2.2.2.6 0 .8-.2.2-.5.2-.7.1l-6.1-4c-.6-.4-.7-1.1-.4-1.7.4-.6 1.1-.7 1.7-.4-.1.1 0 .1.1.2z"
                            />
                            <path
                                className="latnivalok-kerekparos_turautvonalak_svg__st3"
                                d="M31.5 46.5h5.9c.3 0 .5.2.5.5v.9c0 .3-.2.5-.5.5h-5.9c-.3 0-.5-.2-.5-.5V47c0-.3.2-.5.5-.5z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgLatnivalokKerekparosTurautvonalak;
