import * as React from "react";

function SvgChevronUp(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M32 26.1L9.1 49.4c-2.1 2.1-5.5 2.1-7.5 0-2.1-2.1-2.1-5.6 0-7.7l26.7-27.1c2.1-2.1 5.5-2.1 7.5 0l26.7 27.1c2.1 2.1 2.1 5.6 0 7.7-2.1 2.1-5.5 2.1-7.5 0L32 26.1z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgChevronUp;
