import React from 'react';
import {makeStyles} from '@material-ui/core';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import clsx from 'clsx';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        border: '2px solid #D9D9D9',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
    },
    topLayout: {
        padding: '16px',
        width: '100%',
        borderBottom: '1px solid #D9D9D9',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            borderBottom: '0px',
            padding: '24px 24px 0px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            borderBottom: '0px',
            padding: '32px 32px 0px 48px',
        },
    },
    leftTopLayout: {},
    rightTopLayout: {
        marginLeft: '16px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '24px'
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '48px',
        }
    },
    bottomLayout: {
        width: '100%',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '0px 24px 24px 188px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 32px 32px 256px',
        }
    },
    image: {
        height: '120px',
        width: '120px',
        borderRadius: '100px',
        [theme.breakpoints.up('sm')]: {
            height: '140px',
            width: '140px',
            borderRadius: '100px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '160px',
            width: '160px',
            borderRadius: '100px',
        }
    },
    link: {
        textDecoration: 'none',
    },
    title: {
        '&.paragraph4': {
            color: '#5C5C66',
        },
        marginTop: '10px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '10px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '12px',
        }
    }
}));

export default function TourGuideListItem(props) {
    const { tourGuide } = props;
    const classes = useStyles();

    const { i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={classes.topLayout}>
                <div className={classes.leftTopLayout}>
                    <img alt={tourGuide.name} src={`/content/images/${tourGuide.headerImage}`} className={classes.image}/>
                </div>
                <div className={classes.rightTopLayout}>
                    <Link to={getPathWithLanguage(ROUTES_ANP.TOUR_GUIDE(tourGuide.id), i18n.language)}
                          className={classes.link}>
                        <h3>
                            {tourGuide.name}
                        </h3>
                        <div className={clsx('paragraph4', classes.title)}>
                            {tourGuide.title}
                        </div>
                    </Link>
                </div>
            </div>
            <div className={classes.bottomLayout}>
                <div className={clsx('paragraph3', classes.summary)}
                     dangerouslySetInnerHTML={createMarkup(tourGuide.summary)}/>
            </div>
        </div>
    );
}
