import React from 'react';
import ParkContentBar from '../components/contents/park/ParkContentBar';
import ContentScreen from '../components/pages/ContentScreen';

export default function Park() {
    return (
        <ContentScreen>
            <ParkContentBar/>
        </ContentScreen>
    );
}
