import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import NewsIcon from '../../../../icons/mono/NewsIcon';
import TourGuidesIcon from '../../../../icons/mono/TourGuidesIcon';
import EnvironmentalEducationIcon from '../../../../icons/mono/EnvironmentalEducationIcon';
import CompetitionIcon from '../../../../icons/mono/CompetitionIcon';
import ProductsIcon from '../../../../icons/mono/ProductsIcon';
import PhoneIcon from '../../../../icons/mono/PhoneIcon';
import {useSelector} from 'react-redux';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CallMadeIcon from '@material-ui/icons/CallMade';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    layoutOnPage: {
        '& > *:not(:first-child)': {
            marginTop: '12px',
        },
    },
    layoutInMenu: {
        '& > *:not(:first-child)': {
            marginTop: '6px',
        }
    },
    linkOnPage: {
        borderRadius: '8px',
        backgroundColor: '#E6E6E6',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none'
    },
    linkInMenu: {
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: '16px',
        [theme.breakpoints.up('lg')]: {
            padding: '6px 10px',
        }
    },
    iconOnPage: {
        width: '24px',
        height: '24px',
        color: '#5C5C66',
    },
    iconInMenu: {
        width: '20px',
        height: '20px',
        color: '#5C5C66',
        [theme.breakpoints.up('lg')]: {
            width: '16px',
            height: '16px',
        }
    },
    textOnPage: {
        '&.paragraph2': {
            color: '#5C5C66',
            textDecoration: 'none',
            [theme.breakpoints.up('lg')]: {
                color: '#2E2E33',
            }
        },
        marginLeft: '12px',
    },
    textInMenu: {
        '&.paragraph3': {
            color: '#5C5C66',
            textDecoration: 'none',
            [theme.breakpoints.up('lg')]: {
                color: '#2E2E33',
            }
        },
        marginLeft: '16px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '8px',
        }
    },
    externalLinkOnPage: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    externalLinkInMenu: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    externalLinkIconOnPage: {
        width: '24px',
        height: '24px',
        color: '#FF9F00',
        fontWeight: 'bold',
    },
    externalLinkIconInMenu: {
        width: '16px',
        height: '16px',
        color: '#FF9F00',
        fontWeight: 'bold',
    },
    externalLinkTextOnPage: {
        '&.paragraph2': {
            textDecoration: 'none',
            fontWeight: 'bold',
        },
        marginLeft: '12px',
    },
    externalLinkTextInMenu: {
        '&.paragraph3': {
            textDecoration: 'none',
            fontWeight: 'bold',
        },
        marginLeft: '8px',
    },
}));

export default function ParkInfoList(props) {
    const { isMenu } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_PARK_SIDE, user);

    const menuIcons = [
        <NewsIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <TourGuidesIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <EnvironmentalEducationIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)}
                                    fill={'#5C5C66'}/>,
        <CompetitionIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <ProductsIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <PhoneIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
    ];

    return (
        <div className={classes.layout}>
            <div className={clsx(isMenu ? classes.layoutInMenu : classes.layoutOnPage)}>
                {menu.map(menuItem => menuItem.index > 0 &&
                    <Link key={menuItem.id} to={menuItem.url || '/'}
                          className={clsx(isMenu ? classes.linkInMenu : classes.linkOnPage)}>
                        {menuIcons[menuItem.iconId - 1]}
                        <div
                            className={clsx(isMenu ? clsx('paragraph3', classes.textInMenu) : clsx('paragraph2', classes.textOnPage))}>
                            {menuItem.title}
                        </div>
                    </Link>
                )}
            </div>
            <a href={t('Text.DirectorateUrl')}
               className={isMenu ? clsx(classes.linkInMenu, classes.externalLinkInMenu) : clsx(classes.linkOnPage, classes.externalLinkOnPage)}>
                <div
                    className={clsx(isMenu ? clsx('paragraph3', classes.externalLinkTextInMenu) : clsx('paragraph2', classes.externalLinkTextOnPage))}>
                    {t('Text.DirectorateLinkTitle')}
                </div>
                <CallMadeIcon className={isMenu ? classes.externalLinkIconInMenu : classes.externalLinkIconOnPage}/>
            </a>
        </div>
    );
}
