import React from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import {useSelector} from 'react-redux';
import ToursContentBar from '../components/contents/tour/ToursContentBar';

export default function Tours() {
    const promotion = useSelector(state => state.content.promotion);

    return (
        <ContentScreen>
            <ToursContentBar promotion={promotion}/>
        </ContentScreen>
    );
}
