import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

const useStyles = makeStyles(theme => ({
    cardOnPage: {
        width: 'calc(100% / 2 - 20px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '2px solid #D9D9D9',
        borderRadius: '12px',
        textDecoration: 'none',
        height: '179px',
        [theme.breakpoints.up('sm')]: {
            height: '229px',
        },
        [theme.breakpoints.up('lg')]: {
            width: 'calc(100% / 2 - 30px)',
            height: '285px',
        }
    },
    cardInMenu: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        textDecoration: 'none',
        backgroundColor: '#FFFFFF',
        height: '64px',
        width: '100%',
        padding: '8px',
        [theme.breakpoints.up('lg')]: {
            width: '142px',
            height: '190px',
            flexDirection: 'column',
            borderRadius: '6px',
            padding: '0px'
        }
    },
    cardInBanner: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '6px',
        border: '2px solid #D9D9D9',
        textDecoration: 'none',
        backgroundColor: '#FFFFFF',
        height: '80px',
        width: '100%',
        padding: '8px 16px',
        [theme.breakpoints.up('sm')]: {
            height: '145px',
            flexDirection: 'column',
            borderRadius: '6px',
            padding: '0px'
        },
        [theme.breakpoints.up('lg')]: {
            height: '190px',
            flexDirection: 'column',
            borderRadius: '6px',
            padding: '0px'
        }
    },
    cardContentOnPage: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '32px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            '&:not(:last-child)': {
                marginBottom: '12px',
                [theme.breakpoints.up('sm')]: {
                    marginBottom: '24px',
                },
                [theme.breakpoints.up('lg')]: {
                    marginBottom: '35px',
                }
            },
        },
    },
    cardContentInMenu: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            marginTop: '16px',
            flexDirection: 'column',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: '16px',
                },
            },
        }
    },
    cardContentInBanner: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginTop: '16px',
            flexDirection: 'column',
            '& > *': {
                '&:not(:last-child)': {
                    marginBottom: '16px',
                },
            },
        }
    },
    textOnPage: {
        paddingLeft: '10px',
        paddingRight: '10px',
        textAlign: 'center',
        '&.paragraph2': {
            fontWeight: 'bold',
        }
    },
    textInMenu: {
        '&.paragraph2': {
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '&.paragraph3': {
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        marginLeft: '12px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '0px',
        }
    },
    textInBanner: {
        paddingLeft: '10px',
        paddingRight: '10px',
        '&.paragraph2': {
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '&.paragraph3': {
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        marginLeft: '16px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '0px',
        }
    },
    cardImageOnPage: {
        width: '96px',
        height: '96px',
        [theme.breakpoints.up('sm')]: {
            width: '112px',
            height: '112px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '128px',
            height: '128px',
        }
    },
    cardImageInMenu: {
        width: '48px',
        height: '48px',
        [theme.breakpoints.up('lg')]: {
            width: '82.5px',
            height: '82.5px',
        }
    },
    cardImageInBanner: {
        width: '64px',
        height: '64px',
        [theme.breakpoints.up('sm')]: {
            width: '70px',
            height: '70px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '80px',
            height: '80px',
        }
    },
}));

export default function ToursIconListItem(props) {
    const { Icon, title, url, isMenu, isBanner } = props;
    const classes = useStyles();

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));

    if (!Icon || !title || !url) {
        return null;
    }

    return (
        <Link to={url} className={isMenu ? classes.cardInMenu : (isBanner ? classes.cardInBanner : classes.cardOnPage)}>
            <div
                className={isMenu ? classes.cardContentInMenu : (isBanner ? classes.cardContentInBanner : classes.cardContentOnPage)}>
                <Icon
                    className={isMenu ? classes.cardImageInMenu : (isBanner ? classes.cardImageInBanner : classes.cardImageOnPage)}/>
                {isMenu &&
                <div className={clsx(isDesktop ? 'paragraph3' : 'paragraph2', classes.textInMenu)}>{title}</div>}
                {isBanner && <div
                    className={clsx(isDesktop || isTablet ? 'paragraph3' : 'paragraph2', classes.textInBanner)}>{title}</div>}
                {!isMenu && !isBanner && (isDesktop || isTablet) && <h4 className={classes.textOnPage}>{title}</h4>}
                {!isMenu && !isBanner && (!isDesktop && !isTablet) &&
                <div className={clsx('paragraph2', classes.textOnPage)}>{title}</div>}
            </div>
        </Link>
    );
}
