import React from 'react';
import {makeStyles} from '@material-ui/core';
import TourDistanceIcon from '../../icons/tour/LengthIcon';
import TourLengthIcon from '../../icons/tour/TimeIcon';
import TourDifficultyIcon from '../../icons/tour/DifficultyIcon';
import TourTemperatureIcon from '../../icons/tour/TemperatureIcon';
import TourDressingIcon from '../../icons/tour/DressIcon';
import TourStairsIcon from '../../icons/tour/StairsIcon';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    layoutBar: {
        display: 'flex',
        '&:not(:first-child)': {
            marginTop: '12px',
        }
    },
    card: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#FAF4ED',
        borderRadius: '8px',
        '&:not(:first-child)': {
            marginLeft: '12px',
        },
        padding: '8px 12px 8px 12px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
        }
    },
    labelRow: {
        display: 'flex',
        alignItems: 'center',
    },
    valueRow: {
        marginLeft: '22px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '0px',
        }
    },
    icon: {
        width: '16px',
        height: '16px',
        color: '#17171A',
    },
    label: {
        marginLeft: '8px',
        '&.paragraph3': {
            color: '#17171A',
            fontWeight: 'bold',
        }
    },
    value: {
        '&.paragraph3': {
            color: '#5C5C66',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '6px',
        }
    }
}));

function TourParameterCard(props) {
    const { Icon, label, value, className } = props;

    const classes = useStyles();

    return (
        <div className={clsx(classes.card, className)}>
            <div className={classes.labelRow}>
                <Icon className={classes.icon}/>
                <div className={clsx('paragraph3', classes.label)}>
                    {label}:
                </div>
            </div>
            <div className={classes.valueRow}>
                <div className={clsx('paragraph3', classes.value)}>
                    {value}
                </div>
            </div>
        </div>
    );
}

export default function TourParametersBar(params) {
    const { tour, className } = params;
    const classes = useStyles();

    const { t } = useTranslation();

    const isTabletOrDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

    return (
        <div className={clsx(classes.layout, className)}>
            {isTabletOrDesktop &&
            <>
                <div className={classes.layoutBar}>
                    {!_.isEmpty(tour.distance) &&
                    <TourParameterCard Icon={TourDistanceIcon} label={t('Text.Distance')} value={tour.distance}/>}
                    {!_.isEmpty(tour.time) &&
                    <TourParameterCard Icon={TourLengthIcon} label={t('Text.Time')} value={tour.time}/>}
                    {!_.isEmpty(tour.difficulty) &&
                    <TourParameterCard Icon={TourDifficultyIcon} label={t('Text.Difficulty')} value={tour.difficulty}/>}
                </div>
                <div className={classes.layoutBar}>
                    {!_.isEmpty(tour.temperature) &&
                    <TourParameterCard Icon={TourTemperatureIcon} label={t('Text.Temperature')}
                                       value={tour.temperature}/>}
                    {!_.isEmpty(tour.dressCode) &&
                    <TourParameterCard Icon={TourDressingIcon} label={t('Text.DressCode')} value={tour.dressCode}/>}
                    {!_.isEmpty(tour.stairCount) &&
                    <TourParameterCard Icon={TourStairsIcon} label={t('Text.StairCount')} value={tour.stairCount}/>}
                </div>
            </>
            }
            {!isTabletOrDesktop &&
            <>
                <div className={classes.layoutBar}>
                    {!_.isEmpty(tour.distance) &&
                    <TourParameterCard Icon={TourDistanceIcon} label={t('Text.Distance')} value={tour.distance}/>}
                    {!_.isEmpty(tour.time) &&
                    <TourParameterCard Icon={TourLengthIcon} label={t('Text.Time')} value={tour.time}/>}
                </div>
                <div className={classes.layoutBar}>
                    {!_.isEmpty(tour.difficulty) &&
                    <TourParameterCard Icon={TourDifficultyIcon} label={t('Text.Difficulty')} value={tour.difficulty}/>}
                    {!_.isEmpty(tour.temperature) &&
                    <TourParameterCard Icon={TourTemperatureIcon} label={t('Text.Temperature')}
                                       value={tour.temperature}/>}
                </div>
                <div className={classes.layoutBar}>
                    {!_.isEmpty(tour.dressCode) &&
                    <TourParameterCard Icon={TourDressingIcon} label={t('Text.DressCode')} value={tour.dressCode}/>}
                    {!_.isEmpty(tour.stairCount) &&
                    <TourParameterCard Icon={TourStairsIcon} label={t('Text.StairCount')} value={tour.stairCount}/>}
                </div>
            </>
            }
        </div>
    );
}




