import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import RoomIcon from '@material-ui/icons/Room';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '24px',
    },
    pin: {
        backgroundColor: '#DDFF99',
        borderRadius: '12px',
        padding: '4px 8px 4px 4px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
    },
    pinIcon: {
        color: '#96CC29',
        width: '16px',
        height: '16px',
    },
    pinText: {
        '&.paragraph4': {
            color: '#17171A',
            fontWeight: 'bold',
        },
        marginLeft: '4px',
    },
    breadCrumbsLayout: {
        marginLeft: '16px',
        '& > *:not(:first-child)': {
            marginLeft: '4px',
        }
    },
    link: {
        '&.paragraph4': {
            color: '#5C5C66',
        },
        textDecoration: 'none',
    },
    activeLink: {
        '&.paragraph4': {
            fontWeight: 'bold',
        },
    },
    separator: {
        '&.paragraph4': {
            color: '#5C5C66',
        },
    },
}));

export default function NavigationBar(props) {
    const { breadCrumbsTrail = [] } = props;
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <div className={clsx('padding', classes.layout)}>
            <div className={clsx(classes.item, classes.pin)}>
                <RoomIcon className={clsx(classes.pinIcon)}/>
                <div className={clsx('paragraph4', classes.pinText)}>
                    {t('Text.YouAreHere')}
                </div>
            </div>
            <div className={clsx(classes.breadCrumbsLayout)}>
                {breadCrumbsTrail.map((breadCrumb, index) => (
                    <React.Fragment key={index}>
                        <Link
                            to={getPathWithLanguage(breadCrumb.url, i18n.language)}
                            className={clsx('paragraph4', classes.link, index === breadCrumbsTrail.length - 1 && classes.activeLink)}
                        >
                            {breadCrumb.title}
                        </Link>
                        {index < breadCrumbsTrail.length - 1 &&
                        <span className={clsx('paragraph4', classes.separator)}>
                            »
                        </span>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
