import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import ToursListItem from './TourListItem';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '48px',
        },
    },
    headerItem: {
        '&:not(:first-child)': {
            marginLeft: '16px',
        }
    },
    headerLink: {
        '&.linkBig': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            },
        }
    },
    footerLinkBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    footerLink: {
        margin: '24px 0px 32px 0px',
    },
    caveTours: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '-16px',
        '& > *': {
            flex: '0 0 100%',
            marginTop: '16px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '-20px',
            marginTop: '-20px',
            '& > *': {
                flex: '0 0 calc(50% - 20px)',
                marginLeft: '20px',
                marginTop: '20px',
            }
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-30px',
            '& > *': {
                flex: '0 0 calc(33.333% - 30px)',
                marginLeft: '30px',
            }
        },
    },
    caveToursSimple: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '-16px',
        '& > *': {
            flex: '0 0 100%',
            marginTop: '16px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '-20px',
            marginTop: '-20px',
            '& > *': {
                flex: '0 0 calc(50% - 20px)',
                marginLeft: '20px',
                marginTop: '20px',
            }
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-30px',
            '& > *': {
                flex: '0 0 calc(50% - 30px)',
                marginLeft: '30px',
            }
        },
    },
    infoIcon: {
        width: '18px',
        height: '18px',
        color: '#1A9FFF',
    },
    oval: {
        width: '24px',
        height: '24px',
        borderRadius: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#CCEAFF',
    }
}));

export default function TourList(props) {
    const { showTitleLink, info, title = '', description = '', tours, linkTitle, url, simple, className } = props;
    const classes = useStyles();

    if (!tours || tours.length === 0) {
        return null;
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.header}>
                <h2 className={clsx(classes.headerItem)}>
                    {title}
                </h2>
                {showTitleLink &&
                <Link to={url}
                      className={clsx('linkBig', classes.headerLink, classes.headerItem)}>{linkTitle}</Link>
                }
                {info &&
                <div className={clsx(classes.oval, classes.headerItem)}>
                    <InfoIcon className={classes.infoIcon}/>
                </div>
                }
            </div>
            {description && <div className={classes.description} dangerouslySetInnerHTML={createMarkup(description)}/>}
            <div className={clsx(simple ? classes.caveToursSimple : classes.caveTours)}>
                {tours && tours.map(tour => (
                    <React.Fragment key={tour.id}>
                        <ToursListItem tour={tour} simple={simple}/>
                    </React.Fragment>
                ))}
            </div>
            {showTitleLink &&
            <div className={classes.footerLinkBlock}>
                <Link to={url}
                      className={clsx('linkBig', classes.footerLink, classes.headerItem)}>{linkTitle}</Link>
            </div>
            }
        </div>
    );
}
