import React from 'react';
import {makeStyles} from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        boxSizing: 'content-box',
        display: 'flex',
        alignItems: 'center',
        padding: '9px 12px 8px 12px !important',
        borderRadius: '2px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.15)',
    },
    text: {
        '&.paragraph3': {
            fontWeight: 'bold',
            letterSpacing: '0.3px',
            lineHeight: '19px',
            color: theme.palette.common.black,
        },
    },
    icon: {
        height: '12px',
        width: '12px',
        color: '#FF9F00',
        fontWeight: 'bold',
        marginLeft: '6px',
    },
}));

export default function LocationBoxExternalLink(props) {
    const { className, title, url } = props;
    const classes = useStyles();

    return (
        <a href={url} className={clsx('link', className)}>
            <div className={clsx(classes.layout)}>
                <div className={clsx('paragraph3', classes.text)}>
                    {title}
                </div>
                <CallMadeIcon className={classes.icon}/>
            </div>
        </a>
    );
}
