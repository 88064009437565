import React from 'react';
import {makeStyles} from '@material-ui/core';
import MobileMenuIcon from '../icons/mono/MenuIcon';
import DeleteIcon from '../icons/mono/DeleteIcon';
import {menuActions} from 'client_shared/src/state/actions';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        backgroundColor: '#F2F2F2',
        position: 'relative',
        borderRadius: '40px',
        cursor: 'pointer',
        height: '40px',
        width: '40px',
        [theme.breakpoints.up('sm')]: {
            borderRadius: '50px',
            height: '50px',
            width: '50px',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    closeButtonLayout: {
        backgroundColor: '#5C5C66',
    },
    icon: {
        position: 'absolute',
        top: '12px',
        left: '12px',
        width: '16px',
        height: '14px',
        [theme.breakpoints.up('sm')]: {
            top: '16px',
            left: '15px',
            width: '20px',
            height: '18px',
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '14px',
        left: '14px',
        width: '12px',
        height: '12px',
        [theme.breakpoints.up('sm')]: {
            top: '17px',
            left: '17px',
            width: '16px',
            height: '16px',
        }
    },
    hidden: {
        display: 'none',
    }
}));

export default function MobileMenuButton() {
    const classes = useStyles();

    const isMobileMenuOpen = useSelector(state => state.menu.isMobileMenuOpen);

    const dispatch = useDispatch();

    function handleClick() {
        if (!isMobileMenuOpen) {
            dispatch(menuActions.showMobileMenu());
        } else {
            dispatch(menuActions.hideMobileMenuItem());
            dispatch(menuActions.hideMobileMenu());
        }
    }

    return (
        <div className={clsx(classes.layout, isMobileMenuOpen && classes.closeButtonLayout)}
             onClick={handleClick}>
            <MobileMenuIcon className={clsx(isMobileMenuOpen && classes.hidden, classes.icon)}/>
            <DeleteIcon className={clsx(!isMobileMenuOpen && classes.hidden, classes.closeIcon)}
                        fill={'#FFFFFF'}/>
        </div>
    );
}
