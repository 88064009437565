export default {
    '& p': {
        '@media (min-width:760px)': {
            padding: '0px 0px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px',
        },
    },
    '& h3': {
        '@media (min-width:760px)': {
            padding: '0px 0px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px',
        },
    },
    '& h4': {
        '@media (min-width:760px)': {
            padding: '0px 0px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px',
        },
    },
    '& h5': {
        '@media (min-width:760px)': {
            padding: '0px 0px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px',
        },
    },
    '& .fileLink': {
        '@media (min-width:760px)': {
            padding: '0px 0px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px',
        },
    },
}
