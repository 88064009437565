import * as React from "react";

function SvgKornyezetiNevelesOsztalykirandulasok(props) {
    return (
        <svg
            id="kornyezeti_neveles-osztalykirandulasok_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".kornyezeti_neveles-osztalykirandulasok_svg__st2{fill:#5c5c67}.kornyezeti_neveles-osztalykirandulasok_svg__st4{fill:#17171a}"
                }
            </style>
            <g id="kornyezeti_neveles-osztalykirandulasok_svg__Layer_4">
                <g id="kornyezeti_neveles-osztalykirandulasok_svg__kornyezeti_neveles-osztalykirandulasok">
                    <g id="kornyezeti_neveles-osztalykirandulasok_svg__bg-2">
                        <circle cx={32} cy={32} r={27.5} fill="#e6e6e6"/>
                        <path
                            d="M59.4 30.2c-.1-1.8-.4-3.5-.9-5.2-13.2.7-28.1 10.3-28.1 10.3-6.3-3.3-17.1-2.2-25.8-.4C6.2 50 19.7 61 34.8 59.4c14.8-1.6 25.6-14.5 24.6-29.2z"
                            fill="#b3b3b3"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st2"
                            d="M54.9 47.2c-13-6.1-38.8-4.9-48.1-4.3C12.8 56.8 29 63.2 43 57.2c4.8-2.1 9-5.6 11.9-10z"
                        />
                        <path
                            d="M23.3 21.1c-.7-.4-1.5-.5-2.3-.3-.4-3-3.2-5.2-6.3-4.8l-1.2.3c-.4-1.1-1-2.1-1.8-3-3.8 4.1-6.2 9.2-7 14.7 7.1.4 18.2.5 19.5-3 .8-1.3.4-3-.9-3.9z"
                            fill="#fff"
                        />
                    </g>
                    <g id="kornyezeti_neveles-osztalykirandulasok_svg__bag">
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st4"
                            d="M25.1 15.8s-1.9.1-2.2 1.2c-.2 1-.2 2.1 0 3.1l-2.3 12.7 1.2.7-2.2 13.8s-.3 3.7 1.5 4 19.8 0 19.8 0 3.3-1.7 2.2-3.7-5.5-27.2-5.5-27.2l-11.5.5-1-5.1z"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st4"
                            d="M28.9 20.7l-1.3-.3.5-2.4c.2-.9 1-1.5 1.9-1.5h4c.9 0 1.7.7 1.9 1.5l.5 2.5-1.3.3-.5-2.5c-.1-.3-.3-.5-.6-.5h-3.9c-.3 0-.6.2-.6.5l-.6 2.4z"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st2"
                            d="M27.1 20.1v-3.9c0-.4-.3-.6-.6-.6h-.1l-2.1.5c-.3.1-.5.4-.5.7l.3 4.3 3-1zM36.9 20.1v-3.9c0-.4.3-.6.6-.6h.1l2.1.5c.3.1.5.4.5.7l-.2 4.3-3.1-1z"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st4"
                            d="M40.1 35.7h3.6s2.6 4.2 3 7.3.8 6.6-4.8 4.9-1.8-12.2-1.8-12.2zM23.9 35.7h-3.6s-2.6 4.2-3 7.3-.8 6.6 4.8 4.9 1.8-12.2 1.8-12.2z"
                        />
                        <linearGradient
                            id="kornyezeti_neveles-osztalykirandulasok_svg__SVGID_1_"
                            gradientUnits="userSpaceOnUse"
                            x1={31.973}
                            y1={42.5}
                            x2={31.973}
                            y2={16}
                            gradientTransform="matrix(1 0 0 -1 0 66)"
                        >
                            <stop offset={0.19} stopColor="#f2ca00"/>
                            <stop offset={0.88} stopColor="#f2b600"/>
                        </linearGradient>
                        <path
                            d="M24.6 23.5l-3.9 24.3c-.2 1 .5 2 1.6 2.2H41.2c1 0 1.9-.8 1.9-1.9v-.4l-4.4-24.3H24.6z"
                            fill="url(#kornyezeti_neveles-osztalykirandulasok_svg__SVGID_1_)"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st4"
                            d="M23.8 29L22 34.2 40.5 33l-.7-4z"
                        />
                        <linearGradient
                            id="kornyezeti_neveles-osztalykirandulasok_svg__SVGID_2_"
                            gradientUnits="userSpaceOnUse"
                            x1={32}
                            y1={46.38}
                            x2={32}
                            y2={33.82}
                            gradientTransform="matrix(1 0 0 -1 0 66)"
                        >
                            <stop offset={0.19} stopColor="#f2ca00"/>
                            <stop offset={0.88} stopColor="#f2b600"/>
                        </linearGradient>
                        <path
                            d="M21.8 32.2h20.5l-2-11.2c-.1-.8-.8-1.4-1.6-1.4H25.1c-.8 0-1.5.6-1.6 1.4l-1.7 11.2z"
                            fill="url(#kornyezeti_neveles-osztalykirandulasok_svg__SVGID_2_)"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st2"
                            d="M26.6 30.5h1.1c.4 0 .8.3.8.8v3.4c0 .4-.3.8-.8.8h-1.1c-.4 0-.8-.3-.8-.8v-3.4c0-.5.4-.8.8-.8zM36.3 30.5h1.1c.4 0 .8.3.8.8v3.4c0 .4-.3.8-.8.8h-1.1c-.4 0-.8-.3-.8-.8v-3.4c0-.5.4-.8.8-.8z"
                        />
                        <path
                            className="kornyezeti_neveles-osztalykirandulasok_svg__st4"
                            d="M35.5 47.4h-7c-1.2 0-2.2-.9-2.4-2.1l-.6-5.2h13l-.6 5.2c-.2 1.2-1.2 2.1-2.4 2.1z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgKornyezetiNevelesOsztalykirandulasok;
