import React, {useEffect, useState} from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import {useLocation} from 'react-router';
import 'url-search-params-polyfill';
import {useDispatch, useSelector} from 'react-redux';
import {searchActions} from 'client_shared/src/state/actions/searchActions';
import {searchFilterType} from 'shared/src/modules/filter';
import SearchHeaderBar from '../components/search/SearchHeaderBar';
import {makeStyles} from '@material-ui/core/styles';
import SearchResultTourList from '../components/contents/tour/lists/searchResultTourList/SearchResultTourList';
import SearchResultNewsList from '../components/contents/news/lists/searchResultNewsList/SearchResultNewsList';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px'
        },
    },
    searchHeaderBar: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '34px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '36px'
        },
    },
    searchResultTourList: {
        marginTop: '0px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        },
    },
    searchResultNewsList: {
        marginTop: '12px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        },
    }
}));

function Search() {
    const location = useLocation();
    const classes = useStyles();
    const [searchFilter, setSearchFilter] = useState(searchFilterType.all);
    const searchPhrase = useSelector(state => state.search.phrase);
    const searchResults = useSelector(state => state.search.results);
    const dispatch = useDispatch();

    useEffect(() => {
        async function searchSite() {
            const searchParams = new URLSearchParams(location.search);
            const searchPhrase = searchParams.get('phrase');
            dispatch(searchActions.searchSite(searchPhrase));
        }

        if (!location || !dispatch) {
            return;
        }

        searchSite().catch(error => console.log(error));
    }, [location, dispatch]);

    return (
        <ContentScreen showTitleBar={false}>
            <div className={clsx('padding', classes.layout)}>
                <SearchHeaderBar searchFilter={searchFilter} searchPhrase={searchPhrase}
                                 onSearchFilterChange={setSearchFilter} className={classes.searchHeaderBar}/>
                <SearchResultTourList
                    tours={searchResults && searchFilter !== searchFilterType.news ? searchResults.tours : []}
                    className={classes.searchTourNewsList}
                />
                <SearchResultNewsList
                    news={searchResults && searchFilter !== searchFilterType.tours ? searchResults.news : []}
                    className={classes.searchResultNewsList}/>
            </div>
        </ContentScreen>
    );
}

Search.initState = (environment) => {
    const promises = [];

    return promises;
};

export default Search;
