import {sendServerRequest} from './baseService';

export const checkoutService = {
    resetOrder,
    loadOrder,
    saveOrder,
    getInitialOrder,
};

async function resetOrder() {
    try {
        sessionStorage.removeItem('orderToken');
    } catch (error) {
    }
}

async function saveOrder(order, final) {
    const result = await sendServerRequest('PUT', `/api/orders${final ? '?final=true' : ''}`, order);

    const { order: savedOrder} = result;

    try {
        sessionStorage.setItem('orderToken', savedOrder.token);
    } catch (error) {
    }

    return result;
}

async function loadOrder() {
    let orderToken = null;
    try {
        orderToken = sessionStorage.getItem('orderToken');
    } catch (error) {
    }

    if (orderToken) {
        return sendServerRequest('GET', `/api/orders?token=${orderToken}`);
    }

    return null;
}

async function getInitialOrder() {
    return sendServerRequest('GET', `/api/orders?initialOrder=true`);
}
