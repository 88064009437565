import React from 'react';
import {makeStyles} from '@material-ui/core';
import {menuActions} from 'client_shared/src/state/actions';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useDispatch, useSelector} from 'react-redux';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';

const useStyles = makeStyles(theme => ({
    menu: {
        display: "flex",
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    menuItem: {
        display: "block",
        flex: "0 1 auto",
        listStyleType: "none",
        padding: '24px 0px',
        borderBottom: '1px solid #E6E6E6',
    },
    menuItemLink: {
        position: 'relative',
        textDecoration: 'none',
    },
    menuItemLinkContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menuItemLinkText: {
        '&.paragraph2': {
            fontWeight: 'bold',
            color: '#17171A',
        },
    },
    menuItemLinkIcon: {
        width: '25px',
        height: '25px',
        color: '#E6E6E6',
    },
}));

export default function MobileMenu() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_MAIN, user);

    function handleClick(menuItem) {
        return (event) => {
            event.preventDefault();
            dispatch(menuActions.showMobileMenuItem(menuItem));
        };
    }

    return (
        <ul className={classes.menu}>
            {menu.map(menuItem => (
                <li key={menuItem.id} className={classes.menuItem}>
                    <a
                        href={'/'}
                        onClick={handleClick(menuItem)}
                        className={classes.menuItemLink}>
                        <div className={classes.menuItemLinkContent}>
                            <div className={clsx('paragraph2', classes.menuItemLinkText)}>
                                {menuItem.title}
                            </div>
                            <ChevronRightIcon className={classes.menuItemLinkIcon}/>
                        </div>
                    </a>
                </li>
            ))}
        </ul>

    );
}
