import * as React from "react";

function SvgThanks(props) {
    return (
        <svg
            id="thanks_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            enableBackground="new 0 0 64 64"
            {...props}
        >
            <style>
                {".thanks_svg__st4{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}"}
            </style>
            <g id="thanks_svg__icon">
                <linearGradient
                    id="thanks_svg__SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1={-906.354}
                    y1={562.438}
                    x2={-907.688}
                    y2={561.104}
                    gradientTransform="matrix(48 0 0 -48 43569 26997)"
                >
                    <stop offset={0} stopColor="#f2ca00"/>
                    <stop offset={1} stopColor="#f2a200"/>
                </linearGradient>
                <circle
                    cx={32}
                    cy={32}
                    r={32}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="url(#thanks_svg__SVGID_1_)"
                />
                <defs>
                    <circle id="thanks_svg__SVGID_2_" cx={32} cy={32} r={32}/>
                </defs>
                <clipPath id="thanks_svg__SVGID_3_">
                    <use xlinkHref="#thanks_svg__SVGID_2_" overflow="visible"/>
                </clipPath>
                <defs>
                    <filter
                        id="thanks_svg__Adobe_OpacityMaskFilter"
                        filterUnits="userSpaceOnUse"
                        x={-28.7}
                        y={8.2}
                        width={94.4}
                        height={121.8}
                    >
                        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                    </filter>
                </defs>
                <mask
                    maskUnits="userSpaceOnUse"
                    x={-28.7}
                    y={8.2}
                    width={94.4}
                    height={121.8}
                    id="thanks_svg__mask-3_1_"
                >
                    <g filter="url(#thanks_svg__Adobe_OpacityMaskFilter)">
                        <circle
                            id="thanks_svg__path-2_1_"
                            cx={31.1}
                            cy={32.5}
                            r={32.1}
                            clipPath="url(#thanks_svg__SVGID_3_)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="#fff"
                        />
                    </g>
                </mask>
                <g
                    clipPath="url(#thanks_svg__SVGID_3_)"
                    mask="url(#thanks_svg__mask-3_1_)"
                >
                    <image
                        width={279}
                        height={360}
                        id="thanks_svg__kiemeltkicsi"
                        xlinkHref="7A6847730184FEBD.png"
                        transform="translate(-28.712 8.228) scale(.3384)"
                        overflow="visible"
                    />
                </g>
                <defs>
                    <filter
                        id="thanks_svg__Adobe_OpacityMaskFilter_1_"
                        filterUnits="userSpaceOnUse"
                        x={35}
                        y={18.5}
                        width={18}
                        height={14.5}
                    >
                        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                    </filter>
                </defs>
                <mask
                    maskUnits="userSpaceOnUse"
                    x={35}
                    y={18.5}
                    width={18}
                    height={14.5}
                    id="thanks_svg__mask-3_2_"
                >
                    <g filter="url(#thanks_svg__Adobe_OpacityMaskFilter_1_)">
                        <circle
                            id="thanks_svg__path-2_2_"
                            className="thanks_svg__st4"
                            cx={32}
                            cy={32}
                            r={32}
                        />
                    </g>
                </mask>
                <g id="thanks_svg__hearth" mask="url(#thanks_svg__mask-3_2_)">
                    <g transform="translate(26.25 13.875)">
                        <path
                            d="M17.8 7s1.6-2.2 3.9-2.3c3-.1 4.9 2 5 4.3.1 2.3-1.6 4.4-2.9 5.8-1.3 1.4-3.2 2.8-4.7 3.8-.9.6-2.1.6-3 0-1.5-1-3.3-2.5-4.6-3.9-1.3-1.4-2.9-3.5-2.8-5.9.2-2.3 2.2-4.4 5.1-4.2 2.4.2 4 2.4 4 2.4z"
                            fill="#d93616"
                        />
                        <defs>
                            <filter
                                id="thanks_svg__Adobe_OpacityMaskFilter_2_"
                                filterUnits="userSpaceOnUse"
                                x={17.8}
                                y={4.6}
                                width={9}
                                height={14.4}
                            >
                                <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
                            </filter>
                        </defs>
                        <mask
                            maskUnits="userSpaceOnUse"
                            x={17.8}
                            y={4.6}
                            width={9}
                            height={14.4}
                            id="thanks_svg__mask-5_1_"
                        >
                            <g filter="url(#thanks_svg__Adobe_OpacityMaskFilter_2_)">
                                <path
                                    id="thanks_svg__path-4_1_"
                                    className="thanks_svg__st4"
                                    d="M17.8 7s1.6-2.2 3.9-2.3c3-.1 4.9 2 5 4.3.1 2.3-1.6 4.4-2.9 5.8-1.3 1.4-3.2 2.8-4.7 3.8-.9.6-2.1.6-3 0-1.5-1-3.3-2.5-4.6-3.9-1.3-1.4-2.9-3.5-2.8-5.9.2-2.3 2.2-4.4 5.1-4.2 2.4.2 4 2.4 4 2.4z"
                                />
                            </g>
                        </mask>
                        <path
                            id="thanks_svg__Rectangle"
                            mask="url(#thanks_svg__mask-5_1_)"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="#17171a"
                            fillOpacity={0.24}
                            d="M17.8 4.6h9v14.5h-9z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgThanks;
