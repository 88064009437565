import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {useFormikContext} from 'formik';
import clsx from 'clsx';
import {checkoutModule} from 'shared/src/modules/checkout';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {useTranslation} from 'react-i18next';
import InfoPopup from '../controls/InfoPopup';

const useStyles = makeStyles(theme => ({
    ticketTypeBox: {
        height: '201px',
        border: '1px solid #D9D9D9',
        borderRadius: '8px',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 10px 16px 10px',
    },
    ticketTypeBoxUsed: {
        border: '2px solid #B0E645',
    },
    ticketTypeBoxTitleBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    ticketTypeBoxTitle: {
        width: '100%',
        '&.paragraph3': {
            fontWeight: 'bold',
            textAlign: 'center',
        },
        marginBottom: '7px',
    },
    ticketTypeBoxSubTitle: {
        color: '#5C5C66',
        whiteSpace: 'nowrap',
        '& ul': {
            margin: 0,
            paddingLeft: '15px',
        }
    },
    detailsLink: {
        '&.linkSecondary': {
            fontSize: '12px',
            cursor: 'pointer',
        }
    },
    ticketTypeBoxDetailsBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    ticketTypeBoxPrice: {
        '&.paragraph3': {
            whiteSpace: 'nowrap',
            color: '#5C5C66',
        },
        marginBottom: '12px',
    },
}));

export default function TicketTypeBox(props) {
    const { className, orderItemIndex } = props;
    const { touched, errors, setFieldValue, handleBlur, values } = useFormikContext();
    const [open, setOpen] = useState(false);
    const [orderItem, setOrderItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const { t } = useTranslation();

    async function onAmountChange(event) {
        const amount = event.target.value;
        await setFieldValue(`orderItems[${orderItemIndex}].amount`, amount);
        let amountInt = parseInt(amount, 10) || 0;
        if (amountInt < 0) {
            amountInt = 0;
        }
        const price = amountInt * values.orderItems[orderItemIndex].product.price;
        await setFieldValue(`orderItems[${orderItemIndex}].price`, price);
    }

    function handleDetailsClick(orderItem) {
        return (event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
            setOrderItem(orderItem);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
        setOrderItem(null);
    };

    return (
        <div
            className={clsx(classes.ticketTypeBox, className, values.orderItems[orderItemIndex].amount > 0 && classes.ticketTypeBoxUsed)}>
            <div className={classes.ticketTypeBoxTitleBar}>
                <div className={clsx('paragraph3', classes.ticketTypeBoxTitle)}>
                    {values.orderItems[orderItemIndex].product.name}
                </div>
                <div className={clsx('paragraph4', classes.ticketTypeBoxSubTitle)} dangerouslySetInnerHTML={createMarkup(values.orderItems[orderItemIndex].product.description)} />
                {values.orderItems[orderItemIndex].product.summary && values.orderItems[orderItemIndex].product.summary.trim() !== '' &&
                <div className={clsx('linkSecondary', classes.detailsLink)} onClick={handleDetailsClick(values.orderItems[orderItemIndex])}>
                    {t('Text.Details')}
                </div>
                }
            </div>
            <div className={classes.ticketTypeBoxDetailsBar}>
                <div className={clsx('paragraph3', classes.ticketTypeBoxPrice)}>
                    {checkoutModule.formatMoney(values.orderItems[orderItemIndex].product.price)} Ft
                </div>
                <TextField
                    name={`orderItems[${orderItemIndex}].amount`}
                    type={'number'}
                    value={values.orderItems[orderItemIndex].amount}
                    onChange={onAmountChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    error={!!(touched.orderItems && touched.orderItems[orderItemIndex] && touched.orderItems[orderItemIndex].amount && errors.orderItems && errors.orderItems[orderItemIndex] && errors.orderItems[orderItemIndex].amount)}
                    helperText={touched.orderItems && touched.orderItems[orderItemIndex] && touched.orderItems[orderItemIndex].amount && errors.orderItems && errors.orderItems[orderItemIndex] && errors.orderItems[orderItemIndex].amount}
                />
            </div>
            <InfoPopup open={open} title={orderItem && orderItem.product.name} description={orderItem && orderItem.product.summary} anchorEl={anchorEl} handleClose={handleClose}/>
        </div>
    );
}
