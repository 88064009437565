import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import CheckoutTourBox from './CheckoutTourBox';
import {useFormikContext} from 'formik';
import CheckoutSummaryBox from './CheckoutSummaryBox';
import PrimaryBuyingButton from '../buttons/PrimaryBuyingButton';
import {ROUTES_ANP} from 'shared';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useSelector} from 'react-redux';
import {checkoutModule} from 'shared/src/modules/checkout';
import {useHistory} from 'react-router';
import _ from 'lodash';
import {tourService} from 'client_shared/src/services';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        padding: '34px 32px 34px 28px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    toursBar: {
        flex: 1,
    },
    title: {
        marginBottom: '24px',
    },
    tourBox: {
        '&:not(:last-child)': {
            marginBottom: '22px'
        }
    },
    summaryBar: {
        marginTop: '34px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
            marginLeft: '48px',
        },
    },
    ticketSummary: {},
    buyButton: {
        width: '100%',
    },
    summaryBox: {},
}));

export default function CheckoutOrder() {
    const classes = useStyles();
    const order = useSelector(state => state.checkout.order);
    const tours = order ? checkoutModule.getOrderTours(order) : [];
    const [editedTourId, setEditedTourId] = useState(tours.length > 0 ? tours[tours.length - 1].id : null);
    const history = useHistory();

    const { values, handleSubmit, submitForm , setFieldValue, errors} = useFormikContext();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        async function getTourAmount() {
            const orderItem = values.orderItems[0];
            const { tourAmount, tourLimit } = await tourService.getTourAmountForTourTime(editedTourId, orderItem.date, orderItem.time, orderItem.tourDateId, orderItem.tourTimeId);

            await setFieldValue(`tourLimit`, tourLimit);
            await setFieldValue(`tourAmount`, tourAmount);
        }

        if (!_.isEmpty(values) && editedTourId) {
            getTourAmount().catch(error => console.error(error));
        }
    }, [editedTourId, values, setFieldValue]);

    if (_.isEmpty(values)) {
        return null;
    }

    function editedTourIdChanged(newEditedTourId, deleted = false) {
        if (deleted) {
            setEditedTourId(tours.find(tour => tour.id !== newEditedTourId));
            return;
        }

        setEditedTourId(newEditedTourId);
    }

    function handleNextClick() {
        submitForm().then(() => {
            history.push(getPathWithLanguage(ROUTES_ANP.PAYMENT, i18n.language));
        })
            .catch(error => console.log(error));
    }

    const sum = checkoutModule.getOrderSum(values);
    const hasErrors = !_.isEmpty(errors);

    return (
        <form onSubmit={handleSubmit}>
            <div className={classes.layout}>
                <div className={classes.toursBar}>
                    <h3 className={classes.title}>{t('Text.SavedTickets')}</h3>
                    <div>
                    {tours.map(tour =>
                        <CheckoutTourBox key={tour.id} className={classes.tourBox} tour={tour}
                                         open={editedTourId === tour.id}
                                         editedTourIdChanged={editedTourIdChanged}/>
                    )}
                    </div>
                    {/*<CheckoutOfferBox/>*/}
                </div>
                <div className={classes.summaryBar}>
                    <h3 className={classes.title}>{t('Text.Summary')}</h3>
                    <CheckoutSummaryBox order={values}/>
                    <PrimaryBuyingButton title={t('Button.ProceedToPayment')} variant={'big'}
                                         className={classes.buyButton}
                                         disabled={sum === 0 || hasErrors}
                                         onClick={handleNextClick}/>
                </div>
            </div>
        </form>
    );
}
