export const PAGE = {
    TYPE: {
        HOME: 1,
        TOURS: 2,
        LOCATIONS: 3,
        PARK: 4,
        EDUCATION: 5,
        CAVE_TOURS: 6,
        ADVANCED_CAVE_TOURS: 7,
        WALKING_TOURS: 8,
        CYCLING_TOURS: 9,
        OTHER_PROGRAMMES: 10,
        TOUR: 11,
        TOUR_PACKAGES: 12,
        MICRO_SITE: 13,
        NEWS: 14,
        FAQ: 15,
        SEARCH: 16,
        ACCOMMODATIONS: 17,
        ACCOMMODATION: 18,
        TOUR_GUIDES: 19,
        TOUR_GUIDE: 20,
        HORSE_TOURS: 21,
        CONTENT: 22,
        PAYMENT: 23,
        PAYMENT_ACKNOWLEDGEMENT: 24,
        PAYMENT_TIMEOUT: 25,
        MAPS: 26,
        NEWS_ARTICLE: 27,
        CONTENT_ARTICLE: 28,
    },
};
