import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        borderRadius: '12px',
        backgroundColor: '#F2F2F2',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '32px',
        }
    },
    summaryBar: {
        display: 'flex',
        alignItems: 'flex-start',
        '& > * + *': {
            marginLeft: '16px',
        }
    }
}));

export default function TourGuideSummary(props) {
    const { className, tourGuide } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.summaryBar}>
                <div className={clsx('paragraph1')} dangerouslySetInnerHTML={createMarkup(tourGuide.summary)}/>
            </div>
        </div>

    );
}
