import React from 'react';
import {makeStyles} from '@material-ui/core';
import BadgeListItem from './BadgeListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
    },
    badge: {
        '&:not(:first-child)': {
            paddingLeft: '4px',
        }
    }
}));

export default function BadgeList(props) {
    const { badgeIds } = props;
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            {badgeIds && badgeIds.map(badgeId => (
                <BadgeListItem key={badgeId} badgeId={badgeId} className={classes.badge}/>
            ))}
        </div>
    );
}
