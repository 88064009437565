import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import {emailAddressService} from 'client_shared/src/services';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import DeleteIcon from '../../icons/mono/DeleteIcon';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-rounded': {
            borderRadius: '8px',
        }
    },
    layout: {
        minWidth: '200px',
        backgroundColor: '#66BFFF',
        padding: '16px 24px 24px 24px',
        [theme.breakpoints.up('sm')]: {
            padding: '16px 24px 24px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px',
        },
    },
    titleBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    ovalWrapper: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    oval: {
        position: 'relative',
        height: '24px',
        width: '24px',
        borderRadius: '24px',
        backgroundColor: '#1A9FFF',
        cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    icon: {
        position: 'absolute',
        top: '6px',
        left: '6px',
        height: '12px',
        width: '12px',
    },
    email: {
        '&.paragraph2': {
        },
    },
}));

function GoogleRecaptcha(props) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [emailAddress, setEmailAddress] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }

        const handleReCaptchaVerify = async () => {
            try {
                const token = await executeRecaptcha('homepage');
                const { emailAddress } = await emailAddressService.getEmailAddress(token);
                setEmailAddress(emailAddress)
            } catch (e) {
                console.log(e);
            }
        };

        handleReCaptchaVerify();
    }, [executeRecaptcha]);

    return (
        <div>
            <a href={`mailto:${emailAddress}`}
               className={clsx('paragraph2', classes.email)}>{emailAddress}</a>
        </div>
    );
}

export default function EmailPopup(props) {
    const { open, onClose } = props;
    const classes = useStyles();

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
            <div className={classes.layout}>
                <div className={classes.titleBar}>
                    <h4>Email cím</h4>
                    <div className={classes.ovalWrapper}>
                        <div className={classes.oval} onClick={onClose}>
                            <DeleteIcon fill={'#5C5C66'} className={classes.icon}/>
                        </div>
                    </div>
                </div>
                <GoogleReCaptchaProvider
                    useRecaptchaNet
                    reCaptchaKey={'6LeUceQiAAAAAD_BO_Vn-g5GiAAFquWEYFJClsqb'}
                    scriptProps={{ async: true, defer: true, appendTo: 'body' }}
                >
                    <GoogleRecaptcha/>
                </GoogleReCaptchaProvider>
            </div>
        </Dialog>

    );
}
