import React, {useEffect, useState} from 'react';
import {useMediaQuery} from '@material-ui/core';
import MainScreen from './MainScreen';
import NavigationBar from './NavigationBar';
import PageTitleBar from '../pages/PageTitleBar';
import {useSelector} from 'react-redux';
import {pageModule} from 'shared/src/modules/page';
import {useRouteMatch} from 'react-router';

export default function ContentScreen(props) {
    const {
        title,
        subTitle,
        children,
        showTitleBar = true,
        Icon,
        backgroundImage,
        divider,
        breadCrumbsTrailTail = [],
        includePageInBreadCrumbTail = true,
        dangers = []
    } = props;

    const [breadCrumbTrail, setBreadCrumbTrail] = useState([]);
    const pages = useSelector(state => state.page.pages);
    const page = useSelector(state => state.page.page);
    const parentPage = useSelector(state => state.page.parentPage);
    const match = useRouteMatch();

    useEffect(() => {
        if (!page || pages.length === 0) {
            return;
        }

        const parentPages = pageModule.getParentPages(pages, parentPage || page, includePageInBreadCrumbTail);
        let breadCrumbTrail = pageModule.getBreadCrumbsFromPageList(parentPages);

        setBreadCrumbTrail(breadCrumbTrail);
    }, [page, parentPage, pages, includePageInBreadCrumbTail, match]);


    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <MainScreen>
            {isDesktop &&
            <NavigationBar breadCrumbsTrail={[...breadCrumbTrail, ...breadCrumbsTrailTail]}/>}
            {showTitleBar &&
            <PageTitleBar title={title ? title : page && page.name} subTitle={subTitle} Icon={Icon}
                          backgroundImage={backgroundImage}
                          divider={divider} dangers={dangers}/>}
            {children}
        </MainScreen>
    );
}
