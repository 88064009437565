import React from 'react';
import {makeStyles} from '@material-ui/core';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {accommodationModule} from 'shared/src/modules/accommodation';
import ApartmentPlaceIcon from '../../../../icons/accommodations/ApartmentPlaceIcon';
import ApartmentCallIcon from '../../../../icons/accommodations/ApartmentCallIcon';
import ApartmentOpeningIcon from '../../../../icons/accommodations/ApartmentOpeningIcon';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        border: '2px solid #D9D9D9',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px 26px 24px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '32px 26px 32px 48px',
        },
    },
    leftLayout: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    rightLayout: {
        width: '100%',
        position: 'relative',
        marginLeft: '0px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '48px',
        }
    },
    badgeArea: {
        position: 'absolute',
        display: 'flex',
        top: '0px',
        left: '0px',
        [theme.breakpoints.up('sm')]: {
            top: '0px',
            right: '0px',
            left: 'unset',
        },
        '& > * + *': {
            marginLeft: '4px',
        }
    },
    badge: {
        color: '#FFFFFF',
        fontSize: '12px',
        letterSpacing: '0.2px',
        lineHeight: '25.6px',
        textAlign: 'center',
        borderRadius: '4px',
        height: '22px',
        padding: '0px 5px',
    },
    bottomLayout: {
        width: '100%',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '0px 24px 24px 188px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 32px 32px 256px',
        }
    },
    image: {
        height: '120px',
        width: '120px',
        borderRadius: '100px',
        [theme.breakpoints.up('sm')]: {
            height: '140px',
            width: '140px',
            borderRadius: '100px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '160px',
            width: '160px',
            borderRadius: '100px',
        }
    },
    link: {
        textDecoration: 'none',
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            marginTop: '32px',
        },
    },
    contactBlock: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: '6px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '12px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '16px',
            },
        },
    },
    openingHoursBlock: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: '6px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
        },
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& > * + *': {
            marginLeft: '16px'
        }
    },
    icon: {
        height: '24px',
        width: '24px',
    },
    infoText: {
        '&.paragraph4': {
            color: '#17171A',
            fontSize: '12px',
        },
        '& > p': {
            margin: '0px',
            padding: '0px',
        }
    },
    openingHoursText: {
        '&.paragraph4': {
            fontWeight: 'bold',
            fontSize: '12px',
        },
    },
    openingHoursRow: {
        marginLeft: '40px',
    },
    title: {
        marginTop: '34px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
        }
    }
}));

export default function AccommodationListItem(props) {
    const { accommodation } = props;
    const classes = useStyles();
    const allAccommodationCategories = useSelector(state => state.accommodation.accommodationCategories);

    const { t, i18n } = useTranslation();

    const accommodationCategories = accommodationModule.getAccommodationCategories(allAccommodationCategories, accommodation);

    return (
        <div className={classes.layout}>
            <div className={classes.leftLayout}>
                <img alt={accommodation.title} src={`/content/images/${accommodation.headerImage}`} className={classes.image}/>
            </div>
            <div className={classes.rightLayout}>
                <div className={classes.title}>
                    <Link to={getPathWithLanguage(ROUTES_ANP.ACCOMMODATION(accommodation.id), i18n.language)}
                          className={classes.link}>
                        <h3>
                            {accommodation.title}
                        </h3>
                    </Link>
                </div>
                <div className={classes.infoBlock}>
                    <div className={classes.contactBlock}>
                        <div className={classes.infoRow}>
                            <ApartmentPlaceIcon className={classes.icon}/>
                            <div className={clsx('paragraph4', classes.infoText)}>
                                {`${accommodation.locationPostCode} ${accommodation.locationCity}, ${accommodation.locationAddress}`}
                            </div>
                        </div>
                        <div className={classes.infoRow}>
                            <ApartmentCallIcon className={classes.icon}/>
                            <div className={clsx('paragraph4', classes.infoText)}>
                                {accommodation.phone}
                            </div>
                        </div>
                    </div>
                    {accommodation.openingHours && accommodation.openingHours !== '' &&
                    <div className={classes.openingHoursBlock}>
                        <div className={classes.infoRow}>
                            <ApartmentOpeningIcon className={classes.icon}/>
                            <div className={clsx('paragraph4', classes.openingHoursText)}>
                                {t('Text.Open')}
                            </div>
                        </div>
                        <div className={classes.openingHoursRow}>
                            <div className={clsx('paragraph4', classes.infoText)}
                                 dangerouslySetInnerHTML={createMarkup(accommodation.openingHours)}/>
                        </div>
                    </div>
                    }
                </div>
                <div className={classes.badgeArea}>
                    {accommodationCategories.map(accommodationCategory =>
                        <div key={accommodationCategory.id} className={classes.badge}
                             style={{ backgroundColor: accommodationCategory.color }}>
                            {accommodationCategory.title}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
