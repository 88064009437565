import * as React from "react";

function SvgSafety(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M50.3 32L63 19.3c1.2-1.2 1.2-3.3 0-4.5L49.2.9C48-.3 45.9-.3 44.7.9L32 13.6 19.3.9C18.6.3 17.8 0 17 0c-.8 0-1.6.3-2.2.9L.9 14.8C-.3 16-.3 18.1.9 19.3L13.6 32 .9 44.7c-1.2 1.2-1.2 3.3 0 4.5l13.8 13.9c1.2 1.2 3.3 1.2 4.5 0L32 50.4l12.7 12.7c.6.6 1.4.9 2.3.9.8 0 1.6-.3 2.3-.9l13.8-13.9c1.2-1.2 1.2-3.3 0-4.5L50.3 32zM32 22.4c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2 1.5-3.2 3.2-3.2zm-15 6.2L5.4 17.1 17 5.5 28.6 17 17 28.6zm8.7 6.5c-1.8 0-3.2-1.4-3.2-3.2 0-1.8 1.4-3.2 3.2-3.2 1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.5 3.2-3.2 3.2zm6.3 6.4c-1.8 0-3.2-1.4-3.2-3.2 0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2c0 1.8-1.4 3.2-3.2 3.2zm6.4-12.8c1.8 0 3.2 1.4 3.2 3.2 0 1.8-1.4 3.2-3.2 3.2-1.8 0-3.2-1.4-3.2-3.2 0-1.7 1.5-3.2 3.2-3.2zm8.5 29.9L35.3 47l11.6-11.6L58.5 47 46.9 58.6z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgSafety;
