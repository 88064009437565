import React from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import LocationsContentBar from '../components/contents/location/LocationsContentBar';

export default function Locations() {
    return (
        <ContentScreen>
            <LocationsContentBar inMenu={false}/>
        </ContentScreen>
    );
}
