import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '-8px',
        marginLeft: '-8px',
        '& > *': {
            marginTop: '8px',
            marginLeft: '8px',
        }
    },
    badge: {
        borderRadius: '8px',
        backgroundColor: '#F2E0CE',
        padding: '8px 12px',
    }
}));

export default function FaqCategoryBadgeList(props) {
    const { className, faqCategories } = props;
    const classes = useStyles();

    return (
        <div className={clsx(className, classes.layout)}>
            {faqCategories.map(faqCategory =>
                <div className={clsx('paragraph4', classes.badge)}>
                    {faqCategory.title}
                </div>
            )}
        </div>
    );
}
