import React from 'react';
import {makeStyles} from '@material-ui/core';
import SearchFilterListItem from './SearchFilterListItem';
import {searchFilterType} from 'shared/src/modules/filter';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
        },
        '& > * + *': {
            marginLeft: '13px',
            [theme.breakpoints.up('sm')]: {
                marginLeft: '16px',
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '16px',
            },
        }
    }
}));

export default function SearchFilterList(props) {
    const { className, searchFilter, onSearchFilterChange } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={clsx(classes.layout, className)}>
            <SearchFilterListItem searchFilterType={searchFilterType.all}
                                  selected={searchFilter === searchFilterType.all} title={t('Text.SearchAll')}
                                  onClick={onSearchFilterChange}/>
            <SearchFilterListItem searchFilterType={searchFilterType.tours}
                                  selected={searchFilter === searchFilterType.tours} title={t('Text.SearchTours')}
                                  onClick={onSearchFilterChange}/>
            <SearchFilterListItem searchFilterType={searchFilterType.news}
                                  selected={searchFilter === searchFilterType.news} title={t('Text.SearchNews')}
                                  onClick={onSearchFilterChange}/>
        </div>
    );
}
