import * as React from "react"

function SvgComponent(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <defs>
                <linearGradient
                    id="prefix__linear-gradient"
                    x1={4.5}
                    y1={39.04}
                    x2={31.12}
                    y2={39.04}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#f3a200"/>
                    <stop offset={0.04} stopColor="#f3a500"/>
                    <stop offset={0.49} stopColor="#f2c000"/>
                    <stop offset={0.76} stopColor="#f2ca00"/>
                </linearGradient>
                <linearGradient
                    id="prefix__linear-gradient-2"
                    x1={29.45}
                    y1={20.33}
                    x2={41.22}
                    y2={53.15}
                    xlinkHref="#prefix__linear-gradient"
                />
                <style>
                    {
                        ".prefix__cls-1{fill:#fff}.prefix__cls-6{fill:#f3a200}.prefix__cls-7{fill:#5c5c67}"
                    }
                </style>
            </defs>
            <title>{"latnivalok-hucul_menes"}</title>
            <g id="prefix__Layer_2" data-name="Layer 2">
                <g id="prefix__Layer_4" data-name="Layer 4">
                    <g id="prefix__latnivalok-hucul_menes">
                        <path id="prefix__bg" className="prefix__cls-1" d="M0 0h64v64H0z"/>
                        <circle id="prefix__oval" cx={32} cy={32} r={27.5} fill="#e6e6e6"/>
                        <g id="prefix__cloud">
                            <path
                                className="prefix__cls-1"
                                d="M33.45 53.76a1.89 1.89 0 00-.65-2.42 1.77 1.77 0 00-1.36-.18 3.36 3.36 0 00-3.33-2.9 3.47 3.47 0 00-1.17.21 5 5 0 00-9.58.22 3.36 3.36 0 00-2 5.22c.6.45 1.23.88 1.87 1.29a3.17 3.17 0 00.88.13c.81 0 14.21 1.44 15.34-1.57zM49.81 11.05l-.72.22a11.84 11.84 0 00-.46-1.17A27.37 27.37 0 0033.7 4.56a11.23 11.23 0 00-6.06 7.2 7.53 7.53 0 001.72 14.87c1.4 0 20.32 2 29.52-.45a27.57 27.57 0 00-9.07-15.13z"
                            />
                        </g>
                        <path
                            d="M43.84 44.31a1.7 1.7 0 00-2.22.92c-1.53 3.72-3.51 8-6.59 11.32a19 19 0 01-3.45 2.94H32a28.17 28.17 0 005.4-.49l.13-.13c3.43-3.73 5.58-8.32 7.23-12.32a1.7 1.7 0 00-.92-2.24z"
                            fill="#2e2e33"
                        />
                        <path
                            d="M26 39.43c8.44-1.82 4-19.88 4-19.88s-17.23.27-25.28 9.69c-.09.9-.14 1.83-.14 2.76a27.5 27.5 0 0020.17 26.53c1.03-7.3-5.05-17.75 1.25-19.1z"
                            fill="url(#prefix__linear-gradient)"
                        />
                        <path
                            d="M45.83 51.23c1.18 0 3.84-2.33 4.37-4.46s-2.05-5.42-3.82-8.08-2-3.19-5.85-10.63a15.92 15.92 0 00-10.61-8.51c-6 .89-6.31 6.31-6.3 10.3 0 5-.25 10.85 3.29 12.09s6.48 1.15 8.26 2.22C36.49 45 40 49 40.42 50.34c.58 1.66 3.58.97 5.41.89z"
                            fill="url(#prefix__linear-gradient-2)"
                        />
                        <path
                            className="prefix__cls-6"
                            d="M33.36 20.2s3-2 5.67-3.47a36.14 36.14 0 013.75-1.87.39.39 0 01.51.5 28.25 28.25 0 00-1.07 3.14c-.54 1.95-5.58 3.63-7.71 3.54s-3.59-.29-1.15-1.84z"
                        />
                        <path
                            className="prefix__cls-7"
                            d="M29.48 18.52c.15-.37.55-.66 1.38-.88a7.53 7.53 0 014.65.54 16.89 16.89 0 019.6 10.15c.15.49.7 4.94.29 4.86-3-.61-6-1.41-9.12-1.76-3.31-.37-4-4-5.13-7.42-.34-1-.7-2-1.07-3s-.84-1.87-.6-2.49z"
                        />
                        <path
                            className="prefix__cls-6"
                            d="M28.48 20.15a.39.39 0 01-.29-.59 24.88 24.88 0 016.62-7.09A17.7 17.7 0 0140 10a.38.38 0 01.38.63A11.4 11.4 0 0037.89 15c-.76 2.73.51 6-9.41 5.15z"
                        />
                        <path
                            className="prefix__cls-7"
                            d="M29.29 18.16s-14.58.7-24.54 10.16A28 28 0 004.5 32a27.38 27.38 0 001.82 9.83 16.92 16.92 0 013.3-6.23c5.67-6.11 16-9.68 16-9.68s1.83-5.86 3.67-7.76zM46.81 40.39c-1.5.9-9 5.42-9.74 5.93a1.77 1.77 0 00-.49.57C33 50.18 27 52.42 20.29 53a38.91 38.91 0 01-5.93.06 27.71 27.71 0 005.14 3.39l1.1-.07c3.56-.33 12.48-1.68 18.38-7.12a1.69 1.69 0 00.29-.37l1.87-1.16A14.84 14.84 0 0044 52.54 1.7 1.7 0 0046.29 50a14.7 14.7 0 01-2.22-4l4.48-2.7a1.69 1.69 0 10-1.74-2.9z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SvgComponent
