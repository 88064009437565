class AccommodationModule {
    getAccommodationCategories(accommodationCategories, accommodation) {
        if (!accommodation.accommodationCategoryIds || accommodation.accommodationCategoryIds.length === 0) {
            return [];
        }

        return accommodationCategories.filter(accommodationCategory => accommodation.accommodationCategoryIds.includes(accommodationCategory.id));
    }

    getAccommodationServices(accommodationServices, accommodation) {
        if (!accommodation.accommodationServiceIds || accommodation.accommodationServiceIds.length === 0) {
            return [];
        }

        return accommodationServices.filter(accommodationService => accommodation.accommodationServiceIds.includes(accommodationService.id));
    }
}

export const accommodationModule = new AccommodationModule();