import React from 'react';
import {makeStyles, Popover} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {checkoutActions} from 'client_shared/src/state/actions/checkoutActions';
import CheckoutEmpty from './misc/CheckoutEmpty';
import CheckoutOrder from './CheckoutOrder';
import {styleConstants} from '../../styles/styles';
import {Formik} from 'formik';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        width: '100%',
        height: '100%',
        maxHeight: `calc(100% - ${styleConstants.headerBar.mobileHeight}px)`,
        maxWidth: 'unset',
        [theme.breakpoints.up('sm')]: {
            width: 'unset',
            height: 'unset',
            maxHeight: `calc(100% - ${styleConstants.headerBar.tabletHeight}px)`,
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: `calc(100% - ${styleConstants.headerBar.desktopHeight}px)`,
        },
    },
    layout: {
        backgroundColor: '#F2F2F2',
        boxShadow: '-20px 60px 90px 0 #17171A',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: 'unset',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function CheckoutPopover(props) {
    const { headerBarRef } = props;
    const classes = useStyles(props);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const checkoutIsOpen = useSelector(state => state.checkout.open);
    const order = useSelector(state => state.checkout.order);
    const orderSaving = useSelector(state => state.checkout.orderSaving);

    if (!checkoutIsOpen) {
        return null;
    }

    function onSubmit(newOrder) {
        if (order) {
            dispatch(checkoutActions.saveOrder(newOrder));
        }
        dispatch(checkoutActions.interruptCheckout());
    }

    function onClose() {
        dispatch(checkoutActions.interruptCheckout());
    }

    async function validate(values) {
        let errors = {};
        let orderedAmountSum = 0;

        values.orderItems.forEach((orderItem, index) => {
            const amount = parseInt(orderItem.amount, 10) || 0;
            orderedAmountSum += amount;
        });

        let remainingTicketNumber = values.tourLimit - values.tourAmount;
        if (remainingTicketNumber < 0) {
            remainingTicketNumber = 0;
        }

        values.orderItems.forEach((orderItem, index) => {
            let amountError = undefined;

            if (orderedAmountSum > remainingTicketNumber) {
                amountError = t('Form.InvalidAmount');
            }

            const amount = parseInt(orderItem.amount, 10) || 0;

            if (!orderItem.amount && orderItem.amount !== 0) {
                amountError = t('Form.Mandatory');
            }

            if (amount < 0) {
                amountError = t('Form.InvalidAmount');
            }

            if (amountError) {
                if (!errors.orderItems) {
                    errors.orderItems = [];
                }

                errors.orderItems[index] = {
                    amount: amountError,
                };
            }
        });

        return errors;
    }

    if (!headerBarRef || !headerBarRef.current) {
        return null;
    }

    return (
        <>
            {order &&
            <Formik
                enableReinitialize={true}
                initialValues={order}
                validate={validate}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, values, submitForm }) => (
                    <Popover
                        open={checkoutIsOpen}
                        onClose={submitForm}
                        anchorEl={headerBarRef.current}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        marginThreshold={0}
                        classes={{
                            paper: classes.popoverPaper,
                        }}
                    >
                        <div className={classes.layout}>
                            <Backdrop className={classes.backdrop} open={orderSaving}>
                                <CircularProgress color="inherit"/>
                            </Backdrop>
                            <CheckoutOrder/>
                        </div>
                    </Popover>
                )}
            </Formik>
            }
            {!order &&
            <Popover
                open={checkoutIsOpen}
                onClose={onClose}
                anchorEl={headerBarRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                marginThreshold={0}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <div className={classes.layout}>
                    <CheckoutEmpty/>
                </div>
            </Popover>
            }
        </>
    );
}
