import * as React from "react";

function SvgEat(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M17.9 35.9l9.5-9.7L3.9 2.3c-5.2 5.3-5.2 14 0 19.3l14 14.3zm22.7-6.2c5.1 2.4 12.3.7 17.6-4.7 6.4-6.5 7.6-15.9 2.7-20.9C56-.9 46.8.3 40.4 6.9c-5.3 5.4-7 12.8-4.6 18L3.2 58.2 7.9 63 31 39.5 54 63l4.7-4.8-23-23.5 4.9-5z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgEat;
