import React from 'react';
import {makeStyles} from '@material-ui/core';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import TopProgramListItem from './TopProgramListItem';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import Calendar from '../../../../calendar/Calendar';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        marginTop: '24px',
        marginBottom: '48px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
            marginBottom: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
            marginBottom: '0px',
        },
        display: 'flex',
    },
    layoutFlex: {
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row'
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '8px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '8px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '12px',
        },
    },
    headerItem: {
        '&:not(:first-child)': {
            marginLeft: '16px',
        }
    },
    titleCalendar: {
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '23px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '8px',
        },
    },
    headerLink: {
        '&.linkBig': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            },
        }
    },
    mainBlock: {
        width: '100%',
    },
    calendarBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingTop: '0px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '0px',
        },
    },
    footerLinkBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    footerLink: {
        margin: '24px 0px 32px 0px',
    },
    highlightedProgrammes: {},
    divider: {
        margin: '0px 45px 0px 30px',
        borderRight: '4px solid #F2F2F2',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
    },
    calendar: {
        backgroundColor: '#FFFFFF',
        width: '100%',
    },
}));

export default function TopProgramList(props) {
    const { topProgrammes } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <div className={clsx('padding', classes.layout, classes.layoutFlex)}>
            <div className={classes.mainBlock}>
                <div className={classes.header}>
                    <h2 className={classes.headerItem}>
                        {t('Text.HighlightedProgrammes')}
                    </h2>
                    <Link to={getPathWithLanguage(ROUTES_ANP.TOURS, i18n.language)}
                          className={clsx('linkBig', classes.headerLink, classes.headerItem)}>{t('Text.AllProgrammes')}</Link>
                </div>
                <div className={classes.highlightedProgrammes}>
                    {topProgrammes && topProgrammes.map(topProgram =>
                        <TopProgramListItem key={topProgram.id} topProgram={topProgram}/>
                    )}
                </div>
                <div className={classes.footerLinkBlock}>
                    <Link to={getPathWithLanguage(ROUTES_ANP.TOURS, i18n.language)}
                          className={clsx(classes.link, classes.footerLink, classes.headerItem)}>{t('Text.AllProgrammes')}</Link>
                </div>
            </div>
            <div className={classes.divider}/>
            <div className={classes.calendarBlock}>
                <div className={classes.header}>
                    <h2 className={classes.titleCalendar}>
                        {t('Text.Diary')}
                    </h2>
                </div>
                <div className={classes.calendar}>
                    <Calendar/>
                </div>
            </div>
        </div>
    );
}
