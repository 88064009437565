import React from 'react';
import {makeStyles} from '@material-ui/core';
import BadgeList from '../../../badge/lists/badgeList/BadgeList';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import clsx from 'clsx';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid #F2F2F2',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
    },
    header: (props) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        minHeight: '179px',
        '& h3': {
            color: 'white',
        },
        padding: '24px',
        [theme.breakpoints.up('sm')]: {
            minHeight: '190px',
            padding: '14px 24px 21px 24px'
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: '214px',
            padding: '16px 32px 24px 32px'
        },
        width: '100%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px 12px 0 0',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.article.headerImage}')`,
    }),
    badgeList: {
        position: 'absolute',
        top: 12,
        left: 12,
    },
    footer: {
        height: '100%',
        padding: '24px',
        [theme.breakpoints.up('lg')]: {
            padding: '32px'
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    summary: {
        '&.paragraph2': {
            color: '#5C5C66',
        },
        '& p': {
            padding: '0px',
            margin: '0px'
        }
    },
    linkBar: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '21px',
        },
    },
    link: {
        textDecoration: 'none',
        '&.linkSecondary': {
            fontSize: '14px',
        }
    },
}));

export default function ArticleListItem(props) {
    const { page, article } = props;

    const classes = useStyles(props);

    const { t, i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={classes.header}>
                <Link to={getPathWithLanguage(`${page.url}${ROUTES_ANP.CONTENT_ARTICLE(article.id)}`, i18n.language)}
                      className={classes.link}>
                    <h3>
                        {article.title}
                    </h3>
                </Link>
                <div className={classes.badgeList}>
                    <BadgeList badgeIds={article.badgeIds}/>
                </div>
            </div>
            <div className={classes.footer}>
                <div className={clsx('paragraph2', classes.summary)}
                     dangerouslySetInnerHTML={createMarkup(article.summary)}/>
                <div className={classes.linkBar}>
                    <Link
                        to={getPathWithLanguage(`${page.url}${ROUTES_ANP.CONTENT_ARTICLE(article.id)}`, i18n.language)}
                        className={'linkSecondary'}>
                        {t('Text.Read')}
                    </Link>
                </div>
            </div>
        </div>
    );
}
