import React, {useState} from 'react';
import {ClickAwayListener, makeStyles} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 10000,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
                filter: 'drop-shadow(0px -3px 2px rgba(23,23,26,0.1))',
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
                filter: 'drop-shadow(0px 6px 3px rgba(23,23,26,0.1))',
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        zIndex: 200,
        boxSizing: 'border-box',
        minHeight: '270px',
        minWidth: '300px',
        border: '1px solid #E6E6E6',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 5px 0 rgba(23,23,26,0.1), 0 2px 4px 0 rgba(23,23,26,0.1), 0 3px 20px 0 rgba(23,23,26,0.1)',
    },
    header: {
        borderBottom: '1px solid #E6E6E6',

        '& h5': {
            margin: '16px 24px 12px 24px',
        }
    },
    eventBar: {
        margin: '12px 24px 12px 24px',
        borderBottom: '1px solid #D9D9D9',
    },
    description: {
        padding: '16px 24px 12px 24px',
    },
}));

export default function CalendarPopup(props) {
    const { open, title, description, anchorEl, handleClose } = props;
    const classes = useStyles();
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement="top"
            disablePortal={false}
            className={classes.popper}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window',
                },
                arrow: {
                    enabled: true,
                    element: arrowRef,
                },
            }}
            transition
        >
            <ClickAwayListener onClickAway={handleClose}>
                <div>
                    <span className={classes.arrow} ref={setArrowRef}/>
                    <div className={classes.paper}>
                        <div className={classes.header}>
                            <h5>
                                {title}
                            </h5>
                        </div>
                        <div className={classes.description} dangerouslySetInnerHTML={createMarkup(description)}/>
                    </div>
                </div>
            </ClickAwayListener>
        </Popper>
    );
}
