import * as React from "react";

function SvgLatnivalokGyalogturakAFelszinen(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <circle cx={32} cy={32} r={27.5} fill="#e6e6e6"/>
            <path
                d="M58.2 40.2h-1.1c-.6 0-1.3.2-1.8.5L48 44.8c-.6.3-1.2.5-1.9.5H19.3c-.2 0-.5 0-.7.1-1.4.3-6.5 1.3-9.4 1.9 8.4 12.6 25.5 16 38.1 7.6 5.2-3.5 9.1-8.7 10.9-14.7z"
                fill="#b3b3b3"
            />
            <path
                d="M17.2 24.4v6.9c0 2.3-3 6.5-2.6 11s8.1 4.4 8.1 4.4h20.2c.6 0 4.7-3.3 6-4.1s0-5.6 0-5.6-3 .8-7.7-1.4-7.9-7.8-10-10.3-14-.9-14-.9z"
                fill="#f2b600"
            />
            <path
                d="M31.9 4.5l-.7 17.7-14 .7-1-13.5c4.1-2.9 8.9-4.6 13.9-4.9h1.8zM59 27c-.9-4.8-3-9.2-6.2-12.9-.8-.1-1.5-.2-2.3-.1-.9 0-1.7.3-2.4.8-1.8 1.4-1.6 4.2-1.2 6.5-.4-1.1-1.3-1.9-2.5-2.1-1.2-.1-2.3.5-2.7 1.6-.3.9 0 2 .7 2.6-.9-.7-2.2-.8-3.2-.4-.5.4-.8 1-.8 1.6 0 2.6 2.8 2.2 4.5 2.2 4.1.2 8.1.2 12.2.2H59zm-45.8 7.1c0 .2-.1.3-.2.4-.1.1-.3.2-.4.2-2.6.3-5.2.4-7.9.4-.1-1-.2-2.1-.2-3.1 0-1.4.1-2.8.3-4.2.5-.4 1.2-.6 1.8-.7.7-.2 1.4 0 1.9.4.3.3.4.6.5 1 .3 1 .3 2 .2 3 .7-1 2.1-1.2 3-.5 1 .8 1.3 2 1 3.1z"
                fill="#fff"
            />
            <path
                d="M26.9 26.9l-6 5.1c-.2.2-.5.3-.9.3h-3c-.7 0-1.3-.5-1.3-1.2V31l.3-3.8c0-.7.6-1.2 1.2-1.2H24c1.2 0 3.7.1 2.9.9zm14.3 8.6c-4.7-2.2-7.9-7.8-10-10.3-.4-.4-.9-.7-1.5-.9L27 26.8s2.8 1 3.5 3.1 1.5 6 4.3 7.5c2.5 1.1 5.2 1.9 7.9 2.3.4.1.7-.1.8-.5l.8-2.7c-1.2-.1-2.2-.5-3.1-1z"
                fill="#5c5c67"
            />
            <path
                d="M33 27.4H16.1c-.7 0-1.3-.6-1.3-1.3l.3-6.9c0-.8.6-1.4 1.4-1.4H32c.7 0 1.4.6 1.4 1.3l1 6.9c-.1.7-.7 1.4-1.4 1.4zm19.8 13.4l-.2-.3c.1-.3.1-.6.1-.9.1-1.1-.2-2.3-.9-3.2-.8-.8-2-1.1-3-.6-1.5.7-1.8 2.6-2.3 4.2-.5 1.9-1.5 3.8-3.2 4.5-1.1.4-2.3.5-3.5.4-5.4-.1-10.8-.1-16.1-.1-.4 0-.9 0-1.3-.1-.6-.3-1.1-.8-1.5-1.3-.6-.8-1.4-1.4-2.3-1.9-.6-.3-3.7-1.7-4.5-1.6-1.3.2-.3 5.6.3 6.4.2.4.5.7.9.9l.4.8c.1.2.2.3.4.3l1.5.1c.2 0 .4-.1.5-.2l.3-.4h.5l.2.5c.1.2.3.3.5.3l1.5.1c.2 0 .4-.1.5-.2l.3-.5h.5l.3.5c.1.2.3.3.5.3h1.6c.2 0 .4-.1.5-.3.1-.3.2-.5.3-.8l.2-.1c.8-.3 1.6-1 2.5-1 1.4 0 2.2.9 3.5 1.1l.5.6c.1.1.3.2.5.2l1.6-.1c.2 0 .4-.1.5-.2l.3-.4h.4l.3.4c.1.1.3.2.5.2H38c.2 0 .4 0 .5-.2l.4-.4h.3l.4.4c.1.1.3.2.5.2l1.6.1c.2 0 .4 0 .5-.1l.5-.5h.2l.5.5c.1.1.3.2.5.2.5 0 1.1 0 1.6-.1.2 0 .4-.1.5-.2.1-.2.2-.5.3-.7h.1l.7.5c.2.1.4.2.6.1.5-.2 1.1-.4 1.5-.7.2-.1.3-.3.3-.5s0-.5-.1-.7l.3-.2.7.2c.2 0 .4 0 .5-.2.4-.4.7-.9 1-1.3.1-.2.1-.4.1-.6-.1-.3-.2-.5-.3-.7 0 0 0-.1.1-.1.3 0 .5-.1.8-.1.2-.1.3-.2.4-.4.2-.5.3-1.1.3-1.6-.4-.2-.4-.4-.5-.5zm-17-9.3c.4-.2.6-.6.4-1-.2-.4-.6-.6-1-.4h-.1c-1.6.8-3.4 1.2-5.2 1.2h-.1c-.5-.5-1.3-.6-1.8-.1s-.6 1.3-.1 1.8 1.3.6 1.8.1c.1-.1.2-.2.2-.3h.3c2 0 3.9-.4 5.6-1.3zm3.2 2.8c.2-.4.1-.8-.2-1s-.8-.1-1 .2c-1.1 1.8-2.7 3.3-4.5 4.4-.7-.3-1.4 0-1.7.7-.2.4-.1.9.2 1.3.5.5 1.3.6 1.8.1.2-.2.4-.5.4-.8 2-1.2 3.7-2.8 5-4.9zm4.1 1.6c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8 0 1.5-.5 2.9-1.5 4-.4-.1-.8 0-1.1.3-.5.5-.6 1.3-.1 1.8s1.3.6 1.8.1c.3-.3.5-.7.4-1.1 1.4-1.4 2.1-3.2 2.1-5.1z"
                fill="#17171a"
            />
        </svg>
    );
}

export default SvgLatnivalokGyalogturakAFelszinen;
