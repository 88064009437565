import * as React from "react";

function SvgInfo(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M28.8 48h6.4V28.8h-6.4V48zM32 0C14.3 0 0 14.3 0 32s14.3 32 32 32 32-14.3 32-32S49.7 0 32 0zm0 57.6C17.9 57.6 6.4 46.1 6.4 32S17.9 6.4 32 6.4 57.6 17.9 57.6 32 46.1 57.6 32 57.6zm-3.2-35.2h6.4V16h-6.4v6.4z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgInfo;
