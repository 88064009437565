import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import DangerSummary from '../contents/danger/DangerSummary';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    layoutWithBackgroundImage: props => ({
        width: '100%',
        height: '162px',
        [theme.breakpoints.up('sm')]: {
            height: '250px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '24px',
            height: '262px'
        },
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.backgroundImage}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        position: 'relative',
    }),
    titleBar: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px',
        marginBottom: '24px',
        '& h1': {
            lineHeight: '38.4px',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
            marginBottom: '32px',
            '& h1': {
                lineHeight: '50.4px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '64px',
            marginBottom: '48px',
            '& h1': {
                lineHeight: '67.6px',
            }
        }
    },
    titleBarLeft: {
        display: 'flex',
        alignItems:
            'center',
    },
    titleRow: {
        position: 'absolute',
        left: '0px',
        bottom: '24px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            bottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            bottom: '64px',
        }
    },
    title: {
        color: theme.palette.common.white,
    },
    subTitle: {
        '&.paragraph3': {
            color: theme.palette.common.white
        },
    },
    icon: {
        width: '67px',
        height: '67px',
        marginRight: '24px',
    },
    divider: {
        height: '4px',
        borderRadius: '2px',
        backgroundColor:
            '#E6E6E6',
        /*
                marginBottom: '24px',
                [theme.breakpoints.up('lg')]: {
                    marginBottom: '32px',
                },
                [theme.breakpoints.up('lg')]: {
                    marginBottom: '48px',
                },
        */
    },
    dangerSummary: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '23px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
        }
    }
}));

export default function PageTitleBar(props) {
    const { title, subTitle, Icon, backgroundImage, divider, dangers = [] } = props;
    const classes = useStyles(props);

    return (
        <div className={clsx(backgroundImage ? classes.layoutWithBackgroundImage : classes.layout)}>
            <div className={clsx('padding', classes.titleBar)}>
                <div className={classes.titleBarLeft}>
                    {Icon && <Icon className={classes.icon}/>}
                    {backgroundImage &&
                    <div className={clsx('padding-left', classes.titleRow)}>
                        <h1 className={clsx(classes.title)}>{title}</h1>
                        {subTitle && <div className={clsx('paragraph3', classes.subTitle)}>{subTitle}</div>}
                    </div>
                    }
                    {!backgroundImage && <h1>{title}</h1>}
                </div>
                <div className={classes.titleBarRight}>
                    {dangers.length > 0 &&
                    <DangerSummary dangers={dangers} className={classes.dangerSummary}/>
                    }
                </div>
            </div>
            {divider &&
            <div className={classes.divider}/>
            }
        </div>
    )
}
