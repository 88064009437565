import React from 'react';
import {makeStyles} from '@material-ui/core';
import {MENU} from 'shared';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useSelector} from 'react-redux';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import clsx from 'clsx';
import ToursIconList from '../../../contents/tour/lists/toursIconList/ToursIconList';
import TourInfoMenuList from '../../../contents/tour/lists/tourInfoMenuList/TourInfoMenuList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-between',
            flexDirection: 'row',
        }
    },
    layoutColumn: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '& > *:last-child': {
            marginTop: '32px',
            marginBottom: '24px',
            [theme.breakpoints.up('lg')]: {
                marginTop: '38px',
                marginBottom: '0px',
            }
        }
    },
    title: {
        margin: '32px 0px 24px 0px',
    },
    layoutSideMenu: {
        '&.padding': {
            paddingTop: '24px',
            paddingBottom: '32px',
        },
        borderTop: '1px solid rgba(23,23,26, 0.1)',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '24px',
            paddingBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            '&.padding': {
                paddingTop: '0px',
                paddingBottom: '0px',
            },
            borderTop: '0px',
        }
    },
    tabletPadding: {
        paddingLeft: '32px',
        paddingRight: '32px',
    }
}));

export default function MobileMenuPopoverTours() {
    const classes = useStyles();

    /*
        const { t, i18n } = useTranslation();
    */

    const isTablet = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isMobile = !isTablet && !isDesktop;

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_MAIN, user);
    const tourMenu = menu.find(menuItem => menuItem.index === MENU.MAIN_MENU_ANP.TOUR);

    return (
        <div className={clsx(isDesktop && 'padding', classes.layout)}>
            <div className={clsx((isMobile && 'padding') || (isTablet && classes.tabletPadding), classes.layoutColumn)}>
                {!isDesktop &&
                    <h4 className={classes.title}>
                        {tourMenu.title}
                    </h4>
                }
                <ToursIconList isMenu={true}/>
                {/*
                <Link className={'linkSecondary'} to={getPathWithLanguage(ROUTES_ANP.TOUR_PACKAGES, i18n.language)}>
                    {t('Text.PackageOffer')}
                </Link>
*/}
            </div>
            <div
                className={clsx((isMobile && 'padding') || (isTablet && classes.tabletPadding), classes.layoutSideMenu)}>
                <TourInfoMenuList isMenu={true}/>
            </div>
        </div>
    );
}
