export default {
    '& p, & ol, & ul, & pre, & blockquote, & h1, & h2, & h3, & h4, & h5, & h6': {
        counterReset:
            'list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9',
    },
    '& ul': {
        margin: '0px',
        padding: '0px 15px 0px 35px',
        '@media (min-width:400px)': {
            padding: '0px 20px 0px 40px',
        },
        '@media (min-width:760px)': {
            padding: '0px 60px 0px 80px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px 0px 120px',
        },
    },
    '& ol': {
        margin: '0px',
        padding: '0px 15px 0px 35px',
        '@media (min-width:400px)': {
            padding: '0px 20px 0px 40px',
        },
        '@media (min-width:760px)': {
            padding: '0px 60px 0px 80px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px 0px 120px',
        },
    },
    '& ul li': {
        listStyleType: 'none',
        position: 'relative',
        paddingLeft: '0px !important'
    },
    '& li:not(:first-child)': {
        marginTop: '15px',
    },
    '& li::before': {
        color: '#96CC29',
        content: '"\\2022" !important',      /* Unicode for • character */
        position: 'absolute',
        left: '-20px !important',          /* Adjust this value so that it appears where you want. */
        top: '-1px !important',
        fontSize: 'unset !important',
        margin: '0px !important',
        textAlign: 'left !important',
        width: 'unset !important',
    },
    '& ol li': {
        counterReset: 'list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9',
        counterIncrement: 'list-0',
    },
    '& ol li:before': {
        content: "counter(list-0, decimal) '. '",
    },
    '& ol li.ql-indent-1': {
        counterIncrement: 'list-1',
        counterReset: 'list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9',
    },
    '& ol li.ql-indent-1:before': {
        content: "counter(list-1, lower-alpha) '. '",
    },
    '& ol li.ql-indent-2': {
        counterIncrement: 'list-2',
        counterReset: 'list-3 list-4 list-5 list-6 list-7 list-8 list-9',
    },
    '& ol li.ql-indent-2:before': {
        content: "counter(list-2, lower-roman) '. '",
    },
    '& ol li.ql-indent-3': {
        counterIncrement: 'list-3',
        counterReset: 'list-4 list-5 list-6 list-7 list-8 list-9',
    },
    '& ol li.ql-indent-3:before': {
        content: "counter(list-3, decimal) '. '",
    },
    '& ol li.ql-indent-4': {
        counterIncrement: 'list-4',
        counterReset: 'list-5 list-6 list-7 list-8 list-9',
    },
    '& ol li.ql-indent-4:before': {
        content: "counter(list-4, lower-alpha) '. '",
    },
    '& ol li.ql-indent-5': {
        counterIncrement: 'list-5',
        counterReset: 'list-6 list-7 list-8 list-9',
    },
    '& ol li.ql-indent-5:before': {
        content: "counter(list-5, lower-roman) '. '",
    },
    '& ol li.ql-indent-6': {
        counterIncrement: 'list-6',
        counterReset: 'list-7 list-8 list-9',
    },
    '& ol li.ql-indent-6:before': {
        content: "counter(list-6, decimal) '. '",
    },
    '& ol li.ql-indent-7': {
        counterIncrement: 'list-7',
        counterReset: 'list-8 list-9',
    },
    '& ol li.ql-indent-7:before': {
        content: "counter(list-7, lower-alpha) '. '",
    },
    '& ol li.ql-indent-8': {
        counterIncrement: 'list-8',
        counterReset: 'list-9',
    },
    '& ol li.ql-indent-8:before': {
        content: "counter(list-8, lower-roman) '. '",
    },
    '& ol li.ql-indent-9': {
        counterIncrement: 'list-9',
    },
    '& ol li.ql-indent-9:before': {
        content: "counter(list-9, decimal) '. '",
    },
    '& .ql-indent-1, & li.ql-indent-1': {
        paddingLeft: '20px',
        '@media (min-width:400px)': {
            paddingLeft: '30px',
        },
        '@media (min-width:760px)': {
            paddingLeft: '90px',
        },
        '@media (min-width:1280px)': {
            paddingLeft: '140px',
        },
    },
    '& .ql-indent-2, & li.ql-indent-2': {
        paddingLeft: '25px',
        '@media (min-width:400px)': {
            paddingLeft: '40px',
        },
        '@media (min-width:760px)': {
            paddingLeft: '120px',
        },
        '@media (min-width:1280px)': {
            paddingLeft: '180px',
        },
    },
    '& a': {
        color: '#0F6099',
        display: 'inline-block',
        position: 'relative',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '18px',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        '@media (min-width:1280px)': {
            fontSize: '14px',
            lineHeight: '20px',
        },
        '&:before': {
            height: '2px',
            backgroundColor: '#1A9FFF',
            borderRadius: '1px',
            content: '""',
            position: 'absolute',
            bottom: '-1px',
            width: '100%',
        },
        '&:hover': {
            '&:before': {
                backgroundColor: '#F2A200',
            }
        },
    },
};
