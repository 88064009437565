import * as React from "react";

function SvgLatnivalokBarlangiTurak(props) {
    return (
        <svg
            id="latnivalok-barlangi_turak_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>{".latnivalok-barlangi_turak_svg__st2{fill:#17171a}"}</style>
            <g id="latnivalok-barlangi_turak_svg__Layer_4">
                <g id="latnivalok-barlangi_turak_svg__latnivalok-barlangi_turak">
                    <circle
                        id="latnivalok-barlangi_turak_svg__oval"
                        cx={32}
                        cy={32.1}
                        r={27.5}
                        fill="#f2f2f2"
                    />
                    <path
                        id="latnivalok-barlangi_turak_svg__light"
                        d="M50.7 52.3C40 62.2 23.5 62 13 52l18.6-10.1 19.1 10.4zM30.2 4.7c-7.9.5-15.2 4.4-20 10.7L31.6 27l22-12C47.9 7.9 39.2 4.1 30.2 4.7z"
                        fill="#ccc"
                    />
                    <g id="latnivalok-barlangi_turak_svg__lamp">
                        <g id="latnivalok-barlangi_turak_svg__bottom">
                            <path
                                className="latnivalok-barlangi_turak_svg__st2"
                                d="M38.8 48c-.2-1.7-.3-3.7-2.1-4.5-1.5-.5-3.1-.8-4.7-.7-1.6-.1-3.2.2-4.7.7-1.8.8-1.9 2.8-2.1 4.5-.3 1.3-.1 2.6.5 3.8.1.2.3.3.4.5.9.9 3.1 1.6 5.8 1.6h.2c2.6 0 4.9-.7 5.8-1.6.2-.1.3-.3.4-.5.6-1.2.8-2.5.5-3.8z"
                            />
                            <ellipse cx={31.9} cy={45.2} rx={6.1} ry={2.5} fill="#2e2e33"/>
                            <path
                                className="latnivalok-barlangi_turak_svg__st2"
                                d="M27.8 40.5l.9 3.6c0 .6 1.4 1.1 3.1 1.1 1.7 0 3.1-.5 3.1-1.1l1.2-3.6h-8.3z"
                            />
                        </g>
                        <g id="latnivalok-barlangi_turak_svg__middle">
                            <linearGradient
                                id="latnivalok-barlangi_turak_svg__SVGID_1_"
                                gradientUnits="userSpaceOnUse"
                                x1={31.826}
                                y1={23.22}
                                x2={31.826}
                                y2={41.73}
                                gradientTransform="matrix(1 0 0 -1 0 66)"
                            >
                                <stop offset={0} stopColor="#fff"/>
                                <stop offset={0} stopColor="#fffefc"/>
                                <stop offset={0.03} stopColor="#fcf4c8"/>
                                <stop offset={0.06} stopColor="#faea99"/>
                                <stop offset={0.1} stopColor="#f8e170"/>
                                <stop offset={0.13} stopColor="#f6da4d"/>
                                <stop offset={0.17} stopColor="#f4d431"/>
                                <stop offset={0.21} stopColor="#f3d01b"/>
                                <stop offset={0.26} stopColor="#f3cc0c"/>
                                <stop offset={0.31} stopColor="#f2cb03"/>
                                <stop offset={0.38} stopColor="#f2ca00"/>
                                <stop offset={1} stopColor="#f3a200"/>
                            </linearGradient>
                            <path
                                d="M37.1 25.3c-.5-.7-1.3-1.1-2.1-1.1h-6.4c-.8 0-1.6.4-2.1 1.1-1.5 2.2-2.1 4.9-1.8 7.5.5 5.9 1 7.9 2.2 8.8 1.3.6 2.6 1 4 1.1.6.1 1.1.1 1.7 0 1.4-.1 2.7-.5 4-1.1 1.2-.8 1.7-2.9 2.2-8.8.4-2.6-.2-5.2-1.7-7.5z"
                                fill="url(#latnivalok-barlangi_turak_svg__SVGID_1_)"
                            />
                            <ellipse
                                cx={32}
                                cy={40.5}
                                rx={4.2}
                                ry={1.5}
                                opacity={0.46}
                                fill="#fff"
                            />
                            <path
                                d="M31.9 38.3c.5 0 1 .4 1 1v1.1c0 .5-.4 1-1 1-.5 0-1-.4-1-1v-1.1c0-.6.5-1 1-1z"
                                fill="#f3a200"
                            />
                        </g>
                        <path
                            id="latnivalok-barlangi_turak_svg__cross"
                            d="M38.6 38.4c-2-.9-3.9-2-5.6-3.4 2.6-2.4 4.7-5.3 6-8.5.1-.4-.1-.8-.5-1s-.8.1-1 .5c-1.3 3.1-3.2 5.8-5.7 8-2.5-2.2-4.4-4.9-5.7-8-.1-.4-.6-.6-1-.5-.4.1-.6.6-.5.9v.1c1.3 3.3 3.4 6.2 6 8.5-1.7 1.4-3.6 2.5-5.6 3.4-.4.2-.6.6-.4 1 .1.3.4.5.7.5.1 0 .2 0 .3-.1 2.2-1 4.3-2.2 6.1-3.7 1.9 1.5 3.9 2.7 6.1 3.7.1 0 .2.1.3.1.4 0 .8-.3.8-.7.2-.4 0-.6-.3-.8z"
                            fill="#5c5c67"
                        />
                        <path
                            id="latnivalok-barlangi_turak_svg__top"
                            className="latnivalok-barlangi_turak_svg__st2"
                            d="M42.6 28.6l-.2-1c-.2-1.6-1.1-3.1-2.5-4-.8-.4-1.6-.5-2.5-.4v-7c0-2.1-1.7-3.8-3.8-3.8h-3.4c-2.1 0-3.8 1.7-3.8 3.8v7.1c-.9-.3-1.9-.2-2.8.3-1.4.9-2.3 2.4-2.5 4l-.2 1C20 33 19 38.4 21 42.3c1.1 2 3.3 2.7 5.6 2.7.7 0 1.3-.1 2-.2.4 0 .7-.4.7-.8s-.4-.7-.8-.7h-.1c-2.1.4-5 .1-6-1.8-1.8-3.4-.8-8.6 0-12.7l.2-1c.1-1.2.8-2.3 1.7-3 .6-.3 1.3-.3 1.9-.1h.1c0 .3.2.5.4.7.4.3.9.5 1.5.6 1.1.3 2.3.4 3.5.4 1.2 0 2.3-.1 3.5-.4.5-.1 1-.3 1.5-.6.2-.2.4-.4.4-.7.6-.3 1.3-.2 1.9.1 1 .7 1.6 1.8 1.7 3l.2 1c.8 4.1 1.8 9.3 0 12.7-1 1.9-3.8 2.1-6 1.8-.4-.1-.8.1-.9.5-.1.4.1.8.5.9h.1c.7.1 1.3.2 2 .2 2.2 0 4.5-.7 5.6-2.7 2.3-3.8 1.2-9.2.4-13.6zm-10.7-6.7h-1.1c.1-.4.2-.7.5-1 .2-.2.5-.3.8-.3.3 0 .6.1.9.4.2.3.3.6.4.9h-1.5zm4 .9c-.3-.3-.7-.4-1.2-.5-.1-.8-.3-1.5-.8-2.1-.5-.6-1.2-.9-1.9-.9-.7-.1-1.4.2-1.9.7-.6.6-.9 1.5-.9 2.3-.4.1-.8.2-1.1.4v-6.4c0-1.2 1-2.3 2.3-2.3h3.4c1.2 0 2.3 1 2.3 2.3l-.2 6.5z"
                        />
                    </g>
                    <path
                        id="latnivalok-barlangi_turak_svg__gray"
                        d="M45.6 52.8c.1-3.2-.5-6.5.1-9.5.2-1.1 1.3-1.9 2.5-1.6.6.1 1.1.5 1.4 1 .5 1.2.9 2.4 1.1 3.6 0 .2.1.4.2.6.4.3 1 .3 1.3-.1.3-.4.4-.9.5-1.4.4-2.5 1.3-5 2.6-7.2.2-.5.7-.8 1.2-1 .6-.1 1.2.2 1.5.8.2.5.4 1 .5 1.6-2.7 9.7-10.4 17.2-20.2 19.5l.6-1.5 1.5-4.5c.3-1 1.1-2.3 2.2-1.9.7.3.9 1.2 1.2 1.9s1.4 1.3 1.8.6v-.9zM32 4.6c-12.6 0-23.7 8.6-26.7 20.9.5.5.9 1.2 1.1 1.9.5 1.5 1 2.9 1.6 4.4.1.3.3.5.6.6.3 0 .5-.1.7-.3.9-.9 1-2.8 1.1-3.9.1-1.4.1-2.9 0-4.3-.1-2.8-.2-5.7 1.2-8.2.3-.6.9-1.2 1.6-1 1.2.4.7 2.3 1.3 3 .5-1.2.8-2.5 1-3.7 0-.5.3-.9.7-1.2.8-.4 1.5.6 1.8 1.4.4 1.6.6 3.1.8 4.8.1.8.3 1.6.6 2.4.2.6.6 1.2 1 1.7 1-.6 1.1-2.1 1.1-3.2-.1-1.5 0-2.9.1-4.4.2-1.6.6-3.3 1.1-4.8.4-1.2 1-3.2 2.1-3.9.1-.1.3-.2.4-.2.4 0 .7.4.8.8.3 1.2.5 1.4.7 2.6.1.7.3 1.4.5 2.1.1.2.2.5.5.4.1 0 .2-.1.3-.2 1-1 1.6-5 3.7-3 .4.4.6.8 1 1.1.9.9 1.5.3 2.5-.1 1-.3 2 0 2.6.9.6.8.9 1.7 1.1 2.7.3 1.5.7 5.9 1.5 6.6 1.9 1.9 2.3-3 2.3-3.9 0-1.4.6-2.4 3-1 1.5.9 1.2 3.2 1.2 4.7 0 2.4.7 12.6 1.8 13 1.5.6 2.7-5.3 3-7 0-.5.2-.9.4-1.3 1-1.2 2.4 0 2.7 1.1.2.9 0 1.8.1 2.8 0 .5.3 1 .8 1.3.6.2 1.2-.1 1.4-.6.3-.5.4-1.1.4-1.7.2-1.5.5-3 .8-4.4C54.5 12.3 43.9 4.6 32 4.6zm-6.8 52.8c0-1.1-.4-2.1-.9-3.1-.6-.9-1.8-1.3-2.8-.8-.8.5-1.2 1.7-2.1 1.7-.5-.1-.9-.5-1-1-.1-.5-.1-1.1 0-1.6.2-1.8 1-5.9-.5-5.7s-.5 1.5-1.6 1.5c-.5 0-1.2-.8-1.4-1.2-.4-.8-.5-1.6-.4-2.5.1-.7.1-1.3 0-2s-.7-1.2-1.4-1.2c-.7.1-1.2.8-1.5 1.5-.8 1.8-1.3 3.8-1.6 5.8 3.8 5 9.2 8.5 15.3 10 0-.5-.1-1-.1-1.4z"
                        fill="#b3b3b3"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgLatnivalokBarlangiTurak;
