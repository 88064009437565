import React, {useEffect, useState} from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import _ from 'lodash';
import {faqActions} from 'client_shared/src/state/actions/faqActions';
import {useDispatch, useSelector} from 'react-redux';
import FaqCategoriesBar from '../components/contents/faq/FaqCategoriesBar';
import {useParams} from 'react-router';
import {faqModule} from 'shared/src/modules/faq/faqModule';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {FAQ_TOP_CATEGORY_ID} from 'shared/src/constants/faq';

function Faq() {
    const [breadCrumbTrailTail, setBreadCrumbTrailTail] = useState([]);
    const faqCategories = useSelector(state => state.faq.faqCategories);
    const faqCategory = useSelector(state => state.faq.faqCategory);
    const { id } = useParams();
    const categoryId = id ? parseInt(id, 10) : null;
    const dispatch = useDispatch();

    useEffect(() => {
        if (faqCategories.length === 0) {
            return;
        }

        if (id) {
            const faqCategory = faqModule.getFaqCategoryById(faqCategories, categoryId);
            dispatch(faqActions.setFaqCategory(faqCategory));
            return;
        }

        const faqTopCategory = faqModule.getTopFaqCategory(faqCategories);
        dispatch(faqActions.setFaqCategory(faqTopCategory));

    }, [dispatch, id, faqCategories, categoryId]);

    useEffect(() => {
        if (faqCategories.length === 0) {
            return;
        }

        let breadCrumbTrailTail = [];

        if (faqCategory && !faqModule.isTopFaqCategory(faqCategory)) {
            breadCrumbTrailTail = [{ title: faqCategory.title, url: ROUTES_ANP.FAQ(faqCategory.id) }]
        }

        setBreadCrumbTrailTail(breadCrumbTrailTail);
    }, [faqCategory, faqCategories]);


    return (
        <ContentScreen breadCrumbsTrailTail={breadCrumbTrailTail}>
            <FaqCategoriesBar/>
        </ContentScreen>
    );
}

Faq.initState = (environment) => {
    const { dispatch, storeState, params } = environment;

    const promises = [];

    if (_.isEmpty(storeState.faq.faqCategories)) {
        promises.push(dispatch(faqActions.loadFaqCategories()));
    }

    const faqCategoryId = params.id || FAQ_TOP_CATEGORY_ID;

    promises.push(dispatch(faqActions.loadFaqsForCategory(faqCategoryId)));

    return promises;
};

export default Faq;
