import React, {useEffect, useState} from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import {useSelector} from 'react-redux';
import {ROUTES_ANP} from 'shared';
import {accommodationActions} from 'client_shared/src/state/actions/accommodationActions';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import AccommodationContactBar from '../components/contents/accommodations/AccommodationContactBar';
import AccommodationServiceBar from '../components/contents/accommodations/AccommodationServiceBar';
import _ from 'lodash';
import ArticleContent from '../components/contents/article/ArticleContent';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '32px',
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
            marginBottom: '64px',
        },
    },
    subTitle: {
        '&.paragraph1': {
            fontWeight: 'bold',
        },
        marginTop: '16px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '24px'
        },
        '& p': {
            margin: '0px',
        }
    },
    body: {
        marginTop: '32px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        },
    },
    image: (props) => ({
        width: '100%',
        height: '211px',
        [theme.breakpoints.up('sm')]: {
            height: '394px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '502px',
        },
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px',
        backgroundImage: `url('/content/images/${props.accommodation && props.accommodation.headerImage}')`,
    }),
    bodyLayout: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        },
    },
    bodyLayoutContent: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        },
        '& .contentAnp .ql-container p': {
            marginTop: '14px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '14px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '16px',
            },
        }
    },
    leftLayout: {
        flex: 1,
    },
    rightLayout: {
        marginLeft: '29px',
        width: '277px',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        }
    },
    leftLayoutContactWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '22px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginTop: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    contactBar: {},
    serviceBar: {
        marginTop: '12px',
        marginLeft: '0px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
            marginLeft: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '16px',
            marginLeft: '0px',
        }
    },
}));

function Accommodation() {
    const [breadCrumbTrailTail, setBreadCrumbTrailTail] = useState([]);
    const accommodation = useSelector(state => state.accommodation.accommodation);
    const classes = useStyles({ accommodation });

    useEffect(() => {
        if (!accommodation) {
            return;
        }

        const breadCrumbTrailTail = [
            {
                title: accommodation.title,
                url: ROUTES_ANP.ACCOMMODATION(accommodation.id)
            }];

        setBreadCrumbTrailTail(breadCrumbTrailTail);
    }, [accommodation]);

    return (
        <ContentScreen showTitleBar={false} breadCrumbsTrailTail={breadCrumbTrailTail}
                       includePageInBreadCrumbTail={false}>
            {accommodation &&
            <div className={clsx('padding', classes.layout)}>
                <h1>
                    {accommodation.title}
                </h1>
                <div className={clsx('paragraph1', classes.subTitle)} dangerouslySetInnerHTML={createMarkup(accommodation.description)} />
                <div className={classes.bodyLayout}>
                    <div className={classes.leftLayout}>
                        <div className={classes.image}/>
                        <div className={classes.leftLayoutContactWrapper}>
                            <AccommodationContactBar accommodation={accommodation} className={classes.contactBar}/>
                            <AccommodationServiceBar accommodation={accommodation} className={classes.serviceBar}/>
                        </div>
                        <div className={classes.bodyLayoutContent}>
                            <ArticleContent content={accommodation.body} narrow={true}/>
                        </div>
                    </div>
                    <div className={classes.rightLayout}>
                        <AccommodationContactBar accommodation={accommodation} className={classes.contactBar}/>
                        <AccommodationServiceBar accommodation={accommodation} className={classes.serviceBar}/>
                    </div>
                </div>
            </div>
            }

        </ContentScreen>
    );
}

Accommodation.initState = (environment) => {
    const { dispatch, storeState, params } = environment;

    const promises = [];

    promises.push(dispatch(accommodationActions.loadAccommodation(params.id)));

    if (_.isEmpty(storeState.accommodation.accommodationCategories)) {
        promises.push(dispatch(accommodationActions.loadAccommodationCategories()));
    }

    if (_.isEmpty(storeState.accommodation.accommodationServices)) {
        promises.push(dispatch(accommodationActions.loadAccommodationServices()));
    }


    return promises;
};

export default Accommodation;
