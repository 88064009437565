import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / apartment-email"}</title>
            <g fill="none" fillRule="nonzero">
                <path
                    d="M33.596 25.513c.32-.867.48-1.98.48-3.338 0-1.546-.365-2.937-1.095-4.172-.73-1.235-1.829-2.211-3.298-2.928C28.213 14.358 26.429 14 24.33 14c-1.959 0-3.713.405-5.262 1.216-1.55.811-2.769 1.99-3.658 3.536-.89 1.547-1.334 3.386-1.334 5.517 0 1.961.41 3.673 1.229 5.134.82 1.462 1.999 2.593 3.538 3.395C20.383 33.599 22.201 34 24.3 34c6.383 0 8.976-7.619 9.296-8.487z"
                    fill="#F2CA00"
                />
                <path
                    d="M24.397 8c3.274 0 6.089.615 8.444 1.845 2.355 1.23 4.137 2.88 5.346 4.946C39.396 16.858 40 19.134 40 21.62c0 2.24-.353 4.134-1.058 5.684-.705 1.55-1.643 2.707-2.814 3.47-1.172.763-2.475 1.144-3.91 1.144-1.336 0-2.406-.283-3.212-.849-.806-.566-1.285-1.341-1.436-2.325h-.075c-1.41 2.116-3.262 3.174-5.554 3.174-1.788 0-3.205-.597-4.25-1.79-1.046-1.194-1.568-2.799-1.568-4.817 0-1.722.334-3.31 1.001-4.761.667-1.452 1.612-2.602 2.833-3.451 1.222-.849 2.601-1.273 4.137-1.273 1.083 0 2.034.233 2.853.701.818.467 1.379 1.107 1.681 1.92h.076l.415-2.252h3.476l-1.662 9.153a8.274 8.274 0 00-.151 1.587c0 .763.163 1.329.49 1.698.328.37.857.554 1.588.554.579 0 1.158-.253 1.737-.757.58-.504 1.07-1.322 1.474-2.454.403-1.132.604-2.584.604-4.356 0-2.017-.46-3.832-1.379-5.444-.919-1.611-2.304-2.885-4.156-3.82-1.85-.935-4.099-1.402-6.743-1.402-2.469 0-4.679.529-6.63 1.587-1.953 1.058-3.49 2.596-4.61 4.613-1.12 2.018-1.681 4.417-1.681 7.198 0 2.559.516 4.792 1.549 6.699 1.032 1.907 2.518 3.383 4.458 4.429 1.94 1.045 4.231 1.568 6.876 1.568 1.965 0 3.652-.246 5.062-.738 1.411-.492 2.469-1.181 3.174-2.067h4.534c-1.008 1.747-2.626 3.144-4.855 4.19C30.044 39.476 27.407 40 24.359 40c-3.3 0-6.183-.658-8.652-1.975-2.468-1.316-4.37-3.155-5.705-5.518C8.667 30.145 8 27.427 8 24.351c0-3.347.712-6.25 2.135-8.71 1.423-2.461 3.375-4.35 5.856-5.666C18.47 8.658 21.273 8 24.397 8zm-.56 10c-.995 0-1.861.339-2.6 1.016-.74.677-1.298 1.546-1.673 2.606A9.852 9.852 0 0019 24.937c0 1.252.273 2.242.818 2.97.546.729 1.255 1.093 2.127 1.093 1.043 0 1.946-.345 2.71-1.035.763-.69 1.345-1.584 1.745-2.683.4-1.098.6-2.248.6-3.45 0-1.251-.279-2.203-.836-2.855-.558-.651-1.334-.977-2.328-.977z"
                    fill="#17171A"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
