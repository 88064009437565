import * as React from "react";

function SvgLatnivalokRutintIgenyloBarlangturak(props) {
    return (
        <svg
            id="latnivalok-rutint_igenylo_barlangturak_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".latnivalok-rutint_igenylo_barlangturak_svg__st2{fill:#17171a}.latnivalok-rutint_igenylo_barlangturak_svg__st3{fill:#5c5c67}"
                }
            </style>
            <g id="latnivalok-rutint_igenylo_barlangturak_svg__Layer_4">
                <g id="latnivalok-rutint_igenylo_barlangturak_svg__latnivalok-rutint_igenylo_barlangturak">
                    <circle
                        id="latnivalok-rutint_igenylo_barlangturak_svg__oval"
                        cx={32}
                        cy={32.2}
                        r={27.5}
                        fill="#ccc"
                    />
                    <path
                        id="latnivalok-rutint_igenylo_barlangturak_svg__light"
                        d="M59.5 32.2c0-7.3-2.9-14.4-8.2-19.5-7.2 6.2-17 14.7-17.2 14.9l1.7 8.5 21.7 6.5c1.3-3.4 2-6.9 2-10.4z"
                        fill="#f2f2f2"
                    />
                    <g id="latnivalok-rutint_igenylo_barlangturak_svg__helmet">
                        <g id="latnivalok-rutint_igenylo_barlangturak_svg__bottom">
                            <path
                                className="latnivalok-rutint_igenylo_barlangturak_svg__st2"
                                d="M31.5 54.3c-2 0-3.9-.7-5.5-2-3.9-3.5-4.4-10.1-4.4-10.4l3-.2c0 .1.4 5.7 3.4 8.3 1.1.9 2.5 1.4 3.9 1.3 6.2-.4 5.6-11.1 5.6-11.2l3-.2c0 .6.7 13.8-8.4 14.4h-.6z"
                            />
                            <path
                                className="latnivalok-rutint_igenylo_barlangturak_svg__st3"
                                d="M26 52.2c1.5 1.3 3.4 2 5.5 2h.7c.7 0 1.4-.2 2-.4-2.7-.4-5.1-1.9-6.5-4.3-2.6-2.8-3-7.9-3-7.9l-3 .2c-.1.3.4 7 4.3 10.4z"
                            />
                            <path
                                d="M27.1 38.6c.1 2.3-1.7 4.3-4 4.5-2.3-.1-4.1-2.1-4-4.5-.1-2.3 1.7-4.3 4-4.5 2.4.2 4.2 2.2 4 4.5zM39 32.3c-2.3.1-4.1 2.1-4 4.5-.1 2.3 1.7 4.3 4 4.5 2.3-.1 4.1-2.1 4-4.5.1-2.4-1.7-4.4-4-4.5z"
                                fill="#f3a200"
                            />
                        </g>
                        <path
                            d="M46.7 28.4c-.2-3.6-.9-7-3.3-10.1-2.8-3.7-7.1-5.9-11.8-5.9-4.6.1-8.8 2.2-11.6 5.8-3.8 4.9-3.5 11.2-3.5 17 4.6 3 10 4.5 15.4 4.4 8.6-.4 14.8-6.1 14.8-6.1.1-1.8.1-3.5 0-5.1z"
                            fill="#f2b600"
                        />
                        <path
                            className="latnivalok-rutint_igenylo_barlangturak_svg__st3"
                            d="M46.7 28.4c-2.3.8-4.7 1.4-7.2 1.9v4.1c2.5-.4 4.9-1 7.3-1.8 0-1.4-.1-2.8-.1-4.2z"
                        />
                        <path
                            className="latnivalok-rutint_igenylo_barlangturak_svg__st2"
                            d="M36.3 34.9c1.1-.2 2.2-.3 3.2-.5v-4.1c-1.2.2-2.4.4-3.8.6-6.4.9-13 .3-19.1-1.9-.1 1.4-.1 2.8-.1 4.2 4.3 1.4 8.8 2.1 13.3 2.1 2.2.1 4.3-.1 6.5-.4z"
                        />
                        <g id="latnivalok-rutint_igenylo_barlangturak_svg__lamp">
                            <path
                                id="latnivalok-rutint_igenylo_barlangturak_svg__light-2"
                                d="M35.8 36l7 2.1c.1-.4.2-.9.2-1.3 0-.2 0-.5-.1-.7 1.3-.8 2.6-1.7 3.8-2.7 0-1.7 0-3.4-.1-5 0-3.3-1-6.6-2.7-9.4-5.1 4.4-9.6 8.3-9.8 8.5l1.7 8.5z"
                                opacity={0.4}
                                fill="#fff"
                            />
                            <g id="latnivalok-rutint_igenylo_barlangturak_svg__lamp-2">
                                <ellipse
                                    className="latnivalok-rutint_igenylo_barlangturak_svg__st3"
                                    cx={36.5}
                                    cy={31.8}
                                    rx={5.7}
                                    ry={5.9}
                                />
                                <ellipse cx={37.5} cy={31.4} rx={4.9} ry={5.4} fill="#fff3ca"/>
                                <circle cx={38.3} cy={31.4} r={1.6} fill="#f2ca00"/>
                            </g>
                        </g>
                        <path
                            className="latnivalok-rutint_igenylo_barlangturak_svg__st3"
                            d="M21.4 27.3c0 .7-.4 1.3-.9 1.3s-.9-.6-.9-1.3.4-1.3.9-1.3.9.5.9 1.3zm3-1.3c-.5 0-.9.6-.9 1.3s.4 1.3.9 1.3.9-.6.9-1.3-.4-1.3-.9-1.3zm-2-4c-.5 0-.9.6-.9 1.3s.4 1.3.9 1.3.9-.6.9-1.3-.4-1.3-.9-1.3z"
                        />
                    </g>
                    <path
                        id="latnivalok-rutint_igenylo_barlangturak_svg__gray"
                        d="M41.2 11.6c-.9 0-1.4-1.1-1.9-1.9-.6-1.1-1.5-2-2.7-2.4-1.2-.4-2.5-.1-3.4.9-.3.4-.6 1-1.1 1.3-.7.3-1.5.1-2.1-.5-.5-.6-1.2-1.1-1.9-1.6-1.3-.5-2.8-.1-3.8 1-.9 1-1.7 2.1-2.4 3.3-.3.4-.9.9-1.3.5s-.1-.7-.2-1.1c-.2-.6-.9-.9-1.5-.7-.1 0-.1 0-.2.1-.6.4-1 .9-1.2 1.6-1.4 2.9-2.6 5.9-3.4 9-.1.5-.4 1-.9 1.1-.3 0-.6-.2-.7-.4-.8-1-.6-2.4-.6-3.7s-.5-2.7-1.7-2.9c9.3-12 26.6-14.1 38.6-4.8.2.2.5.4.7.6 0 .1-.1.1-.2.2-.5.6-1.4.9-2.2.6-1.2-.5-1.7-2.6-3-2.5s-1.6 2.3-2.9 2.3zM25 58.8C23.8 57 22.9 55 22.3 53c-.7-.3-1.3.5-2.1.5-1.3.2-1.9-1.5-2-2.8-.2-2.1-.5-4.2-.7-6.2-.5-.3-1.1.1-1.4.5-.2.5-.6.9-.9 1.3-.9.7-2.2.1-2.8-.9-.4-1-.6-2.1-.6-3.2-.1-1.2-.2-2.5-.5-3.7-.2-.8-.5-1.6-1.3-1.9-1.1-.3-1.9.9-2.3 1.9-.5 1.4-.9 2.9-1 4.3 3.4 8 10.1 13.8 18.3 16zm30.3-13.7c-1.3-1-3.3-.3-4.3 1-.8 1.5-1.2 3.1-1.1 4.8-.9-.5-2.1-1-2.7-.2-.4.5-.3 1.4-.8 1.9-1 0-1.2-1.3-1.3-2.3s-.7-2.3-1.6-1.9c-.4.2-.7.6-.8 1.1-.6 1.5-1 3.1-1.3 4.7-.2 1.4-.6 2.9-1.1 4.2 6.6-2.1 12.1-6.6 15.5-12.5-.1-.4-.3-.6-.5-.8z"
                        fill="#b3b3b3"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgLatnivalokRutintIgenyloBarlangturak;
