import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import EducationIconMenuListItem from './EducationIconMenuListItem';
import EducationTrailsIcon from '../../../../icons/multicolor/KornyezetiNevelesTanosvenyekIcon';
import ClassTripsIcon from '../../../../icons/multicolor/KornyezetiNevelesOsztalykirandulasokIcon';
import ForestCampsIcon from '../../../../icons/multicolor/KornyezetiNevelesErdeiTaborokIcon';
import {ROUTES_ANP} from 'shared';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layoutContentOnPage: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-30px',
        marginTop: '-30px',
        '& > *': {
            marginLeft: '30px',
            marginTop: '30px',
        },

        '& > *:not(:first-child)': {
            marginLeft: '20px',
        },
    },
    layoutContentInMenu: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '-12px',
        '& > *': {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            width: 'unset',
            marginLeft: '-20px',
            '& > *': {
                marginLeft: '20px',
            },
            flexDirection: 'row',
        }
    },
}));

export default function EducationIconMenuList(props) {
    const { isMenu } = props;
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <div className={clsx(isMenu ? classes.layoutContentInMenu : classes.layoutContentOnPage)}>
            <EducationIconMenuListItem Icon={EducationTrailsIcon} title={t('Text.EducationTrails')}
                                       summary={t('Text.LoremIpsum')}
                                       url={getPathWithLanguage(ROUTES_ANP.NATURE_TRAILS, i18n.language)}
                                       isMenu={isMenu}/>
            <EducationIconMenuListItem Icon={ClassTripsIcon} title={t('Text.ClassTrips')} summary={t('Text.LoremIpsum')}
                                       url={getPathWithLanguage(ROUTES_ANP.CLASS_TRIPS, i18n.language)}
                                       isMenu={isMenu}/>
            <EducationIconMenuListItem Icon={ForestCampsIcon} title={t('Text.ForestCamps')}
                                       summary={t('Text.LoremIpsum')}
                                       url={getPathWithLanguage(ROUTES_ANP.FOREST_CAMPS, i18n.language)}
                                       isMenu={isMenu}/>
        </div>

    );
}
