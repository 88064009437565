import React, {useEffect, useState} from 'react';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import {faqModule} from 'shared/src/modules/faq/faqModule';
import {useHistory} from 'react-router';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useTranslation} from 'react-i18next';
import ChevronRightIcon from '../../../../icons/mono/ChevronRightIcon';
import ChevronLeftIcon from '../../../../icons/mono/ChevronLeftIcon';
import ChevronUpIcon from '../../../../icons/mono/ChevronUpIcon';
import ChevronDownIcon from '../../../../icons/mono/ChevronDownIcon';
import {faqActions} from 'client_shared/src/state/actions/faqActions';

const useStyles = makeStyles(theme => ({
    layout: {
        borderRadius: '12px',
        backgroundColor: '#F2F2F2',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '277px',
        }
    },
    listLayout: props => ({
        width: '100%',
        margin: 0,
        padding: 0,
        '& > *': {
            padding: props.isLeafList ? '8px 0px' : '16px 0px',
            margin: '0px 16px',
            [theme.breakpoints.up('sm')]: {
                margin: '0px 24px',
            }
        },
        '& > *:first-child': {
            [theme.breakpoints.up('lg')]: {
                paddingTop: '0px',
            }
        },
        '& > *:not(:last-child)': {
            borderBottom: props.isLeafList ? '0px' : '2px solid #E6E6E6',
        },
        '& > *:last-child': {
            borderBottom: props.isLeafList ? '2px solid #E6E6E6' : '0px',
            padding: props.isLeafList ? '8px 0px 32px 0px' : '16px 0px',
        }
    }),
    category: {
        display: "block",
        flex: "0 1 auto",
        listStyleType: "none",
    },
    menuItemLink: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    categoryItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        paddingBottom: '16px',
        margin: '0px 16px',
        [theme.breakpoints.up('sm')]: {
            margin: '0px 24px',
        },
        '& > .paragraph3': {
            fontWeight: 'bold',
        },
        '& > .paragraph2': {
            fontWeight: 'bold',
        }
    },
    backLink: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '16px 0px 24px 0px',
        margin: '0px 16px',
        [theme.breakpoints.up('sm')]: {
            margin: '0px 24px',
        },
        '& > * + *': {
            marginLeft: '10px'
        }
    },
    menuItemLinkText: {
        '&.paragraph3': {
            color: '#17171A',
        },
    },
    menuItemLinkIcon: {
        minWidth: '12px',
        minHeight: '12px',
        width: '12px',
        height: '12px',
        color: '#5C5C66',
    },
    topCategoryLinkIcon: {
        minWidth: '16px',
        minHeight: '16px',
        width: '16px',
        height: '16px',
        color: '#5C5C66',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    topCategory: props => ({
        padding: '16px',
        borderBottom: props.open ? '1px solid #E6E6E6' : '0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            padding: '16px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            cursor: 'unset',
            borderBottom: '0px',
            padding: '24px',
            '& > h4': {
                fontSize: '16px'
            },
        },
    }),
    pageCategory: {
        '&.paragraph2': {
            fontWeight: 'bold',
            fontSize: '14px',
        }
    },
    childCategory: {
        '&.paragraph2': {
            fontWeight: 'bold',
            fontSize: '14px',
        }
    },
}));

export default function FaqCategoriesList() {
    const [topFaqCategory, setTopFaqCategory] = useState(null);
    const [childFaqCategories, setChildFaqCategories] = useState([]);
    const [open, setOpen] = useState(false);

    const faqCategories = useSelector(state => state.faq.faqCategories);
    const faqCategory = useSelector(state => state.faq.faqCategory);
    const faqChildCategory = useSelector(state => state.faq.faqChildCategory);

    const dispatch = useDispatch();

    const history = useHistory();
    const { t, i18n } = useTranslation();

    const isLeafList = faqCategory && topFaqCategory && faqCategory.id !== topFaqCategory.id;

    const classes = useStyles({ open, isLeafList });

    useEffect(() => {
        if (!faqCategory || faqCategories.length === 0) {
            return;
        }

        const childFaqCategories = faqModule.getFaqChildCategories(faqCategories, faqCategory);
        const topFaqCategory = faqModule.getTopFaqCategory(faqCategories);

        setTopFaqCategory(topFaqCategory);
        setChildFaqCategories(childFaqCategories);

        if (faqCategory.id !== topFaqCategory.id) {
            dispatch(faqActions.setFaqChildCategory(childFaqCategories[0]));
        }
    }, [dispatch, faqCategories, faqCategory]);

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

    function handleTopCategoryClick() {
        setOpen(!open);
    }

    function handleClick(faqCategory) {
        return () => {
            if (!faqModule.isLeafFaqCategory(faqCategory)) {
                history.push(getPathWithLanguage(ROUTES_ANP.FAQ(faqCategory.id), i18n.language));
                return;
            }

            dispatch(faqActions.setFaqChildCategory(faqCategory));
        }
    }

    function handleBackClick() {
        return () => {
            history.push(getPathWithLanguage(ROUTES_ANP.FAQ_TOP, i18n.language));
        }
    }

    return (
        <div className={classes.layout}>
            <div className={classes.topCategory} onClick={handleTopCategoryClick}>
                <h4>
                    {topFaqCategory && topFaqCategory.title}
                </h4>
                {open ?
                    <ChevronUpIcon className={classes.topCategoryLinkIcon} fill={'#5C5C66'}/>
                    :
                    <ChevronDownIcon className={classes.topCategoryLinkIcon} fill={'#5C5C66'}/>
                }
            </div>
            {(isDesktop || open) && isLeafList && faqCategory && topFaqCategory &&
            <div className={classes.categoryItem}>
                <div className={clsx(isDesktop ? 'paragraph3' : 'paragraph2', classes.menuItemLinkText)}>
                    {faqCategory.title}
                </div>
            </div>
            }
            {(isDesktop || open) && faqCategory && topFaqCategory &&
            <>
                <ul className={classes.listLayout}>
                    {childFaqCategories.map((faqCategory) => (
                        <li key={faqCategory.id} className={classes.category}>
                            <div className={classes.menuItemLink} onClick={handleClick(faqCategory)}>
                                <div
                                    className={clsx(isDesktop ? 'paragraph3' : 'paragraph2', classes.menuItemLinkText)}>
                                    {`${isLeafList && faqChildCategory && faqCategory && faqChildCategory.id === faqCategory.id ? '» ' : ''}${faqCategory.title}`}
                                </div>
                                {!isLeafList &&
                                <ChevronRightIcon className={classes.menuItemLinkIcon} fill={'#5C5C66'}/>}
                            </div>
                        </li>
                    ))}
                </ul>
                {isLeafList &&
                <div className={classes.backLink} onClick={handleBackClick()}>
                    <ChevronLeftIcon className={classes.menuItemLinkIcon} fill={'#5C5C66'}/>
                    <div className={clsx(isDesktop ? 'paragraph3' : 'paragraph2', classes.menuItemLinkText)}>
                        {t('Text.BackToFaqHomePage')}
                    </div>
                </div>
                }
            </>
            }
        </div>
    );
}
