import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / apartment-place"}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M24 46c8.837 0 16-3.806 16-8.5S32.837 29 24 29 8 32.806 8 37.5 15.163 46 24 46zm0-1c-8.357 0-15-3.529-15-7.5S15.643 30 24 30s15 3.529 15 7.5S32.357 45 24 45z"
                    fill="#17171A"
                    fillRule="nonzero"
                />
                <path
                    d="M24 43c6.627 0 12-2.686 12-6s-5.373-6-12-6-12 2.686-12 6 5.373 6 12 6zm0-1c-6.146 0-11-2.427-11-5s4.854-5 11-5 11 2.427 11 5-4.854 5-11 5z"
                    fill="#17171A"
                    fillRule="nonzero"
                />
                <path
                    d="M24 40c4.418 0 8-1.567 8-3.5S28.418 33 24 33s-8 1.567-8 3.5 3.582 3.5 8 3.5zm0-1c-3.925 0-7-1.345-7-2.5s3.075-2.5 7-2.5 7 1.345 7 2.5-3.075 2.5-7 2.5z"
                    fill="#17171A"
                    fillRule="nonzero"
                />
                <path
                    d="M23.921 3c4.887 0 8.855 3.969 8.855 8.855a8.857 8.857 0 01-7.996 8.816v15.28a.859.859 0 01-1.717 0v-15.28a8.857 8.857 0 01-7.997-8.816C15.066 6.97 19.035 3 23.92 3z"
                    fill="#17171A"
                />
                <path
                    d="M23.921 4.717a7.14 7.14 0 10.002 14.274 7.14 7.14 0 00-.002-14.274z"
                    fill="#F2CA00"
                />
            </g>
        </svg>
    )
}

export default SvgComponent