import React from 'react';
import {makeStyles} from '@material-ui/core';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {contentModule} from 'shared/src/modules/content';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            height: '500px',
            flexDirection: 'row',
        },
    },
    leftLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        backgroundColor: '#F2CA00',
        borderRadius: '12px 12px 0 0',
        [theme.breakpoints.up('lg')]: {
            borderRadius: '12px 0 0 12px',
            width: '380px',
            height: 'unset',
            padding: '32px 81px 32px 32px',
        },
        padding: '24px',
    },
    rightLayout: props => ({
        height: '195px',
        borderRadius: '0 0 12px 12px',
        [theme.breakpoints.up('sm')]: {
            height: '427px',
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: '0 12px 12px 0',
            height: 'unset',
            flex: 1,
        },
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.promotion && props.promotion.headerImage}')`,
    }),
    description: {
        borderLeft: '4px solid #FFFFFF',
        borderRadius: '2px',
        paddingLeft: '16px',
        '&.paragraph1': {
            lineHeight: '25.6px',
            [theme.breakpoints.up('sm')]: {},
            [theme.breakpoints.up('lg')]: {
                lineHeight: '28.8px',
            },
            '& p': {
                margin: '0px',
                padding: '0px',
            }
        },
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '12px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '32px',
        }
    },
    callToAction: {
        marginTop: '23px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        }
    }
}));

export default function PromotionWideBig(props) {
    const { className, promotion } = props;
    const classes = useStyles(props);
    const history = useHistory();

    const { i18n } = useTranslation();

    function handleClick() {
        history.push(contentModule.getContentUrl(promotion, i18n.language));
    }

    if (!promotion) {
        return null;
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.leftLayout}>
                <h3>{promotion.title}</h3>
                <div className={clsx('paragraph1', classes.description)}
                     dangerouslySetInnerHTML={createMarkup(promotion.summary)}/>
                <PrimaryBasicButton className={classes.callToAction} variant={'big'} title={promotion.callToActionText}
                                    onClick={handleClick}/>
            </div>
            <div className={classes.rightLayout}>
            </div>
        </div>
    );
}
