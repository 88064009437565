import * as React from "react";

function SvgPhone(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M12.9 27.7a52.95 52.95 0 0023.4 23.4l7.8-7.8c1-1 2.4-1.3 3.6-.9 4 1.3 8.3 2 12.7 2 2 0 3.6 1.6 3.6 3.6v12.4c0 2-1.6 3.6-3.6 3.6C27.1 64 0 36.9 0 3.6 0 1.6 1.6 0 3.6 0H16c2 0 3.6 1.6 3.6 3.6 0 4.4.7 8.7 2 12.7.4 1.2.1 2.6-.9 3.6l-7.8 7.8z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgPhone;
