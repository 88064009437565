import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / tuzrakohely"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#FA9" cx={24} cy={24} r={24}/>
                <path
                    d="M32.503 24.158a1.628 1.628 0 011.954 1.197 1.615 1.615 0 01-1.209 1.95l-2.15.502 2.149.502c.681.154 1.18.733 1.246 1.425l.007.15c0 .125-.014.25-.043.372a1.632 1.632 0 01-1.954 1.2l-8.506-1.989-8.5 1.99a1.63 1.63 0 01-1.91-1.056l-.044-.142a1.615 1.615 0 011.209-1.95l2.149-.503-2.148-.501a1.614 1.614 0 01-1.246-1.425l-.007-.15c0-.125.014-.25.043-.372a1.629 1.629 0 011.954-1.2l8.505 1.988zm-9.92-10.639c.492-.074.992.072 1.37.4.77.635 1.16 1.893 1.16 3.74 0 .166.152.23.242.253.407.105 1.064-.191 1.545-1.087a.453.453 0 01.71-.12c.062.057 1.491 1.424 1.136 4.391-.31 2.592-2.263 4.403-4.75 4.403-2.451 0-4.45-1.852-4.75-4.403-.184-1.558.47-3.158 1.797-4.39a.496.496 0 01.026-.023c.36-.288 1.312-1.196 1.39-1.929a.63.63 0 00-.139-.491.466.466 0 01-.076-.447.458.458 0 01.338-.297z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent