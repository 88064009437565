import React from 'react';
import {makeStyles} from '@material-ui/core';
import DangerLargeListItem from './DangerLargeListItem';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(180deg, #E6E6E6 0%, #FFFFFF 100%)',
        marginTop: '32px',
        paddingBottom: '32px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
            paddingBottom: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
            paddingBottom: '64px',
        }
    },
    dangerList: {
        '& > * + *': {
            marginTop: '14px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '20px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '16px',
            }
        },
    },
    title: {
        marginTop: '32px',
        marginBottom: '24px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
            marginBottom: '48px',
        }
    }
}));

export default function DangerLargeList(props) {
    const { dangers, className } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    if (!dangers || dangers.length === 0) {
        return null;
    }

    return (
        <div id={'dangerList'} className={clsx(classes.layout, className)}>
            <h2 className={classes.title}>{t('Text.Dangers')}</h2>
            <div className={classes.dangerList}>
                {dangers.map(danger =>
                <DangerLargeListItem key={danger.id} danger={danger}/>
                )}
            </div>
        </div>
);
}
