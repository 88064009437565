import React from 'react';
import MainScreen from "../components/pages/MainScreen";
import {makeStyles} from '@material-ui/core';
import TopContentBar from '../components/contents/home/TopContentBar';
import {useTranslation} from 'react-i18next';
import {ROUTES_ANP} from 'shared';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {contentActions} from 'client_shared/src/state/actions';
import PromotionBig from '../components/contents/promotions/PromotionBig';
import {CONTENT} from 'shared/src/constants/content';
import {tourActions} from 'client_shared/src/state/actions/tourActions';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import TourList from '../components/contents/tour/lists/tourList/TourList';
import OfferedProgramList from '../components/contents/tour/lists/offeredProgramList/OfferedProgramList';
import TopProgramList from '../components/contents/tour/lists/topProgramList/TopProgramList';
import {SITE} from 'shared/src/constants/site';
import clsx from 'clsx';
import DangerList from '../components/contents/danger/lists/dangerList/DangerList';

const useStyles = makeStyles(theme => ({
    divider: {
        width: '100%',
        borderBottom: '2px solid #E6E6E6',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    dangerList: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
        },
    },
    tourList: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
        },
    }
}));

function Home() {
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    const topCaveTours = useSelector(state => state.tour.topCaveTours);
    const promotion = useSelector(state => state.content.promotion);
    const offeredProgrammes = useSelector(state => state.content.offeredProgrammes);
    const topProgrammes = useSelector(state => state.tour.topProgrammes);
    const headlineContent = useSelector(state => state.content.headlineContent);
    const homePageNews = useSelector(state => state.content.homePageNews);

    const allDangers = useSelector(state => state.content.dangers);
    const page = useSelector(state => state.page.page);
    const dangers = allDangers.filter(danger => page && page.dangerIds && page.dangerIds.includes(danger.id));

    return (
        <MainScreen>
            <TopContentBar headlineContent={headlineContent} homePageNews={homePageNews}/>
            <div className={clsx('padding')}>
                <DangerList dangers={dangers} className={classes.dangerList}/>
            </div>
            <TourList showTitleLink={true} title={t('Text.PopularCaveTours')} tours={topCaveTours}
                      linkTitle={t('Text.AllCaveTours')}
                      url={getPathWithLanguage(ROUTES_ANP.CAVE_TOURS, i18n.language)}
                      className={clsx('padding', classes.tourList)}
            />
            <div className={classes.divider}/>
            <OfferedProgramList offeredProgrammes={offeredProgrammes}/>
            <div className={classes.divider}/>
            <TopProgramList topProgrammes={topProgrammes}/>
            {promotion && <PromotionBig promotion={promotion}/>}
        </MainScreen>
    );
}

Home.initState = (environment) => {
    const { dispatch, storeState, page } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.headlineContent)) {
        promises.push(dispatch(contentActions.loadHeadlineContent()));
    }

    if (_.isEmpty(storeState.content.homePageNews)) {
        promises.push(dispatch(contentActions.loadHomePageNews(CONTENT.MAX_HOME_PAGE_NEWS_COUNT, SITE.ANP)));
    }

    if (_.isEmpty(storeState.tour.topCaveTours)) {
        promises.push(dispatch(tourActions.loadTopCaveTours(CONTENT.MAX_HOME_PAGE_TOP_CAVE_TOUR_COUNT)));
    }

    if (_.isEmpty(storeState.content.offeredProgrammes)) {
        promises.push(dispatch(contentActions.loadOfferedProgrammes(page.programOffers.map(programOffer => programOffer.contentId))));
    }

    if (_.isEmpty(storeState.tour.topProgrammes)) {
        promises.push(dispatch(tourActions.loadTopProgrammes(CONTENT.MAX_HOME_PAGE_TOP_PROGRAM_COUNT)));
    }

    if (_.isEmpty(storeState.content.dangers)) {
        promises.push(dispatch(contentActions.loadDangers()));
    }

    return promises;
};

export default Home;