import {sendServerRequest} from './baseService';

export const faqService = {
    getFaqCategories,
    getFaqsForCategory,
};

async function getFaqCategories() {
    return sendServerRequest('GET', `/api/faqCategories`);
}

async function getFaqsForCategory(categoryId) {
    return sendServerRequest('GET', `/api/faqs?categoryId=${categoryId}`);
}
