import React from 'react';
import {makeStyles} from '@material-ui/core';
import {CONTENT} from 'shared';
import InfoFilledIcon from '../../../../icons/mono/InfoFilledIcon';
import DangerIcon from '../../../../icons/mono/DangerIcon';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        width: '100%',
    },
    iconBar: props => ({
        backgroundColor: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#199FFF' : '#D93616',
        padding: '21px 12px',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 26px',
        },
        lineHeight: '1px',
        borderRadius: '6px 0 0 6px',
    }),
    infoIcon: {
        width: '16px',
        height: '16px',
        [theme.breakpoints.up('sm')]: {
            width: '32px',
            height: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '48px',
            height: '48px',
        }
    },
    dangerIcon: {
        width: '16px',
        height: '16px',
        [theme.breakpoints.up('sm')]: {
            width: '32px',
            height: '28px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '48px',
            height: '42px',
        }
    },
    messageBar: props => ({
        flex: 1,
        borderRadius: '0 6px 6px 0',
        backgroundColor: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#66BFFF' : '#E66045',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px 32px',
        },
    }),
    message: props => ({
        '&.paragraph2': {
            lineHeight: '22.4px',
            [theme.breakpoints.up('lg')]: {
                lineHeight: '25.6px',
            },
            color: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#17171A' : '#FFFFFF',
        },
        '& p': {
            margin: 0,
            padding: 0,
        }
    }),
    title: props => ({
        color: props.danger.subType === CONTENT.DANGER_TYPE.INFO ? '#17171A' : '#FFFFFF',
        marginBottom: '12px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '16px'
        },
    })
}));

export default function DangerLargeListItem(props) {
    const { danger } = props;
    const classes = useStyles({ danger });

    return (
        <div className={classes.layout}>
            <div className={classes.iconBar}>
                {danger.subType === CONTENT.DANGER_TYPE.INFO ?
                    <InfoFilledIcon className={classes.infoIcon} fill={'#FFFFFF'}/> :
                    <DangerIcon className={classes.dangerIcon} fill={'#F2CA00'}/>}
            </div>
            <div className={classes.messageBar}>
                <h4 className={classes.title}>{danger.title}</h4>
                <div className={clsx('paragraph2', classes.message)}
                     dangerouslySetInnerHTML={createMarkup(danger.summary)}/>
            </div>
        </div>
    );
}
