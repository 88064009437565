import * as React from "react";

function Svg03Nehezseg(props) {
    return (
        <svg width={16} height={12} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M14.684 0c.359 0 .65.238.65.531V11.47c0 .293-.291.531-.65.531H1.3 10V3.612L14.225.156a.71.71 0 01.357-.15z"
                    fill="#5C5C66"
                />
                <path
                    d="M7.333 5.999v6L1.26 12a.7.7 0 01-.349-.09l-.071-.05c-.232-.185-.232-.486 0-.671l6.492-5.19z"
                    fill="#17171A"
                />
            </g>
        </svg>
    );
}

export default Svg03Nehezseg;
