import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {tourModule} from 'shared/src/modules/tour';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #D9D9D9',
        borderBottomRadius: '12px',
        padding: '16px 24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: '24px 24px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: '48px 0px',
        },
    },
    leftLayout: {
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: '7px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '16px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '12px',
            },
        }
    },
    rightLayout: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row-reverse',
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
            alignItems: 'center',
            flexDirection: 'row',
        },
    },
    subTitle: {
        '&.paragraph3': {
            color: '#5C5C66'
        },
    },
    programType: (props) => ({
        '&.paragraph3': {
            fontWeight: 'bold',
            color: props.tour.tourType.color,
        },
        paddingBottom: '4px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '0px',
        },
    }),
    programDivider: {
        borderRight: '4px solid #D9D9D9',
        borderRadius: '12px',
        margin: '0px 10px 0px 12px',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    programBlock: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    summaryBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    link: {
        marginTop: '0px',
        '&:not(:only-child)': {
            marginLeft: '24px',
        },
        [theme.breakpoints.up('sm')]: {
            '&:not(:only-child)': {
                marginTop: '24px',
            },
            marginLeft: '0px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '0px',
            marginTop: '0px',
        },
    },
    buyTicketButton: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: '32px',
        },
    }
}));

export default function SearchResultTourListItem(props) {
    const { tour } = props;
    const classes = useStyles({ tour });

    const { t, i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={classes.leftLayout}>
                <div>
                    <h3>
                        {tour.name}
                    </h3>
                    <div className={clsx('paragraph3', classes.subTitle)}>
                        {tour.subTitle}
                    </div>
                </div>
                <div className={classes.programBlock}>
                    <div className={classes.summaryBlock}>
                        <div className={clsx('paragraph3', classes.programType)}>
                            {tour.tourType.title}
                        </div>
                        <div className={classes.programDivider}/>
                        <div className={'paragraph3'}>
                            {tourModule.formatSummary(tour)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(classes.rightLayout)}>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(tour.id), i18n.language)}
                      className={clsx('linkSecondary', classes.link)}>{t('Text.Details')}</Link>
                {/*
                {tourModule.hasTickets(tour) &&
                <PrimaryBuyingButton className={classes.buyTicketButton} variant={'big'} tour={tour}/>
                }
*/}
            </div>
        </div>
    );
}
