import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import TourGuideTourListItem from './TourGuideTourListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > * + *': {
            marginTop: '16px',
            [theme.breakpoints.up('lg')]: {
                marginTop: '24px'
            }
        }
    },
    tourList: {
        width: '100%',
        '& > * + *': {
            marginTop: '12px',
            [theme.breakpoints.up('lg')]: {
                marginTop: '14px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '16px'
            },
        }
    }
}));

export default function TourGuideTourList(props) {
    const { className, tours } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={clsx(classes.layout, className)}>
            <h3>
                {t('Text.MyTours')}
                {}
            </h3>
            <div className={classes.tourList}>
                {tours.map(tour =>
                    <TourGuideTourListItem key={tour.id} tour={tour}/>
                )}
            </div>
        </div>
    );
}
