import React from 'react';
import {makeStyles} from '@material-ui/core';
import HeaderBar from './headers/HeaderBar';
import FooterBar from './footers/FooterBar';
import Div100vh from 'react-div-100vh';
import {styleConstants} from '../../styles/styles';
import CookieCard from '../cookie/CookieCard';
import CookiePopup from '../cookie/CookiePopup';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
    overallLayout: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    layout: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mapLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: `calc(100% - ${styleConstants.headerBar.mobileHeight}px)`,
        [theme.breakpoints.up('md')]: {
            height: `calc(100% - ${styleConstants.headerBar.tabletHeight}px)`,
        },
        [theme.breakpoints.up('lg')]: {
            height: `calc(100% - ${styleConstants.headerBar.desktopHeight}px)`,
        },
    },
    gap: {
        height: styleConstants.headerBar.mobileHeight,
        [theme.breakpoints.up('sm')]: {
            height: styleConstants.headerBar.tabletHeight,
        },
        [theme.breakpoints.up('lg')]: {
            height: styleConstants.headerBar.desktopHeight,
        },
    },
}));

export default function MainScreen(props) {
    const { children, isMapScreen } = props;
    const classes = useStyles();

    const page = useSelector(state => state.page.page);

    return (
        <>
            <Helmet>
                <title>
                    {page && page.name} - Aggteleki Nemzeti Park
                </title>
            </Helmet>
            {!isMapScreen &&
            <div className={classes.overallLayout}>
                <HeaderBar/>
                <main className={classes.layout}>
                    <div className={classes.gap}/>
                    {children}
                </main>
                <FooterBar/>
            </div>
            }
            {isMapScreen &&
            <Div100vh style={{ height: '100rvh', width: '100%' }}>
                <HeaderBar/>
                <div className={classes.gap}/>
                <main className={classes.mapLayout}>
                    {children}
                </main>
            </Div100vh>
            }
            <CookieCard/>
            <CookiePopup/>
        </>
    );
}
