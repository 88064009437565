import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / csak_forint"}</title>
            <g fill="none" fillRule="evenodd">
                <circle cx={24} cy={24} r={24} fill="#80C497"/>
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M20.63 28.379h-2.259v-3.62h-2.612v3.62H13.5V19.5h2.26v3.291h2.611V19.5h2.26zM28.669 19.5v5.344c0 1.162-.308 2.062-.925 2.7-.616.637-1.505.956-2.666.956-1.134 0-2.006-.31-2.614-.93-.61-.619-.914-1.51-.914-2.671V19.5h2.26v5.21c0 .628.11 1.084.33 1.367.22.283.544.425.972.425.46 0 .792-.14 1-.422.206-.281.31-.742.31-1.382V19.5h2.247zM31.797 28.379h-2.214V19.5H34.5v1.925h-2.703v1.694h2.492v1.926h-2.492z"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
