import React from 'react';

export default function SimplePayForm(props) {
    const { paymentInfo } = props;

    if (!paymentInfo) {
        return null;
    }

    return (
        <form action={paymentInfo.url} method='POST' id='SimplePayForm' acceptCharset='UTF-8'>
            <input type='hidden' name='MERCHANT' id='MERCHANT' value={paymentInfo.merchant}/>
            <input type='hidden' name='ORDER_REF' id='ORDER_REF' value={paymentInfo.orderRef}/>
            <input type='hidden' name='ORDER_DATE' id='ORDER_DATE' value={paymentInfo.orderDate}/>
            {paymentInfo.orderItems.map(orderItem =>
                <input type='hidden' name='ORDER_PNAME[]' id='ORDER_PNAME' value={orderItem.orderProductName}/>
            )}
            {paymentInfo.orderItems.map(orderItem =>
                <input type='hidden' name='ORDER_PCODE[]' id='ORDER_PCODE' value={orderItem.orderProductCode}/>
            )}
            {paymentInfo.orderItems.map(orderItem =>
                <input type='hidden' name='ORDER_PRICE[]' id='ORDER_PRICE' value={orderItem.orderPrice}/>
            )}
            {paymentInfo.orderItems.map(orderItem =>
                <input type='hidden' name='ORDER_QTY[]' id='ORDER_QTY' value={orderItem.orderQuantity}/>
            )}
            {paymentInfo.orderItems.map(orderItem =>
                <input type='hidden' name='ORDER_VAT[]' id='ORDER_VAT' value={orderItem.orderVat}/>
            )}
            <input type='hidden' name='PRICES_CURRENCY' id='PRICES_CURRENCY' value={paymentInfo.pricesCurrency}/>
            <input type='hidden' name='PAY_METHOD' id='PAY_METHOD' value={paymentInfo.paymentMethod}/>
            <input type='hidden' name='LANGUAGE' id='LANGUAGE' value={paymentInfo.language}/>
            <input type='hidden' name='ORDER_TIMEOUT' id='ORDER_TIMEOUT' value={paymentInfo.orderTimeout}/>
            <input type='hidden' name='TIMEOUT_URL' id='TIMEOUT_URL' value={paymentInfo.timeoutUrl}/>
            <input type='hidden' name='BACK_REF' id='BACK_REF' value={paymentInfo.backrefUrl}/>
            <input type='hidden' name='BILL_EMAIL' id='BILL_EMAIL' value={paymentInfo.billEmail}/>
            <input type='hidden' name='BILL_FNAME' id='BILL_FNAME'
                   value={paymentInfo.billInvoiceRequested ? paymentInfo.billCompany : paymentInfo.billName}/>
            <input type='hidden' name='BILL_LNAME' id='BILL_LNAME' value={''}/>
            {paymentInfo.billInvoiceRequested ?
                <>
                    <input type='hidden' name='BILL_FISCALCODE' id='BILL_FISCALCODE'
                           value={paymentInfo.billVATNumber}/>
                    <input type='hidden' name='BILL_COMPANY' id='BILL_COMPANY' value={paymentInfo.billCompany}/>
                    <input type='hidden' name='BILL_CITY' id='BILL_CITY' value={paymentInfo.billCity}/>
                    <input type='hidden' name='BILL_COUNTRYCODE' id='BILL_COUNTRYCODE'
                           value={paymentInfo.billCountry}/>
                    <input type='hidden' name='BILL_ADDRESS' id='BILL_ADDRESS' value={paymentInfo.billAddress}/>
                    <input type='hidden' name='BILL_ZIPCODE' id='BILL_ZIPCODE' value={paymentInfo.billPostCode}/>
                </>
                : null}
            {!paymentInfo.billInvoiceRequested ?
                <>
                    <input type='hidden' name='BILL_CITY' id='BILL_CITY' value={paymentInfo.guideCity}/>
                    <input type='hidden' name='BILL_ADDRESS' id='BILL_ADDRESS' value={paymentInfo.guideAddress}/>
                    <input type='hidden' name='BILL_ZIPCODE' id='BILL_ZIPCODE' value={paymentInfo.guidePostCode}/>
                </>
                : null}
            <input type='hidden' name='ORDER_HASH' id='ORDER_HASH' value={paymentInfo.orderHash}/>
        </form>
    );
}
