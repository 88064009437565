import React from 'react';
import {makeStyles} from '@material-ui/core';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {contentModule} from 'shared/src/modules/content';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import LatnivalokBarlangiTurakIcon from '../../icons/multicolor/LatnivalokBarlangiTurakIcon';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '12px',
        background: 'linear-gradient(135deg, #F2E0CE 0%, #FAF4ED 100%)',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center',
            width: '995px',
        },
        padding: '48px',
    },
    rightLayout: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '48px',
            flexDirection: 'row',
            alignItems: 'center',
        }
    },
    iconBar: {},
    titleBar: {
        display: 'flex',
        '& > * + *': {
            marginLeft: '16px',
            [theme.breakpoints.up('sm')]: {
                marginLeft: '0px',
            }
        }
    },
    textBar: {},
    callToActionBar: {
        marginTop: '24px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '64px',
            marginTop: '0px',
        }
    },
    icon: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            height: '64px',
            width: '64px',
            minHeight: '64px',
            minWidth: '64px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '96px',
            width: '96px',
            minHeight: '96px',
            minWidth: '96px',
        }
    },
    iconMobile: {
        height: '48px',
        width: '48px',
        minHeight: '48px',
        minWidth: '48px',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    descriptionBlock: {
        borderLeft: '4px solid #F2A200',
        borderRadius: '2px',
        paddingLeft: '16px',
    },
    description: {
        '&.paragraph1': {
            color: '#5C5C66',
            lineHeight: '25.6px',
            marginTop: '16px',
            [theme.breakpoints.up('lg')]: {
                marginTop: '12px',
            },
            [theme.breakpoints.up('lg')]: {
                lineHeight: '28.8px',
            }
        }
    }
}));

export default function Promotion(props) {
    const { className, promotion } = props;
    const classes = useStyles();
    const history = useHistory();

    const { i18n } = useTranslation();

    function handleClick() {
        history.push(contentModule.getContentUrl(promotion, i18n.language));
    }

    if (!promotion || !promotion.title || !promotion.summary || !promotion) {
        return null;
    }

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={classes.iconBar}>
                <LatnivalokBarlangiTurakIcon className={classes.icon}/>
            </div>
            <div className={classes.rightLayout}>
                <div className={classes.textBar}>
                    <div className={classes.titleBar}>
                        <LatnivalokBarlangiTurakIcon className={classes.iconMobile}/>
                        <h3>{promotion.title}</h3>
                    </div>
                    <div className={classes.descriptionBlock}>
                        <div className={clsx('paragraph1', classes.description)}
                             dangerouslySetInnerHTML={createMarkup(promotion.summary)}/>
                    </div>
                </div>
                <div className={classes.callToActionBar}>
                    <PrimaryBasicButton variant={'big'} title={promotion.callToActionText} onClick={handleClick}/>
                </div>
            </div>
        </div>
    );
}
