import React from 'react';
import ContentScreen from '../components/pages/ContentScreen';
import _ from 'lodash';
import {contentActions} from 'client_shared/src/state/actions';
import NewsContentBar from '../components/contents/news/NewsContentBar';
import {makeStyles} from '@material-ui/core/styles';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    bodyLayout: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '64px',
        },
    },
}));

export default function News() {
    const classes = useStyles();

    return (
        <ContentScreen>
            <div className={clsx('padding', classes.bodyLayout)}>
                <NewsContentBar/>
            </div>
        </ContentScreen>
    );
}
News.initState = (environment) => {
    const { dispatch, storeState } = environment;

    const promises = [];

    if (_.isEmpty(storeState.content.news)) {
        promises.push(dispatch(contentActions.loadNews()));
    }

    return promises;
};