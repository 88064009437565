import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import FaqCategoriesList from './lists/faqCategoriesList/FaqCategoriesList';
import FaqCategoryList from './lists/faqCategoryList/FaqCategoryList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '64px',
            '& > * + *': {
                marginLeft: '30px',
                flex: 1,
            },
        }
    }
}));

export default function FaqCategoriesBar() {
    const classes = useStyles();

    return (
        <div className={clsx('padding', classes.layout)}>
            <FaqCategoriesList/>
            <FaqCategoryList/>
        </div>
    );
}
