import React from 'react';
import {makeStyles} from '@material-ui/core';
import ArticleListItem from './ArticleListItem';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    listLayout: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flex: '0 0 100%',
            marginTop: '12px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '-20px',
            marginTop: '-20px',
            '& > *': {
                flex: '0 0 calc(50% - 20px)',
                marginLeft: '20px',
                marginTop: '20px',
            }
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '-30px',
            marginLeft: '-30px',
            '& > *': {
                flex: '0 0 calc(33.333% - 30px)',
                marginLeft: '30px',
                marginTop: '30px',
            }
        },
    },
    title: {
        lineHeight: '36.4px',
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            lineHeight: '42px',
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight: '44.8px',
            marginBottom: '43px',
        }
    }
}));

export default function ArticleList(props) {
    const { page, title, articles } = props;
    const classes = useStyles();

    const sortedArticles = articles.sort((article1, article2) => {
        if (!article1.index && !article2.index) {
            return 0;
        }
        if (!article1.index) {
            return 1;
        }
        if (!article2.index) {
            return -1;
        }
        if (article1.index < article2.index) {
            return -1;
        }
        if (article1.index > article2.index) {
            return 1;
        }
        return 0;
    });

    return (
        <div className={classes.layout}>
            <h2 className={classes.title}>
                {title}
            </h2>
            <div className={classes.listLayout}>
                {sortedArticles.map(article =>
                    <ArticleListItem key={article.id} page={page} article={article}/>
                )}
            </div>
        </div>
    );
}
