import React from 'react';
import SecondaryHeaderBar from '../components/pages/headers/SecondaryHeaderBar';
import PaymentInfo from '../components/checkout/payment/PaymentInfo';
import SecondaryScreen from '../components/pages/SecondaryScreen';
import Backdrop from '@material-ui/core/Backdrop';
import FooterBarBottom from '../components/pages/footers/FooterBarBottom';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Payment(props) {
    const classes = useStyles(props);
    const order = useSelector(state => state.checkout.order);
    const orderSaving = useSelector(state => state.checkout.orderSaving);

    return (
        <SecondaryScreen>
            <Backdrop className={classes.backdrop} open={!order || orderSaving}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            {order &&
            <>
                <SecondaryHeaderBar/>
                <PaymentInfo/>
                <FooterBarBottom/>
            </>
            }
        </SecondaryScreen>
    );
}
