import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        borderRadius: '12px',
        border: '2px solid #D9D9D9',
        backgroundColor: theme.palette.background.default,
        paddingLeft: '25px',
        paddingRight: '25px',
        textTransform: 'uppercase',
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#E6E6E6',
        },
        '&:active': {
            cursor: 'pointer',
            backgroundColor: '#E6E6E6',
        },
        '&:focus': {
            /*outline: '0 !important',*/
        },
    },
    variantBig: {
        '&.paragraph3': {
            color: '#17171A',
            fontWeight: 'bold',
        },
        height: '44px',
        whiteSpace: 'nowrap',
    },
    variantNormal: {
        '&.paragraph4': {
            color: '#17171A',
            fontWeight: 'bold',
        },
        height: '34px',
        whiteSpace: 'nowrap',
    },
}));

export default function SecondaryButton(props) {
    const { title, variant, className, onClick } = props;
    const classes = useStyles();

    return (
        <button
            className={clsx(variant === 'big' ? clsx('paragraph3', classes.variantBig) : clsx('paragraph4', classes.variantNormal), classes.layout, className)}
            onClick={onClick}
        >
            {title}
        </button>
    );
}
