import React from 'react';
import {makeStyles} from '@material-ui/core';
import MainMenuPopoverTours from './MainMenuPopoverTours';
import MainMenuPopoverLocations from './MainMenuPopoverLocations';
import clsx from 'clsx';
import MainMenuPopoverPark from './MainMenuPopoverPark';
import MainMenuPopoverEducation from './MainMenuPopoverEducation';
import {useSelector} from 'react-redux';
import {MENU} from 'shared/src/constants/menu';
import {styleConstants} from '../../../../styles/styles';

const useStyles = makeStyles(theme => ({
    layout: {
        zIndex: 1000,
        position: 'fixed',
        top: styleConstants.headerBar.desktopHeight,
        right: '0px',
        width: '100%',
        backgroundColor: '#E6CFB8',
    },
    content: {
        width: '100%',
        padding: '49px 0px',
    }
}));

export default function MainMenuPopover(props) {
    const { mouseMoveHandler } = props;
    const classes = useStyles(props);

    const selectedMenuItem = useSelector(state => state.menu.selectedMainMenuItem);

    if (!selectedMenuItem) {
        return null;
    }

    function handleMouseEnter() {
        return () => {
            mouseMoveHandler && mouseMoveHandler.handleMouseEnter();
        }
    }

    function handleMouseLeave() {
        return () => {
            mouseMoveHandler && mouseMoveHandler.handleMouseLeave();
        }
    }

    return (
        <div className={classes.layout} onMouseEnter={handleMouseEnter()} onMouseLeave={handleMouseLeave()}>
            <div className={clsx('mainMenuPopover', classes.content)}>
                {selectedMenuItem.index === MENU.MAIN_MENU_ANP.TOUR &&
                <MainMenuPopoverTours/>
                }
                {selectedMenuItem.index === MENU.MAIN_MENU_ANP.LOCATION &&
                <MainMenuPopoverLocations/>
                }
                {selectedMenuItem.index === MENU.MAIN_MENU_ANP.PARK &&
                <MainMenuPopoverPark/>
                }
                {selectedMenuItem.index === MENU.MAIN_MENU_ANP.EDUCATION &&
                <MainMenuPopoverEducation/>
                }
            </div>
        </div>
    );
}
