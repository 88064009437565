import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        height: '20px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            height: '22px',
        }
    },
    badge: {
        borderRadius: '4px',
        '&.paragraph4': {
            textAlign: 'center',
            verticalAlign: 'middle',
            lineHeight: '20px',
            letterSpacing: '0.2px',
        },
        padding: '2px 10px 0px 10px',
        [theme.breakpoints.up('lg')]: {
            lineHeight: '22px',
        }
    }
}));

export default function BadgeListItem(props) {
    const { badgeId, className } = props;
    const classes = useStyles();

    const { badges } = useSelector(state => state.badge);

    if (!badges || badges.length === 0) {
        return null;
    }

    const badge = badges.find(badge => badge.id === badgeId);

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={clsx('paragraph4', classes.badge)}
                 style={{ color: badge.color, backgroundColor: badge.backgroundColor }}>
                {badge.title}
            </div>
        </div>
    );
}
