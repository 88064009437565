import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        border: '2px solid #D9D9D9',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    image: (props) => ({
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px 12px 0 0',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.tour.headerImage}')`,
        width: 'unset',
        height: '186px',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            width: '300px',
            height: 'unset',
            borderRadius: '12px 0 0 12px',
        },
    }),
    badgeArea: {
        position: 'relative',
        top: '12px',
        left: '12px',
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: '4px',
        }
    },
    badgeList: {
        display: 'flex',
        '& > * + *': {
            marginLeft: '4px',
        }
    },
    badge: {
        borderRadius: '4px',
        backgroundColor: 'rgb(23,23,26, 0.4)',
        padding: '4px 10px 4px 10px',
        color: '#FFFFFF',
        fontSize: '10px',
        fontWeight: 'bold',
        letterSpacing: '0.25px',
        lineHeight: '16px',
    },
    rightLayout: {
        flex: 1,
        padding: '16px 24px 24px 24px',
        [theme.breakpoints.up('sm')]: {
            padding: '16px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px',
        }
    },
    headCountRow: {
        display: 'flex',
        marginTop: '12px'
    },
    textLight: {
        marginLeft: '4px',
        '&.paragraph4': {
            color: '#5C5C66',
        }
    },
    headCountRowRight: {
        marginLeft: '16px',
    },
    ticketRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '33px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '22px',
        }
    },
    tourDateRow: {
        display: 'flex',
        alignItems: 'center',
    },
    headCountTitle: {
        '&.paragraph4': {
            fontWeight: 'bold',
        }
    },
    tourDate: {
        '&.paragraph3': {
            fontWeight: 'bold'
        }
    },
    tourTime: {
        marginLeft: '8px'
    },
    buyButton: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '0px',
        },
    },
    link: {
        textDecoration: 'none',
    },
    subTitle: {
        '&.paragraph4': {
            color: '#5C5C66',
        }
    }
}));

export default function TourGuideTourListItem(props) {
    const { tour } = props;
    const classes = useStyles({ tour });

    const { t, i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={classes.image}>
                <div className={classes.badgeArea}>
                    <div className={classes.badgeList}>
                        {tour.distance && <div className={classes.badge}>
                            {tour.distance}
                        </div>}
                        {tour.time && <div className={classes.badge}>
                            {tour.time}
                        </div>}
                        {tour.difficulty && <div className={classes.badge}>
                            {tour.difficulty}
                        </div>}
                    </div>
                    <div className={classes.badgeList}>
                        <div className={classes.badge}>
                            {tour.tourLocation.name}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.rightLayout}>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(tour.id), i18n.language)} className={classes.link}>
                    <h4>
                        {tour.name}
                    </h4>
                    {tour.subTitle &&
                        <div className={clsx('paragraph4', classes.subTitle)}>
                            {tour.subTitle}
                        </div>
                    }
                </Link>
                <div className={classes.headCountRow}>
                    <div className={clsx('paragraph4', classes.headCountTitle)}>
                        {t('Text.Saturation')}
                    </div>
                    <div className={clsx('paragraph4', classes.textLight)}>
                        {`${tour.tourDates.length > 0 ? tour.tourDates[0].limit : 0}/${0} ${t('Text.Person')}`}
                    </div>
                    <div className={clsx('paragraph4', classes.headCountTitle, classes.headCountRowRight)}>
                        {t('Text.MinimumLimit')}
                    </div>
                    <div className={clsx('paragraph4', classes.textLight)}>
                        {`${tour.tourDates.length > 0 ? tour.tourDates[0].minimumLimit : 0} ${t('Text.Person')}`}
                    </div>
                </div>
                <div className={classes.ticketRow}>
                    <div className={classes.tourDateRow}>
                        <div className={clsx('paragraph3', classes.tourDate)}>
                            {'2019. július 2.'}
                        </div>
                        <div className={clsx('paragraph3', classes.tourTime)}>
                            {'15:00'}
                        </div>
                    </div>
                    {/*<PrimaryBuyingButton variant={'big'} className={classes.buyButton} tour={tour}/>*/}
                </div>
            </div>
        </div>
    );
}
