import * as React from "react";

function Svg02Idotartam(props) {
    return (
        <svg width={16} height={16} {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M5.667.346V9l6.096 6.062A8 8 0 115.667.346z" fill="#5C5C66"/>
                <path d="M8 0a8 8 0 015.703 13.61L8 8V0z" fill="#17171A"/>
            </g>
        </svg>
    );
}

export default Svg02Idotartam;
