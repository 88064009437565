export default {
    '& .calendarWrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '32px 0px',
        width: '100%',
        '@media (min-width:400px)': {
            padding: '32px 0px',
        },
        '@media (min-width:760px)': {
            flexDirection: 'row',
            padding: '48px 60px',
            borderRadius: '12px',
        },
        '@media (min-width:1280px)': {
            padding: '48px 100px',
        },
        '& > * + *': {
            marginTop: '19px',
            '@media (min-width:760px)': {
                marginTop: '0px',
                marginLeft: '19px',
            },
            '@media (min-width:1280px)': {
                marginTop: '0px',
                marginLeft: '30px',
            }
        },
    },
    '& .calendarWrapper iframe': {
        '@media (min-width:760px)': {
            borderRadius: '12px',
        },
        display: 'block',
        margin: '0px auto',
    },
};
