import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / barlangbejarat"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#E6CFB8" cx={24} cy={24} r={24}/>
                <path
                    d="M34.498 30h-9.402c-.032-3.276-1.036-5.82-2.312-5.82-1.277 0-2.28 2.544-2.312 5.82H13.5c.08-3.296 1.629-5.95 3.495-5.95l.21.006.014-.152c.381-4.22 2.733-7.404 5.469-7.404 2.59 0 4.861 2.865 5.402 6.811l.028.205.2-.075c.425-.16.863-.242 1.301-.242 2.691 0 4.881 2.98 4.881 6.644 0 .052 0 .105-.002.157z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent