import * as React from "react";

function Svg01Hossz(props) {
    return (
        <svg width={12} height={16} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M4.347 7.963a.363.363 0 01.329.25c.032.065.265.526.448 1.094.098.305.18.637.203.958.023.321-.01.643-.196.901-.33.458-.895.717-1.48.803-.583.085-1.2.001-1.655-.327-.256-.185-.394-.476-.48-.785a4.236 4.236 0 01-.135-.968 7.954 7.954 0 01.045-1.176.36.36 0 01.303-.362l2.554-.384a.342.342 0 01.064-.004zM2.13.001c.819.025 1.566.7 1.955 1.727.887 2.34.132 5.015.13 5.021a.357.357 0 01-.293.306l-2.316.348a.36.36 0 01-.39-.24c-.084-.144-.457-.81-.768-1.695C.098 4.465-.186 3.17.151 1.986.343 1.312.621.816.965.483c.257-.25.554-.403.859-.46L1.9.013C1.957.005 2.015 0 2.073 0z"
                    fill="#17171A"
                />
                <path
                    d="M7.464 11.876a.35.35 0 01.065.007l2.563.52a.362.362 0 01.287.381c.005.067.039.586-.016 1.185-.03.32-.083.66-.188.967-.104.307-.258.592-.528.765-.476.306-1.105.358-1.691.242-.586-.117-1.145-.407-1.454-.886-.174-.27-.19-.594-.15-.916.039-.322.14-.652.256-.954a7.951 7.951 0 01.511-1.076.368.368 0 01.345-.235zM10.004 4a1.472 1.472 0 01.31.04c.305.072.597.242.844.506.329.353.585.868.743 1.556.278 1.208-.077 2.497-.485 3.487a10.63 10.63 0 01-.865 1.666.365.365 0 01-.409.221l-2.323-.472a.36.36 0 01-.28-.323c-.002-.007-.624-2.738.397-5.046.448-1.013 1.24-1.652 2.069-1.635z"
                    fill="#5C5C66"
                />
            </g>
        </svg>
    );
}

export default Svg01Hossz;
