import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import {contentActions} from 'client_shared/src/state/actions';
import {useTranslation} from 'react-i18next';
import NewsList from './lists/newsList/NewsList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    }
}));

export default function NewsContentBar() {
    const classes = useStyles();
    const news = useSelector(state => state.content.news);
    const newsCount = news.length;
    const [page, setPage] = useState(1);
    const settings = useSelector(state => state.setting.settings);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    function onMore() {
        setPage(page + 1);
        dispatch(contentActions.getNews());
    }

    const newsNumber = settings ? page * settings.newsPageNewsLimit : 0;

    return (
        <div className={classes.layout}>
            <NewsList news={news.slice(0, newsNumber)}/>
            {newsCount > newsNumber &&
            <div className={classes.buttonRow}>
                <div className={clsx('linkBig', classes.moreNews)} onClick={onMore}>{t('Text.MoreNews')}</div>
            </div>
            }
        </div>
    );
};
