import * as React from "react";

function SvgChevronDownSmaller(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M32 34.8l10.7-11c1-1 2.6-1 3.5 0 1 1 1 2.6 0 3.6L33.8 40.2c-1 1-2.6 1-3.5 0L17.7 27.4c-1-1-1-2.6 0-3.6s2.6-1 3.5 0l10.8 11z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgChevronDownSmaller;
