import * as React from "react";

function SvgDanger(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M35.6 6l27.9 47.7c.4.6.6 1.4.6 2.1 0 2.3-1.8 4.1-4.1 4.1H4.1c-.7 0-1.5-.2-2.1-.6-2-1.2-2.6-3.7-1.5-5.7L28.4 6c.4-.6.9-1.1 1.5-1.5 2-1.1 4.5-.4 5.7 1.5zm-1.5 39.5H30v4.1h4.1v-4.1zm0-20.7H30v14.5h4.1V24.8z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgDanger;
