import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / szallas / csak_keszpenz"}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#B5D96C" cx={24} cy={24} r={24}/>
                <path
                    d="M27.747 27.434c1.547 0 2.8 1.28 2.785 2.839C30.49 31.789 29.238 33 27.691 33c-1.562 0-2.8-1.252-2.786-2.769.014-1.572 1.266-2.797 2.842-2.797zm3.967 0c1.562 0 2.8 1.267 2.786 2.825a2.833 2.833 0 01-4.108 2.435c.731-.46 1.252-1.378 1.266-2.435.028-1.113-.492-2.06-1.224-2.532.38-.195.816-.293 1.28-.293zm-2.926-12.286c.59-.236.59-.236.83.348l3.18 7.597c.253.585.253.585-.338.821l-14.786 6.067c-.591.236-.591.236-.83-.348l-3.194-7.597c-.24-.585-.24-.585.352-.821zm-3.194 6.442c-.534-1.266-2.04-1.892-3.334-1.363-1.308.529-1.942 2.031-1.393 3.311a2.595 2.595 0 003.349 1.378c1.294-.529 1.913-2.032 1.378-3.326z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
