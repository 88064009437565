import * as React from "react";

function Svg04Homerseklet(props) {
    return (
        <svg width={8} height={16} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M4 0a3 3 0 013 3v6.354a4 4 0 11-6 0V3a3 3 0 013-3zm0 1.333c-.92 0-1.667.747-1.667 1.667v6.918a2.667 2.667 0 103.333 0V3c0-.92-.746-1.667-1.666-1.667z"
                    fill="#5C5C66"
                />
                <path
                    d="M4 2.333c.368 0 .667.299.667.667l.001 7.846a1.333 1.333 0 11-1.335 0V3c0-.368.299-.667.667-.667z"
                    fill="#17171A"
                />
            </g>
        </svg>
    );
}

export default Svg04Homerseklet;
