import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import TicketIcon from '../../icons/multicolor/TicketIcon';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '500px',
        },
    },
    layoutTicketBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '270px',
        padding: '48px 28px',
        backgroundColor: 'white',
        [theme.breakpoints.up('sm')]: {
            height: '355px',
            padding: '64px',
        },
        '& > * + *': {
            marginTop: '16px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '32px',
            },
        },
        '& h3': {
            margin: ''
        }
    },
    ticketBarTitle: {
        textAlign: 'center',
    },
    ticketBarImage: {
        width: '96px',
        [theme.breakpoints.up('sm')]: {
            width: '128px',
        },
    },
    topProgrammes: {
        padding: '16px 28px',
        backgroundColor: '#F2F2F2',
        [theme.breakpoints.up('sm')]: {
            padding: '32px',
        },
    }
}));

export default function CheckoutEmpty() {
    const classes = useStyles();
    /*
        const [topProgrammes, setTopProgrammes] = useState([]);
    */

    const { t } = useTranslation();

    /*    useEffect(() => {
            let cancelled = false;

            async function loadTopProgrammes() {
                const topProgrammes = await tourService.getTours({
                    topProgram: true,
                    start: 1,
                    end: 3,
                    sort: 'topProgramIndex',
                    order: 'ASC'
                });

                if (!cancelled) {
                    setTopProgrammes(topProgrammes);
                }
            }

            loadTopProgrammes().catch(error => console.log(error));

            return () => {
                cancelled = true
            };
        }, []);*/


    return (
        <div className={classes.layout}>
            <div className={classes.layoutTicketBar}>
                <TicketIcon className={classes.ticketBarImage}/>
                <h3 className={classes.ticketBarTitle}>{t('Text.TicketsArea')}</h3>
            </div>
            {/*
            <div className={classes.topProgrammes}>
                <TopCheckoutProgramList topProgrammes={topProgrammes}/>
            </div>
*/}
        </div>
    );
}
