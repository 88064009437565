import * as React from "react";

function Svg06Lepcsoszam(props) {
    return (
        <svg width={16} height={12} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M2 7.333h3.333v-1a2 2 0 012-2h3.334v-1a2 2 0 012-2H16V8a4 4 0 01-4 4H2.333A2.333 2.333 0 010 9.667v-.334a2 2 0 012-2z"
                    fill="#5C5C66"
                />
                <path
                    d="M5.333 7.333v2H0a2 2 0 012-2h3.333zm5.334-3.666v2H5.333a2 2 0 012-2h3.334zM16 0v2h-5.333a2 2 0 012-2H16z"
                    fill="#17171A"
                />
            </g>
        </svg>
    );
}

export default Svg06Lepcsoszam;
