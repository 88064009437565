import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        position: 'relative',
        width: '100%',
        height: '182px',
        marginTop: '0px',
        [theme.breakpoints.up('sm')]: {
            height: '230px',
            marginTop: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '300px',
            marginTop: '24px',
        },
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url("/content/images/${props.article.headerImage}")`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }),
    titleLayout: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '& h1': {
            color: '#FFFFFF',
            padding: '32px 28px',
            [theme.breakpoints.up('sm')]: {
                padding: '32px 0px',
            },
            [theme.breakpoints.up('lg')]: {
                padding: '48px 100px 48px 100px',
            },
        },
    },
}));

export default function MicroSiteArticleHeader(props) {
    const { article } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.layout}>
            <div className={clsx('padding-article', classes.titleLayout)}>
                <h1>
                    {article.title}
                </h1>
            </div>
        </div>
    );
}
