import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import InfoIcon from '../../../../icons/mono/InfoIcon';
import RestaurantMenuIcon from '../../../../icons/mono/EatIcon';
import HealingIcon from '../../../../icons/mono/SafetyIcon';
import AccessibleIcon from '../../../../icons/mono/WheelchairIcon';
import PhoneIcon from '../../../../icons/mono/PhoneIcon';
import {useSelector} from 'react-redux';
import {getActualMenu} from 'shared/src/helpers/menuHelper';
import {MENU} from 'shared';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layoutOnPage: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        '& > *:not(:first-child)': {
            marginTop: '12px',
        },
    },
    layoutInMenu: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        '& > *:not(:first-child)': {
            marginTop: '6px',
        }
    },
    linkOnPage: {
        borderRadius: '8px',
        backgroundColor: '#E6E6E6',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none'
    },
    linkInMenu: {
        borderRadius: '6px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: '16px',
        [theme.breakpoints.up('lg')]: {
            padding: '6px 10px',
        }
    },
    iconOnPage: {
        width: '24px',
        height: '24px',
        color: '#5C5C66',
    },
    iconInMenu: {
        width: '20px',
        height: '20px',
        color: '#5C5C66',
        [theme.breakpoints.up('lg')]: {
            width: '16px',
            height: '16px',
        }
    },
    textOnPage: {
        '&.paragraph2': {
            color: '#5C5C66',
            textDecoration: 'none',
            [theme.breakpoints.up('lg')]: {
                color: '#2E2E33',
            }
        },
        marginLeft: '12px',
    },
    textInMenu: {
        '&.paragraph3': {
            color: '#5C5C66',
            textDecoration: 'none',
            [theme.breakpoints.up('lg')]: {
                color: '#2E2E33',
            }
        },
        marginLeft: '16px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: '8px',
        }
    },
}));

export default function TourInfoMenuList(props) {
    const { isMenu } = props;
    const classes = useStyles();

    const menus = useSelector(state => state.menu.menus);
    const user = useSelector(state => state.user.user);

    const { i18n } = useTranslation();

    const menu = getActualMenu(menus, MENU.TYPES_ANP.ANP_MENU_TOURS_SIDE, user);

    const menuIcons = [
        <InfoIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <RestaurantMenuIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <HealingIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <AccessibleIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
        <PhoneIcon className={clsx(isMenu ? classes.iconInMenu : classes.iconOnPage)} fill={'#5C5C66'}/>,
    ];

    return (
        <div className={clsx(isMenu ? classes.layoutInMenu : classes.layoutOnPage)}>
            {menu.map(menuItem => menuItem.index > 0 &&
                <Link key={menuItem.id} to={getPathWithLanguage(menuItem.url, i18n.language)}
                      className={clsx(isMenu ? classes.linkInMenu : classes.linkOnPage)}>
                    {menuIcons[menuItem.iconId - 1]}
                    <div
                        className={clsx(isMenu ? clsx('paragraph3', classes.textInMenu) : clsx('paragraph2', classes.textOnPage))}>
                        {menuItem.title}
                    </div>
                </Link>
            )}
        </div>
    );
}
