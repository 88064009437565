import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / apartment-web"}</title>
            <g fill="none" fillRule="nonzero">
                <path
                    d="M23.989 13C17.917 13 13 17.928 13 24s4.917 11 10.989 11C30.072 35 35 30.072 35 24s-4.928-11-11.011-11z"
                    fill="#F2CA00"
                />
                <path
                    d="M24 8C15.121 8 8 15.214 8 24c0 8.879 7.214 16 16 16 8.879 0 16-7.214 16-16 0-8.879-7.121-16-16-16zM10.035 25.017h5.456c.093 1.85.278 3.515.648 5.087h-4.625c-.832-1.572-1.387-3.237-1.48-5.087zm14.982-9.156v-5.734c1.758.648 3.237 2.682 4.255 5.642h-4.255v.092zm4.81 2.035c.37 1.572.647 3.33.647 5.087h-5.55v-5.087h4.903zm-6.844-7.769v5.642h-4.255c1.018-2.867 2.497-4.994 4.255-5.642zm0 7.769v5.087h-5.55c.093-1.85.278-3.515.648-5.087h4.902zm-7.492 5.087h-5.456c.092-1.85.647-3.515 1.387-5.087h4.624c-.37 1.572-.555 3.237-.555 5.087zm1.943 2.034h5.549v5.087h-4.81a28.03 28.03 0 01-.74-5.087zm5.549 7.122v5.734c-1.758-.648-3.237-2.682-4.255-5.642h4.255v-.092zm2.034 5.734V32.23h4.255c-1.018 2.867-2.497 4.994-4.255 5.642zm0-7.769v-5.087h5.55c-.093 1.85-.278 3.515-.648 5.087h-4.902zm7.492-5.087h5.456c-.092 1.85-.647 3.515-1.387 5.087h-4.624c.37-1.572.555-3.237.555-5.087zm0-2.034c-.093-1.85-.278-3.515-.648-5.087h4.625c.74 1.572 1.202 3.33 1.387 5.087h-5.364zm2.867-7.122h-3.977c-.648-1.942-1.387-3.607-2.405-4.901 2.59.924 4.81 2.682 6.382 4.901zm-16.37-4.901c-1.018 1.294-1.757 2.96-2.405 4.901h-3.977c1.573-2.22 3.792-3.977 6.382-4.901zm-6.382 21.179h3.977c.648 1.942 1.387 3.607 2.405 4.901-2.59-.924-4.81-2.682-6.382-4.901zm16.37 4.901c1.018-1.294 1.757-2.96 2.405-4.901h3.977c-1.573 2.22-3.792 3.977-6.382 4.901z"
                    fill="#17171A"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
