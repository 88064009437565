import React, {useState} from 'react';
import {makeStyles, Popover} from '@material-ui/core';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import clsx from 'clsx';
import Lightbox from 'react-image-lightbox';
import {imageModule} from 'shared/src/modules/image';

const useStyles = makeStyles(theme => ({
    '@global': {
        '.lightBox': {},
        '.lightBox-single': {
            '& .ril__navButtons': {
                display: 'none'
            }
        }
    }
}));

export default function ArticleContent(props) {
    const { className, content, narrow = false} = props;
    const [lightBoxOpen, setLightBoxOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [images, setImages] = useState(['/content/images/153_menes_.jpg']);
    useStyles({narrow});

    function handleClick(event) {
        if (event.target && event.target.getAttribute('class') && event.target.getAttribute('class').indexOf('imageButton-gallery') > -1) {
            const imagesWrapperNode = imageModule.getImagesWrapperNode(event.target);
            if (imagesWrapperNode) {
                const { images } = imageModule.getImagesAndOptionsFromWrapperNode(imagesWrapperNode);
                setImages(images || []);
                setImageIndex(0);
                setLightBoxOpen(true);
            }
        }
        if (event.target && event.target.getAttribute('class') && event.target.getAttribute('class').indexOf('imageButton-enlarge') > -1) {
            const imageWrapperNode = imageModule.getImageWrapperNode(event.target);
            if (imageWrapperNode) {
                const image = imageModule.getImageFromWrapperNode(imageWrapperNode);
                setImages(image ? [image] : []);
                setImageIndex(0);
                setLightBoxOpen(true);
            }
        }
    }

    return (
        <div className={clsx(className, 'contentAnp', narrow && 'contentNarrow')} onClick={handleClick}>
            <div className={'ql-container'} dangerouslySetInnerHTML={createMarkup(content)}/>
            {lightBoxOpen &&
            <Popover
                anchorEl={document.body}
                open={lightBoxOpen}
                marginThreshold={0}
            >
                <Lightbox
                    mainSrc={images[imageIndex].src}
                    imageCaption={images[imageIndex].title}
                    nextSrc={images[(imageIndex + 1) % images.length].src}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length].src}
                    onCloseRequest={() => setLightBoxOpen(false)}
                    onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
                    reactModalStyle={{ overlay: { zIndex: 2000 } }}
                    wrapperClassName={images.length > 1 ? 'lightBox' : 'lightBox-single'}
                />
            </Popover>
            }
        </div>
    );
}
