import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {tourModule} from 'shared/src/modules/tour';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderBottom: '1px solid #D9D9D9',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            '&:last-child': {
                borderBottom: '0px',
            },
            padding: '0px 0px 0px 0px',
            flexDirection: 'row',
            height: '132px',
        },
    },
    title: {
        paddingBottom: '7px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '10px',
        },
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '16px 0px 0px 0px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px 0px 24px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '32px 0px 32px 0px',
        },
    },
    summaryBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    subTitle: {
        '&.paragraph4': {
            color: '#5C5C66',
        }
    },
    programType: (props) => ({
        '&.paragraph3': {
            fontWeight: 'bold',
            color: props.topProgram.tourType.color,
        },
        paddingBottom: '4px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '0px',
        },
    }),
    programDivider: {
        borderRight: '4px solid #D9D9D9',
        borderRadius: '12px',
        margin: '0px 10px 0px 12px',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    programBlock: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    withBuyButton: {
        justifyContent: 'space-between',
    },
    moreDetailsBlock: {
        minWidth: '160px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'flex-start',
        padding: '24px 0px 24px 0px',
        [theme.breakpoints.up('sm')]: {
            padding: '28px 0px 28px 0px',
            minWidth: '150px',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '25px 0px 25px 0px',
            minWidth: '160px',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
    },
    buyTicketButton: {
        width: '136px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5px',
            marginBottom: '5px',
            width: '150px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '160px',
        },
    },
    linkRight: {
        marginLeft: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '5px',
            marginBottom: '5px',
            marginLeft: '0px',
        },
    },
    summary: {
        '&.paragraph3': {
            color: '#5C5C66'
        }
    }
}));

export default function TopProgramListItem(props) {
    const { topProgram } = props;
    const classes = useStyles(props);
    const { t, i18n } = useTranslation();

    const summary = tourModule.formatSummary(topProgram);

    return (
        <div className={classes.layout}>
            <div className={classes.infoBlock}>
                <h3>
                    {topProgram.name}
                </h3>
                {topProgram.subTitle &&
                    <div className={clsx('paragraph4', classes.subTitle)}>
                        {topProgram.subTitle}
                    </div>
                }
                <div className={classes.programBlock}>
                    <div className={classes.summaryBlock}>
                        <div className={clsx('paragraph3', classes.programType)}>
                            {topProgram.tourType.title}
                        </div>
                        {summary && <div className={classes.programDivider}/>}
                        <div className={clsx('paragraph3', classes.summary)}>
                            {summary}
                        </div>
                    </div>
                </div>
            </div>
            {tourModule.hasTickets(topProgram) &&
                <div className={clsx(classes.moreDetailsBlock/*, classes.withBuyButton*/)}>
                    {/*
                <PrimaryBuyingButton className={classes.buyTicketButton} variant={'big'}
                                     tour={topProgram}/>
*/}
                    <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(topProgram.id), i18n.language)}
                          className={clsx('linkSecondary', classes.linkRight)}>{t('Text.Details')}</Link>
                </div>
            }
            {!tourModule.hasTickets(topProgram) &&
                <div className={classes.moreDetailsBlock}>
                    <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(topProgram.id), i18n.language)}
                          className={'linkSecondary'}>{t('Text.Details')}</Link>
                </div>
            }
        </div>
    );
}
