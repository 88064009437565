import * as React from "react";

function SvgLatnivalokSajatSzervezesuProgramok(props) {
    return (
        <svg
            id="latnivalok-sajat_szervezesu_programok_svg__Layer_1"
            x={0}
            y={0}
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            {...props}
        >
            <style>
                {
                    ".latnivalok-sajat_szervezesu_programok_svg__st2{fill:#45454e}.latnivalok-sajat_szervezesu_programok_svg__st5{fill:#fff}"
                }
            </style>
            <g id="latnivalok-sajat_szervezesu_programok_svg__Layer_4">
                <g id="latnivalok-sajat_szervezesu_programok_svg__latnivalok-sajat_szervezesu_programok">
                    <circle cx={32.1} cy={32.1} r={27.5} fill="#ccc"/>
                    <path
                        d="M59.6 33.5H4.7v.4c1 15.2 14.1 26.6 29.2 25.7 13.9-.9 25-12.1 25.7-26.1z"
                        fill="#b3b3b3"
                    />
                    <g id="latnivalok-sajat_szervezesu_programok_svg__naptar">
                        <path
                            className="latnivalok-sajat_szervezesu_programok_svg__st2"
                            d="M38.5 42.6H15c-1.7 0-3.1-1.4-3.1-3.1v-.3l1.8-18.5c.2-1.5 1.4-2.6 2.9-2.7l27.1 1-2.1 20.8c-.2 1.6-1.5 2.8-3.1 2.8z"
                        />
                        <path
                            d="M15 18.9c.1-1 1.6-.9 3.1-.9l23.7 1c.5 0 3.3-.7 3.4-.3l.8 17.6c.2.7-.7 4.9-1.4 4.9-.1 1-2.5 1.9-3.5 1.9H22.4c-.6 0-1.3-.1-1.9-.2-.9-.1-1.8-.3-2.6-.8-.9-.5-1.5-1.3-1.8-2.2-.5-1.7-.7-3.6-.6-5.4 0-1.9-.1-3.8-.1-5.7-.1-3.3-.4-6.6-.4-9.9z"
                            fill="#5c5c67"
                        />
                        <path
                            d="M15.7 18.4c-.2-.6 3.5-.4 4-.4l24.1 1c.9 0 2.9-.4 3.1.5l3 18.5c.4 1.5-.8 4.6-2.4 4.6 0 .5-3.4.9-4 .9H25.1c-2.2 0-4.1-1.4-4.9-3.5-.3-.9-.6-1.9-.8-2.8-1.2-4.9-1.2-10.1-2.4-15-.4-1.3-.9-2.5-1.3-3.8z"
                            fill="#717180"
                        />
                        <path
                            className="latnivalok-sajat_szervezesu_programok_svg__st5"
                            d="M50.6 44H27.9c-1.5 0-2.9-1-3.3-2.5 0 0-4.8-17-5.6-20S16.6 18 16.6 18h29c1.3 0 2.5.9 2.8 2.2l5.4 19.5c.5 1.8-.5 3.7-2.3 4.2-.3.1-.6.1-.9.1z"
                        />
                        <path
                            d="M22.6 23.5c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1 1.1-1 .5 0 1.2-.9 1.2-2.4s-.7-2.4-1.2-2.4c-.8 0-1.1.5-1.3 1.3h-1.9c0-1.8 1.4-3.2 3.2-3.4 1.9 0 3.3 2 3.3 4.5s-1.4 4.5-3.3 4.5zM37.5 19c0-2.5-1.5-4.5-3.3-4.5-1.8.2-3.2 1.7-3.2 3.5h1.9c.2-.8.5-1.3 1.3-1.3.5 0 1.2.9 1.2 2.4s-.7 2.4-1.2 2.4c-.6 0-1 .5-1 1 0 .6.5 1.1 1 1.1 1.8-.1 3.3-2.1 3.3-4.6zm10.2 0c0-2.5-1.5-4.5-3.3-4.5a3.4 3.4 0 00-3.2 3.4h1.9c.2-.8.5-1.3 1.3-1.3.5 0 1.2.9 1.2 2.4s-.7 2.4-1.2 2.4c-.6 0-1 .5-1 1 0 .6.5 1.1 1 1.1 1.8 0 3.3-2 3.3-4.5z"
                            fill="#17171a"
                        />
                        <path
                            className="latnivalok-sajat_szervezesu_programok_svg__st2"
                            d="M49.7 35.2h-4.5L43.5 29h4.2c.4 0 .8-.3.8-.8s-.3-.8-.8-.8h-4.6l-.9-3.3c-.1-.4-.5-.6-.9-.5-.4.1-.6.5-.5.9l.8 2.9H30.9l-.8-2.9c-.1-.4-.5-.7-.9-.6-.4.1-.7.5-.6.9v.1l.7 2.5h-5.5c-.4 0-.8.3-.8.8s.3.8.8.8h5.9l1.7 6.2h-5.6c-.4 0-.8.3-.8.8s.3.8.8.8h6l1.1 4c.1.3.4.6.7.5h.2c.4-.1.6-.5.5-.9l-1-3.7h10.6l1 3.7c.1.3.4.6.7.5h.2c.4-.1.6-.5.5-.9l-.9-3.3h4c.4 0 .8-.3.8-.8s-.1-.7-.5-.7zm-16.6 0L31.4 29H42l1.7 6.2H33.1z"
                        />
                    </g>
                    <g id="latnivalok-sajat_szervezesu_programok_svg__ceruza">
                        <path d="M24.4 50c-.2.1-.3.3-.2.5 0 .1.1.2.2.2l1.8.7v-2.2l-1.8.8z"/>
                        <path
                            className="latnivalok-sajat_szervezesu_programok_svg__st5"
                            d="M30.1 47.7l-3.9 1.5v2.2l3.9 1.5c.1 0 .3.1.4.1v-5.3h-.4z"
                        />
                        <path d="M30.6 47.6V53h27.7v-5.4H30.6z" fill="#f2b600"/>
                        <path
                            d="M62.5 47.6h-4.2V53h4.2c.8 0 1.5-.7 1.5-1.5v-2.4c0-.8-.7-1.5-1.5-1.5z"
                            fill="#f3a200"
                        />
                        <path
                            d="M57.5 47.3h1.6c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5h-1.6c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5z"
                            fill="#9b9ba0"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgLatnivalokSajatSzervezesuProgramok;
