import React from 'react';
import {makeStyles} from '@material-ui/core';
import LogoIcon from '../../icons/logos/AnpiLogoWTextCenteredInversIcon';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        height: '68px',
        backgroundColor: '#17171A',
        [theme.breakpoints.up('sm')]: {
            height: '90px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '75px',
        },
    },
    logoBar: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        color: theme.palette.common.white,
        height: '58px',
    }
}));

export default function SecondaryHeaderBar() {
    const classes = useStyles();

    const { i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={clsx('padding', classes.logoBar)}>
                <Link to={getPathWithLanguage(ROUTES_ANP.HOME, i18n.language)}>
                    <LogoIcon className={classes.logo}/>
                </Link>
            </div>
        </div>
    );
}
