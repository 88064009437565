import * as React from "react";

function SvgSearch(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M45.7 40.3h-2.9l-1-1c3.6-4.2 5.7-9.6 5.7-15.5C47.6 10.6 36.9 0 23.8 0S0 10.6 0 23.8s10.6 23.8 23.8 23.8c5.9 0 11.3-2.2 15.5-5.7l1 1v2.9L58.5 64l5.5-5.5-18.3-18.2zm-21.9 0c-9.1 0-16.5-7.4-16.5-16.5S14.7 7.3 23.8 7.3s16.5 7.4 16.5 16.5-7.4 16.5-16.5 16.5z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgSearch;
