import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '12px',
        background: 'linear-gradient(135deg, #F2E0CE 0%, #FAF4ED 100%)',
        padding: '24px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '25px 48px 25px 25px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0px 100px',
            padding: '32px'
        }
    },
    message: {
        '&.h4': {
            fontWeight: 'normal',
            marginBottom: '24px',
            [theme.breakpoints.up('sm')]: {
                marginRight: '100px',
                marginBottom: '0px',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '24px',
                marginRight: '32px',
                marginBottom: '0px',
            },
        },
    }
}));

export default function OtherProgrammesBanner() {
    const classes = useStyles();
    const history = useHistory();

    const { t, i18n } = useTranslation();

    function handleShowMeClick() {
        history.push(getPathWithLanguage(ROUTES_ANP.TOURS, i18n.language));
    }

    return (
        <div className={classes.layout}>
            <div className={clsx('h4', classes.message)} dangerouslySetInnerHTML={createMarkup(t('Text.OtherToursAndProgrammes'))} />
            <PrimaryBasicButton variant={'big'} title={t('Text.ShowMe')} onClick={handleShowMeClick}/>
        </div>
    );
}
