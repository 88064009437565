import * as React from "react";

function SvgNews(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M47.6 57.8c-.9 0-1.7-.2-2.4-.5-2.2-1.1-3.8-2.7-5.3-7.3-1.6-4.8-4.6-7.1-7.4-9.2-2.5-1.9-5-3.8-7.2-7.8-1.6-3-2.6-6.2-2.6-9.1 0-8.6 6.8-15.4 15.6-15.4S53.8 15.3 53.8 24H60c0-12.1-9.6-21.6-21.8-21.6S16.4 11.9 16.4 24c0 3.9 1.2 8.2 3.3 12 2.8 5.1 6.2 7.6 8.9 9.7C31.2 47.6 33 49 34 52c1.9 5.6 4.3 8.7 8.5 10.9 1.6.7 3.3 1.1 5.1 1.1C54.4 64 60 58.5 60 51.7h-6.2c0 3.4-2.8 6.1-6.2 6.1zM18.4 4.4L14 0C7.8 6.1 4 14.6 4 24s3.8 17.8 10 24l4.4-4.3c-5-5-8.2-11.9-8.2-19.6s3.2-14.7 8.2-19.7zm12 19.6c0 4.3 3.5 7.7 7.8 7.7S46 28.2 46 24s-3.5-7.7-7.8-7.7-7.8 3.4-7.8 7.7z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgNews;
