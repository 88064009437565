import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
            <title>{"icons / multi / apartment-opening"}</title>
            <g fill="none" fillRule="nonzero">
                <path
                    d="M23.989 13C17.917 13 13 17.928 13 24s4.917 11 10.989 11C30.072 35 35 30.072 35 24s-4.928-11-11.011-11z"
                    fill="#F2CA00"
                />
                <path
                    d="M23.984 8C15.152 8 8 15.168 8 24s7.152 16 15.984 16C32.832 40 40 32.832 40 24S32.832 8 23.984 8zm0 2C31.725 10 38 16.27 38 24s-6.275 14-14.016 14C16.26 38 10 31.731 10 24s6.26-14 13.984-14zm-.734 6c-.69 0-1.25.56-1.25 1.25v8.586l7.728 4.561c.568.335 1.3.155 1.647-.405a1.155 1.155 0 00-.373-1.59l-.026-.016-6.476-3.78V17.25c0-.69-.56-1.25-1.25-1.25z"
                    fill="#17171A"
                />
            </g>
        </svg>
    )
}

export default SvgComponent
