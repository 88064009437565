import createTheme from '@material-ui/core/styles/createTheme';

export const styleConstants = {
    headerBar: {
        mobileHeight: 70,
        tabletHeight: 90,
        desktopTopHeight: 40,
        desktopBottomHeight: 78,
        desktopHeight: 120,
    }
};

export const themeBase = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 760,
            md: 1020,
            lg: 1280,
            xl: 1430,
        }
    },
    typography: {
        fontFamily: [
            '"Nunito Sans"',
            '"sans-serif"',
        ].join(','),
        h1: {
            fontSize: '32px',
            color: '#17171A',
            lineHeight: '38px',
            letterSpacing: '0.5px',
            fontWeight: '900',
            '@media (min-width:760px)': {
                lineHeight: '50px',
                fontSize: '42px',
            },
            '@media (min-width:1280px)': {
                lineHeight: '57px',
                fontSize: '48px',
            },
        },
        h2: {
            fontSize: '26px',
            color: '#17171A',
            lineHeight: '44px',
            letterSpacing: '0.5px',
            fontWeight: '900',
            '@media (min-width:760px)': {
                fontSize: '30px',
            },
            '@media (min-width:1280px)': {
                fontSize: '32px',
            },
        },
        h3: {
            fontSize: '22px',
            color: '#17171A',
            lineHeight: '33px',
            letterSpacing: '0.5px',
            fontWeight: '800',
            '@media (min-width:760px)': {
                fontSize: '24px',
            },
        },
        h4: {
            fontSize: '18px',
            color: '#17171A',
            lineHeight: '28px',
            letterSpacing: '0.5px',
            fontWeight: '800',
            '@media (min-width:760px)': {
                fontSize: '20px',
            },
        },
        h5: {
            fontSize: '14px',
            color: '#17171A',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            fontWeight: '800',
            '@media (min-width:760px)': {
                fontSize: '16px',
            },
        },
        h6: {
            fontSize: '14px',
            color: '#17171A',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            fontWeight: '800',
            '@media (min-width:760px)': {
                fontSize: '16px',
            },
        },
        body1: {
            fontSize: '16px',
            color: '#17171A',
            lineHeight: '28px',
            letterSpacing: '0.5px',
            '@media (min-width:1280px)': {
                fontSize: '18px',
            },
        },
        paragraph1: {
            fontSize: '16px',
            color: '#17171A',
            lineHeight: '28px',
            letterSpacing: '0.5px',
            '@media (min-width:1280px)': {
                fontSize: '18px',
            },
        },
        body2: {
            fontSize: '14px',
            color: '#17171A',
            lineHeight: '25px',
            letterSpacing: '0.5px',
            fontWeight: '400',
            '@media (min-width:1280px)': {
                fontSize: '16px',
            },
        },
        paragraph2: {
            fontSize: '14px',
            color: '#17171A',
            lineHeight: '25px',
            letterSpacing: '0.5px',
            fontWeight: '400',
            '@media (min-width:1280px)': {
                fontSize: '16px',
            },
        },
        paragraph3: {
            fontSize: '12px',
            color: '#17171A',
            lineHeight: '22px',
            letterSpacing: '0.5px',
            fontWeight: '400',
            '@media (min-width:1280px)': {
                fontSize: '14px',
            },
        },
        paragraph4: {
            fontSize: '10px',
            color: '#17171A',
            lineHeight: '19px',
            letterSpacing: '0.5px',
            fontWeight: '400',
            '@media (min-width:1280px)': {
                fontSize: '12px',
            },
        },
        linkBig: {
            color: '#0F6099',
            display: 'inline-block',
            position: 'relative',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '20px',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            '@media (min-width:1280px)': {
                fontSize: '16px',
                lineHeight: '24px',
            },
            '&:before': {
                height: '2px',
                backgroundColor: '#1A9FFF',
                borderRadius: '1px',
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                width: '100%',
            },
            '&:hover': {
                '&:before': {
                    backgroundColor: '#F2A200',
                }
            },
            /*
                    '&:visited': {
                        '&:before': {
                            backgroundColor: '#CCEAFF',
                        }
                    },
            */
        },
        linkBigInverse: {
            color: '#CCEAFF',
            display: 'inline-block',
            position: 'relative',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '20px',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            '@media (min-width:1280px)': {
                fontSize: '16px',
                lineHeight: '24px',
            },
            '&:before': {
                height: '2px',
                backgroundColor: '#1A9FFF',
                borderRadius: '1px',
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                width: '100%',
            },
            '&:hover': {
                '&:before': {
                    backgroundColor: '#1A9FFF',
                }
            },
            /*
                    '&:visited': {
                        '&:before': {
                            backgroundColor: '#66BFFF',
                        }
                    },
            */
        },
        linkNormal: {
            color: '#0F6099',
            display: 'inline-block',
            position: 'relative',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '18px',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            '@media (min-width:1280px)': {
                fontSize: '14px',
                lineHeight: '20px',
            },
            '&:before': {
                height: '2px',
                backgroundColor: '#1A9FFF',
                borderRadius: '1px',
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                width: '100%',
            },
            '&:hover': {
                '&:before': {
                    backgroundColor: '#F2A200',
                }
            },
            /*
                    '&:visited': {
                        '&:before': {
                            backgroundColor: '#CCEAFF',
                        }
                    },
            */
        },
        linkNormalInverse: {
            color: '#CCEAFF',
            display: 'inline-block',
            position: 'relative',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '18px',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            '@media (min-width:1280px)': {
                fontSize: '14px',
                lineHeight: '20px',
            },
            '&:before': {
                height: '2px',
                backgroundColor: '#1A9FFF',
                borderRadius: '1px',
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                width: '100%',
            },
            '&:hover': {
                '&:before': {
                    backgroundColor: '#1A9FFF',
                }
            },
            /*
                    '&:visited': {
                        '&:before': {
                            backgroundColor: '#66BFFF',
                        }
                    },
            */
        },
        linkSecondary: {
            color: '#17171A',
            display: 'inline-block',
            position: 'relative',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '19px',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
            '@media (min-width:1280px)': {
                fontSize: '14px',
                lineHeight: '22px',
            },
            '&:before': {
                height: '1px',
                backgroundColor: '#17171A',
                borderRadius: '1px',
                content: '""',
                position: 'absolute',
                bottom: '4px',
                width: '100%',
            },
            '&:hover': {
                color: '#1A9FFF',
                '&:before': {
                    backgroundColor: '#1A9FFF',
                }
            },
            /*
                    '&:visited': {
                        '&:before': {
                            backgroundColor: '#5C5C66',
                        }
                    },
            */
        }
    },
    palette: {
        background: {
            default: 'rgb(255, 255, 255)',
            paper: 'rgb(255, 255, 255)',
        }
    },
    overrides: {
        MuiOutlinedInput: {
            input: {
                borderWidth: '1px',
                borderRadius: '8px',
                padding: '10px 12px 10px 12px',
                '@media (min-width:1280px)': {
                    padding: '12px 12px 12px 12px',
                }
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '12px',
                color: '#17171A',
                lineHeight: '1.6',
                letterSpacing: '0.3px',
                fontWeight: '400',
                '@media (min-width:1280px)': {
                    fontSize: '14px',
                },
            },
            input: {
                borderColor: '#D9D9D9',
                backgroundColor: '#FFFFFF',
            }
        },
    }
};

const theme = createTheme(themeBase);

export default theme;