import React from 'react';
import {makeStyles} from '@material-ui/core';
import TopNews from '../news/TopNews';
import TopNewsList from '../news/lists/topNewsList/TopNewsList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            minHeight: '600px',
            flexDirection: 'row',
        }
    },
    topNews: {
        [theme.breakpoints.up('lg')]: {
            flex: 1,
        }
    }
}));

export default function TopContentBar(props) {
    const { headlineContent, homePageNews } = props;
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <TopNews className={classes.topNews} headlineContent={headlineContent}/>
            <TopNewsList homePageNews={homePageNews}/>
        </div>
    );
}
