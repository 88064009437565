import React from 'react';
import {makeStyles} from '@material-ui/core';
import {ROUTES_ANP} from 'shared';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import OfferedProgramListItem from './OfferedProgramListItem';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '48px',
            marginBottom: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '64px',
            marginBottom: '0px',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '48px',
        },
    },
    headerItem: {
        '&:not(:first-child)': {
            marginLeft: '16px',
        }
    },
    headerLink: {
        '&.linkBig': {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            },
        }
    },
    footerLinkBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    footerLink: {
        margin: '24px 0px 32px 0px',
    },
    topProgrammes: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginTop: '-16px',
        '& > *': {
            height: '320px',
            marginTop: '16px',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginTop: '-20px',
            marginLeft: '-20px',
            '& > *': {
                height: '340px',
                flex: '1 1 calc(50% - 20px)',
                marginLeft: '20px',
                marginTop: '20px',
            }
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            height: '615px',
            marginLeft: '-30px',
            marginTop: '-30px',
            '& > *': {
                flex: '1 1 277px',
                height: '277px',
                marginLeft: '30px',
                marginTop: '30px',
            }
        },
    },
}));

export default function OfferedProgramList(props) {
    const { offeredProgrammes } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <div className={clsx('padding', classes.layout)}>
            <div className={classes.header}>
                <h2 className={classes.headerItem}>
                    {t('Text.TopProgrammes')}
                </h2>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOURS, i18n.language)}
                      className={clsx('linkBig', classes.headerLink, classes.headerItem)}>{t('Text.AllChoices')}</Link>
            </div>
            <div className={classes.topProgrammes}>
                {offeredProgrammes.map(offeredProgram =>
                    <OfferedProgramListItem key={offeredProgram.id} program={offeredProgram}/>
                )}
            </div>
            <div className={classes.footerLinkBlock}>
                <Link to={getPathWithLanguage(ROUTES_ANP.TOURS, i18n.language)}
                      className={clsx('linkBig', classes.footerLink, classes.headerItem)}>{t('Text.AllChoices')}</Link>
            </div>
        </div>
    );
}
