import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import Promotion from '../promotions/Promotion';
import ToursIconList from './lists/toursIconList/ToursIconList';
import TourInfoList from './lists/tourInfoList/TourInfoList';

const useStyles = makeStyles(theme => ({
    layout: {
        marginBottom: '32px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '48px',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '48px',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        }
    },
    layoutLeft: {
        flex: 1,
    },
    layoutRight: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            '&:not(:last-child)': {
                marginBottom: '32px',
            },
            flex: 1,
        },
        marginTop: '32px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginTop: '48px',
            marginLeft: '-20px',
            '& > *': {
                flex: 1,
                marginTop: '0px',
                marginLeft: '20px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            width: '278px',
            marginTop: '0px',
            marginLeft: '132px',
            '& > *': {
                marginLeft: '0px',
            },
            flexDirection: 'column',
        }
    }
}));

export default function ToursContentBar(props) {
    const { promotion } = props;
    const classes = useStyles();

    return (
        <div className={clsx('padding', classes.layout)}>
            <div className={classes.layoutLeft}>
                <ToursIconList isMenu={false}/>
            </div>
            <div className={classes.layoutRight}>
                <TourInfoList isMenu={false}/>
                <div>
                    <Promotion promotion={promotion}/>
                </div>
            </div>
        </div>
    );
}
