export default {
    '& .imagesWrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '32px 0px',
        width: '100%',
        '@media (min-width:400px)': {
            padding: '32px 0px',
        },
        '@media (min-width:760px)': {
            flexDirection: 'row',
            padding: '48px 60px',
            borderRadius: '12px',
        },
        '@media (min-width:1280px)': {
            padding: '48px 100px',
        },
        '& > * + *': {
            marginTop: '19px',
            '@media (min-width:760px)': {
                marginTop: '0px',
                marginLeft: '19px',
            },
            '@media (min-width:1280px)': {
                marginTop: '0px',
                marginLeft: '30px',
            }
        },
    },
    '& .imageWrapperNoRounding img': {
        '@media (min-width:760px)': {
            borderRadius: '0px',
        },
    },
    '& .imagesWrapper.imagesWrapperNoRounding': {
        '@media (min-width:760px)': {
            borderRadius: '0px',
        },
    },
    '& .imagesWrapper.imagesWrapperWide': {
        '@media (min-width:760px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    '& .imagesWrapper.imagesWrapperSlides': {
        '& > * + *': {
            display: 'none',
        }
    },
    '& .imageWrapper': {
        position: 'relative',
        display: 'inline-block',
        margin: '0px auto',
    },
    '& .imageWrapper:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'inline-block',
        '@media (min-width:760px)': {
            borderRadius: '12px',
        },
        background: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%)`,
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 )",
    },
    '& .imageWrapper.imageWrapperNoRounding:after': {
        '@media (min-width:760px)': {
            borderRadius: '0px',
        },
    },
    '& .imageWrapperNoGradient:after': {
        background: 'none',
        filter: 'none',
    },
    '& .imageTitle': {
        fontSize: '14px',
        lineHeight: '25px',
        letterSpacing: '0.5px',
        fontWeight: '400',
        '@media (min-width:1280px)': {
            fontSize: '16px',
        },
        color: '#FFFFFF',
        bottom: 0,
        position: 'absolute',
        width: '100%',
        padding: '24px',
        zIndex: 100,
    },
    '& .imageButton': {
        zIndex: 100,
        height: '40px',
        width: '40px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        left: 'auto',
        bottom: 0,
        right: 0,
        margin: '24px',
        border: 'none',
        cursor: 'pointer',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    '& .imageButton-enlarge': {
        backgroundSize: '20px 20px',
        backgroundImage: 'url("/content/images/icons/zoom_out_map.svg")',
    },
    '& .imageButton-gallery': {
        backgroundSize: '20px 20px',
        backgroundImage: 'url("/content/images/icons/collections.svg")',
    },
    '& .videoWrapper': {
        display: 'inline-block',
        margin: '0px auto',
        width: '100%',
        height: '100%',
    },
    '& .videoWrapper iframe, .video-container object, .video-container embed': {
        '@media (min-width:760px)': {
            borderRadius: '12px',
        },
        display: 'block',
        margin: '0px auto',
    },
};
