import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {tourModule} from 'shared/src/modules/tour';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {ROUTES_ANP} from 'shared/src/constants/routes';

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
                filter: 'drop-shadow(0px -3px 2px rgba(23,23,26,0.1))',
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
                filter: 'drop-shadow(0px 6px 3px rgba(23,23,26,0.1))',
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        zIndex: 200,
        boxSizing: 'border-box',
        minHeight: '270px',
        minWidth: '300px',
        border: '1px solid #E6E6E6',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 5px 0 rgba(23,23,26,0.1), 0 2px 4px 0 rgba(23,23,26,0.1), 0 3px 20px 0 rgba(23,23,26,0.1)',
    },
    header: {
        borderBottom: '1px solid #E6E6E6',

        '& h5': {
            margin: '16px 24px 12px 24px',
        }
    },
    eventBar: {
        margin: '12px 24px 12px 24px',
        borderBottom: '1px solid #D9D9D9',
    }
}));

export default function CalendarPopup(props) {
    const { open, anchorEl, date, tours = [] } = props;
    const classes = useStyles();
    const [arrowRef, setArrowRef] = useState(null);

    const { t, i18n } = useTranslation();

    const programs = tourModule.getToursForDate(tours, date);

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement="top"
            disablePortal={false}
            className={classes.popper}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window',
                },
                arrow: {
                    enabled: true,
                    element: arrowRef,
                },
            }}
            transition
        >
            <span className={classes.arrow} ref={setArrowRef}/>
            <div className={classes.paper}>
                <div className={classes.header}>
                    <h5>
                        {t('Text.ThisDay')}
                    </h5>
                </div>
                {programs.map(program => (
                    <div key={program.id} className={classes.eventBar}>
                        <Link to={getPathWithLanguage(ROUTES_ANP.TOUR(program.id), i18n.language)} className={clsx('linkSecondary')}>
                            {program.name}
                        </Link>
                    </div>
                ))}
            </div>
        </Popper>
    );
}
