import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';
import LocationBoxExternalLink from './LocationBoxExternalLink';
import clsx from 'clsx';
import {startsWith} from 'shared/src/utils/stringUtils';

const useStyles = makeStyles(theme => ({
    layoutOnPage: props => ({
        height: '425px',
        minWidth: '300px',
        borderRadius: '12px',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url(${props.image})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
    }),
    layoutInMenu: props => ({
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '380px',
        },
        height: '275px',
        borderRadius: '12px',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0.6) 0%, rgba(23,23,26,0.7) 100%), url(${props.image})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
    }),
    descriptionBoxOnPage: {
        padding: '24px',
        '& > *': {
            color: 'white',
            '&.paragraph2': {
                color: 'white'
            },
            '& > h2': {
                color: 'white',
            },
            '&:not(:first-child)': {
                marginTop: '16px',
            }
        }
    },
    descriptionBoxInMenu: {
        padding: '24px',
        '& > *': {
            '&.paragraph2': {
                color: 'white'
            },
            '& > h3': {
                color: 'white',
            },
        }
    },
    externalLinkBoxOnPage: {
        position: 'absolute',
        bottom: '24px',
        right: '40px',
    },
    externalLinkBoxInMenu: {
        position: 'absolute',
        top: '24px',
        right: '40px',
    }
}));

export default function LocationBox(props) {
    const { title, summary, url, externalLinkTitle, isMenu } = props;
    const classes = useStyles(props);

    return (
        <div className={clsx(isMenu ? classes.layoutInMenu : classes.layoutOnPage)}>
            <div className={clsx(isMenu ? classes.descriptionBoxInMenu : classes.descriptionBoxOnPage)}>
                {!startsWith(url, 'http') ?
                    <Link to={url} className={'link'}>
                        {isMenu ? <h3>{title}</h3> : <h2>{title}</h2>}
                    </Link>
                    :
                    <a href={url} className={'link'}>
                        {isMenu ? <h3>{title}</h3> : <h2>{title}</h2>}
                    </a>
                }
                <div className={'paragraph2'}>{summary}</div>
            </div>
            {startsWith(url, 'http') &&
            <LocationBoxExternalLink
                className={clsx(isMenu ? classes.externalLinkBoxInMenu : classes.externalLinkBoxOnPage)}
                title={externalLinkTitle} url={url}/>}
        </div>
    );
}
