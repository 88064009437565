import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {ROUTES_ANP} from 'shared';
import TextFieldComponent from '../../controls/TextFieldComponent';
import CheckboxComponent from '../../controls/CheckboxComponent';
import PrimaryBasicButton from '../../buttons/PrimaryBasicButton';
import {MessageBar} from 'client_shared/src/components/errors/MessageBar';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useDispatch, useSelector} from 'react-redux';
import {userActions} from 'client_shared/src/state/actions';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        backgroundColor: '#FAF4ED',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
        '&.padding': {
            '@media (max-width:1279px)': {
                paddingRight: '0px',
                paddingLeft: '0px',
            }
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '0px',
        paddingBottom: '0px',
        [theme.breakpoints.up('sm')]: {
            '& > * + *': {
                marginLeft: '10px',
            },
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            '& > * + *': {
                marginLeft: '10px',
            },
            flexDirection: 'row',
            paddingTop: '24px',
            paddingBottom: '24px',
        }
    },
    explanationBox: {
        display: 'flex',
        flexDirection: 'column',
        '&.padding': {
            paddingTop: '32px',
            paddingBottom: '24px',
            [theme.breakpoints.up('sm')]: {
                paddingTop: '35px',
                paddingRight: '0px',
                paddingBottom: '35px',
            },
            [theme.breakpoints.up('lg')]: {
                padding: '15px 0px 15px 0px',
            }
        },
    },
    explanationBoxTopBar: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        paddingBottom: '16px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: '0px',
        }

    },
    descriptionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '16px',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '48px',
            width: '480px',
        }
    },
    summary: {
        '&.paragraph2': {
            color: '#5C5C66',
        },
    },
    divider: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'box',
            marginRight: '28px',
            minWidth: '4px',
            borderRadius: '2px',
            backgroundColor: '#D9D9D9',
        }
    },
    summaryBlockTop: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            marginTop: '14.2px',
            display: 'flex',
        }
    },
    summaryBlockBottom: {
        display: 'block',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    envelopeCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: '#FFFFFF',
        borderRadius: '64px',
        minHeight: '64px',
        minWidth: '64px',
        [theme.breakpoints.up('lg')]: {
            borderRadius: '175px',
            minHeight: '175px',
            minWidth: '175px',
        }
    },
    envelopeWrapper: {
        position: 'relative',
    },
    envelope: {
        width: '28.31px',
        height: '20.03px',
        [theme.breakpoints.up('lg')]: {
            height: '54.77px',
            width: '77.4px',
        }
    },
    redDot: {
        position: 'absolute',
        backgroundColor: '#D93616',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            top: '-2px',
            right: '-2px',
            borderRadius: '5px',
            width: '5px',
            height: '5px',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            top: '-4px',
            right: '-4px',
            borderRadius: '14.60px',
            height: '14.60px',
            width: '14.50px',
        }
    },
    subscriptionBox: {
        backgroundColor: '#F2E0CE',
        [theme.breakpoints.up('lg')]: {
            borderRadius: '12px',
            width: '380px',
        },
        '&.padding': {
            paddingTop: '24px',
            paddingBottom: '32px',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '24px',
                paddingBottom: '24px',
            },
            [theme.breakpoints.up('lg')]: {
                paddingRight: '24px',
            }
        },
    },
    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    formItem: {
        '&:not(:last-child)': {
            marginBottom: '18px',
        }
    }
}));


function NewsletterForm(props) {
    const { handleSubmit } = props;

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    function onRulesApprovedChange(event) {
        props.setFieldValue('rulesApproved', event.target.checked);
    }

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <TextFieldComponent inputId='footerBarNewsLetter-emailAddress' className={classes.formItem} placeholder={t('Text.NewsletterEmailPlaceholder')}
                                propertyName={'emailAddress'} {...props} ariaLabel={t('Text.EmailAddress')}/>
            <CheckboxComponent inputId='footerBarNewsLetter-emailAddress' className={classes.formItem} propertyName={'rulesApproved'}
                               onChange={onRulesApprovedChange}
                               label={`${t('Text.NewsletterGDPR')} <a href='${getPathWithLanguage(ROUTES_ANP.PRIVACY_POLICY, i18n.language)}'>${t('Text.More')}</a>`} {...props}/>
            <PrimaryBasicButton className={classes.formItem} title={t('Button.SubscribeToNewsletter')}
                                variant={'normal'} type={'submit'}/>
        </form>
    )
}

export default function FooterBarNewsletter() {
    const classes = useStyles();
    const [successMessageOpen, setSuccessMessageOpen] = useState(false);
    const newsletterSubscribed = useSelector(state => state.user.newsletterSubscribed);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    function validate(values) {
        let errors = {};
        const requiredFields = ['emailAddress'];
        requiredFields.forEach(field => {
            if (!values[field] && values[field] !== 0) {
                errors[field] = t('Form.Mandatory')
            }
        });
        if (
            values.emailAddress &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
        ) {

            errors.emailAddress = t('Form.InvalidEmail');
        }
        if (!values.rulesApproved) {

            errors.rulesApproved = t('Form.RuleApprovalMandatory');
        }
        return errors;
    }

    async function onSubmit(values, { setSubmitting, resetForm }) {
        dispatch(userActions.subscribeToNewsletter(values.emailAddress));
        setSuccessMessageOpen(true);
        resetForm();
        setSubmitting(false);
    }

    function handleSuccessMessageClose() {
        setSuccessMessageOpen(false);
    }

    return (
        <>
            {!newsletterSubscribed &&
            <div className={clsx('padding', classes.layout)}>
                <div className={classes.content}>
                    <div className={clsx('padding', classes.explanationBox)}>
                        <div className={classes.explanationBoxTopBar}>
                            <div className={classes.envelopeCircle}>
                                <div className={clsx(classes.envelopeWrapper, classes.envelope)}>
                                    <img className={classes.envelope} src={'/assets/envelope.png'} alt={''} aria-hidden={true} tabIndex={-1}/>
                                    <div className={classes.redDot}/>
                                </div>
                            </div>
                            <div className={classes.descriptionWrapper}>
                                <h2>
                                    {t('Text.NewsletterCallTitle')}
                                </h2>
                                <div className={classes.summaryBlockTop}>
                                    <div className={classes.divider}/>
                                    <div className={clsx('paragraph2', classes.summary)}>
                                        {t('Text.NewsletterCallSummary')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.summaryBlockBottom}>
                            <div className={clsx('paragraph2', classes.summary)}>
                                {t('Text.NewsletterCallSummary')}
                            </div>
                        </div>
                    </div>
                    <div className={clsx('padding', classes.subscriptionBox)}>
                        <Formik
                            initialValues={{ emailAddress: '', rulesApproved: false }}
                            validate={validate}
                            onSubmit={onSubmit}
                        >
                            {(props) => (
                                <NewsletterForm {...props}/>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            }
            {successMessageOpen &&
            <MessageBar message={t('Text.NewsletterSubscriptionSucceeded')} hasCloseIcon={false}
                        onClose={handleSuccessMessageClose}/>}
        </>
    );
}

