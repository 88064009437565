import React from 'react';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import clsx from 'clsx';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import BadgeList from '../../../badge/lists/badgeList/BadgeList';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            width: '364px',
            height: '100%',
        }
    },
    subNewsBlock: {
        flexDirection: 'row',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    subNewsItem: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            flexBasis: '100%',
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
        },
    },
    subNews: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            padding: '28px 20px 28px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px 32px 27px 32px',
        },
    },
    subNewsTitle: {
        color: '#17171A',
        padding: '16px 0px 16px 0px',
    },
    subNewsSummary: {
        '&.paragraph3': {
            color: '#5C5C66',
        },
        '& > p': {
            margin: '0px',
        }
    },
    sectionDivider: {
        [theme.breakpoints.up('sm')]: {
            margin: '16px 0px 16px 0px',
            borderRight: '1px solid #D9D9D9',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '0px 24px 0px 40px',
            borderBottom: '1px solid #D9D9D9',
            width: 'calc(100% - 64px)',
        },
    },
    dividerBottom: {
        borderBottom: '2px solid #F2F2F2',
    },
    earlierNews: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        paddingTop: '24px',
        paddingBottom: '26px',
        backgroundColor: '#F2F2F2',
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
            paddingTop: '12px',
            paddingBottom: '26px',
            paddingLeft: '0px',
            paddingRight: '0px',
            backgroundColor: theme.palette.background.default,
        },
        [theme.breakpoints.up('lg')]: {
            alignItems: 'flex-start',
            paddingTop: '30px',
            paddingRight: '32px',
            paddingBottom: '30px',
            paddingLeft: '32px',
            backgroundColor: theme.palette.background.default,
        },
        [theme.breakpoints.up('xl')]: {
            alignItems: 'flex-start',
            paddingTop: '30px',
            paddingRight: '32px',
            paddingBottom: '30px',
            paddingLeft: '32px',
            backgroundColor: theme.palette.background.default,
        }
    },
    link: {
        textDecoration: 'none',
    }
}));

export default function TopNewsList(props) {
    const { homePageNews } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const mobileScreen = useMediaQuery('(max-width:759px)');

    return (
        <div className={classes.layout}>
            <div className={classes.subNewsBlock}>
                {homePageNews && homePageNews.map((news, index) =>
                    <React.Fragment key={news.id}>
                        <div className={classes.subNewsItem}>
                            <div className={classes.subNews}>
                                <BadgeList badgeIds={news.badgeIds}/>
                                <Link to={getPathWithLanguage(ROUTES_ANP.NEWS_ARTICLE(news.id), i18n.language)}
                                      className={classes.link}>
                                    <h5 className={classes.subNewsTitle}>
                                        {news.title}
                                    </h5>
                                </Link>
                                <div className={clsx('paragraph3', classes.subNewsSummary)}
                                     dangerouslySetInnerHTML={createMarkup(news.description)}/>
                            </div>
                        </div>
                        {index < homePageNews.length - 1 &&
                        <div className={classes.sectionDivider}/>
                        }
                    </React.Fragment>
                )}
            </div>
            <div className={clsx(mobileScreen ? 'padding-left' : null, classes.earlierNews)}>
                <Link to={getPathWithLanguage(ROUTES_ANP.NEWS, i18n.language)}
                      className={'linkBig'}>{t('Text.EarlierNews')}</Link>
            </div>
            <div className={classes.dividerBottom}/>
        </div>
    );
}
