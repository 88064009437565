import React from 'react';
import {makeStyles} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {Link} from 'react-router-dom';
import {contentModule} from 'shared/src/modules/content';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoBlock: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '12px',
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url('/content/images/${props.program.headerImage}')`,
        padding: '0px 0px 16px 24px',
        [theme.breakpoints.up('lg')]: {
            padding: '0px 0px 24px 32px',
        },
    }),
    title: {
        color: theme.palette.common.white,
        paddingBottom: '11px',
    },
    summaryBlock: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    icon: {
        color: '#B0E645',
        fontWeight: 'bold',
    },
    summary: {
        '&.paragraph2': {
            color: theme.palette.common.white,
        },
        display: 'flex',
        marginLeft: '4px',
    },
    link: {
        textDecoration: 'none',
    }
}));

export default function OfferedProgramListItem(props) {
    const { program } = props;
    const classes = useStyles(props);

    const { i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <div className={classes.infoBlock}>
                <Link to={contentModule.getContentUrl(program, i18n.language)} className={classes.link} tabIndex={-1}>
                    <h3 className={classes.title}>
                        {program.title}
                    </h3>
                </Link>
                <Link to={contentModule.getContentUrl(program, i18n.language)} className={classes.link}>
                    <div className={classes.summaryBlock}>
                        <ArrowForwardIcon className={classes.icon}/>
                        <div className={clsx('paragraph2', classes.summary)}
                             dangerouslySetInnerHTML={createMarkup(program.summary)}/>
                    </div>
                </Link>
            </div>
        </div>
    );
}
