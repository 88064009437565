import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import CaveToursIcon from '../../../../icons/multicolor/LatnivalokBarlangiTurakIcon';
import TrailsIcon from '../../../../icons/multicolor/LatnivalokGyalogturakAFelszinenIcon';
import CycleTrailsIcon from '../../../../icons/multicolor/LatnivalokKerekparosTurautvonalakIcon';
import HorseToursIcon from '../../../../icons/multicolor/LatnivalokHuculMenesIcon';
import CustomProgramsIcon from '../../../../icons/multicolor/LatnivalokSajatSzervezesuProgramokIcon';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {useSelector} from 'react-redux';
import {pageModule} from 'shared/src/modules/page';
import {PAGE} from 'shared/src/constants/pages';
import ToursIconListItem from './ToursIconListItem';

const useStyles = makeStyles(theme => ({
    layoutOnPage: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-20px',
        marginTop: '-20px',
        '& > *': {
            marginLeft: '20px',
            marginTop: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-30px',
            marginTop: '-30px',
            '& > *': {
                marginLeft: '30px',
                marginTop: '30px',
            },
        }
    },
    layoutInMenu: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '-8px',
        '& > *': {
            marginTop: '8px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-10px',
            '& > *': {
                marginLeft: '10px',
            },
            flexDirection: 'row',
        }
    },
    layoutInBanner: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-8px',
        '& > *': {
            marginTop: '8px',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: '-10px',
            '& > *': {
                flex: '0 0 calc(33.333% - 10px)',
                marginLeft: '10px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '0px',
            marginLeft: '-30px',
            '& > *': {
                flex: 1,
                marginTop: '0px',
                marginLeft: '30px',
            },
            flexDirection: 'row',
        }
    },
    title: {
        lineHeight: '36.4px',
        marginTop: '32px',
        marginBottom: '16px',
        [theme.breakpoints.up('sm')]: {
            lineHeight: '42px',
            marginTop: '48px',
            marginBottom: '36px',
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight: '44.8px',
            marginTop: '62px',
            marginBottom: '48px',
        }
    }
}));


export default function ToursIconList(props) {
    const { title, isMenu, isBanner } = props;
    const classes = useStyles();
    const pages = useSelector(state => state.page.pages);
    const [pageMap, setPageMap] = useState({
        [PAGE.TYPE.CAVE_TOURS]: {},
        [PAGE.TYPE.ADVANCED_CAVE_TOURS]: {},
        [PAGE.TYPE.WALKING_TOURS]: {},
        [PAGE.TYPE.CYCLING_TOURS]: {},
        [PAGE.TYPE.HORSE_TOURS]: {},
        [PAGE.TYPE.OTHER_PROGRAMMES]: {}
    });

    const { i18n } = useTranslation();

    useEffect(() => {
        if (pages.length === 0) {
            return;
        }

        const pageMap = {};

        [
            PAGE.TYPE.CAVE_TOURS,
            PAGE.TYPE.ADVANCED_CAVE_TOURS,
            PAGE.TYPE.WALKING_TOURS,
            PAGE.TYPE.CYCLING_TOURS,
            PAGE.TYPE.HORSE_TOURS,
            PAGE.TYPE.OTHER_PROGRAMMES
        ].forEach((pageType => {
            pageMap[pageType] = pageModule.getPageByType(pages, pageType);
        }));

        setPageMap(pageMap);
    }, [pages]);


    return (
        <>
            {title &&
            <h2 className={classes.title}>
                {title}
            </h2>
            }
            <div className={isMenu ? classes.layoutInMenu : (isBanner ? classes.layoutInBanner : classes.layoutOnPage)}>
                <ToursIconListItem
                    Icon={CaveToursIcon}
                    title={pageMap[PAGE.TYPE.CAVE_TOURS].name}
                    url={getPathWithLanguage(pageMap[PAGE.TYPE.CAVE_TOURS].url, i18n.language)}
                    isMenu={isMenu}
                    isBanner={isBanner}
                />
                {/*
                <ToursIconListItem
                    Icon={CaveToursAdvancedIcon}
                    title={pageMap[PAGE.TYPE.ADVANCED_CAVE_TOURS].name}
                    url={getPathWithLanguage(pageMap[PAGE.TYPE.ADVANCED_CAVE_TOURS].url, i18n.language)}
                    isMenu={isMenu}
                    isBanner={isBanner}
                />
*/}
                <ToursIconListItem
                    Icon={TrailsIcon}
                    title={pageMap[PAGE.TYPE.WALKING_TOURS].name}
                    url={getPathWithLanguage(pageMap[PAGE.TYPE.WALKING_TOURS].url, i18n.language)}
                    isMenu={isMenu}
                    isBanner={isBanner}
                />
                <ToursIconListItem
                    Icon={CycleTrailsIcon}
                    title={pageMap[PAGE.TYPE.CYCLING_TOURS].name}
                    url={getPathWithLanguage(pageMap[PAGE.TYPE.CYCLING_TOURS].url, i18n.language)}
                    isMenu={isMenu}
                    isBanner={isBanner}
                />
                <ToursIconListItem
                    Icon={HorseToursIcon}
                    title={pageMap[PAGE.TYPE.HORSE_TOURS].name}
                    url={getPathWithLanguage(pageMap[PAGE.TYPE.HORSE_TOURS].url, i18n.language)}
                    isMenu={isMenu}
                    isBanner={isBanner}
                />
                <ToursIconListItem
                    Icon={CustomProgramsIcon}
                    title={pageMap[PAGE.TYPE.OTHER_PROGRAMMES].name}
                    url={getPathWithLanguage(pageMap[PAGE.TYPE.OTHER_PROGRAMMES].url, i18n.language)}
                    isMenu={isMenu}
                    isBanner={isBanner}
                />
            </div>
        </>
    );
}
