import * as React from "react";

function SvgWheelchair(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M54.2 35.1v-6.2c-4.9.1-9.8-2.3-12.9-5.7l-4.1-4.4c-.5-.6-1.2-1.1-1.9-1.4h-.1c-1.1-.6-2.4-.9-3.8-.8-3.3.3-5.7 3.2-5.7 6.4v18.3c0 3.4 2.9 6.2 6.3 6.2h15.9V63h6.3V45.9c0-3.4-2.9-6.2-6.3-6.2h-9.5V29.1c4 3.3 10.3 6 15.8 6zm-31.7-2.8v6.4c-3.7 1.3-6.3 4.7-6.3 8.8 0 5.1 4.3 9.3 9.5 9.3 4.2 0 7.7-2.6 9-6.2h6.6c-1.6 7.1-8 12.4-15.6 12.4-8.8 0-15.9-6.9-15.9-15.5 0-7.5 5.5-13.8 12.7-15.2zM32 1c3.5 0 6.3 2.8 6.3 6.2s-2.8 6.2-6.3 6.2-6.3-2.8-6.3-6.2S28.5 1 32 1z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgWheelchair;
