import React from 'react';
import {makeStyles} from '@material-ui/core';
import BadgeList from '../../../badge/lists/badgeList/BadgeList';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {ROUTES_ANP} from 'shared/src/constants/routes';
import {useTranslation} from 'react-i18next';
import {getPathWithLanguage} from 'shared/src/helpers/pathHelper';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: {
        border: '2px solid #F2F2F2',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        padding: '16px 16px 24px 16px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '32px',
        },
    },
    title: {
        marginTop: '24px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '16px',
        },
    },
    subTitle: {
        '&.paragraph4': {
            color: '#5C5C66',
        },
    },
    summary: {
        '&.paragraph2': {
            color: '#5C5C66',
        },
        '& > p': {
            margin: '0px',
        },
        marginTop: '12px',
    },
    read: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '24px',
        },
    }
}));

export default function SearchResultNewsListItem(props) {
    const { news } = props;
    const classes = useStyles();

    const { t, i18n } = useTranslation();

    return (
        <div className={classes.layout}>
            <BadgeList badgeIds={news.badgeIds}/>
            <div className={classes.leftLayout}>
                <h3 className={classes.title}>
                    {news.title}
                </h3>
                <div className={clsx('paragraph4', classes.subTitle)}>
                    {news.subTitle}
                </div>
                <div className={clsx('paragraph2', classes.summary)}
                     dangerouslySetInnerHTML={createMarkup(news.summary)}/>
                <Link to={getPathWithLanguage(ROUTES_ANP.NEWS_ARTICLE(news.id), i18n.language)}
                      className={clsx('linkSecondary', classes.read)}>
                    {t('Text.Read')}
                </Link>
            </div>
        </div>
    );
}
