export default {
    '& .imagesWrapper': {
        '@media (min-width:760px)': {
            padding: '48px 0px',
        },
        '@media (min-width:1280px)': {
            padding: '48px 0px',
        },
    },
};
