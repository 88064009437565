import React from 'react';
import {makeStyles} from '@material-ui/core';
import {styleConstants} from '../../styles/styles';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mapLayout: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: `calc(100% - ${styleConstants.headerBar.mobileHeight}px)`,
        [theme.breakpoints.up('md')]: {
            height: `calc(100% - ${styleConstants.headerBar.tabletHeight}px)`,
        },
        [theme.breakpoints.up('lg')]: {
            height: `calc(100% - ${styleConstants.headerBar.desktopHeight}px)`,
        },
    },
    gap: {
        height: styleConstants.headerBar.mobileHeight,
        [theme.breakpoints.up('sm')]: {
            height: styleConstants.headerBar.tabletHeight,
        },
        [theme.breakpoints.up('lg')]: {
            height: styleConstants.headerBar.desktopHeight,
        },
    },
}));

export default function SecondaryScreen(props) {
    const { children } = props;
    const classes = useStyles();

    return (
        <main className={classes.layout}>
            {children}
        </main>
    );
}
