import * as React from "react";

function SvgChevronDown(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <path
                d="M32 37.9l22.9-23.3c2.1-2.1 5.5-2.1 7.5 0 2.1 2.1 2.1 5.6 0 7.7L35.8 49.4c-2.1 2.1-5.5 2.1-7.5 0L1.6 22.3c-2.1-2.1-2.1-5.6 0-7.7s5.5-2.1 7.5 0L32 37.9z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "#17171a"}
            />
        </svg>
    );
}

export default SvgChevronDown;
