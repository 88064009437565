import React from 'react';
import MainScreen from "../components/pages/MainScreen";
import PaymentLandingContent from '../components/checkout/payment/PaymentLandingContent';

export default function PaymentLanding() {
    return (
        <MainScreen>
            <PaymentLandingContent/>
        </MainScreen>
    );
}
