import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {createMarkup} from 'shared/src/helpers/componentHelper';

const useStyles = makeStyles(theme => ({
    layout: props => ({
        width: '100%',
        height: '350px',
        marginTop: '0px',
        [theme.breakpoints.up('sm')]: {
            height: '400px',
            marginTop: '16px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '500px',
            marginTop: '24px',
        },
        backgroundImage: `linear-gradient(180deg, rgba(46,46,51,0) 0%, rgba(23,23,26,0.7) 100%), url("/content/images/${props.content.headerImage}")`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    }),
    descriptionsLayout: {
        width: '100%',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        '&.padding': {
            paddingBottom: '24px',
            [theme.breakpoints.up('sm')]: {
                paddingBottom: '34px',
            },
            [theme.breakpoints.up('lg')]: {
                paddingBottom: '64px',
            },
        }
    },
    lowerBlockLayout: {
        marginTop: '16px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '48px',
        },
    },
    title: {
        color: theme.palette.common.white,
        width: '100%',
        fontSize: '32px',
        lineHeight: '38.4px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '42px',
            lineHeight: '50.4px',
            width: '580px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '687px',
            fontSize: '64px',
            lineHeight: '76.8px',
        },
    },
    titlePadding: {
        paddingTop: '12px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: '16px 0px 25px 0px',
            width: '460px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '24px 0px 32px 0px',
            width: '790px',
        },
    },
    summaryBlock: {
        display: 'flex',
        marginBottom: '24px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '32px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '32px',
        },
    },
    leftBar: {
        width: '4px',
        borderRadius: '2px',
        backgroundColor: '#F2CA00',
    },
    summary: {
        '&.paragraph1': {
            color: theme.palette.common.white,
            lineHeight: '25.6px',
            [theme.breakpoints.up('sm')]: {
                lineHeight: '25.6px',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '24px',
                lineHeight: '38.4px',
            }
        },
        paddingLeft: '12px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '32px',
        },
        '& > p': {
            margin: '0px',
            '&:not(:last-child)': {
                margin: '0px 0px 16px 0px',

            }
        }
    },
    summaryAlignment: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '552px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '651px',
        },
    },
}));

export default function MicroSiteHeader(props) {
    const { className, content, microSite } = props;
    const classes = useStyles(props);

    return (
        <div className={clsx(classes.layout, className)}>
            <div className={clsx('padding', classes.descriptionsLayout)}>
                <h1 className={classes.title}>
                    {(content.title && content.title !== '') ? content.title : microSite.name}
                </h1>
                <div className={classes.lowerBlockLayout}>
                    <div className={classes.summaryBlock}>
                        <div className={classes.leftBar}/>
                        <div className={clsx('paragraph1', classes.summary, classes.summaryAlignment)}
                             dangerouslySetInnerHTML={createMarkup(content.summary)}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
