export default {
    '& ul': {
        '@media (min-width:760px)': {
            padding: '0px 0px 0px 20px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px 0px 20px',
        },
    },
    '& ol': {
        '@media (min-width:760px)': {
            padding: '0px 0px 0px 20px',
        },
        '@media (min-width:1280px)': {
            padding: '0px 0px 0px 20px',
        },
    },
};
