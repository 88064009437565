import * as React from "react";

function SvgTicket(props) {
    return (
        <svg viewBox="0 0 64 64" {...props}>
            <circle cx={32} cy={32} r={32} fill="#f2ca00"/>
            <path
                d="M46 11.5c0-1.4 1.1-2.5 2.5-2.5h5.7c1.6 1.5 3 3.2 4.2 5h-10c-1.3 0-2.4-1.1-2.4-2.5zM62.7 23c-.5-1.7-1.2-3.4-1.9-5H56c-1.4 0-2.5 1.1-2.5 2.5S54.6 23 56 23h6.7zM17 51.5H6.6c1.4 1.8 3 3.5 4.8 5H17c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5zm-5-6c0-1.4-1.1-2.5-2.5-2.5H1.9c.6 1.7 1.4 3.4 2.4 5h5.2c1.4 0 2.5-1.1 2.5-2.5z"
                fill="#fff"
            />
            <path
                d="M42.7 32c0-1.5 1.2-2.7 2.7-2.7V24c0-1.5-1.2-2.7-2.7-2.7H21.3c-1.5 0-2.7 1.2-2.7 2.7v5.3c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7V40c0 1.5 1.2 2.7 2.7 2.7h21.3c1.5 0 2.7-1.2 2.7-2.7v-5.3c-1.4 0-2.6-1.2-2.6-2.7zm-5.9 6.4L32 35.3l-4.8 3.1 1.4-5.5-4.4-3.6 5.7-.3 2.1-5.3 2.1 5.3 5.7.3-4.4 3.6 1.4 5.5z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#f2a200"
            />
            <path
                d="M42.7 30.7c0-1.5 1.2-2.7 2.7-2.7v-5.3c0-1.5-1.2-2.7-2.7-2.7H21.3c-1.5 0-2.7 1.2-2.7 2.7V28c1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7v5.3c0 1.5 1.2 2.7 2.7 2.7h21.3c1.5 0 2.7-1.2 2.7-2.7v-5.3c-1.4-.1-2.6-1.3-2.6-2.7zm-5.9 6.4L32 34l-4.8 3.1 1.4-5.5-4.3-3.6 5.7-.3 2.1-5.3 2.1 5.3 5.7.3-4.4 3.6 1.3 5.5z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgTicket;
