import React from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import ApartmentPlaceIcon from '../../icons/accommodations/ApartmentPlaceIcon';
import ApartmentCallIcon from '../../icons/accommodations/ApartmentCallIcon';
import ApartmentEmailIcon from '../../icons/accommodations/ApartmentEmailIcon';
import ApartmentWebIcon from '../../icons/accommodations/ApartmentWebIcon';
import ApartmentOpeningIcon from '../../icons/accommodations/ApartmentOpeningIcon';
import {createMarkup} from 'shared/src/helpers/componentHelper';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    layout: {
        width: '100%',
        borderRadius: '12px',
        backgroundColor: '#F2F2F2',
        padding: '16px',
        [theme.breakpoints.up('sm')]: {
            padding: '24px',
        }
    },
    openingHoursBlock: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& > $infoRow': {
            marginTop: '0px',
        }
    },
    infoRow: {
        marginTop: '8px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '12px',
        },
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& > * + *': {
            marginLeft: '9px'
        }
    },
    icon: {
        height: '20px',
        width: '20px',
    },
    infoText: {
        '&.paragraph4': {
            color: '#17171A',
            fontSize: '12px',
        },
        '& > p': {
            margin: '0px',
            padding: '0px',
        }
    },
    openingHoursText: {
        '&.paragraph4': {
            fontWeight: 'bold',
            fontSize: '12px',
        },
    },
    openingHoursRow: {
        marginLeft: '28px',
    },
    location: {
        marginTop: '24px',
    }
}));

export default function AccommodationContactBar(props) {
    const { className, accommodation } = props;
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <div className={clsx(className, classes.layout)}>
            {accommodation.openingHours && accommodation.openingHours !== '' &&
            <div className={classes.openingHoursBlock}>
                <div className={classes.infoRow}>
                    <ApartmentOpeningIcon className={classes.icon}/>
                    <div className={clsx('paragraph4', classes.openingHoursText)}>
                        {t('Text.Open')}
                    </div>
                </div>
                <div className={classes.openingHoursRow}>
                    <div className={clsx('paragraph4', classes.infoText)}
                         dangerouslySetInnerHTML={createMarkup(accommodation.openingHours)}/>
                </div>
            </div>
            }
            <div className={clsx(classes.infoRow, classes.location)}>
                <ApartmentPlaceIcon className={classes.icon}/>
                <div className={clsx('paragraph4', classes.infoText)}>
                    {`${accommodation.locationPostCode} ${accommodation.locationCity}, ${accommodation.locationAddress}`}
                </div>
            </div>
            <div className={classes.infoRow}>
                <ApartmentCallIcon className={classes.icon}/>
                <div className={clsx('paragraph4', classes.infoText)}>
                    {accommodation.phone}
                </div>
            </div>
            <div className={classes.infoRow}>
                <ApartmentEmailIcon className={classes.icon}/>
                <div className={clsx('paragraph4', classes.infoText)}>
                    {accommodation.email}
                </div>
            </div>
            <div className={classes.infoRow}>
                <ApartmentWebIcon className={classes.icon}/>
                <div className={clsx('paragraph4', classes.infoText)}>
                    {accommodation.website}
                </div>
            </div>
        </div>
    );
}
